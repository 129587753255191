import { cancelAnalysis } from '../../../logic/requests/trainings';

export const handleResign = (id, remove) => {
    cancelAnalysis(id)
        .then((res) => {
            if (res.refund_err) {
                // TODO: Coś lepszego tutaj?
                console.error('handleResign', 'Backend returned error during refund');
            }
            remove?.();
        })
        .catch((err) => {
            console.error('handleResign', err);
        });
};

export const download = (link, filename) => {
    const a = document.createElement('a');
    a.href = link;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
};
