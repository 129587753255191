import backendRequest from "./index.js";

const login = (email, pass) =>
    backendRequest("POST", "/users/login", {
        email: email,
        password: pass,
    });

const isLoggedIn = () => backendRequest("POST", "/users/am-i-logged-in", {});

const logout = () => backendRequest("POST", "/users/logout");

const register = (data) => backendRequest("POST", "/users/register", data);

const resendEmail = (data) => backendRequest("POST", "/users/resend-email", data);

const update = (data) => backendRequest("POST", "/users/update", { data });

const verify = (data) => backendRequest("POST", "/users/verify", data);

const changePassword = (oldpassword, newpassword) =>
    backendRequest("POST", "/users/change-password", { data: { oldpassword, newpassword } });

const requestPasswordLink = (email) =>
    backendRequest("GET", `/users/send-password-link?email=${email}`);

const resetPassword = (email, password, token) =>
    backendRequest("POST", `/users/password-reset`, { email, password, token });

const getAvatar = (user_id) => backendRequest("GET", `/users/get-avatar/${user_id}`);

const checkWCA = (wca_id) =>
    backendRequest("GET", `https://www.worldcubeassociation.org/api/v0/persons/${wca_id}`);

const setLang = (lang) => backendRequest("POST", `/users/set-lang`, { lang });

export {
    changePassword,
    checkWCA,
    getAvatar,
    login,
    logout,
    register,
    isLoggedIn,
    requestPasswordLink,
    resetPassword,
    resendEmail,
    setLang,
    update,
    verify,
};
