import styled from 'styled-components';
import { scrollBlueStyles } from '../../../styles/sharedStyles';

export const StyledScrollWrapper = styled.div`
    margin-top: 8px;
    overflow-y: scroll;
    padding-right: 10px;
    padding-bottom: 10px;
    height: calc(100% - 110px);
    width: 100%;

    ${scrollBlueStyles};

    @media only screen and (max-width: 926px) {
        padding: 0 20px;
        overflow-y: visible;
        height: auto;
    }
`;
