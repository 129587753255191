import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';
import { useToggle } from 'react-use';
import { set } from 'date-fns';

import EmployeesDataElement from '../EmployeesDataElement/EmployeesDataElement';

import { StyledEmployeesAllElement } from './EmployeesAllElement.styles';
import { StyledText } from '../../atoms/Text/StyledText';
import {
    StyledHeading,
    StyledArrow,
    StyledHeader,
    StyledHeaderElement,
} from '../../../styles/sharedStyles';
import { StyledHeadingCheckbox } from '../../atoms/SettlementsDoneElement/StyledHeadingCheckbox';
import { StyledButton } from '../../atoms/Button/StyledButton';
import { StyledButtonsWrapper } from '../../atoms/UserPanelParent/StyledButtonsWrapper';

import { getLang, getLocale, getString } from '../../../strings';
import { StyledElements } from '../../atoms/UserPanelStudentsPayments/StyledElements';
import useScrollBar from '../../../logic/hooks/useScrollBar';
import downloadFilePost from '../../../utils/downloadFilePost';

const currentMonth = set(new Date(), { date: 1, hours: 0, minutes: 0, seconds: 0 });

const EmployeesAllElement = ({ month, hours, defaultOpen }) => {
    const title = useMemo(
        () =>
            new Date(month).toLocaleDateString(getLocale(getLang()), {
                year: 'numeric',
                month: 'long',
            }),
        [month]
    );
    const isMonthSubmitted = useMemo(
        () =>
            hours
                .filter(({ contract }) => contract && contract !== 'error')
                .every(({ has_record }) => has_record),
        [hours]
    );

    const [isOpen, toggleIsOpen] = useToggle(defaultOpen);
    const { scrollElement, isScrollBar } = useScrollBar(false, true);

    const [isCheckbox, toggleIsCheckbox] = useToggle(false);
    const canSelect = useMemo(
        () => Boolean(hours.filter(({ contract }) => contract && contract !== 'error').length),
        [hours]
    );

    const [checkedHours, setCheckedHours] = useState([]);
    const toggleHourChecked = useCallback(
        (hour) =>
            setCheckedHours((hours) =>
                hours.some(
                    ({ employee_id, contract_id }) =>
                        hour.employee_id === employee_id && hour.contract_id === contract_id
                )
                    ? hours.filter(
                          ({ employee_id, contract_id }) =>
                              !(
                                  hour.employee_id === employee_id &&
                                  hour.contract_id === contract_id
                              )
                      )
                    : hours.concat(hour)
            ),
        [setCheckedHours]
    );

    useEffect(() => {
        if (!isCheckbox) setCheckedHours((hours) => (hours.length ? [] : hours));
    }, [isCheckbox, setCheckedHours]);

    const recordsToDownload = useMemo(
        () =>
            isCheckbox
                ? checkedHours.map(({ employee_id }) => employee_id).filter((val) => val)
                : hours.filter(({ has_record }) => has_record).map(({ trainer: { id } }) => id),
        [isCheckbox, checkedHours, hours]
    );

    const canDownloadRecords = useMemo(
        () => Boolean(recordsToDownload.length),
        [recordsToDownload]
    );

    const downloadRecords = useCallback(() => {
        if (!recordsToDownload.length) return;

        return downloadFilePost('/employees/admin/get-hours-records', {
            ids: recordsToDownload,
            month,
        });
    }, [recordsToDownload, month]);

    const contractsToDownload = useMemo(
        () =>
            isCheckbox
                ? checkedHours.map(({ contract_id }) => contract_id).filter((val) => val)
                : hours
                      .filter(({ contract }) => contract && contract !== 'error')
                      .map(({ contract: { id } }) => id),
        [isCheckbox, checkedHours, hours]
    );

    const canDownloadContracts = useMemo(
        () => Boolean(contractsToDownload.length),
        [contractsToDownload]
    );

    const downloadContracts = useCallback(() => {
        if (!contractsToDownload.length) return;

        return downloadFilePost('/employees/admin/get-contracts', {
            ids: contractsToDownload,
            month,
        });
    }, [contractsToDownload, month]);

    const isRedColor = useMemo(() => {
        const monthDate = new Date(month);

        return monthDate.getTime() < currentMonth.getTime() && !isMonthSubmitted;
    }, [month, isMonthSubmitted]);

    return (
        <StyledEmployeesAllElement hasdeclaredbordercolor={isRedColor} isopen={isOpen}>
            <StyledHeading onClick={toggleIsOpen}>
                <StyledText
                    hasdeclaredfontsize="22px"
                    hasdeclaredfontweight="700"
                    hasdeclaredtextalign="center"
                    hasdeclaredtexttransform="uppercase"
                    hasdeclaredfontcolor="#6786BE"
                >
                    {title}
                </StyledText>
                <StyledHeadingCheckbox>
                    <StyledArrow isopen={isOpen} />
                </StyledHeadingCheckbox>
            </StyledHeading>
            {isOpen ? (
                <StyledElements>
                    <ScrollSync>
                        <>
                            <ScrollSyncPane>
                                <StyledHeader
                                    isscrollbar={isScrollBar}
                                    ref={scrollElement}
                                    hasalignself={isCheckbox ? 'flex-end' : 'flex-start'}
                                    $isCheckbox={isCheckbox}
                                >
                                    <div>
                                        <StyledHeaderElement $hasWidth="25%">
                                            <StyledText
                                                hasdeclaredfontsize="16px"
                                                hasdeclaredfontweight="700"
                                                hasdeclaredtextalign="center"
                                                hasdeclaredlineheight="1.4em"
                                            >
                                                {getString('User_panel_employess_pracownik')}
                                            </StyledText>
                                        </StyledHeaderElement>
                                        <StyledHeaderElement $hasWidth="15%">
                                            <StyledText
                                                hasdeclaredfontsize="16px"
                                                hasdeclaredfontweight="700"
                                                hasdeclaredtextalign="center"
                                                hasdeclaredlineheight="1.4em"
                                            >
                                                {getString('User_panel_employess_godziny')}
                                            </StyledText>
                                        </StyledHeaderElement>
                                        <StyledHeaderElement $hasWidth="15%">
                                            <StyledText
                                                hasdeclaredfontsize="16px"
                                                hasdeclaredfontweight="700"
                                                hasdeclaredtextalign="center"
                                                hasdeclaredlineheight="1.4em"
                                            >
                                                {getString('User_panel_employess_wypłata')}
                                            </StyledText>
                                        </StyledHeaderElement>
                                        <StyledHeaderElement $hasWidth="20%">
                                            <StyledText
                                                hasdeclaredfontsize="16px"
                                                hasdeclaredfontweight="700"
                                                hasdeclaredtextalign="center"
                                                hasdeclaredlineheight="1.4em"
                                            >
                                                {getString('User_panel_employess_ewidencja')}
                                            </StyledText>
                                        </StyledHeaderElement>
                                        <StyledHeaderElement $hasWidth="25%">
                                            <StyledText
                                                hasdeclaredfontsize="16px"
                                                hasdeclaredfontweight="700"
                                                hasdeclaredtextalign="center"
                                                hasdeclaredlineheight="1.4em"
                                            >
                                                {getString('User_panel_employess_umowa')}
                                            </StyledText>
                                        </StyledHeaderElement>
                                    </div>
                                </StyledHeader>
                                {hours.map(
                                    ({
                                        trainer: { id, firstname, lastname },
                                        hours,
                                        price_trainer,
                                        has_record,
                                        contract,
                                    }) => (
                                        <EmployeesDataElement
                                            key={id}
                                            isCheckbox={isCheckbox && (has_record || contract?.id)}
                                            employee_id={id}
                                            employee={`${firstname} ${lastname}`}
                                            hours={hours}
                                            payment={price_trainer}
                                            isSubmitted={has_record}
                                            contract={contract}
                                            month={month}
                                            toggleChecked={() =>
                                                toggleHourChecked({
                                                    employee_id: has_record ? id : undefined,
                                                    contract_id: contract?.id,
                                                })
                                            }
                                        />
                                    )
                                )}
                            </ScrollSyncPane>
                        </>
                    </ScrollSync>
                    {canSelect || canDownloadRecords || canDownloadContracts ? (
                        <StyledButtonsWrapper bottomsize>
                            {canSelect ? (
                                <StyledButton
                                    hasdeclaredbgcolor="#ee745c"
                                    hasdeclaredpadding="0 28px"
                                    hasdeclaredfontcolor="#fff"
                                    hasdeclaredborderradius="25px"
                                    hasdeclaredfontweight="700"
                                    hasdeclaredtextalign="center"
                                    hasdeclaredcursor="pointer"
                                    hasdeclaredfontsize="18px"
                                    hasdeclaredtexttransform="uppercase"
                                    onClick={toggleIsCheckbox}
                                    className="hover-red-effect"
                                >
                                    {getString(
                                        isCheckbox
                                            ? 'User_panel_employess_anuluj'
                                            : 'User_panel_employess_wybierz'
                                    )}
                                </StyledButton>
                            ) : null}
                            {canDownloadRecords ? (
                                <StyledButton
                                    hasdeclaredbgcolor="#6786BE"
                                    hasdeclaredpadding="0 28px"
                                    hasdeclaredfontcolor="#fff"
                                    hasdeclaredborderradius="25px"
                                    hasdeclaredfontweight="700"
                                    hasdeclaredtextalign="center"
                                    hasdeclaredcursor="pointer"
                                    hasdeclaredfontsize="18px"
                                    hasdeclaredtexttransform="uppercase"
                                    whileHover={{ scale: 0.95 }}
                                    onClick={downloadRecords}
                                >
                                    {getString('User_panel_employess_pobierz_ewidencje')}
                                </StyledButton>
                            ) : null}
                            {canDownloadContracts ? (
                                <StyledButton
                                    hasdeclaredbgcolor="#6786BE"
                                    hasdeclaredpadding="0 28px"
                                    hasdeclaredfontcolor="#fff"
                                    hasdeclaredborderradius="25px"
                                    hasdeclaredfontweight="700"
                                    hasdeclaredtextalign="center"
                                    hasdeclaredcursor="pointer"
                                    hasdeclaredfontsize="18px"
                                    hasdeclaredtexttransform="uppercase"
                                    onClick={downloadContracts}
                                    whileHover={{ scale: 0.95 }}
                                >
                                    {getString('User_panel_employess_pobierz_umowy')}
                                </StyledButton>
                            ) : null}
                        </StyledButtonsWrapper>
                    ) : null}
                </StyledElements>
            ) : null}
        </StyledEmployeesAllElement>
    );
};

export default EmployeesAllElement;
