import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';

import AdditionDataElement from '../AdditionDataElement/AdditionDataElement';

import { StyledUserPanelAdditionElement } from './UserPanelAdditionElement.styles';
import {
    StyledArrow,
    StyledHeading,
    StyledHeader,
    StyledHeaderElement,
} from '../../../styles/sharedStyles';
import { StyledText } from '../../atoms/Text/StyledText';
import { StyledHeadingCheckbox } from '../../atoms/SettlementsDoneElement/StyledHeadingCheckbox';
import { StyledElements } from '../../atoms/UserPanelStudentsPayments/StyledElements';

import { getLang, getLocale, getString } from '../../../strings';
import useScrollBar from '../../../logic/hooks/useScrollBar';
import { listTrainers } from '../../../logic/requests/students';
import { cebulaCompare } from '../../../logic/arrays';

const UserPanelAdditionElement = ({ month, additions, trainers }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [allTrainers, setAllTrainers] = useState();

    const title = useMemo(
        () =>
            new Date(month).toLocaleDateString(getLocale(getLang()), {
                year: 'numeric',
                month: 'long',
            }),
        [month]
    );

    const { scrollElement, isScrollBar } = useScrollBar(false, true);

    const handleOpen = () => {
        setIsOpen(!isOpen);
    };

    const loadStudents = useCallback(() => {
        listTrainers()
            .then((res) => {
                setAllTrainers(
                    res
                        .map((student) => ({
                            ...student,
                            name: `${student.lastname} ${student.firstname}`,
                        }))
                        .sort((a, b) => cebulaCompare(a.name, b.name))
                );
            })
            .catch((err) => {
                console.error('listTrainers', err);
            });
    }, [setAllTrainers]);

    useEffect(() => {
        loadStudents();
    }, []);

    return (
        <StyledUserPanelAdditionElement isopen={isOpen}>
            <StyledHeading onClick={handleOpen}>
                <StyledText
                    hasdeclaredfontsize="22px"
                    hasdeclaredfontweight="700"
                    hasdeclaredtextalign="center"
                    hasdeclaredtexttransform="uppercase"
                    hasdeclaredfontcolor="#6786BE"
                >
                    {title}
                </StyledText>
                <StyledHeadingCheckbox>
                    <StyledArrow isopen={isOpen} />
                </StyledHeadingCheckbox>
            </StyledHeading>
            {isOpen ? (
                <StyledElements>
                    <ScrollSync>
                        <>
                            <ScrollSyncPane>
                                {!additions ? (
                                    <StyledHeader
                                        isscrollbar={isScrollBar}
                                        ref={scrollElement}
                                        hasalignself="flex-start"
                                        $isCheckbox
                                        $isCheckboxMinusWidth={104}
                                    >
                                        <div>
                                            <StyledHeaderElement $hasWidth="50%">
                                                <StyledText
                                                    hasdeclaredfontsize="16px"
                                                    hasdeclaredfontweight="700"
                                                    hasdeclaredtextalign="center"
                                                    hasdeclaredlineheight="1.4em"
                                                >
                                                    {getString(
                                                        'User_panel_addition_data_element_imie'
                                                    )}
                                                </StyledText>
                                            </StyledHeaderElement>
                                            <StyledHeaderElement $hasWidth="10%">
                                                <StyledText
                                                    hasdeclaredfontsize="16px"
                                                    hasdeclaredfontweight="700"
                                                    hasdeclaredtextalign="center"
                                                    hasdeclaredlineheight="1.4em"
                                                >
                                                    {getString(
                                                        'User_panel_addition_data_element_kwota'
                                                    )}
                                                </StyledText>
                                            </StyledHeaderElement>
                                            <StyledHeaderElement $hasWidth="40%">
                                                <StyledText
                                                    hasdeclaredfontsize="16px"
                                                    hasdeclaredfontweight="700"
                                                    hasdeclaredtextalign="center"
                                                    hasdeclaredlineheight="1.4em"
                                                >
                                                    {getString(
                                                        'User_panel_addition_data_element_opis'
                                                    )}
                                                </StyledText>
                                            </StyledHeaderElement>
                                        </div>
                                    </StyledHeader>
                                ) : (
                                    <StyledText
                                        hasdeclaredfontsize="16px"
                                        hasdeclaredfontweight="700"
                                        hasdeclaredtextalign="center"
                                        hasdeclaredlineheight="1.4em"
                                    >
                                        {getString('user_panel_addition_element')}
                                    </StyledText>
                                )}
                                {(additions ?? []).map(
                                    ({ id, id_Trainer, amount, description }) => (
                                        <AdditionDataElement
                                            key={id}
                                            id={id}
                                            trainer={trainers.find(({ id }) => id === id_Trainer)}
                                            amount={amount}
                                            description={description}
                                            allTrainers={allTrainers}
                                        />
                                    )
                                )}
                            </ScrollSyncPane>
                        </>
                    </ScrollSync>
                </StyledElements>
            ) : null}
        </StyledUserPanelAdditionElement>
    );
};

export default UserPanelAdditionElement;
