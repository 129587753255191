import { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { StyledInputsDivWrapper } from '../../atoms/StyledInputsDivWrapper/StyledInputsDivWrapper';
import { StyledInputWrapper } from '../../../styles/sharedStyles';
import { StyledText } from '../../atoms/Text/StyledText';
import { StyledInputsButtonsWrapper } from '../../atoms/TrackHours/StyledInputsButtonsWrapper';
import { formSchema } from './UserPanelManageContracts.data';
import { getString } from '../../../strings';
import { createContract, listTrainersEmployeeConf } from '../../../logic/requests/employees';
import { StyledCustomFile } from '../../molecules/UserPanelReqElementTrainer/UserPanelReqElementTrainer.styles';
import { useQuery, useQueryClient } from '@tanstack/react-query';

const AddContract = ({ onSuccessfulAdd, companies, contractTypes }) => {
    const [trigger, setTrigger] = useState(false);
    const queryClient = useQueryClient();

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
        setValue,
        getValues,
    } = useForm({
        resolver: yupResolver(formSchema),
    });

    const { data } = useQuery(['trainers', 'all', 'employeeConf'], listTrainersEmployeeConf);

    const trainers = useMemo(
        () =>
            !data
                ? []
                : Object.values(data).sort((a, b) => {
                      const lastname = a.lastname.localeCompare(b.lastname);
                      if (lastname) return lastname;

                      const firstname = a.firstname.localeCompare(a.firstname);
                      if (firstname) return firstname;

                      return 0;
                  }),
        [data]
    );

    const handleFileUpload = useCallback((e) => {
        const file = e.target.files[0];
        setValue('contract', file);
    }, [setValue]);

    const onSubmit = useCallback(
        (data) => {
            const formData = new FormData();
            formData.append('startDate', data.startDate);
            if (data.endDate) formData.append('endDate', data.endDate);
            formData.append('type', data.contract_type);
            formData.append('trainerId', data.trainer);
            formData.append('content', data.contract);
            formData.append('companyId', data.company);

            createContract(formData)
                .then((res) => {
                    const trainer = trainers.find((trainer) => trainer.id === +data.trainer);
                    const company = companies.find((company) => company.id === +data.company);
                    onSuccessfulAdd([
                        {
                            title: getString('User_panel_employee_konfiguracja_trener') + ':',
                            value: `${trainer.lastname} ${trainer.firstname}`,
                        },
                        {
                            title: getString('User_panel_employee_contracts_company') + ':',
                            value: company.name,
                        },
                        {
                            title: getString('user_panel_manage_contract_typ_umowy') + ':',
                            value: getString(`contract_${data.contract_type}`),
                        },
                        {
                            title: getString('user_panel_manage_contract_filename') + ':',
                            value: data.contract.name,
                        },
                        {
                            title: getString('user_panel_manage_contracts_start') + ':',
                            value: new Date(data.startDate).toLocaleDateString(),
                        },
                        {
                            title: getString('user_panel_manage_contracts_koniec') + ':',
                            value: data.endDate ? new Date(data.endDate).toLocaleDateString() : 'Trwa',
                        },
                    ]);
                    reset();
                    queryClient.invalidateQueries('contracts');
                })
                .catch((err) => {
                    console.error('createContract', err);
                });
        },
        [companies, onSuccessfulAdd, queryClient, reset, trainers]
    );

    const errorText = useMemo(
        () => (
            <StyledText
                as="h4"
                hasdeclaredfontsize="16px"
                hasdeclaredfontcolor="#F74017"
                hasdeclaredtextalign="center"
                hasdeclaredfontweight="700"
                hasdeclaredtexttransform="uppercase"
                hasdeclaredlineheight="1.4em"
            >
                {getString('user_panel_manage_contract_przeslij_to_pole_jest_wymagane')}
            </StyledText>
        ),
        []
    );

    const getContractName = useMemo(() => {
        const contractValue = getValues('contract');

        return contractValue ? (
            <StyledText
                hasdeclaredfontsize="16px"
                hasdeclaredtextalign="center"
                hasdeclaredfontweight="700"
                hasdeclaredtexttransform="uppercase"
                hasdeclaredlineheight="1.4em"
            >
                {getValues('contract').name}
            </StyledText>
        ) : null
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getValues, trigger])

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <StyledInputsDivWrapper>
                <StyledInputWrapper error={errors.trainer}>
                    <label htmlFor="trainer">
                        <StyledText
                            hasdeclaredfontsize="20px"
                            hasdeclaredtextalign="center"
                            hasdeclaredpadding="8px 0 8px 0"
                            hasdeclaredfontweight="600"
                        >
                            {getString('User_panel_employee_konfiguracja_trener')}:
                        </StyledText>
                    </label>
                    <select id="trainer" {...register('trainer')}>
                        <option />
                        {trainers.map(({ id, firstname, lastname }) => (
                            <option key={id} value={id}>
                                {lastname} {firstname}
                            </option>
                        ))}
                    </select>
                    {errors.trainer?.message ? <StyledText
                        as="h4"
                        hasdeclaredfontsize="16px"
                        hasdeclaredfontcolor="#F74017"
                        hasdeclaredtextalign="center"
                        hasdeclaredfontweight="700"
                        hasdeclaredtexttransform="uppercase"
                        hasdeclaredlineheight="1.4em"
                    >
                        {errors.trainer.message}
                    </StyledText> : null}
                </StyledInputWrapper>
                <StyledInputWrapper error={errors.company}>
                    <label htmlFor="company">
                        <StyledText
                            hasdeclaredfontsize="20px"
                            hasdeclaredtextalign="center"
                            hasdeclaredpadding="8px 0 8px 0"
                            hasdeclaredfontweight="600"
                        >
                            {getString('User_panel_employee_contracts_company')}:
                        </StyledText>
                    </label>
                    <select id="company" {...register('company')}>
                        <option />
                        {companies?.map(({ id, name }) => (
                            <option key={id} value={id}>
                                {name}
                            </option>
                        ))}
                    </select>
                    {errors.company?.message ? <StyledText
                        as="h4"
                        hasdeclaredfontsize="16px"
                        hasdeclaredfontcolor="#F74017"
                        hasdeclaredtextalign="center"
                        hasdeclaredfontweight="700"
                        hasdeclaredtexttransform="uppercase"
                        hasdeclaredlineheight="1.4em"
                    >
                        {errors.company.message}
                    </StyledText> : null}
                </StyledInputWrapper>
            </StyledInputsDivWrapper>
            <StyledInputsDivWrapper>
                <StyledInputWrapper error={errors?.['contract_type']} hasdeclaredwidth="33%">
                    <label>
                        <StyledText
                            hasdeclaredfontsize="20px"
                            hasdeclaredtextalign="center"
                            hasdeclaredpadding="8px 0 8px 0"
                            hasdeclaredfontweight="600"
                        >
                            {getString('user_panel_manage_contract_typ_umowy')}:
                        </StyledText>
                    </label>
                    <select id="contract_type" {...register('contract_type')}>
                        <option />
                        {contractTypes?.map((type) => (
                            <option key={type} value={type}>
                                {getString(`contract_${type}`)}
                            </option>
                        ))}
                    </select>
                    {errors.contract_type ? errorText : null}
                </StyledInputWrapper>
                <StyledInputWrapper error={errors?.['startDate']} hasdeclaredwidth="33%">
                    <label>
                        <StyledText
                            hasdeclaredfontsize="20px"
                            hasdeclaredtextalign="center"
                            hasdeclaredpadding="8px 0 8px 0"
                            hasdeclaredfontweight="600"
                        >
                            {getString('user_panel_manage_contracts_start')}:
                        </StyledText>
                    </label>
                    <input type="date" {...register('startDate')} />
                    {errors.startDate ? errorText : null}
                </StyledInputWrapper>
                <StyledInputWrapper error={errors?.['endDate']} hasdeclaredwidth="33%">
                    <label>
                        <StyledText
                            hasdeclaredfontsize="20px"
                            hasdeclaredtextalign="center"
                            hasdeclaredpadding="8px 0 8px 0"
                            hasdeclaredfontweight="600"
                        >
                            {getString('user_panel_manage_contracts_koniec')}:
                        </StyledText>
                    </label>
                    <input type="date" {...register('endDate')} />
                </StyledInputWrapper>
            </StyledInputsDivWrapper>
            <StyledInputsDivWrapper style={{ marginTop: '20px' }}>
                <StyledInputWrapper
                    error={errors?.['contract']}
                    $isCenter
                    hasdeclaredwidth="100%"
                    $hasGap="8px"
                    style={{ flexDirection: 'column' }}
                >
                    <StyledCustomFile
                        $hasDeclaredWidth="100%"
                        $hasDeclaredHeight="50px"
                        file={Boolean(getValues('contract'))}
                    >
                        <label htmlFor="upload">
                            {getString('user_panel_manage_contract_przeslij_umowe')}
                        </label>
                        <input
                            id="upload"
                            type="file"
                            onChange={(e) => {
                                handleFileUpload(e);
                                setValue(
                                    'contract',
                                    e.target.files?.length ? e.target.files[0] : null
                                );
                                setTrigger(prev => !prev)
                            }}
                            style={{ display: 'none' }}
                            accept=".pdf,.doc,.docx"
                        />
                    </StyledCustomFile>
                    {getContractName}
                    {errors.contract ? errorText : null}
                </StyledInputWrapper>
            </StyledInputsDivWrapper>
            <StyledInputsButtonsWrapper style={{ marginTop: '20px' }}>
                <input type="submit" value={getString('user_panel_manage_contract_zapisz')} />
                <button type="button" className="reset" onClick={() => reset()}>
                    {getString('user_panel_manage_contracts_anuluj')}
                </button>
            </StyledInputsButtonsWrapper>
        </form>
    );
};

export default AddContract;
