import React from 'react';

import { getLang } from '../../../strings';

import LoginLink from '../LoginLink/LoginLink';
import HomePageCardGallery from './HomePageCardGallery';
import HomePageCardTournaments from './HomePageCardTournaments';
import HomePageCardTraining from './HomePageCardTraining';
import HomePageCardContact from './HomePageCardContact';
import HomePageLogo from '../../molecules/Home/HomePageLogo';

import { StyledHomePageCards } from '../../atoms/Home/StyledHome';
import useWindowSize from '../../../utils/getWindowSize';

const HomePageCards = () => {
    const width = useWindowSize();

    return (
        <StyledHomePageCards>
            <LoginLink />
            <HomePageCardTournaments />
            <HomePageCardTraining />
            {getLang() === 'gb' && width < 1036 ? (
                <>
                    <HomePageCardGallery />
                    <HomePageCardContact />
                </>
            ) : width > 767 ? (
                <>
                    <HomePageCardContact />
                    <HomePageCardGallery />
                </>
            ) : (
                <>
                    <HomePageCardGallery />
                    <HomePageCardContact />
                </>
            )}
            <HomePageLogo />
        </StyledHomePageCards>
    );
};

export default HomePageCards;
