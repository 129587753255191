import styled from 'styled-components';

export const StyledIconWrapper = styled.div`
    width: 25%;
    height: 50px;
    display: flex;
    align-items: center;

    > svg {
        width: 100%;
        fill: #fff;
        height: 80%;
    }

    @media only screen and (max-width: 1084px) {
        width: 30%;
        justify-content: center;
    }

    @media only screen and (max-width: 728px) {
        width: 100%;
    }

    @media only screen and (max-width: 420px) {
        > svg {
            height: 70%;
        }
    }
`;
