import styled from 'styled-components';

export const StyledEmployeesDataElement = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;

    > div {
        &:first-child {
            margin-right: ${({ $isCheckbox }) => ($isCheckbox ? '10px' : '0')};
        }

        &:nth-child(${({ $isCheckbox }) => ($isCheckbox ? '2' : '1')}) {
            width: 100%;
            overflow-x: scroll;

            scrollbar-color: transparent transparent;
            scrollbar-width: thin;

            &::-webkit-scrollbar {
                height: 0;
            }

            &::-webkit-scrollbar-track {
                background: transparent;
            }

            &::-webkit-scrollbar-thumb {
                background: transparent;
            }
        }
    }
`;

export const StyledWrapper = styled.div`
    width: 100%;
    min-width: 700px;
    min-height: 48px;
    height: 100%;
    background: #fff;
    border: 4px solid ${({ $isRedBg }) => ($isRedBg ? '#ee745c' : '#6786be')};
    display: flex;
    justify-content: space-between;
    border-radius: 20px;
    padding: 5px 0;
    margin-left: ${({ $isCheckbox }) => ($isCheckbox ? '10px' : null)};
`;

export const StyledEmployeesDataText = styled.div`
    width: ${({ $hasDeclaredWidth }) => ($hasDeclaredWidth ? $hasDeclaredWidth : '20%')};
    display: flex;
    min-height: 30px;
    align-items: center;
    justify-content: center;
    padding: 0 4px;
`;

export const StyledIconBtn = styled.button`
    width: 30px;
    height: 30px;
    padding: 4px 2px;
    border: none;
    background-color: ${({ $isWhiteBg }) => ($isWhiteBg ? '#fff' : '#6786be')};
    border-radius: 5px;
    margin-left: 5px;
    color: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        width: 70%;
        height: 70%;
    }
`;
