import * as yup from 'yup';

export const formSchema = yup.object({
    startDate: yup.date().required(),
    endDate: yup.mixed(),
    company: yup.string().required(),
    contract_type: yup.string().required(),
    contract: yup.mixed().required(),
    trainer: yup.string().required(),
});
