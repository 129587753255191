import backendRequest from "./index.js";

const addTrainerSubject = ({ subjectId, trainerId, trainerPrice }) =>
    backendRequest("POST", "/subjects/add-trainer-subject", {
        subjectId,
        trainerId,
        trainerPrice,
    });

const editTrainerSubject = ({ subjectId, trainerId, trainerPrice }) =>
    backendRequest("PUT", "/subjects/edit-trainer-subject", {
        subjectId,
        trainerId,
        trainerPrice,
    });

const deleteTrainerSubject = ({ subjectId, trainerId }) =>
    backendRequest("DELETE", `/subjects/delete-trainer-subject/${trainerId}/${subjectId}`);

export { addTrainerSubject, editTrainerSubject, deleteTrainerSubject };