import styled from 'styled-components';

export const StyledMenuElement = styled.button`
    width: ${({ ismobile }) => (ismobile ? '100%' : '300px')};
    min-height: 50px;
    padding: 12px;
    display: flex;
    border: none;
    justify-content: ${({ iscenterelement }) => (iscenterelement ? 'center' : 'space-between')};
    align-items: center;
    background-color: ${({ hasdeclaredbgcolor }) =>
        hasdeclaredbgcolor ? hasdeclaredbgcolor : '#6786be'};
    border-radius: 25px;
    cursor: pointer;
    margin: ${({ hasdeclaredmargin }) => (hasdeclaredmargin ? hasdeclaredmargin : '10px 0 0 0')};
    transition: background-color 250ms linear;

    &:hover {
        background-color: #602b26;
    }

    > p {
        width: 100%;
    }

    @media only screen and (max-width: 993px) {
        width: ${({ ismobile }) => (ismobile ? '100%' : '220px')};

        > p {
            font-size: 16px;
        }
    }

    @media only screen and (max-width: 767px) {
        padding: 0 22px;

        > p {
            font-size: 24px;
        }
    }

    @media only screen and (max-width: 374px) {
        > p {
            font-size: 16px;
        }
    }
`;
