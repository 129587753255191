import styled from 'styled-components';

export const StyledSavePayElement = styled.button`
    background-color: ${({ isedit }) => (isedit ? '#5FEE5C' : '#6786be')};
    width: 40px;
    height: 40px;
    cursor: pointer;
    border-radius: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
`;
