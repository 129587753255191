import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { sessionService } from 'redux-react-session';

import Coach from '../organisms/Coach/Coach';
import AboutUs from '../organisms/AboutUs/AboutUs';
import TrainingsInformations from '../organisms/Trainings/TrainingsInformations';
import TrainingsJoinUs from '../organisms/TrainingsJoinUs/TrainingsJoinUs';
import OrderRek from '../organisms/OrderRek/OrderRek';
import Questions from '../organisms/Questions/Questions';
import TrainingsPartners from '../organisms/TrainingsPartners/TrainingsPartners';
import TrainingsStartPage from '../organisms/TrainingsStartPage/TrainingsStartPage';
import Loader from '../organisms/Loader/Loader';

import LogoLink from '../organisms/Logo/LogoLink';
import LoginLink from '../organisms/LoginLink/LoginLink';
import TrainingsMenu from '../organisms/Trainings/TrainingsMenu';
import RegisterCube from '../molecules/RegisterCube/RegisterCube';
import HelmetTemplate from '../molecules/HelmetTemplate/HelmetTemplate';

import { StyledTrainingsPage } from '../atoms/Trainings/StyledTrainingsPage';
import { StyledLeftTrainingWrapper } from '../atoms/Trainings/StyledLeftTrainingWrapper';
import { StyledRightTrainingWrapper } from '../atoms/Trainings/StyledRightTrainingWrapper';
import { StyledTrainingsWrapper } from '../atoms/Trainings/StyledTrainingsWrapper';
import { StyledScrollContainer } from '../atoms/Trainings/StyledScrollContainer';

import { getFormData } from '../../logic/requests/trainings.js';
import useScrollBar from '../../logic/hooks/useScrollBar';

import useWindowSize from '../../utils/getWindowSize';

import { getLang, getString } from '../../strings';

const Trainings = () => {
    const { panel: menuVal } = useParams();
    const navigate = useNavigate();

    const { isScrollBar, scrollElement } = useScrollBar();
    const scrollToElement = useRef(null);
    const width = useWindowSize();

    const [user, setUser] = useState(undefined);
    useEffect(
        () =>
            (async () => {
                let user = null;
                try {
                    user = await sessionService.loadUser();
                } catch (err) {}
                setUser(user);
            })(),
        [sessionService, setUser]
    );

    const [formData, setFormData] = useState();
    useEffect(() => {
        if (typeof formData === 'object' && Object.keys(formData).length) return;

        getFormData()
            .then((res) => {
                const order = ['single', 'mo3', 'ao5', 'ao12', 'ao25', 'ao50', 'ao100'];

                for (let comp of Object.values(res.offers))
                    comp.sort((a, b) => order.indexOf(a) - order.indexOf(b));

                res.trainers = res.trainers.filter((trainer) => trainer.langs.includes(getLang()));

                setFormData(res);
            })
            .catch((err) => {
                console.error('getFormData', err);
                setFormData({});
            });
    }, [formData]);

    useEffect(() => {
        const { current } = scrollToElement;
        if (width < 768) {
            current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' });
        }
    }, [menuVal]);

    return (
        <>
            <HelmetTemplate
                title={getString('seo_trainings_title')}
                desc={getString('seo_trainings_desc')}
                ogTitle={getString('seo_trainings_twitter_title')}
            />
            <StyledTrainingsPage
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
            >
                <RegisterCube isContactPage={true} />
                <LogoLink />
                <LoginLink />
                <StyledTrainingsWrapper>
                    <StyledLeftTrainingWrapper>
                        <StyledScrollContainer isscrollbar={isScrollBar} ref={scrollElement}>
                            <TrainingsMenu
                                setMenuVal={(val) =>
                                    menuVal === val
                                        ? navigate('/trainings')
                                        : navigate(`/trainings/${val}`)
                                }
                                menuVal={menuVal}
                            />
                        </StyledScrollContainer>
                    </StyledLeftTrainingWrapper>
                    <StyledRightTrainingWrapper ref={scrollToElement}>
                        {user === undefined ? (
                            <Loader />
                        ) : (
                            (() => {
                                switch (menuVal) {
                                    case 'trainers':
                                        return <Coach />;
                                    case 'about-us':
                                        return <AboutUs />;
                                    case 'info':
                                        return <TrainingsInformations />;
                                    case 'sign-up':
                                        return <TrainingsJoinUs user={user} />;
                                    case 'order-analysis':
                                        return <OrderRek user={user} formData={formData} />;
                                    case 'questions':
                                        return <Questions />;
                                    case 'partners':
                                        return <TrainingsPartners />;
                                    default:
                                        return <TrainingsStartPage user={user} />;
                                }
                            })()
                        )}
                    </StyledRightTrainingWrapper>
                </StyledTrainingsWrapper>
            </StyledTrainingsPage>
        </>
    );
};

export default Trainings;
