import React from 'react';
import { getString } from '../../../strings';
import SummaryPlayersCube from './SummaryPlayersCube';

import { StyledText } from '../../atoms/Text/StyledText';
import { StyledSummaryPlayers } from '../../atoms/Tournament/StyledSummaryPlayers';

const SummaryPlayers = ({ quantities, totalPlayers }) => {
    return (
        <StyledSummaryPlayers>
            <div>
                <StyledText
                    hasdeclaredfontsize="26px"
                    hasdeclaredtexttransform="uppercase"
                    hasdeclaredfontweight="700"
                    hasdeclaredfontcolor="#6786be"
                    hasdeclaredtextalign="center"
                    hasdeclaredlineheight="1.3em"
                >
                    {getString('tournament_summaryPlayers_liczbaZawodnikow')}
                </StyledText>
                <StyledText
                    hasdeclaredfontsize="26px"
                    hasdeclaredtexttransform="uppercase"
                    hasdeclaredfontweight="700"
                    hasdeclaredpadding="0 0 0 8px"
                    hasdeclaredlineheight="1.3em"
                >
                    {totalPlayers}
                </StyledText>
            </div>
            <div>
                <SummaryPlayersCube cubes={quantities} />
            </div>
        </StyledSummaryPlayers>
    );
};

export default SummaryPlayers;
