import styled from 'styled-components';

export const StyledChangeLanguage = styled.div`
    background: #fff;
    border-radius: ${({ isactive }) => (isactive ? '10px 10px 0 0' : '10px')};
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    cursor: pointer;
    width: 70px;
    height: 25px;

    &:hover {
        > i {
            transition: border-color 250ms linear;
            border-color: #ee745c;
        }
    }

    > i {
        border-width: 0 2px 2px 0;
        padding: 4px;
        margin-left: 8px;
    }

    > a {
        text-decoration: none;

        &:hover {
            > p {
                transition: color 250ms ease-in;
                color: #ee745c;
            }
        }
    }

    @media only screen and (max-width: 485px) {
        padding: 5px 10px;

        > p {
            font-size: 14px;
        }
    }
`;
