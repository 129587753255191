import styled from 'styled-components';

export const StyledPhoneLink = styled.a`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    cursor: pointer;
    color: #000000;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 14px;

    @media only screen and (max-width: 528px) {
        font-size: 16px;
    }
`;

export const StyledEditHandler = styled.div`
    position: absolute;
    right: -4px;
    bottom: -4px;
    width: 50px;
    height: 50px;
    border-radius: 9px;
    background-color: #6786be;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

export const StyledEmailHandler = styled.div`
    position: absolute;
    left: -4px;
    bottom: -4px;
    width: 50px;
    height: 50px;
    border-radius: 9px;
    background-color: #6786be;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

export const StyledRow = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;

    @media only screen and (max-width: 1240px) {
        flex-direction: column;
        gap: 8px;
    }
`;

export const StyledColumn = styled.div`
    width: 50%;
    display: flex;
    align-items: center;
    gap: 8px;

    @media only screen and (max-width: 1240px) {
        width: 100%;
    }

    @media only screen and (max-width: 528px) {
        p {
            font-size: 16px;
        }
    }
`;

export const StyledUserListElement = styled.div`
    width: 100%;
    background-color: #fff;
    border: 4px solid #6786be;
    border-radius: 25px 25px 9px 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    align-items: center;
    padding: 25px 20px;
    position: relative;
    padding-bottom: ${({ $former }) => ($former ? '20px' : '60px')};
`;

export const StyledUserListEditElement = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
`;

export const StyledEditElementInputWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;

    > h4 {
        padding-left: ${({ $errorpdleft }) => ($errorpdleft ? '0' : '150px')};
    }

    > div {
        display: flex;
        align-items: center;
        width: 100%;
        padding-right: 50px;
        justify-content: space-between;

        > label {
            width: 200px;

            @media only screen and (max-width: 528px) {
                width: 150px;
                p {
                    font-size: 16px;
                }
            }
        }

        > input {
            width: 70%;
            height: 40px;
            border-radius: 25px;
            font-size: 16px;
            border: 3px solid ${({ error }) => (error ? '#F74017' : '#6786BE')};
            padding: 0 12px;
            font-family: 'Poppins';
            outline: none;
            transition: outline 250ms;
            background: #fff;
        }

        @media only screen and (max-width: 422px) {
            flex-direction: column;
            justify-content: center;
            gap: 10px;

            > label {
                min-width: unset;
                width: 100%;
            }

            > input {
                width: 100%;
            }
        }
    }

    @media only screen and (max-width: 528px) {
        h4 {
            padding-left: ${({ errorpdleft }) => (errorpdleft ? '0' : '100px')};
        }
    }

    @media only screen and (max-width: 422px) {
        h4 {
            padding-left: 0;
        }
    }
`;

export const StyledCloseForm = styled.div`
    width: 50px;
    height: 50px;
    cursor: pointer;
    position: absolute;
    top: -4px;
    right: -4px;
    background-color: #ee745c;
    border-radius: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const StyledSaveForm = styled.div`
    background-color: #5fee5c;
    width: 50px;
    height: 50px;
    cursor: pointer;
    position: absolute;
    bottom: -4px;
    right: -4px;
    border-radius: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const StyledEdirRole = styled.div`
    position: absolute;
    right: -4px;
    top: -4px;
    width: 50px;
    height: 50px;
    border-radius: 9px;
    background-color: #6786be;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    > svg {
        transform: rotate(45deg);
    }
`;
