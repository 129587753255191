import styled from 'styled-components';

export const StyledIconsMobileResults = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    > svg {
        width: 16px;
        height: 16px;
        fill: #000;
    }
`;
