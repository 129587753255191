import styled from 'styled-components';

export const StyledSumElement = styled.div`
    position: fixed;
    top: 0;
    left: 30%;
    display: flex;
    flex-direction: column;
    padding-top: 20px;

    > div {
        display: flex;
        gap: 5px;
    }

    @media only screen and (max-width: 1266px) {
        left: 25%;
    }

    @media only screen and (max-width: 926px) {
        position: relative;
        top: auto;
        left: auto;
        margin-top: 40px;
    }
`;
