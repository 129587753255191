import styled from 'styled-components';
import { scrollBlueStyles } from '../../../styles/sharedStyles';

export const StyledSeriesResultsWrapper = styled.div`
    width: 90%;
    min-height: 70%;
    max-height: 73%;
    overflow-y: scroll;
    margin: 0 auto;
    padding-bottom: 70px;

    ${scrollBlueStyles};

    @media only screen and (max-width: 1204px) {
        width: 95%;
    }

    @media only screen and (max-width: 767px) {
        width: 100%;
        padding-bottom: 60px;
        min-height: unset;
        max-height: unset;
        overflow-y: visible;
    }
`;
