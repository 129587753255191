import styled from 'styled-components';

export const StyledScrollWrapperElement = styled.div`
    width: 100%;
    overflow-x: scroll;
    display: flex;
    gap: 7px;
    align-items: center;
    align-self: flex-end;

    scrollbar-color: transparent transparent;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
        height: 6px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background: transparent;
    }
`;
