import backendRequest from "./index.js";

const getEvents = ({ cursorId } = { cursorId: 0 }) =>
    backendRequest("GET", `/events/list?cursor_id=${cursorId}`);

const getEventsForDiploma = () => backendRequest("GET", `/events/get-events-for-diploma`);

const getSponsorsForEvent = (id) => backendRequest("GET", `/events/get-sponsors/${id}`);

const getEventData = (code, { withLongTexts } = { withLongTexts: false }) =>
    backendRequest("GET", `/events/get/by-code/${code}?with_long_texts=${withLongTexts ? 1 : 0}`);

const getEventSchedule = (code) => backendRequest("GET", `/events/get-schedule/${code}`);

const getEventScriptForCurrentUser = (event_id) =>
    backendRequest("GET", `/events/get-script-for-me/${event_id}`);

const getEventsForCurrentUser = ({ cursorId } = { cursorId: 0 }) =>
    backendRequest("POST", `/events/list/for-current-user`, { cursor_id: cursorId });

const registerUserForEvent = (eventId, data) =>
    backendRequest("POST", `/events/register/${eventId}`, { ...data });

const updateUserRegistration = (eventId, competitions) =>
    backendRequest("POST", `/events/update-register/${eventId}`, { competitions });

const unregisterUserFromEvent = (eventId, competitions) =>
    backendRequest("POST", `/events/unregister/${eventId}`);

const getPlayersForEvent = (eventId) => backendRequest("GET", `/events/get-players/${eventId}`);

const isCurrentUserRegistered = (eventId) =>
    backendRequest("POST", `/events/current-user-registered/${eventId}`);

const registerUpgrade = (eventId) => backendRequest("POST", `/events/register-upgrade/${eventId}`);

const getResultsForUser = (eventId) =>
    backendRequest("GET", `/events/get-results-for-me/${eventId}`);

const sendPaymentReminder = (eventId) =>
    backendRequest("GET", `/events/send-payment-reminder/${eventId}`);

export {
    isCurrentUserRegistered,
    getEvents,
    getEventsForCurrentUser,
    getEventData,
    getEventScriptForCurrentUser,
    getEventSchedule,
    getPlayersForEvent,
    getResultsForUser,
    registerUserForEvent,
    registerUpgrade,
    updateUserRegistration,
    unregisterUserFromEvent,
    getSponsorsForEvent,
    getEventsForDiploma,
    sendPaymentReminder
};
