import styled from 'styled-components';

export const StyledSendFileInput = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    > input {
        opacity: 0;
        pointer-events: none;
    }

    @media only screen and (max-width: 1084px) {
        padding-bottom: 24px;
    }

    @media only screen and (max-width: 926px) {
        padding-bottom: 12px;
    }
`;
