import React from 'react'

import parse from "html-react-parser";

import { StyledPartnerElement } from "../../atoms/TrainingsPartners/StyledPartnerElement";
import { StyledPartnerElementImage } from "../../atoms/TrainingsPartners/StyledPartnerElementImage";
import { StyledButton } from '../../atoms/Button/StyledButton';
import { StyledParserWrapper } from "../../atoms/TrainingsPartners/StyledParserWrapper";

import { getString } from '../../../strings';

const PartnerElement = ({ image, title, alt, link }) => {
    return (
        <StyledPartnerElement>
            <StyledPartnerElementImage>
                <img src={image} alt={alt} />
            </StyledPartnerElementImage>
            <StyledParserWrapper>
                {parse(title)}
            </StyledParserWrapper>
            <a aria-label={getString("seo_aria_label_przejdz_do_strony")} href={link}>
                <StyledButton
                    hasdeclaredfontcolor="#fff"
                    hasdeclaredpadding="8px 44px"
                    hasdeclaredbgcolor="#6786BE"
                    hasdeclaredfontweight="600"
                    hasdeclaredborderradius="25px"
                    hasdeclaredcursor="pointer"
                    hasdeclaredtexttransform="uppercase"
                    whileHover={{ scale: 0.95 }}
                >
                    {getString("PartnerElement__button")}
                </StyledButton>
            </a>
        </StyledPartnerElement>
    );
}

export default PartnerElement
