import styled from 'styled-components';

export const StyledElements = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
    padding-right: 10px;
    padding-left: 10px;
    width: 100%;
`;
