import styled from 'styled-components';

export const StyledTournamentsCompetitions = styled.div`
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    border: ${({ isborder }) => (isborder ? '2px solid #6D85BA' : 'none')};
    border-radius: ${({ isborder }) => (isborder ? '45px' : 'none')};

    > span {
        svg {
            max-width: ${({ size }) => (size ? size : '25px')};
            max-height: ${({ size }) => (size ? size : '25px')};
            margin: ${({ hasdeclaredmargin }) =>
                hasdeclaredmargin ? hasdeclaredmargin : '0 5px 0 5px'};
        }
    }
`;
