import { getString } from '../../../strings';
import { StyledButton } from '../../atoms/Button/StyledButton';
import { StyledText } from '../../atoms/Text/StyledText';
import PaymentsPopup from '../Payments/PaymentsPopup';

const SendEmailPopup = ({ onCancel, onSend, onCloseWithoutSend, role }) => {
    const title =
        role === 'student'
            ? getString('send_email_popup_title_student')
            : role === 'parent'
            ? getString('send_email_popup_title_parent')
            : role === 'former_student'
            ? getString('send_email_popup_title_former_student')
            : "";
    return (
        <PaymentsPopup>
            <StyledText
                hasdeclaredfontsize="18px"
                hasdeclaredpadding={'50px 20px 20px 20px'}
                hasdeclaredlineheight="1.4em"
                hasdeclaredtextalign="center"
            >
                {title}
            </StyledText>
            <div style={{ display: 'flex', justifyContent: 'center', gap: '20px' }}>
                <StyledButton
                    hasdeclaredfontcolor="#fff"
                    hasdeclaredbgcolor="#6d85ba"
                    hasdeclaredtextalign="center"
                    hasdeclaredcursor="pointer"
                    hasdeclaredwidth="150px"
                    hasdeclaredheight="50px"
                    hasdeclaredfontsize="20px"
                    hasdeclaredborderradius="35px"
                    hasdeclaredmargin="20px 0 0 0"
                    hasdeclaredfontweight="600"
                    whileTap={{ scale: 0.9 }}
                    whileHover={{ scale: 1.05 }}
                    onClick={onSend}
                >
                    {getString("send_email_popup_yes")}
                </StyledButton>
                <StyledButton
                    hasdeclaredfontcolor="#fff"
                    hasdeclaredbgcolor="#6d85ba"
                    hasdeclaredtextalign="center"
                    hasdeclaredcursor="pointer"
                    hasdeclaredwidth="150px"
                    hasdeclaredheight="50px"
                    hasdeclaredfontsize="20px"
                    hasdeclaredborderradius="35px"
                    hasdeclaredmargin="20px 0 0 0"
                    hasdeclaredfontweight="600"
                    whileTap={{ scale: 0.9 }}
                    whileHover={{ scale: 1.05 }}
                    onClick={onCloseWithoutSend}
                >
                    {getString("send_email_popup_no")}
                </StyledButton>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', gap: '20px' }}>
                <StyledButton
                    hasdeclaredfontcolor="#fff"
                    hasdeclaredbgcolor="#ee745c"
                    hasdeclaredtextalign="center"
                    hasdeclaredcursor="pointer"
                    hasdeclaredwidth="280px"
                    hasdeclaredheight="50px"
                    hasdeclaredfontsize="20px"
                    hasdeclaredborderradius="35px"
                    hasdeclaredmargin="20px 0 0 0"
                    hasdeclaredfontweight="600"
                    whileTap={{ scale: 0.9 }}
                    whileHover={{ scale: 1.05 }}
                    onClick={onCancel}
                >
                    {getString("send_email_popup_cancel")}
                </StyledButton>
            </div>
        </PaymentsPopup>
    );
};

export default SendEmailPopup;
