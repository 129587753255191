import styled from 'styled-components';
import { motion } from 'framer-motion';

export const StyledUserPanel = styled(motion.section)`
    width: 100%;
    height: 100vh;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    background: #f9ec4b;
    overflow: hidden;

    @media only screen and (max-width: 926px) {
        flex-direction: column-reverse;
        justify-content: flex-end;
        align-items: center;
        height: auto;
    }
`;
