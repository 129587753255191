import { useCallback, useMemo } from 'react';
import UserPanelHeadingText from '../../atoms/UserPanel/UserPanelHeadingText';
import { StyledText } from '../../atoms/Text/StyledText';
import {
    StyledGlobalScrollWrapper,
    StyledGlobalHeadingWrapper,
} from '../../../styles/sharedStyles';
import { StyledUserPanelManageContracts } from './UserPanelManageContracts.styles';
import useScrollBar from '../../../logic/hooks/useScrollBar';
import ManageContractsElement from '../ManageContractsElement/ManageContractsElement';
import { getString } from '../../../strings';
import { getContractTypes, listCompanies, listContracts } from '../../../logic/requests/employees';
import { useQuery } from '@tanstack/react-query';
import useSuccessMessage from '../../../logic/hooks/useSuccessMessage';
import SuccessMessage from '../../molecules/SuccessMessage';
import AddContract from './AddContract';

const UserPanelManageContracts = () => {
    const { scrollElement, isScrollBar } = useScrollBar();

    const { data: contractsList } = useQuery(['contracts', 'all'], listContracts);

    const { data: companies } = useQuery(['companies', 'all'], listCompanies);

    const { data: contractTypes } = useQuery(['contractTypes', 'all'], getContractTypes);

    const contracts = useMemo(
        () =>
            !contractsList
                ? []
                : contractsList.sort((a, b) => {
                      const lastname = a.lastname.localeCompare(b.lastname);
                      if (lastname) return lastname;

                      const firstname = a.firstname.localeCompare(a.firstname);
                      if (firstname) return firstname;

                      return 0;
                  }),
        [contractsList]
    );

    const {
        infos: successMessageInfos,
        isShowing: isSuccessMessage,
        show: showSuccessMessage,
    } = useSuccessMessage();

    const onSuccessfulAdd = useCallback(
        (infos) => {
            showSuccessMessage(infos);
        },
        [showSuccessMessage]
    );

    return (
        <StyledUserPanelManageContracts>
            <StyledGlobalHeadingWrapper>
                <StyledGlobalHeadingWrapper>
                    <UserPanelHeadingText text={`Umowy:`} />
                </StyledGlobalHeadingWrapper>
            </StyledGlobalHeadingWrapper>
            {isSuccessMessage ? (
                <SuccessMessage
                    title={getString('UserPanelContracts__success__created')}
                    infos={successMessageInfos}
                />
            ) : undefined}
            <StyledGlobalScrollWrapper isscrollbar={isScrollBar} ref={scrollElement}>
                <AddContract
                    onSuccessfulAdd={onSuccessfulAdd}
                    companies={companies}
                    contractTypes={contractTypes}
                />
                <StyledText
                    hasdeclaredfontsize="22px"
                    hasdeclaredfontweight="700"
                    hasdeclaredtextalign="center"
                    hasdeclaredtexttransform="uppercase"
                    hasdeclaredmargin="clamp(30px, 4vw, 80px) 0 20px 0"
                >
                    {getString('user_panel_manage_contracts_umowy')}:
                </StyledText>
                {contractsList?.map((trainer) => (
                    <ManageContractsElement
                        key={trainer.id}
                        trainer={trainer}
                        companies={companies}
                        contractTypes={contractTypes}
                    />
                ))}
            </StyledGlobalScrollWrapper>
        </StyledUserPanelManageContracts>
    );
};

export default UserPanelManageContracts;
