import React from 'react';
import { Link } from 'react-router-dom';

import { getString, getLang } from '../../../strings';

import { StyledLeftWrapper } from './StyledLeftWrapper';
import { StyledText } from '../../atoms/Text/StyledText';
import { StyledRegisterImage } from './StyledRegisterImage';

import { ReactComponent as RegisterImage } from '../../../images/LoginPage/rejestruj.svg';
import { ReactComponent as UkrainskiImage } from '../../../images/LoginPage/ukrainski.svg';
import { ReactComponent as AngielskiImage } from '../../../images/LoginPage/angielski.svg';
import { ReactComponent as CzeskiImage } from '../../../images/LoginPage/czeski.svg';

import useWindowSize from '../../../utils/getWindowSize';

const LoginLeftWrapper = () => {
    const windowWidth = useWindowSize();

    return (
        <StyledLeftWrapper>
            <StyledText
                hasdeclaredfontsize="64px"
                hasdeclaredfontcolor="#fff"
                hasdeclaredfontweight="800"
                hasdeclaredtextalign="center"
                hasdeclaredpadding="0 20px"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.2, delay: 0.2 }}
            >
                {getString('login_loginLeftWrapper_nieMaszKonta')}
            </StyledText>
            <StyledRegisterImage
                initial={windowWidth < 500 ? { opacity: 0 } : { scale: 0 }}
                animate={windowWidth < 500 ? { opacity: 1 } : { scale: 1 }}
                transition={{ delay: 0.2, duration: 0.2 }}
            >
                <Link to="/register">
                    {(() => {
                        switch (getLang()) {
                            case 'pl':
                                return <RegisterImage />;
                            case 'cz':
                                return <CzeskiImage />;
                            case 'gb':
                                return <AngielskiImage />;
                            case 'ua':
                                return <UkrainskiImage />;
                            default:
                                return <></>;
                        }
                    })()}
                </Link>
            </StyledRegisterImage>
        </StyledLeftWrapper>
    );
};

export default LoginLeftWrapper;
