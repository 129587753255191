import styled from 'styled-components';

export const StyledScriptDataRightWrapper = styled.div`
    display: flex;
    width: 65%;
    align-items: center;
    min-height: 50px;
    background-color: #fff;
    border: 2px solid #000;
    border-radius: 15px;

    > p {
        width: 25%;
        display: block;
        padding: 0 4px;
        text-align: center;
    }

    @media only screen and (max-width: 1118px) {
        > p {
            font-size: 20px;
        }
    }

    @media only screen and (max-width: 728px) {
        width: 85%;
    }
`;
