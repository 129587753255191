import styled from 'styled-components';

export const StyledTournament = styled.div`
    width: 100%;
    border-radius: 75px;
    min-height: 220px;
    background-color: #fff;
    border: 15px solid #6786be;
    margin-top: 24px;
    padding: 34px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;

    @media only screen and (max-width: 1086px) {
        width: 100%;
        margin: 24px 0;
    }

    @media only screen and (max-width: 1017px) {
        border: 8px solid #6786be;
    }

    @media only screen and (max-width: 922px) {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

    @media only screen and (max-width: 513px) {
        padding: 30px 20px;
    }
`;
