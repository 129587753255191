import React, { useCallback, useEffect, useState } from 'react';
import { getString } from '../../strings';
import LoginLink from '../organisms/LoginLink/LoginLink';
import LogoLink from '../organisms/Logo/LogoLink';
import TournamentElement from '../organisms/Tournaments/TournamentElement';
import CompetitionsCubes from '../organisms/Tournament/CompetitionsCubes';
import SeriesButton from '../molecules/Tournaments/SeriesButton';
import Loader from '../organisms/Loader/Loader';
import HelmetTemplate from '../molecules/HelmetTemplate/HelmetTemplate';

import { StyledTournaments } from '../atoms/Tournaments/StyledTournaments';
import { StyledTournamentsWrapper } from '../molecules/Tournaments/StyledTournamentsWrapper';
import { StyledText } from '../atoms/Text/StyledText';
import { StyledContent } from '../atoms/Tournaments/StyledContent';
import { StyledInfiniteScroll } from '../atoms/Tournaments/StyledInfiniteScroll';

import { getEvents } from '../../logic/requests/events.js';
import useScrollBar from '../../logic/hooks/useScrollBar';

const Tournaments = () => {
    class PageState {
        constructor(state) {
            this.value = state;
        }

        static DEFAULT_LOADING = new PageState(0);
        static LOADING_FINISHED = new PageState(1);
        static LOADING_ERROR = new PageState(2);
    }

    const [pageState, setPageState] = useState(PageState.DEFAULT_LOADING.value);
    const [events, setEvents] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const { scrollElement, isScrollBar } = useScrollBar();

    const loadEvents = useCallback(() => {
        getEvents({ cursorId: events[events.length - 1]?.id || 0 })
            .then((res) => {
                if (res.length) {
                    setEvents(events.concat(res));
                } else {
                    setHasMore(false);
                }
                if (pageState === PageState.DEFAULT_LOADING.value)
                    setPageState(PageState.LOADING_FINISHED.value);
            })
            .catch((err) => {
                console.error('getEvents', err);
                // nie ukrywamy poprzednio wczytanych zawodów, jeśli wystąpi błąd przy > 1 ładowaniu
                if (pageState === PageState.DEFAULT_LOADING.value)
                    setPageState(PageState.LOADING_ERROR.value);
            });
    }, [
        events,
        pageState,
        PageState.DEFAULT_LOADING.value,
        PageState.LOADING_FINISHED.value,
        PageState.LOADING_ERROR.value,
    ]);

    useEffect(() => loadEvents(), []);
    useEffect(() => {
        if (!process.env.REACT_APP_GOOGLE_MAPS_KEY)
            console.error(
                'Nie ustawiono klucza API Google Maps! Ustaw REACT_APP_GOOGLE_MAPS_KEY w frontend/.env przed kompilacją strony!'
            );
    }, []);

    return (
        <>
            <HelmetTemplate
                title={getString('seo_tournaments_title')}
                desc={getString('seo_tournaments_desc')}
                ogTitle={getString('seo_tournaments_twitter_title')}
            />
            <StyledTournaments
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
            >
                <LogoLink />
                <LoginLink />
                <CompetitionsCubes />
                <StyledTournamentsWrapper>
                    <StyledText
                        hasdeclaredfontsize="72px"
                        hasdeclaredfontweight="700"
                        as="h1"
                        hasdeclaredpadding="40px 0 44px 0"
                    >
                        {getString('pages_tournaments_zawody')}
                    </StyledText>
                    <StyledContent ref={scrollElement} isscrollbar>
                        {(() => {
                            switch (pageState) {
                                case PageState.DEFAULT_LOADING.value:
                                    return <Loader declaredWidth="250px" declaredHeight="250px" />;
                                case PageState.LOADING_FINISHED.value:
                                    return (
                                        <StyledInfiniteScroll
                                            loadMore={loadEvents}
                                            hasMore={hasMore}
                                            initialLoad={false}
                                            getScrollParent={() => scrollElement.current}
                                            useWindow={!isScrollBar}
                                        >
                                            {(() => {
                                                const ret = [];
                                                let minionki = false;

                                                for (let i = 0; i < events.length; i++) {
                                                    if (!minionki) {
                                                        if (events[i].status === 'over') {
                                                            // TODO: tu mogą być false-positive/negative'y
                                                            ret.push(
                                                                <StyledText
                                                                    key={i}
                                                                    hasdeclaredtexttransform="uppercase"
                                                                    hasdeclaredfontsize="54px"
                                                                    hasdeclaredpadding="32px 20px 32px 20px"
                                                                    hasdeclaredfontweight="700"
                                                                    hasdeclaredtextalign="center"
                                                                    as="h2"
                                                                >
                                                                    {getString(
                                                                        'pages_tournaments_minione'
                                                                    )}
                                                                </StyledText>
                                                            );
                                                            minionki = true;
                                                        }
                                                    }
                                                    ret.push(
                                                        <TournamentElement
                                                            event={events[i]}
                                                            key={minionki ? i + 1 : i}
                                                        />
                                                    );
                                                }

                                                return ret;
                                            })()}
                                        </StyledInfiniteScroll>
                                    );
                                case PageState.LOADING_ERROR.value:
                                    return (
                                        <StyledText
                                            hasdeclaredfontsize="44px"
                                            hasdeclaredfontweight="600"
                                            hasdeclaredlineheight="1.4em"
                                            as="h2"
                                            hasdeclaredtextalign="center"
                                        >
                                            {getString('pages_tournaments_bladLadowaniaListy')}
                                        </StyledText>
                                    );
                                default:
                                    return <></>;
                            }
                        })()}
                    </StyledContent>
                </StyledTournamentsWrapper>
                <SeriesButton />
            </StyledTournaments>
        </>
    );
};

export default Tournaments;
