import React from 'react';
import { StyledTournamentsCompetitions } from '../../atoms/Tournaments/StyledTournamentsCompetitions';
import CompetitionElement from '../CompetitionElement/CompetitionElement';

const TournamentsCompetitions = ({ competitions, isBorder, size, hasdeclaredmargin }) => (
    <StyledTournamentsCompetitions
        isborder={isBorder}
        size={size}
        hasdeclaredmargin={hasdeclaredmargin}
    >
        {competitions.map((competition, idx) => (
            <span key={idx}>
                <CompetitionElement competition={competition} />
            </span>
        ))}
    </StyledTournamentsCompetitions>
);

export default TournamentsCompetitions;
