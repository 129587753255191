import React from 'react';
import parse from 'html-react-parser';

import { StyledTournamentAdditionalInformationsContent } from '../../atoms/Tournament/StyledTournamentAdditionalInformationsContent';
import { StyledTournamentAdditionalData } from '../../atoms/Tournament/StyledTournamentAdditionalData';
import { StyledTournamentAdditionalInformationsContentWrapper } from '../../atoms/Tournament/StyledTournamentAdditionalInformationsContentWrapper';

import useScrollBar from '../../../logic/hooks/useScrollBar';

const TournamentAdditionalInformationsContent = ({ event }) => {
    const { scrollElement, isScrollBar } = useScrollBar();

    return (
        <StyledTournamentAdditionalInformationsContent>
            <StyledTournamentAdditionalInformationsContentWrapper
                isscrollbar={isScrollBar}
                ref={scrollElement}
            >
                <StyledTournamentAdditionalData>
                    {parse(event?.additional_info_text || '')}
                </StyledTournamentAdditionalData>
            </StyledTournamentAdditionalInformationsContentWrapper>
        </StyledTournamentAdditionalInformationsContent>
    );
};

export default TournamentAdditionalInformationsContent;
