import React from 'react';

import { StyledCubeWrapper } from '../../atoms/LoginCube/StyledCubeWrapper';

import { Styled444Cube } from '../../atoms/Cubes/Styled444Cube';

import useWindowSize from '../../../utils/getWindowSize';

const Cube444 = () => {
    const windowWidth = useWindowSize();

    return (
        <StyledCubeWrapper
            hasdeclaredleftposition={windowWidth < 1600 ? '90px' : '140px'}
            hasdeclaredtopposition={windowWidth < 1126 ? '60px' : '120px'}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, rotate: -22 }}
            transition={{ duration: 0.4 }}
        >
            <Styled444Cube
                width={windowWidth >= 2000 ? '240px' : '180px'}
                height={windowWidth >= 2000 ? '240px' : '180px'}
                fill="rgba(0,0,0,0.1)"
            />
        </StyledCubeWrapper>
    );
};

export default Cube444;
