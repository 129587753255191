import styled from 'styled-components';
import { scrollBlueStyles, userPanelElementStyles } from '../../../styles/sharedStyles';

export const StyledUserPanelEmployeeDataElement = styled.div`
    ${userPanelElementStyles};
`;

export const StyledElement = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
    padding-right: 10px;
    padding-left: 10px;
    width: 100%;
    position: relative;
`;

export const StyledHeader = styled.div`
    width: ${({ $removeOneIcon }) => $removeOneIcon ? "calc(100% - 54px)" : "calc(100% - 104px)"};
    overflow-x: scroll;
    align-self: ${({ hasalignself }) => (hasalignself ? hasalignself : 'flex-end')};

    ${scrollBlueStyles};

    > div {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        align-self: flex-end;
        border: 4px solid transparent;
        min-width: 700px;
    }
`;

export const StyledHeaderElement = styled.div`
    width: ${({ simulatecheckbox, $hasWidth }) =>
        simulatecheckbox ? '41px' : $hasWidth ? $hasWidth : '25%'};
    padding: 0 4px;
`;

export const StyledLeftBottomBtn = styled.button`
    width: 50px;
    height: 50px;
    align-self: flex-end;
    transform: translate(14px, 24px);
    border-radius: 9px;
    border: none;
    background-color: #6786be;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const StyledForm = styled.form``;
