import styled from 'styled-components';
import { ReactComponent as Cube } from '../../../images/cubes/skewb.svg';

export const StyledCubeSkewb = styled(({ iscontactpage, ...props }) => <Cube {...props} />)`
    position: absolute;
    left: ${({ hasdeclaredleft2 }) => (hasdeclaredleft2 ? hasdeclaredleft2 : '220px')};
    top: ${({ hasdeclaredtop2 }) => (hasdeclaredtop2 ? hasdeclaredtop2 : '500px')};
    transform: rotate(-30deg);
    fill: rgba(0, 0, 0, 0.1);
`;
