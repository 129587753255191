import React from 'react';

import { StyledCubeWrapper } from '../../atoms/LoginCube/StyledCubeWrapper';

import { StyledSkwebCube } from '../../atoms/Cubes/StyledSkewbCube';

import useWindowSize from '../../../utils/getWindowSize';

const CubeSkewb = () => {
    const windowWidth = useWindowSize();

    return (
        <StyledCubeWrapper
            hasdeclaredbottomposition={windowWidth < 1600 ? '60px' : '140px'}
            hasdeclaredleftposition={windowWidth < 1600 ? '120px' : '260px'}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, rotate: -44 }}
            transition={{ duration: 0.4, delay: 0.3 }}
        >
            <StyledSkwebCube
                width={windowWidth >= 2000 ? '290px' : '220px'}
                height={windowWidth >= 2000 ? '290px' : '220px'}
                fill="rgba(0,0,0,0.1)"
            />
        </StyledCubeWrapper>
    );
};

export default CubeSkewb;
