import React, { useState, useCallback, useMemo } from 'react';

import { StyledAllTrainerPayPopup, StyledButtonsWrapper } from './AllTrainerPayPopup.styles';

import { StyledText } from '../../atoms/Text/StyledText';
import { StyledButton } from '../../atoms/Button/StyledButton';
import AllTrainerPayPopupElement from '../AllTrainerPayPopupElement/AllTrainerPayPopupElement';
import PaymentsPopup from '../../organisms/Payments/PaymentsPopup';
import { getString } from '../../../strings';
import { paySalary } from '../../../logic/requests/students';
import { StyledBigLoader } from '../../atoms/Loader/StyledBigLoader';
import { useQueryClient } from '@tanstack/react-query';
import Decimal from 'decimal.js';

const AllTrainerPayPopup = ({ closePopup, trainers, month, handlePay }) => {
    const queryClient = useQueryClient();
    const [isPaymentSend, setIsPaymentSend] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [trainersToPay, setTrainersToPay] = useState([]);
    const [returnedTrainers, setReturnedTrainers] = useState([]);
    const [error, setError] = useState(null);

    const onSubmit = useCallback(
        (e) => {
            setError(null);
            e.preventDefault();
            setIsLoading(true);
            paySalary({ trainers: trainersToPay, month })
                .then((data) => {
                    setIsLoading(false);
                    setIsPaymentSend(true);
                    setReturnedTrainers(data.trainers);
                    handlePay();
                    queryClient.invalidateQueries({ queryKey: ['listAllPay'] });
                })
                .catch((e) => {
                    setIsLoading(false);
                    setError(e.data.message);
                });
        },
        [handlePay, month, queryClient, trainersToPay]
    );

    const handleChange = useCallback(
        (id, name, paidAmount, totalAmount) => {
            if (!paidAmount) {
                paidAmount = 0;
            }
            if (trainersToPay.some((t) => t.id === id)) {
                setTrainersToPay((prev) =>
                    prev.map((t) => (t.id === id ? { id, name, paidAmount, totalAmount } : t))
                );
            } else {
                setTrainersToPay((prev) => [...prev, { id, name, paidAmount, totalAmount }]);
            }
        },
        [trainersToPay]
    );

    const trainersList = useMemo(
        () =>
            trainers.length
                ? trainers.map((t) => (
                      <AllTrainerPayPopupElement
                          trainer={t}
                          key={t.id}
                          handleChange={handleChange}
                      />
                  ))
                : null,
        [trainers, handleChange]
    );

    const paidTrainersList = useMemo(
        () =>
            returnedTrainers.map((t) => (
                <StyledText
                    hasdeclaredfontsize="18px"
                    hasdeclaredfontweight="600"
                    hasdeclaredlineheight="1.8em"
                    hasdeclaredtextalign="center"
                > 
                    {t.name}: {t.isPaid ? `${new Decimal(t.paidAmount).toFixed(2).toString()} PLN` : <span style={{color: "#EE745C"}}>{t.message}</span>}
                </StyledText>
            )),
        [returnedTrainers]
    );

    return (
        <PaymentsPopup>
            <>
                {/* todo - isscrollbar === true when elements.length > 2 && isPaymentSend === false */}
                <StyledAllTrainerPayPopup isscrollbar={true && isPaymentSend === false}>
                    {error ? (
                        <StyledText
                            hasdeclaredfontsize="24px"
                            hasdeclaredfontweight="600"
                            hasdeclaredlineheight="1.4em"
                            hasdeclaredfontcolor="#EE745C"
                            hasdeclaredtexttransform="uppercase"
                            hasdeclaredtextalign="center"
                            hasdeclaredmargin="0 0 15px 0"
                        >
                            {error}
                        </StyledText>
                    ) : null}
                    {isPaymentSend ? (
                        <div>
                            <StyledText
                                hasdeclaredfontsize="24px"
                                hasdeclaredfontweight="600"
                                hasdeclaredlineheight="1.4em"
                                hasdeclaredfontcolor="#6786BE"
                                hasdeclaredtexttransform="uppercase"
                                hasdeclaredtextalign="center"
                                hasdeclaredmargin="0 0 15px 0"
                            >
                                {getString('all_trainer_pay_popup_zrealizowano_wyplaty')}
                            </StyledText>
                            {paidTrainersList}
                            <StyledButton
                                hasdeclaredbgcolor="#6786BE"
                                hasdeclaredpadding="0 28px"
                                hasdeclaredfontcolor="#fff"
                                hasdeclaredborderradius="25px"
                                hasdeclaredfontweight="700"
                                hasdeclaredtextalign="center"
                                hasdeclaredcursor="pointer"
                                hasdeclaredfontsize="18px"
                                hasdeclaredtexttransform="uppercase"
                                hasdeclaredwidth="100%"
                                hasdeclaredheight="50px"
                                hasdeclaredmargin="20px auto 0"
                                type="button"
                                onClick={closePopup}
                            >
                                {getString('all_trainer_pay_popup_zamknij')}
                            </StyledButton>
                        </div>
                    ) : isLoading ? (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <StyledBigLoader />
                        </div>
                    ) : (
                        <form>
                            {trainersList}
                            <StyledButtonsWrapper>
                                <StyledButton
                                    hasdeclaredbgcolor="#6786BE"
                                    hasdeclaredpadding="0 28px"
                                    hasdeclaredfontcolor="#fff"
                                    hasdeclaredborderradius="25px"
                                    hasdeclaredfontweight="700"
                                    hasdeclaredtextalign="center"
                                    hasdeclaredcursor="pointer"
                                    hasdeclaredfontsize="18px"
                                    hasdeclaredtexttransform="uppercase"
                                    hasdeclaredwidth="49%"
                                    hasdeclaredheight="50px"
                                    onClick={onSubmit}
                                >
                                    {getString('open_elment_wyplac')}
                                </StyledButton>
                                <StyledButton
                                    hasdeclaredbgcolor="#EE745C"
                                    hasdeclaredpadding="0 28px"
                                    hasdeclaredfontcolor="#fff"
                                    hasdeclaredborderradius="25px"
                                    hasdeclaredfontweight="700"
                                    hasdeclaredtextalign="center"
                                    hasdeclaredcursor="pointer"
                                    hasdeclaredfontsize="18px"
                                    hasdeclaredtexttransform="uppercase"
                                    hasdeclaredwidth="49%"
                                    hasdeclaredheight="50px"
                                    type="button"
                                    onClick={closePopup}
                                >
                                    {getString('open_element_anuluj')}
                                </StyledButton>
                            </StyledButtonsWrapper>
                        </form>
                    )}
                </StyledAllTrainerPayPopup>
            </>
        </PaymentsPopup>
    );
};

export default AllTrainerPayPopup;
