import { css } from 'styled-components';

export const scrollStyles = css`
    width: calc(100% - 104px);
    overflow-x: scroll;

    scrollbar-color: transparent transparent;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
        height: 0;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background: transparent;
    }
`;
