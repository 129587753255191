import styled from 'styled-components';
import { motion } from 'framer-motion';
import { scrollBlueStyles } from '../../../styles/sharedStyles';

export const StyledTrainingsJoinUs = styled(motion.div)`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justif-content: flex-start;

    max-height: ${({ issend }) => (issend ? '100%' : '90%')};
    overflow-y: ${({ issend }) => (issend ? 'visible' : 'scroll')};
    overflow-x: hidden;

    ${scrollBlueStyles};

    > form {
        width: 80%;
    }

    > h2 {
        a {
            color: #6786be;
            text-decoration: none;
            cursor: pointer;
        }
    }

    @media only screen and (max-width: 1192px) {
        > form {
            width: 100%;
        }

        > h2 {
            font-size: 18px;
        }
    }

    @media only screen and (max-width: 767px) {
        max-height: unset;
        height: auto;
        overflow-y: visible;
        padding: 0 20px 40px 20px;

        > h1 {
            font-size: 32px;
        }

        > h2 {
            font-size: 28px;
            font-weight: 600;
        }
    }

    @media only screen and (max-width: 450px) {
        > h1 {
            font-size: 24px;
        }

        > h2 {
            font-size: 18px;
        }
    }
`;
