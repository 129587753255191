import styled from 'styled-components';
import { scrollBlueStyles } from '../../../styles/sharedStyles';

export const StyledPayWrapper = styled.div`
    width: 100%;
    margin-top: 8px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    gap: 6px;
    height: calc(100% - 60px);
    padding-right: 15px;

    ${scrollBlueStyles};

    @media only screen and (max-width: 926px) {
        width: 100%;
        overflow-y: visible;
        height: auto;
        padding-right: 0;
    }
`;
