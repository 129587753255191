import styled from 'styled-components';

export const StyledUserPanelParentHeading = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px 30px 0;

    > p {
        &:last-child {
            padding-left: 20px;
        }

        > a {
            color: #ee745c;
            text-decoration: none;
        }
    }

    @media only screen and (max-width: 1502px) {
        flex-direction: column;

        p {
            margin-top: 10px;
            padding: 0;

            &:last-child {
                padding-left: 0;
            }
        }
    }

    @media only screen and (max-width: 926px) {
        padding: 0 20px;

        > h2 {
            font-size: 30px;
        }
    }
`;
