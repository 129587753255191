import styled from 'styled-components';

import { motion } from 'framer-motion';

import Background from '../../../images/gallery/gallerybg.jpg';
import { scrollBlueStyles, scrollRedStyles } from '../../../styles/sharedStyles';

export const StyledButtonsWrapper = styled.div`
    width: 320px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 34px;
`;

export const StyledChooseGallery = styled.div`
    margin: 22px 0;
    padding: 0 22px;
    background-color: #6786be;
    min-height: 50px;
    border-radius: 25px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    > p {
        width: 100%;
    }

    @media only screen and (max-width: 374px) {
        > p {
            font-size: 16px;
        }
    }
`;

export const StyledChoseImagesWrapper = styled(motion.div)`
    width: 62%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: 833px) {
        > h2 {
            font-size: 32px;
        }
    }

    @media only screen and (max-width: 767px) {
        > h2 {
            padding-top: 30px;
        }
    }
`;

export const StyledCloseButton = styled.div`
    position: absolute;
    top: 20px;
    right: 20px;
    width: 60px;
    height: 60px;
    cursor: pointer;
    border-radius: 50%;
    transform: rotate(45deg);

    &::after {
        content: '';
        width: 4px;
        height: 70%;
        background-color: ${({ isblack }) => (isblack ? '#000' : '#fff')};
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border-radius: 25px;
    }

    &::before {
        content: '';
        width: 70%;
        height: 4px;
        background-color: ${({ isblack }) => (isblack ? '#000' : '#fff')};
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border-radius: 25px;
    }
`;

export const StyledFavouriteWrapper = styled.div`
    width: 300px;

    @media only screen and (max-width: 993px) {
        width: 220px;
    }

    @media only screen and (max-width: 767px) {
        width: 100%;
    }
`;

export const StyledGalleryContent = styled(motion.div)`
    width: 62%;
    height: calc(100vh - 60px);
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 2;
    padding-top: 120px;
    padding-right: 20px;

    @media only screen and (min-width: 1920) {
        > h2 {
            font-size: 54px;
        }
    }

    @media only screen and (max-width: 1220px) {
        width: 60%;

        > h2 {
            font-size: 42px;
        }
    }

    @media only screen and (max-width: 767px) {
        width: 100%;
        padding-right: 0;

        > h2 {
            font-size: 32px;
        }
    }
`;

export const StyledGalleryContentWrapper = styled(motion.div)`
    max-height: 100%;
    overflow-y: scroll;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: flex-start;
    gap: 10px;
    padding-right: 10px;

    ${scrollBlueStyles};

    @media only screen and (max-width: 767px) {
        overflow-y: visible;
        padding-right: 0;
    }
`;

export const StyledGalleryImageWrapper = styled.div`
    width: 90%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const StyledGalleryIsOpenWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const StyledGalleryList = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: ${({ ismobile }) => (ismobile ? '100%' : '300px')};
`;

export const StyledGalleryMenu = styled.div`
    width: 25%;
    height: 95vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 220px;
    position: relative;
    z-index: 2;

    @media only screen and (max-width: 1305px) {
        width: 38%;
    }

    @media only screen and (max-width: 1220px) {
        width: 30%;
        margin-right: 10%;
    }

    @media only screen and (max-width: 1090px) {
        width: 35%;
        margin-right: 5%;
    }

    @media only screen and (max-width: 767px) {
        margin-bottom: ${({ ismobile }) => (ismobile ? '40px' : 'none')};
        width: 100%;
        height: auto;
        padding: 0 20px;
        margin-right: 0;
    }
`;

export const StyledGalleryMenuMobile = styled.div`
    width: 100%;
`;

export const StyledGalleryMenuWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    min-height: 100%;
    max-height: 100%;
    overflow-y: scroll;
    padding: 10px;

    ${scrollRedStyles};

    > div {
        > div {
            &::-webkit-scrollbar {
                background: transparent;
                width: 0;
            }

            &::-webkit-scrollbar-track {
                background: transparent;
                width: 0;
            }

            &::-webkit-scrollbar-thumb {
                background: transparent;
                width: 0;
            }

            &::-webkit-scrollbar-thumb:hover {
                background: transparent;
                width: 0;
            }
        }
    }

    @media only screen and (max-width: 767px) {
        overflow-y: visible;
        width: 100%;
    }
`;

export const StyledGalleryMenuWrapperDiv = styled.div`
    width: 100%;

    > div {
        &:last-child {
            width: 100%;
            > div {
                width: 100%;
                scrollbar-width: none;

                &::-webkit-scrollbar {
                    background: transparent;
                    width: 0;
                }

                &::-webkit-scrollbar-track {
                    background: transparent;
                    width: 0;
                }

                &::-webkit-scrollbar-thumb {
                    background: transparent;
                    width: 0;
                }

                &::-webkit-scrollbar-thumb:hover {
                    background: transparent;
                    width: 0;
                }
            }
        }
    }
`;

export const StyledGalleryPage = styled(motion.section)`
    min-height: 100vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: space-between;
    -ms-flex-pack: space-between;
    justify-content: space-between;
    position: relative;
    background-image: url(${Background});
    background-size: cover;
    background-repeat: no-repeat;

    @media only screen and (max-width: 767px) {
        flex-direction: column;
        justify-content: flex-start;
        align-item: center;
        background: #f9ec4b;
    }
`;

export const StyledHeart = styled.div`
    position: absolute;
    z-index: 2;
    bottom: 10px;
    right: 10px;
    width: 50px;
    height: 50px;

    > svg {
        width: 100%;
        height: 100%;
        cursor: pointer;
        fill: ${({ hasfill }) => (hasfill ? '#fff' : 'none')};
    }
`;

export const StyledImageListLoadingWrapper = styled(motion.div)`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: 510px) {
        > h1 {
            font-size: 32px;
        }
    }
`;

export const StyledImageWrapper = styled.div`
    width: 31%;
    height: 204px;
    position: relative;

    > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        cursor: pointer;
        border: 2px solid #fff;
    }

    @media only screen and (max-width: 1072px) {
        width: 49%;
    }

    @media only screen and (max-width: 909px) {
        width: 80%;
    }

    @media only screen and (max-width: 767px) {
        height: 300px;
        width: 49%;
    }

    @media only screen and (max-width: 628px) {
        width: 98%;
    }
`;

export const StyledLoadingWrapper = styled(motion.div)`
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: 510px) {
        > h1 {
            font-size: 32px;
        }
    }
`;

export const StyledModalGalleryWrapper = styled.div`
    width: 100%;
    height: 100vh;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 100;
`;

export const StyledModalImage = styled.div`
    width: auto;
    max-width: 90%;
    height: 60%;
    margin: 0 auto;
    position: relative;

    > div {
        width: 100%;
        height: 100%;

        > img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    @media only screen and (max-width: 767px) {
        height: 50%;

        > div {
            > img {
                object-fit: scale-down;
            }
        }
    }
`;
