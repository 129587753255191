import styled from 'styled-components';
import { scrollRedStyles } from '../../../styles/sharedStyles';

export const StyledElement = styled.div`
    padding: 0 10px;

    div {
        &.red {
            background-color: #ee745c;
            width: 100%;
            height: 2px;
            border-radius: 5px;
            margin-bottom: 20px;
        }
    }
`;

export const StyledAllTrainerPayPopup = styled.div`
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 20px;

    > form,
    > div {
        &:first-child {
            width: 100%;
            overflow-y: scroll;
            max-height: 600px;

            ${scrollRedStyles};
        }
    }
`;

export const StyledButtonsWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    padding: 0 10px;
`;

export const StyledCheckboxWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin: 20px 0 5px;
    width: 100%;
`;

export const StyledInputWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin: 20px 0;
    opacity: ${({ $disabled }) => ($disabled ? '0.4' : '1')};

    input {
        max-width: 300px;
        width: 300px;
        height: 40px;
        border-radius: 25px;
        font-size: 16px;
        border: 4px solid ${({ error }) => (error ? '#F74017' : '#6786BE')};
        padding: 0 12px;
        font-family: 'Poppins';
        outline: none;
        transition: outline 250ms;
        background: #fff;
    }

    > h4 {
        display: ${({ error }) => (error ? 'block' : 'none')};
    }
`;
