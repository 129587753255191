import { addHours } from '../../../logic/requests/students.js';

export const handleSubmit = (data, hasFree, onSucc, onErr, setErrors) => {
    if (
        !data.student ||
        isNaN(data.student) ||
        !data.subject ||
        typeof data.half !== 'boolean' ||
        ((!data.hours || isNaN(data.hours)) && !data.half) ||
        !data.date?.length
    ) {
        setErrors({
            student: !data.student || isNaN(data.student) ? true : false,
            subject: !data.subject ? true : false,
            half: typeof data.half !== 'boolean' ? true : false,
            hours: !data.hours || isNaN(data.hours) ? true : false,
            date: !data.date?.length ? true : false,
        });

        return;
    }

    if (data.half) delete data.hours;

    if (!hasFree && data.free) delete data.free;

    addHours(data)
        .then(() => {
            onSucc?.();
        })
        .catch((err) => {
            console.error(err);
            onErr?.();
        });
};
