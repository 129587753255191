import { useCallback, useMemo, useState } from 'react';
import { Field, Formik } from 'formik';
import * as yup from 'yup';
import Decimal from 'decimal.js';

import { StyledInputWrapper } from '../../../styles/sharedStyles';
import { StyledText } from '../../atoms/Text/StyledText';
import { StyledInputsButtonsWrapper } from '../../atoms/TrackHours/StyledInputsButtonsWrapper';
import { StyledUserPanelEmployeeElement } from '../../atoms/UserPanelEmployeeConfiguration/StyledUserPanelEmployeeElement';
import { StyledInputsDivWrapper } from '../../atoms/StyledInputsDivWrapper/StyledInputsDivWrapper';

import { getString } from '../../../strings';
import { getDateString } from '../../../logic/eventtime';
import { addEmployeeConf } from '../../../logic/requests/employees';
import { useQueryClient } from '@tanstack/react-query';

const initialValues = {
    trainer: '',
    price: 0,
    bonus_amount: null,
    bonus_hours: null,
};

const UserPanelEmployeeConfElement = ({ trainers, onSuccess }) => {
    const [trainerId, setTrainerId] = useState('');
    const trainer = useMemo(
        () => trainers.find(({ id }) => id === trainerId),
        [trainers, trainerId]
    );
    const queryClient = useQueryClient();

    const schema = useMemo(
        () =>
            yup.object({
                trainer: yup
                    .number()
                    .required(getString('Formik__field_is_required'))
                    .oneOf(trainers.map(({ id }) => id)),
                price: yup
                    .mixed()
                    .required(getString('Formik__field_is_required'))
                    .not([0], getString('Formik__field_is_required')),
                bonus_amount: yup.mixed().nullable(),
                bonus_hours: yup.number().positive().nullable(),
            }),
        [trainers]
    );

    const handleSubmit = (data, { setSubmitting, setFieldValue }, onSuccess) => {
        addEmployeeConf(data).then(() => {
            //TODO: Better reset
            setFieldValue('trainer', '');
            setFieldValue('price', ''); 
            setFieldValue('bonus_amount', '');  
            setFieldValue('bonus_hours', '');
            setSubmitting(false);
            onSuccess?.();
            queryClient.invalidateQueries(['trainers', 'all', 'employeeConf']);
        });
    };

    const onSubmit = useCallback(
        (data, formikHelpers) => {
            if (!trainer) return;

            const { firstname, lastname } = trainer;
            const price = data.price.replace(',', '.');
            const bonus_amount = data.bonus_amount
                ? data.bonus_amount.replace(',', '.')
                : data.bonus_amount;
            const bonus_hours = data.bonus_hours;

            handleSubmit(data, formikHelpers, () =>
                onSuccess([
                    {
                        title: getString('User_panel_employee_konfiguracja_trener'),
                        value: `${firstname} ${lastname}`,
                    },
                    {
                        title: getString('User_panel_employee_konfiguracja_stawka_godzinowa'),
                        value: (new Decimal(price).dp() > 0 ? price.toFixed(2) : price.toString()) + ' PLN',
                    },
                    {
                        title: getString('User_panel_employee_konfiguracja_wysokosc_premii'),
                        value: !bonus_amount
                            ? getString('none')
                            : (new Decimal(bonus_amount).dp() > 0
                                  ? bonus_amount.toFixed(2)
                                  : bonus_amount.toString()) + ' PLN',
                    },
                    {
                        title: getString('User_panel_employee_konfiguracja_godzin_do_premii'),
                        value: !bonus_hours ? getString('none') : bonus_hours.toString(),
                    },
                ])
            );
        },
        [trainer, onSuccess]
    );

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={onSubmit}
            validateOnChange={false}
            validateOnBlur={false}
        >
            {({ errors, initialValues, setFieldValue }) => (
                <StyledUserPanelEmployeeElement>
                    <StyledInputsDivWrapper>
                        <StyledInputWrapper error={errors.trainer}>
                            <label htmlFor="trainer">
                                <StyledText
                                    hasdeclaredfontsize="20px"
                                    hasdeclaredtextalign="center"
                                    hasdeclaredpadding="8px 0 8px 0"
                                    hasdeclaredfontweight="600"
                                >
                                    {getString('User_panel_employee_konfiguracja_trener')}
                                </StyledText>
                            </label>
                            <Field
                                as="select"
                                name="trainer"
                                defaultValue={initialValues.trainer}
                                onChange={({ target: { value } }) => {
                                    setFieldValue('price', '');
                                    setFieldValue('bonus_amount', '');
                                    setFieldValue('bonus_hours', '');
                                    const parsed = value ? parseInt(value, 10) : value;
                                    setTrainerId(parsed);
                                    setFieldValue('trainer', parsed);
                                    const trainer = trainers.find((t) => t.id === parsed);
                                    if (trainer?.price) setFieldValue('price', trainer.price);
                                    if (trainer?.bonus_amount)
                                        setFieldValue('bonus_amount', trainer.bonus_amount);
                                    if (trainer?.bonus_hours)
                                        setFieldValue('bonus_hours', trainer.bonus_hours);
                                }}
                            >
                                <option value="">
                                    - {getString('UserPanelAdditions__inputs__trainer__default')} -
                                </option>
                                {trainers.map(({ id, firstname, lastname }) => (
                                    <option key={id} value={id}>
                                        {lastname} {firstname}
                                    </option>
                                ))}
                            </Field>
                            <StyledText
                                as="h4"
                                hasdeclaredfontsize="16px"
                                hasdeclaredfontcolor="#F74017"
                                hasdeclaredtextalign="center"
                                hasdeclaredfontweight="700"
                                hasdeclaredtexttransform="uppercase"
                                hasdeclaredlineheight="1.4em"
                            >
                                {errors.trainer}
                            </StyledText>
                        </StyledInputWrapper>
                        <StyledInputWrapper error={errors.price}>
                            <label htmlFor="price">
                                <StyledText
                                    hasdeclaredfontsize="20px"
                                    hasdeclaredtextalign="center"
                                    hasdeclaredpadding="8px 0 8px 0"
                                    hasdeclaredfontweight="600"
                                >
                                    {getString('User_panel_employee_konfiguracja_stawka_godzinowa')}
                                </StyledText>
                            </label>
                            <Field
                                as="input"
                                name="price"
                                defaultValue={initialValues.price}
                                step="any"
                            />
                            <StyledText
                                as="h4"
                                hasdeclaredfontsize="16px"
                                hasdeclaredfontcolor="#F74017"
                                hasdeclaredtextalign="center"
                                hasdeclaredfontweight="700"
                                hasdeclaredtexttransform="uppercase"
                                hasdeclaredlineheight="1.4em"
                            >
                                {errors.price}
                            </StyledText>
                        </StyledInputWrapper>
                    </StyledInputsDivWrapper>
                    <StyledInputsDivWrapper>
                        <StyledInputWrapper error={errors.bonus_amount}>
                            <label htmlFor="bonus_amount">
                                <StyledText
                                    hasdeclaredfontsize="20px"
                                    hasdeclaredtextalign="center"
                                    hasdeclaredpadding="8px 0 8px 0"
                                    hasdeclaredfontweight="600"
                                >
                                    {getString('User_panel_employee_konfiguracja_wysokosc_premii')}
                                </StyledText>
                            </label>
                            <Field
                                as="input"
                                name="bonus_amount"
                                step="any"
                                defaultValue={initialValues.bonus_amount ?? 0}
                            />
                            <StyledText
                                as="h4"
                                hasdeclaredfontsize="16px"
                                hasdeclaredfontcolor="#F74017"
                                hasdeclaredtextalign="center"
                                hasdeclaredfontweight="700"
                                hasdeclaredtexttransform="uppercase"
                                hasdeclaredlineheight="1.4em"
                            >
                                {errors.bonus_amount}
                            </StyledText>
                        </StyledInputWrapper>
                        <StyledInputWrapper error={errors.bonus_hours}>
                            <label htmlFor="bonus_hours">
                                <StyledText
                                    hasdeclaredfontsize="20px"
                                    hasdeclaredtextalign="center"
                                    hasdeclaredpadding="8px 0 8px 0"
                                    hasdeclaredfontweight="600"
                                >
                                    {getString('User_panel_employee_konfiguracja_godzin_do_premii')}
                                </StyledText>
                            </label>
                            <Field
                                as="input"
                                type="number"
                                name="bonus_hours"
                                min={0}
                                defaultValue={initialValues.bonus_hours ?? 0}
                                onChange={({ target: { value } }) => {
                                    const parsed = value ? parseInt(value, 10) : value;
                                    return setFieldValue(
                                        'bonus_hours',
                                        isNaN(parsed) || !parsed ? null : parsed
                                    );
                                }}
                            />
                            <StyledText
                                as="h4"
                                hasdeclaredfontsize="16px"
                                hasdeclaredfontcolor="#F74017"
                                hasdeclaredtextalign="center"
                                hasdeclaredfontweight="700"
                                hasdeclaredtexttransform="uppercase"
                                hasdeclaredlineheight="1.4em"
                            >
                                {errors.bonus_hours}
                            </StyledText>
                        </StyledInputWrapper>
                    </StyledInputsDivWrapper>
                    <StyledInputsButtonsWrapper>
                        <input
                            type="submit"
                            value={getString('User_panel_employee_konfiguracja_zapisz')}
                        />
                        <input
                            type="reset"
                            value={getString('User_panel_employee_konfiguracja_anuluj')}
                        />
                    </StyledInputsButtonsWrapper>
                </StyledUserPanelEmployeeElement>
            )}
        </Formik>
    );
};

export default UserPanelEmployeeConfElement;
