import { useCallback } from 'react';
import { createAnnouncement } from '../../../logic/requests/announcements';
import { useQueryClient } from '@tanstack/react-query';
import AnnouncementForm from './AnnouncementForm/AnnouncementForm';
import { getString } from '../../../strings';

const initialValues = {
    title: '',
    text: '',
};

const handleSubmit = (data, { setSubmitting, resetForm }, onSuccess) => {
    createAnnouncement(data)
        .then((res) => {
            resetForm();
            onSuccess?.();
        })
        .catch((err) => {
            console.error('createAnnouncement', err);
        })
        .finally(() => {
            setSubmitting(false);
        });
};

const CreateAnnouncement = ({ roles, onSuccessfulAdd }) => {
    const queryClient = useQueryClient();

    const onSubmit = useCallback(
        (data, ...params) => {
            return handleSubmit(data, ...params, () => {
                queryClient.invalidateQueries('announcements');
                const platforms = [];
                if (data.sendMail) platforms.push(getString('User_panel_announcements_email'));
                if (data.sendDiscord) platforms.push(getString('User_panel_announcements_discord'));
                const infos = [
                    {
                        title: getString('User_panel_announcements_title') + ':',
                        value: getString(data.title),
                    },
                    {
                        title: getString('User_panel_announcements_roles') + ':',
                        value: data.roles.map((role) => getString(`roles_${role}`)).join(',  '),
                    },
                ];
                if (data.sendMail || data.sendDiscord) {
                    infos.push({
                        title: getString('User_panel_announcements_platforms') + ':',
                        value: platforms.join(', '),
                    });
                }
                onSuccessfulAdd(infos);
            });
        },
        [onSuccessfulAdd, queryClient]
    );

    return <AnnouncementForm initialValues={initialValues} roles={roles} onSubmit={onSubmit} />;
};

export default CreateAnnouncement;
