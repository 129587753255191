const getDateString = (time) =>
    time instanceof Date
        ? `${String(time.getDate()).padStart(2, "0")}.${String(time.getMonth() + 1).padStart(
              2,
              "0"
          )}.${time.getFullYear()}`
        : "";

const getTimeString = (time) =>
    time instanceof Date
        ? `${String(time.getHours()).padStart(2, "0")}:${String(time.getMinutes()).padStart(
              2,
              "0"
          )}`
        : "";

const getDateTimeString = (time) =>
    time instanceof Date ? `${getDateString(time)}, ${getTimeString(time)}` : "";

const getUTCDateString = (time) =>
    time instanceof Date
        ? `${String(time.getUTCDate()).padStart(2, "0")}.${String(time.getUTCMonth() + 1).padStart(
              2,
              "0"
          )}.${time.getUTCFullYear()}`
        : "";

const getUTCTimeString = (time) =>
    time instanceof Date
        ? `${String(time.getUTCHours()).padStart(2, "0")}:${String(time.getUTCMinutes()).padStart(
              2,
              "0"
          )}`
        : "";

const getUTCDateTimeString = (time) =>
    time instanceof Date ? `${getUTCDateString(time)}, ${getUTCTimeString(time)}` : "";

export {
    getDateString,
    getTimeString,
    getDateTimeString,
    getUTCDateString,
    getUTCTimeString,
    getUTCDateTimeString,
};
