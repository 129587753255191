import styled from 'styled-components';

export const StyledDataWrapper = styled.div`
    min-width: 700px;
    display: flex;
    justify-content: space-between;
    min-height: 50px;
    align-items: center;
    border-radius: 25px 9px 9px 25px;
    border: 4px solid
        ${({ hasdeclaredbordercolor }) => (hasdeclaredbordercolor ? '#ee745c' : '#6786BE')};
`;
