import React from 'react';

import RegisterErrorMessage from '../../molecules/Register/RegisterErrorMessage';

import { StyledSelectWrapper } from '../../atoms/Register/StyledSelectWrapper';
import { StyledSelectInput } from '../../molecules/Register/StyledSelectInput';
import { StyledText } from '../../atoms/Text/StyledText';

import { getString } from '../../../strings';

const SelectCoach = ({ title, name, error, onChange, annotation, coaches }) => {
    return (
        <StyledSelectWrapper>
            <label htmlFor={name}>
                <StyledText
                    hasdeclaredfontsize="20px"
                    hasdeclaredtextalign="center"
                    hasdeclaredpadding="8px 0 8px 0"
                    hasdeclaredfontweight="600"
                    hasdeclaredlineheight="1.4em"
                >
                    {title}
                </StyledText>
            </label>
            <StyledSelectInput as="select" error={error} name={name} onChange={onChange}>
                <option
                    label={getString(
                        coaches?.length
                            ? 'trainings_orderrek_wybierztrenera'
                            : coaches
                            ? 'trainings_orderrek_braktrenerow'
                            : 'trainings_orderrek_wybierzkonkurencjeaby'
                    )}
                />
                {coaches?.length
                    ? coaches.map((coach) => (
                          <option
                              key={coach.id}
                              value={coach.id}
                              label={`${coach.firstname} ${coach.lastname}`}
                          />
                      ))
                    : undefined}
            </StyledSelectInput>
            {annotation && (
                <StyledText
                    hasdeclaredfontsize="12px"
                    hasdeclaredtextalign="center"
                    hasdeclaredpadding="2px 0 1px 0"
                    hasdeclaredfontweight="600"
                >
                    {annotation}
                </StyledText>
            )}
            <RegisterErrorMessage name={name} />
        </StyledSelectWrapper>
    );
};

export default SelectCoach;
