import React, { useCallback, useMemo, useState } from 'react';
import { ScrollSyncPane } from 'react-scroll-sync';
import Decimal from 'decimal.js';

import { StyledEmployeeTableDataElement } from './EmployeeTableDataElement.styles';
import { StyledEditInputElement, StyledClosePayElement } from '../../../styles/sharedStyles';
import { StyledText } from '../../atoms/Text/StyledText';
import { StyledSavePayElement } from '../../atoms/PayElement/StyledSavePayElement';
import { StyledGlobalUserPanelWrapper } from '../../atoms/StyledGlobalUserPanelWrapper/StyledGlobalUserPanelWrapper';
import { StyledEditWrapper } from '../../atoms/StyledEditWrapper/StyledEditWrapper';
import { StyledTextElement } from '../../atoms/StyledTextElement/StyledTextElement';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { formSchema } from './EmployeeTableDataElement.data';

import CloseIcon from '../../atoms/UserPanelStudens/CloseIcon';
import PencilIcon from '../../atoms/UserPanelStudens/PencilIcon';
import AcceptIcon from '../../atoms/UserPanelStudens/AcceptIcon';

import { getLang, getString } from '../../../strings';
import { deleteTrainerSubject, editTrainerSubject } from '../../../logic/requests/subjects';
import { StyledFuckingBtn } from '../AdditionDataElement/AdditionDataElement.styles';
import { useQueryClient } from '@tanstack/react-query';
import PaymentsPopup from '../../organisms/Payments/PaymentsPopup';

const emptyValue = '...';

const EmployeeTableDataElement = ({
    subjectDescription,
    price,
    trainerId,
    allSubjects,
    subjectId,
}) => {
    const queryClient = useQueryClient();

    const [isEdit, setIsEdit] = useState(false);
    const [confirmPopup, setConfirmPopup] = useState({ show: false });

    const {
        register,
        handleSubmit,
        formState: { errors, isDirty },
    } = useForm({
        resolver: yupResolver(formSchema),
        defaultValues: {
            subject: subjectId,
            price,
        },
    });

    const onSubmit = useCallback(
        (dataForm) => {
            if (!isDirty) {
                setIsEdit(false)

                return;
            };

            editTrainerSubject({
                subjectId,
                trainerId,
                trainerPrice: dataForm.price.replace(',', '.'),
            })
                .then(() => {
                    queryClient.invalidateQueries({
                        queryKey: ['trainers', 'all', 'employeeConf'],
                    });
                    setIsEdit(false);
                    setConfirmPopup({ show: true, message: getString("employee_table_data_element_poprawnie_zmodyfikowano_przedmiot")});
                })
                .catch(() => setConfirmPopup({ show: true, message: getString("employee_table_data_element_problem_zmodyfikowano_przedmiot")}));
        },
        [isDirty, queryClient, subjectId, trainerId]
    );

    const displayPrice = useMemo(() => {
        if (!price) return emptyValue;

        const parsed = new Decimal(price);
        return parsed.dp() > 0 ? parsed.toFixed(2) : parsed.toString();
    }, [price]);

    const retEditButton = useMemo(
        () =>
            isEdit ? (
                <StyledSavePayElement isedit={isEdit} type="submit">
                    <AcceptIcon />
                </StyledSavePayElement>
            ) : (
                <StyledFuckingBtn isedit={isEdit} onClick={() => setIsEdit(true)}>
                    <PencilIcon />
                </StyledFuckingBtn>
            ),
        [isEdit]
    );

    const handleDelete = useCallback(() => {
        deleteTrainerSubject({
            subjectId,
            trainerId
        }).then(() => {
            setConfirmPopup({ show: true, message: getString("employee_table_data_element_usunieto") })
            queryClient.invalidateQueries({
                queryKey: ['trainers', 'all', 'employeeConf'],
            });
        })
    }, [queryClient, subjectId, trainerId])

    return (
        <>
            {confirmPopup.show ? (
                <PaymentsPopup
                    title={
                        confirmPopup.message
                    }
                    onClose={() => {
                        setConfirmPopup({ show: false });
                    }}
                />
            ) : null}
            <StyledEmployeeTableDataElement onSubmit={handleSubmit(onSubmit)}>
                <ScrollSyncPane>
                    <div>
                        {isEdit ? (
                            <StyledEditWrapper>
                                <StyledEditInputElement
                                    error={errors.subject}
                                    $hasDeclaredWidth="50%"
                                >
                                    <StyledText
                                        hasdeclaredfontsize="13px"
                                        hasdeclaredfontweight="600"
                                        hasdeclaredtextalign="center"
                                    >
                                        {subjectDescription[getLang()]}
                                    </StyledText>
                                </StyledEditInputElement>
                                <StyledEditInputElement
                                    error={errors.price}
                                    $hasDeclaredWidth="50%"
                                >
                                    <input id="price" {...register('price')} />
                                </StyledEditInputElement>
                            </StyledEditWrapper>
                        ) : (
                            <StyledGlobalUserPanelWrapper>
                                <StyledTextElement $hasDeclaredWidth="50%">
                                    <StyledText
                                        hasdeclaredfontsize="13px"
                                        hasdeclaredfontweight="600"
                                        hasdeclaredtextalign="center"
                                    >
                                        {subjectDescription[getLang()]}
                                    </StyledText>
                                </StyledTextElement>
                                <StyledTextElement $hasDeclaredWidth="50%">
                                    <StyledText
                                        hasdeclaredfontsize="13px"
                                        hasdeclaredfontweight="600"
                                        hasdeclaredtextalign="center"
                                    >
                                        {displayPrice}
                                    </StyledText>
                                </StyledTextElement>
                            </StyledGlobalUserPanelWrapper>
                        )}
                    </div>
                </ScrollSyncPane>
                {retEditButton}
                <StyledClosePayElement type="button" onClick={handleDelete}>
                    <CloseIcon />
                </StyledClosePayElement>
            </StyledEmployeeTableDataElement>
        </>
    );
};

export default EmployeeTableDataElement;
