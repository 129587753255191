import React, { useRef, useEffect } from "react";
import { getString } from "../../../strings";
import ScriptContent from "./ScriptContent";

import { StyledUserPanelTournamentScript } from "../../atoms/UserPanelTournamentScript/StyledUserPanelTournamentScript";
import { StyledUserIdWrapper } from "../../atoms/UserPanelTournamentScript/StyledUserIdWrapper";
import { StyledUserIdContentWrapper } from "../../molecules/UserPanelTournamentScript/StyledUserIdContentWrapper";
import { StyledButtonWrapper } from "../../molecules/UserPanelTournamentScript/StyledButtonWrapper";

import { StyledButton } from "../../atoms/Button/StyledButton";
import { StyledText } from "../../atoms/Text/StyledText";

const UserPanelTournamentScript = ({ user, eventScript, close }) => {
    const containerRef = useRef(null);

    useEffect(() => {
        containerRef.current.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "nearest" });
    }, []);

    return (
        <StyledUserPanelTournamentScript ref={containerRef}>
            <StyledUserIdWrapper>
                <StyledUserIdContentWrapper hasdeclaredpadding="0 24px 0 0">
                    <StyledText
                        hasdeclaredfontsize="38px"
                        hasdeclaredfontweight="700"
                        hasdeclaredpadding="0 8px 0 0"
                    >
                        {getString("userPanel_userPanelTournamentScript_id")}
                    </StyledText>
                    <StyledText hasdeclaredfontsize="38px" hasdeclaredfontweight="700">
                        {eventScript.registrantId}
                    </StyledText>
                </StyledUserIdContentWrapper>
                <StyledUserIdContentWrapper>
                    <StyledText
                        hasdeclaredfontsize="38px"
                        hasdeclaredfontweight="700"
                        hasdeclaredpadding="0 8px 0 0"
                    >
                        {getString("userPanel_userPanelTournamentScript_wcaID")}
                    </StyledText>
                    <StyledText hasdeclaredfontsize="38px" hasdeclaredfontweight="700">
                        {user.wca_id}
                    </StyledText>
                </StyledUserIdContentWrapper>
            </StyledUserIdWrapper>
            <ScriptContent activities={eventScript.activities} />
            <StyledButtonWrapper>
                <StyledButton
                    hasdeclaredfontweight="bold"
                    hasdeclaredfontsize="18px"
                    hasdeclaredtextalign="center"
                    hasdeclaredbgcolor="#4E6BAA"
                    hasdeclaredfontcolor="#fff"
                    hasdeclaredtexttransform="uppercase"
                    hasdeclaredborderradius="25px"
                    hasdeclaredcursor="pointer"
                    whileHover={{ scale: 0.95 }}
                    onClick={close}
                >
                    {getString("userPanel_userPanelTournamentScript_zamknij")}
                </StyledButton>
            </StyledButtonWrapper>
        </StyledUserPanelTournamentScript>
    );
};

export default UserPanelTournamentScript;
