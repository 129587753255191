import styled from 'styled-components';

export const StyledChooseTournamentWrapper = styled.div`
    width: 100%;
    padding: 0 20px;
    min-height: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;
