import styled from 'styled-components';

export const StyledAttemptResultsSubmit = styled.div`
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    @media only screen and (max-width: 868px) {
        > h2 {
            font-size: 22px;
        }
    }
`;
