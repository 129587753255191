import React, { useEffect, useState } from 'react';

import PlayerElement from './PlayerElement';
import Loader from '../../organisms/Loader/Loader';

const playersPerUpdate = 15,
    // około - sprawdzone ręcznie, nie idzie znaleźć o tym informacji
    reactMaxUpdates = 50;

const PlayerList = ({ players, triggerParentScroll, renderingFinishedCallback }) => {
    const [rendered, setRendered] = useState(players.slice(0, playersPerUpdate)),
        [lastIndex, setLastIndex] = useState(playersPerUpdate),
        [updates, setUpdates] = useState(0),
        [stopUpdate, setStopUpdate] = useState(false);

    useEffect(() => {
        if (stopUpdate) return;

        if (lastIndex >= players.length) {
            renderingFinishedCallback?.();
            return;
        }

        let newIndex = lastIndex + playersPerUpdate;

        // nie wychodź poza przedział indeksów listy
        if (newIndex > players.length) newIndex = players.length;

        setRendered((oldRendered) => [...oldRendered, ...players.slice(lastIndex, newIndex)]);
        setLastIndex(newIndex);
        setUpdates(updates + 1);

        if (updates > reactMaxUpdates - 2) {
            // przerywamy "nieskończoną" pętlę aktualizacji
            // zapobiega błędowi "maximum update depth reached"
            setUpdates(0);
            setStopUpdate(true);
            setTimeout(() => setStopUpdate(false), 1);
        }

        triggerParentScroll?.();
    }, [lastIndex, players, updates, stopUpdate]);

    return (
        <>
            {rendered.map((player, index) => (
                <PlayerElement playerData={player} key={index} />
            ))}
            {!lastIndex >= players.length ? <Loader /> : null}
        </>
    );
};

export default PlayerList;
