import React from 'react';
import { Link } from 'react-router-dom';

import { StyledCountdownButton } from '../../atoms/EventCountdown/StyledCountdownButton';
import { StyledText } from '../../atoms/Text/StyledText';
import { StyledCountdownContent } from '../../atoms/EventCountdown/StyledCountdownContent';

const EventCountdownContent = ({ eventId, button, isDisabled, isErr, date, time }) => {
    return (
        <StyledCountdownContent>
            <StyledText
                hasdeclaredfontsize="28px"
                hasdeclaredmargin="22px 0"
                hasdeclaredtextalign="center"
            >
                Zawody rozpoczną się:
            </StyledText>
            <StyledText
                hasdeclaredfontsize="54px"
                hasdeclaredmargin="0 0 22px 0"
                hasdeclaredfontcolor={isErr && 'red'}
                hasdeclaredtextalign="center"
                as="h3"
            >
                {date}
            </StyledText>
            <StyledText
                hasdeclaredfontsize="54px"
                hasdeclaredmargin="0 0 22px 0"
                hasdeclaredtextalign="center"
                as="h4"
            >
                {time}
            </StyledText>
            {button ? (
                <Link to={`/SelectOfCompetition/${eventId}`}>
                    <StyledCountdownButton disabled={isDisabled} isDisabled={isDisabled}>
                        Weź udział
                    </StyledCountdownButton>
                </Link>
            ) : (
                <></>
            )}
        </StyledCountdownContent>
    );
};

export default EventCountdownContent;
