import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useToggle } from 'react-use';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';

import SettlementsDoneDataElement from './SettlementsDoneDataElement';

import { StyledSettlementsDoneElement } from '../../atoms/SettlementsDoneElement/StyledSettlementsDoneElement';
import { StyledSettlementsCheckBoxWrapper } from '../../atoms/SettlementsDoneElement/StyledSettlementsCheckBoxWrapper';
import { StyledContentElement } from '../../atoms/SettlementsDoneElement/StyledContentElement';
import { StyledHeading } from '../../atoms/SettlementsDoneElement/StyledHeading';
import { StyledHeadingCheckbox } from '../../atoms/SettlementsDoneElement/StyledHeadingCheckbox';
import { StyledDataHeaderElement } from '../../atoms/SettlementsDoneElement/StyledDataHeaderElement';
import { StyledText } from '../../atoms/Text/StyledText';
import { StyledCheckboxDone } from '../../atoms/SettlementsDoneElement/StyledCheckboxDone';
import { StyledArrow, StyledHeader, StyledDataWrapper } from '../../../styles/sharedStyles';
import { StyledDataSum } from '../../atoms/SettlementsDoneDataElement/StyledDataSum';
import { StyledDesc } from '../../atoms/SettlementsDoneDataElement/StyledDesc';
import { StyledButtonsWrapper } from '../../atoms/UserPanelParent/StyledButtonsWrapper';
import { StyledButton } from '../../atoms/Button/StyledButton';
import { ReactComponent as CheckboxAccept } from '../../../images/checkboxAccept.svg';

import { payDues } from '../../../logic/requests/students.js';
import { getString } from '../../../strings';
import useScrollBar from '../../../logic/hooks/useScrollBar';
import { StyledFlexBoxWrapper } from '../OpenElement/OpenElement.styles';

const handleSubmit = (container, all_hours, month, show_popup, submit_all) => {
    const checked_hours = [...container.querySelectorAll('[id^="pay-"]')]
            .filter((checkbox) => checkbox.checked)
            .map(({ id }) => parseInt(id.split('-').slice(1).join('-'))),
        hours = (submit_all ? Object.values(all_hours).map(({ id }) => id) : checked_hours).map(
            (id) => all_hours.find((hour) => hour.id === id)?.id
        ),
        data = {
            month,
            hours,
        };

    if (show_popup) {
        const now = new Date();
        if (month.split('_')[0] === `${now.getFullYear()}-${now.getMonth()}`) {
            return show_popup(() =>
                handleSubmit(container, all_hours, month, undefined, submit_all)
            );
        }
    }

    payDues(data)
        .then((res) => {
            if (res.token) {
                const service = process.env.NODE_ENV === 'production' || process.env.NODE_ENV === "uat" ? 'secure' : 'sandbox';
                window.location = `https://${service}.przelewy24.pl/trnRequest/${res.token}`;
            }
        })
        .catch((err) => {
            console.log('payDues', err);
        });
};

const SettlementsDoneElement = ({
    id,
    name,
    hours,
    isTrainer = false,
    isPaid = false,
    parentIsSelecting = false,
    parentSetIsSelecting,
    onIsSelectingChange,
    bonus,
    showPopup,
    triggerParentScroll,
    isFirstChild,
}) => {
    const [isOpen, toggleIsOpen] = useToggle(false);
    useEffect(() => triggerParentScroll?.(), [triggerParentScroll, isOpen]);

    const { scrollElement, isScrollBar } = useScrollBar(false, true);

    const [isSelecting, reallySetIsSelecting] = useState(false);

    useEffect(() => {
        if (parentIsSelecting) reallySetIsSelecting(false);
    }, [parentIsSelecting]);
    const setIsSelecting = useCallback(
        (val) => {
            parentSetIsSelecting?.(false);
            reallySetIsSelecting(val);
        },
        [parentIsSelecting, reallySetIsSelecting]
    );
    useEffect(() => {
        const diff = isSelecting ? 1 : -1;
        onIsSelectingChange?.((val) => {
            const ret = val + diff;
            return ret < 0 ? val : ret;
        });
    }, [isSelecting, onIsSelectingChange]);
    useEffect(() => {
        if (!isOpen) reallySetIsSelecting(false);
    }, [isOpen, reallySetIsSelecting]);

    const hoursContainer = useRef();

    return (
        <StyledSettlementsDoneElement>
            {!isPaid && parentIsSelecting ? (
                <StyledSettlementsCheckBoxWrapper>
                    <StyledCheckboxDone>
                        <input type="checkbox" id={`accept-${id}`} />
                        <label htmlFor={`accept-${id}`}>
                            <CheckboxAccept />
                        </label>
                    </StyledCheckboxDone>
                </StyledSettlementsCheckBoxWrapper>
            ) : undefined}
            <StyledContentElement
                isfirstchild={true}
                isopen={isOpen}
                hasdeclaredbordercolor={!isPaid}
                parentIsSelecting={parentIsSelecting}
            >
                <StyledHeading onClick={toggleIsOpen}>
                    <StyledText
                        hasdeclaredfontsize="22px"
                        hasdeclaredfontweight="700"
                        hasdeclaredtextalign="center"
                    >
                        {name}
                    </StyledText>
                    <StyledHeadingCheckbox hasdeclaredbgcolor={!isPaid}>
                        <StyledArrow isopen={isOpen} />
                    </StyledHeadingCheckbox>
                </StyledHeading>
                {isOpen && (
                    <StyledDataWrapper>
                        <ScrollSync>
                            <>
                                <StyledFlexBoxWrapper calcwidth={isSelecting}>
                                    <ScrollSyncPane>
                                        <StyledHeader
                                            calcwidth={isSelecting}
                                            isscrollbar={isScrollBar}
                                            ref={scrollElement}
                                        >
                                            <div>
                                                <StyledDataHeaderElement>
                                                    <StyledText
                                                        hasdeclaredfontsize="16px"
                                                        hasdeclaredfontweight="700"
                                                        hasdeclaredtextalign="center"
                                                        hasdeclaredlineheight="1.4em"
                                                    >
                                                        {getString(
                                                            'Trainings_trainings_settlements_done_element_lekcja'
                                                        )}
                                                    </StyledText>
                                                </StyledDataHeaderElement>
                                                <StyledDataHeaderElement>
                                                    <StyledText
                                                        hasdeclaredfontsize="16px"
                                                        hasdeclaredfontweight="700"
                                                        hasdeclaredtextalign="center"
                                                        hasdeclaredlineheight="1.4em"
                                                    >
                                                        {getString(
                                                            'Trainings_trainings_settlements_done_element_data'
                                                        )}
                                                    </StyledText>
                                                </StyledDataHeaderElement>
                                                <StyledDataHeaderElement>
                                                    <StyledText
                                                        hasdeclaredfontsize="16px"
                                                        hasdeclaredfontweight="700"
                                                        hasdeclaredtextalign="center"
                                                        hasdeclaredlineheight="1.4em"
                                                    >
                                                        {getString(
                                                            'Trainings_trainings_settlements_done_element_godziny'
                                                        )}
                                                    </StyledText>
                                                </StyledDataHeaderElement>
                                                <StyledDataHeaderElement>
                                                    <StyledText
                                                        hasdeclaredfontsize="16px"
                                                        hasdeclaredfontweight="700"
                                                        hasdeclaredtextalign="center"
                                                        hasdeclaredlineheight="1.4em"
                                                    >
                                                        {getString(
                                                            'Trainings_trainings_settlements_done_element_trener'
                                                        )}
                                                    </StyledText>
                                                </StyledDataHeaderElement>
                                                <StyledDataHeaderElement>
                                                    <StyledText
                                                        hasdeclaredfontsize="16px"
                                                        hasdeclaredfontweight="700"
                                                        hasdeclaredtextalign="center"
                                                        hasdeclaredlineheight="1.4em"
                                                    >
                                                        {getString(
                                                            'Trainings_trainings_settlements_done_element_cena'
                                                        )}
                                                    </StyledText>
                                                </StyledDataHeaderElement>
                                                <StyledDataHeaderElement>
                                                    <StyledText
                                                        hasdeclaredfontsize="16px"
                                                        hasdeclaredfontweight="700"
                                                        hasdeclaredtextalign="center"
                                                        hasdeclaredlineheight="1.4em"
                                                    >
                                                        {getString(
                                                            'Trainings_trainings_settlements_done_element_platnosci'
                                                        )}
                                                    </StyledText>
                                                </StyledDataHeaderElement>
                                            </div>
                                        </StyledHeader>
                                    </ScrollSyncPane>
                                </StyledFlexBoxWrapper>
                                <StyledFlexBoxWrapper
                                    diff
                                    ref={hoursContainer}
                                    style={{ marginTop: '4px' }}
                                >
                                    {hours.map((hour) => (
                                        <SettlementsDoneDataElement
                                            key={hour.id}
                                            hour={hour}
                                            parentIsSelecting={isSelecting}
                                            paid={isPaid}
                                        />
                                    ))}
                                </StyledFlexBoxWrapper>
                            </>
                        </ScrollSync>
                        {bonus ? (
                            <StyledDesc>
                                <div>
                                    <StyledText
                                        hasdeclaredfontsize="18px"
                                        hasdeclaredfontweight="600"
                                        hasdeclaredlineheight="1.4em"
                                        hasdeclaredfontcolor="#6786BE"
                                    >
                                        {getString(
                                            'Trainings_trainings_settlements_done_element_dodatek'
                                        )}{' '}
                                        <span style={{ color: '#000' }}>150 PLN</span>
                                    </StyledText>
                                </div>
                                <div>
                                    <StyledText
                                        hasdeclaredfontsize="18px"
                                        hasdeclaredfontweight="600"
                                        hasdeclaredlineheight="1.4em"
                                        hasdeclaredfontcolor="#6786BE"
                                    >
                                        {getString(
                                            'Trainings_trainings_settlements_done_element_opis'
                                        )}{' '}
                                        <span style={{ color: '#000' }}>
                                            {' '}
                                            Wyrównanie wynagrodzenia za miesiąc październik oraz
                                            dodatek kierowniczy za miesiąc listopad
                                        </span>
                                    </StyledText>
                                </div>
                            </StyledDesc>
                        ) : undefined}
                        {isTrainer ? (
                            <StyledDataSum>
                                <div>
                                    <StyledText
                                        hasdeclaredfontsize="18px"
                                        hasdeclaredfontweight="600"
                                        hasdeclaredlineheight="1.4em"
                                        hasdeclaredfontcolor="#6786BE"
                                    >
                                        {getString(
                                            'Trainings_trainings_settlements_done_element_lacznaLiczaGodzin'
                                        )}{' '}
                                        <span style={{ color: '#000' }}>150 PLN</span>
                                    </StyledText>
                                </div>
                                <div>
                                    <StyledText
                                        hasdeclaredfontsize="18px"
                                        hasdeclaredfontweight="600"
                                        hasdeclaredlineheight="1.4em"
                                        hasdeclaredfontcolor="#6786BE"
                                    >
                                        {getString(
                                            'Trainings_trainings_settlements_done_element_lacznaKwotaOplacona'
                                        )}
                                        : <span style={{ color: '#000' }}>150 PLN</span>
                                    </StyledText>
                                </div>
                                <div>
                                    <StyledText
                                        hasdeclaredfontsize="18px"
                                        hasdeclaredfontweight="600"
                                        hasdeclaredlineheight="1.4em"
                                        hasdeclaredfontcolor="#6786BE"
                                    >
                                        {getString(
                                            'Trainings_trainings_settlements_done_element_statusPlatnosci'
                                        )}{' '}
                                        <span style={{ color: '#000' }}>150 PLN</span>
                                    </StyledText>
                                </div>
                                <div>
                                    <StyledText
                                        hasdeclaredfontsize="18px"
                                        hasdeclaredfontweight="600"
                                        hasdeclaredlineheight="1.4em"
                                        hasdeclaredfontcolor="#6786BE"
                                    >
                                        {getString(
                                            'Trainings_trainings_settlements_done_element_dodatki'
                                        )}{' '}
                                        <span style={{ color: '#000' }}>150 PLN</span>
                                    </StyledText>
                                </div>
                            </StyledDataSum>
                        ) : undefined}
                        {isPaid ? undefined : !isTrainer ? (
                            <StyledButtonsWrapper bottomsize>
                                <StyledButton
                                    hasdeclaredbgcolor={isSelecting ? '#EE745C' : '#6786BE'}
                                    hasdeclaredpadding="0 28px"
                                    hasdeclaredfontcolor="#fff"
                                    hasdeclaredborderradius="25px"
                                    hasdeclaredfontweight="700"
                                    hasdeclaredtextalign="center"
                                    hasdeclaredcursor="pointer"
                                    hasdeclaredfontsize="18px"
                                    onClick={() => setIsSelecting((val) => !val)}
                                >
                                    {isSelecting
                                        ? getString(
                                              'Trainings_trainings_settlements_done_element_anuluj'
                                          )
                                        : getString(
                                              'Trainings_trainings_settlements_done_element_wybierzIOplac'
                                          )}
                                </StyledButton>
                                <StyledButton
                                    hasdeclaredbgcolor="#6786BE"
                                    hasdeclaredpadding="0 28px"
                                    hasdeclaredfontcolor="#fff"
                                    hasdeclaredborderradius="25px"
                                    hasdeclaredfontweight="700"
                                    hasdeclaredtextalign="center"
                                    hasdeclaredcursor="pointer"
                                    hasdeclaredfontsize="18px"
                                    whileHover={{ scale: 0.95 }}
                                    onClick={() =>
                                        handleSubmit(
                                            hoursContainer.current,
                                            hours,
                                            id,
                                            showPopup,
                                            !isSelecting
                                        )
                                    }
                                >
                                    {getString(
                                        'Trainings_trainings_settlements_done_element_oplac'
                                    )}{' '}
                                    {isSelecting
                                        ? getString(
                                              'Trainings_trainings_settlements_done_element_zaznaczone'
                                          )
                                        : getString(
                                              'Trainings_trainings_settlements_done_element_wszystko'
                                          )}
                                </StyledButton>
                            </StyledButtonsWrapper>
                        ) : undefined}
                    </StyledDataWrapper>
                )}
            </StyledContentElement>
        </StyledSettlementsDoneElement>
    );
};

export default SettlementsDoneElement;
