import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { getString } from '../../../strings';
import { Formik, Field } from 'formik';
import { AnimatePresence } from 'framer-motion';

import RegisterCompetitions from '../../molecules/Register/RegisterCompetitions';
import InputWrapper from '../../molecules/Register/InputWrapper';
import SelectSize from '../../molecules/Register/SelectSize';
import SelectCountry from '../../molecules/Register/SelectCountry';

import { StyledRegisterForm } from '../../../components/atoms/Register/StyledRegisterForm';
import { StyledText } from '../../../components/atoms/Text/StyledText';
import { StyledButton } from '../../atoms/Button/StyledButton';
import { StyledInputsWrapper } from '../../atoms/Register/StyledInputsWrapper';
import { StyledSendFileInput } from '../../molecules/Register/StyledSendFileInput';
import { StyledSendFileLabel } from '../../atoms/Register/StyledSendFileLabel';
import { StyledAvatarInformation } from '../../atoms/Register/StyledAvatarInformation';
import { StyledRegisterButtons } from '../../atoms/Register/StyledRegisterButtons';
import { StyledCheckboxWrapper } from '../../molecules/Register/StyledCheckboxWrapper';
import { StyledButtonSvg } from '../../atoms/Login/StyledLogin';
import { StyledAdnotationText } from '../../atoms/Register/StyledAdnotationText';
import { StyledWCAIDInformation } from '../../molecules/Register/StyledWCAIDInformation';

import { ReactComponent as AvatarIcon } from '../../../images/register/avatarIcon.svg';
import { ReactComponent as CheckboxAccept } from '../../../images/checkboxAccept.svg';

import { validationSchema } from '../../constants/registerValidationSchema';
import { initialValues } from '../../constants/registerInitialValues';

import { register, checkWCA } from '../../../logic/requests/users.js';
import { readFileAsync, arrayBufferToBase64 } from '../../../logic/file.js';

const RegisterForm = () => {
    const navigate = useNavigate();

    const [isAvatarInformation, setIsAvatarInformation] = useState(false);
    const [isAccepted, setIsAccepted] = useState(false);
    const [isClicked, setIsClicked] = useState(0);
    const [isAvatarSrc, setIsAvatarSrc] = useState(false);
    const [isFileReady, setIsFileReady] = useState(true);
    const [avatar, setAvatar] = useState();
    const inputElement = useRef(null);
    const [registerError, setRegisterError] = useState();
    const [wcaInfoBox, setWcaInfoBox] = useState(false);
    const [submitData, setSubmitData] = useState();

    const sendFileHandler = (e) => {
        if (isClicked == 0) setIsAvatarInformation(true);
        setIsClicked(1);
        if (!isAccepted) e.preventDefault();
        setIsAccepted(true);
    };
    const acceptInformationHandler = () => {
        inputElement.current.click();
        setIsAvatarInformation(false);
    };
    const handleFile = (event) => {
        if (event.target.files && event.target.files.length) {
            const file = event.target.files[0];

            if (!file.type.startsWith('image')) {
                return;
            }

            setIsFileReady(false);
            (async () => {
                try {
                    const ret = await readFileAsync(file);
                    setAvatar(arrayBufferToBase64(ret));
                    setIsAvatarSrc(file.type);
                } catch (err) {
                    console.error('readFileAsync', err);
                }
                setIsFileReady(true);
            })();
        }
    };

    const handleSubmit = async (data, props) => {
        setWcaInfoBox(false);

        if (data.wca_id) {
            let res;
            try {
                res = await checkWCA(data.wca_id);

                if (!res?.person?.name?.startsWith?.(`${data.firstname} ${data.lastname}`)) {
                    // nie zgadza się; really_handleSubmit będzie wywołany z closeWcaPopUp
                    setSubmitData({ data, props });
                    setWcaInfoBox(true);
                    return;
                }
            } catch (err) {
                console.error('checkWCA', err);
                // TODO: Co teraz? Pokazać coś?
            }
        }

        really_handleSubmit(data, props);
    };

    const closeWcaPopUp = (accepted) => {
        setWcaInfoBox(false);

        if (accepted) {
            really_handleSubmit(submitData.data, submitData.props);
        } else {
            submitData.props.setSubmitting(false);
        }
    };

    const really_handleSubmit = (data, { setSubmitting }) => {
        if (!isFileReady) {
            // TODO: pokazać coś?
            setSubmitting(false);
            return;
        }

        const comp_names = [
                '222',
                '333',
                '333bf',
                '333oh',
                '444',
                '555',
                '666',
                '777',
                'clock',
                'minx',
                'pyram',
                'skewb',
                'sq1',
            ],
            user_data = {
                ...// kopiujemy dane do nowego obiektu
                // inaczej są bug'i przy wielokrotnej próbie rejestracji jeśli
                // obiekt przekazany przez parametr ('data') zostanie zmodyfikowany
                Object.fromEntries(
                    Object.keys(data)
                        .filter(
                            // wszystko poza konkurencjami
                            (key) => !comp_names.includes(key)
                        )
                        .map((key) => [key, data[key]])
                ),
                competitions: Object.fromEntries(
                    Object.keys(data)
                        .filter(
                            // tylko konkurencje
                            (key) => comp_names.includes(key)
                        )
                        .map((key) => [key, data[key]])
                ),
            };

        for (const prop of ['firstname', 'lastname', 'city', 'wca_id']) {
            if (user_data[prop]) user_data[prop] = user_data[prop].trim();
        }

        if (user_data['wca_id']) user_data['wca_id'] = user_data['wca_id'].toUpperCase();

        // dodajemy avatar osobno bo Formik ssie i podaje tylko ścieżkę do pliku
        if (avatar) user_data.avatar = avatar;

        register(user_data)
            .then(() => {
                navigate('/thank-you', {
                    state: {
                        email: user_data.email,
                    },
                });
            })
            .catch((err) => {
                setRegisterError(
                    (() => {
                        switch (err?.data?.code) {
                            case 'db_unique':
                                const friendly_names = {
                                    'wca_id': 'WCA ID',
                                    'email': getString('register_registerForm_adresieEmail'),
                                };
                                const constraint = err?.data?.target?.split('_UNIQUE')?.[0],
                                    field = friendly_names?.[constraint];
                                return field
                                    ? `${getString(
                                          'register_registerForm_uzytkownikOPodanym'
                                      )} ${field} ${getString('register_registerForm_juzIstnieje')}`
                                    : constraint
                                    ? `${getString(
                                          'register_registerForm_ograniczenie'
                                      )} ${constraint} ${getString(
                                          'register_registerForm_nieSpelnioneWBazie'
                                      )}`
                                    : true;
                            case 'db_other':
                                return getString('register_registerForm_nieznanyBladZapisu');
                            default:
                                return getString('register_registerForm_nieznanyBlad');
                        }
                    })()
                );
                setSubmitting(false);
            });
    };
    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            validateOnChange={false}
            validateOnBlur={false}
        >
            {({ errors, handleSubmit, isSubmitting, values, setFieldValue }) => (
                <AnimatePresence>
                    <StyledRegisterForm onSubmit={handleSubmit}>
                        <StyledText
                            hasdeclaredfontweight="bold"
                            hasdeclaredfontsize="72px"
                            hasdeclaredtextalign="center"
                            as="h1"
                            hasdeclaredpadding="36px 0"
                        >
                            {getString('register_registerForm_zarejestrujSie')}
                        </StyledText>
                        <StyledSendFileInput>
                            <StyledSendFileLabel
                                htmlFor="avatar"
                                issend={isAvatarSrc ? true : false}
                            >
                                {isAvatarSrc ? (
                                    <img
                                        src={`data:${isAvatarSrc};base64,${avatar}`}
                                        alt="avatar"
                                    />
                                ) : (
                                    <AvatarIcon />
                                )}
                            </StyledSendFileLabel>
                            <Field
                                type="file"
                                name="avatar"
                                id="avatar"
                                innerRef={inputElement}
                                onClick={(e) => sendFileHandler(e)}
                                onChange={handleFile}
                            />
                            <StyledText hasdeclaredfontweight="600">
                                {getString('register_registerForm_ustawAwatar')}
                            </StyledText>
                        </StyledSendFileInput>
                        {isAvatarInformation ? (
                            <StyledAvatarInformation
                                key={isAvatarInformation}
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                            >
                                <StyledText hasdeclaredfontsize="22px">
                                    {getString('register_registerForm_zanimPrzesleszZdjecie')}
                                </StyledText>
                                <StyledText>{getString('register_registerForm_list1')}</StyledText>
                                <StyledText>{getString('register_registerForm_list2')}</StyledText>
                                <StyledText>{getString('register_registerForm_list3')}</StyledText>
                                <StyledText>{getString('register_registerForm_list4')}</StyledText>
                                <StyledText>{getString('register_registerForm_list5')}</StyledText>
                                <StyledButton
                                    hasdeclaredbgcolor="#3474e8"
                                    hasdeclaredpadding="14px 20px"
                                    hasdeclaredfontcolor="#fff"
                                    hasdeclaredborderradius="22px"
                                    hasdeclaredmargin="24px 0 0 0"
                                    hasdeclaredfontsize="16px"
                                    hasdeclaredcursor="pointer"
                                    onClick={acceptInformationHandler}
                                    whileHover={{ scale: 1.05 }}
                                    whileTap={{ scale: 0.9 }}
                                >
                                    {getString('register_registerForm_akceptuje')}
                                </StyledButton>
                            </StyledAvatarInformation>
                        ) : null}
                        <StyledInputsWrapper>
                            <InputWrapper
                                inputType="text"
                                title={getString('register_registerForm_firstnameTitle')}
                                name="firstname"
                                error={errors.firstname}
                            />
                            <InputWrapper
                                inputType="text"
                                title={getString('register_registerForm_lastnameTitle')}
                                name="lastname"
                                error={errors.lastname}
                            />
                            <InputWrapper
                                inputType="email"
                                title={getString('register_registerForm_emailTitle')}
                                name="email"
                                error={errors.email}
                            />
                            <InputWrapper
                                inputType="password"
                                title={getString('register_registerForm_passwordTitle')}
                                name="password"
                                error={errors.password}
                            />
                            <InputWrapper
                                inputType="date"
                                title={getString('register_registerForm_dateTitle')}
                                name="birthdate"
                                error={errors.birthdate}
                            />
                            <InputWrapper
                                inputType="select"
                                title={getString('register_registerForm_plecTitle')}
                                name="gender"
                                error={errors.gender}
                            />
                            <SelectCountry
                                title={getString('register_registerForm_panstwoTitle')}
                                name="country"
                                inputType="select"
                                error={errors.country}
                            />
                            <InputWrapper
                                inputType="text"
                                title={getString('register_registerForm_miejscowoscTitle')}
                                name="city"
                                error={errors.city}
                            />
                            <InputWrapper
                                inputType="text"
                                title={getString('register_registerForm_numertelefonuTitle')}
                                name="phone"
                                error={errors.phone}
                                hasDeclaredBottomPosition="8px"
                            />
                            <InputWrapper
                                inputType="text"
                                title={getString('register_registerForm_wcaidTitle')}
                                name="wca_id"
                                error={errors.wca_id}
                                annotation={getString('register_registerForm_wcaidAnnotation')}
                                hasDeclaredBottomPosition="-24px"
                            />
                            {wcaInfoBox && (
                                <StyledWCAIDInformation>
                                    <div>
                                        <StyledText
                                            hasdeclaredfontsize="16px"
                                            hasdeclaredfontweight="700"
                                            hasdeclaredtextalign="center"
                                            hasdeclaredlineheight="1.4em"
                                            hasdeclaredfontcolor="#F74017"
                                        >
                                            {getString('register_registerForm_ostrzezenie')}
                                        </StyledText>
                                        <div>
                                            <StyledButton
                                                hasdeclaredbgcolor="#3474e8"
                                                hasdeclaredpadding="12px 18px"
                                                hasdeclaredfontcolor="#fff"
                                                hasdeclaredborderradius="25px"
                                                hasdeclaredfontweight="700"
                                                hasdeclaredmargin="22px 0 0 0"
                                                hasdeclaredtextalign="center"
                                                hasdeclaredcursor="pointer"
                                                whileTap={{ scale: 0.9 }}
                                                whileHover={{ scale: 1.05 }}
                                                onClick={() => closeWcaPopUp(true)}
                                            >
                                                {getString('register_registerForm_akceptuje2')}
                                            </StyledButton>
                                            <StyledButton
                                                hasdeclaredbgcolor="rgb(223, 123, 99)"
                                                hasdeclaredpadding="12px 18px"
                                                hasdeclaredfontcolor="#fff"
                                                hasdeclaredborderradius="25px"
                                                hasdeclaredfontweight="700"
                                                hasdeclaredmargin="4px 0 0 0"
                                                hasdeclaredtextalign="center"
                                                hasdeclaredcursor="pointer"
                                                whileTap={{ scale: 0.9 }}
                                                whileHover={{ scale: 1.05 }}
                                                onClick={() => closeWcaPopUp(false)}
                                            >
                                                {getString('register_registerForm_anuluj')}
                                            </StyledButton>
                                        </div>
                                    </div>
                                </StyledWCAIDInformation>
                            )}
                        </StyledInputsWrapper>
                        <StyledText
                            hasdeclaredfontsize="24px"
                            hasdeclaredtextalign="center"
                            hasdeclaredpadding="38px 0 8px 0"
                            hasdeclaredfontweight="600"
                        >
                            {getString('register_registerForm_ustawSwojeDomyslne')}
                        </StyledText>
                        <RegisterCompetitions
                            competitions={[
                                '222',
                                '333',
                                '333bf',
                                '333oh',
                                '444',
                                '555',
                                '666',
                                '777',
                                'clock',
                                'minx',
                                'pyram',
                                'skewb',
                                'sq1',
                            ]}
                        />
                        <SelectSize
                            title={getString('register_registerForm_domyslnyRozmiarKoszulki')}
                            name="shirtSize"
                            error={errors.shirtSize}
                            onChange={(event) => setFieldValue('shirtsize', event.target.value)}
                        />
                        <StyledRegisterButtons>
                            <div>
                                <StyledCheckboxWrapper
                                    error={errors.regulationsField}
                                    hasdeclaredadnotationcolor="#EE745C"
                                >
                                    <Field
                                        type="checkbox"
                                        name="regulationsField"
                                        id="regulationsField"
                                    />
                                    <label htmlFor="regulationsField">
                                        <CheckboxAccept />
                                    </label>
                                    <StyledText
                                        hasdeclaredfontweight="bold"
                                        hasdeclaredpadding="0 0 0 14px"
                                    >
                                        {getString('register_registerForm_akceptuje3')}{' '}
                                        <a
                                            aria-label={getString(
                                                'seo_aria_label_przejdz_do_strony'
                                            )}
                                            href="/regulamin"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            {getString('register_registerForm_regulamin')}
                                        </a>
                                    </StyledText>
                                </StyledCheckboxWrapper>
                                <StyledAdnotationText>
                                    <StyledText
                                        as="h4"
                                        hasdeclaredfontsize="12px"
                                        hasdeclaredpadding="2px 0 1px 0"
                                        hasdeclaredfontweight="600"
                                        hasdeclaredlineheight="1.4em"
                                    >
                                        {getString('register_registerForm_wRegularminieZnajduje')}
                                    </StyledText>
                                </StyledAdnotationText>
                                <StyledCheckboxWrapper>
                                    <Field type="checkbox" name="newsletter" id="newsletter" />
                                    <label htmlFor="newsletter">
                                        <CheckboxAccept />
                                    </label>
                                    <StyledText
                                        hasdeclaredfontweight="bold"
                                        hasdeclaredpadding="0 0 0 14px"
                                    >
                                        {getString('register_registerForm_wyrazamZgodeNa')}
                                    </StyledText>
                                </StyledCheckboxWrapper>
                            </div>
                            <StyledButton
                                hasdeclaredfontcolor="#fff"
                                hasdeclaredfontsize="18px"
                                hasdeclaredbgcolor="#4c7bbc"
                                hasdeclaredpadding="12px 18px"
                                hasdeclaredtextalign="center"
                                hasdeclaredcursor="pointer"
                                hasdeclaredwidth="100px"
                                hasdeclaredheight="65px"
                                hasdeclaredborderradius="35px"
                                hasdeclaredmargin="0 0 0 24px"
                                type="submit"
                                disabled={isSubmitting}
                                whileTap={{ scale: 0.9 }}
                                whileHover={{ scale: 1.05 }}
                            >
                                <StyledButtonSvg />
                            </StyledButton>
                        </StyledRegisterButtons>
                        {registerError ? (
                            <StyledText
                                hasdeclaredfontsize="16px"
                                hasdeclaredfontweight="600"
                                hasdeclaredfontcolor="#F74017"
                                hasdeclaredlineheight="1.4em"
                            >
                                {getString('register_registerForm_bladRejestracji')}
                                {typeof registerError === 'string' ? `: ${registerError}` : null}
                            </StyledText>
                        ) : (
                            <></>
                        )}
                    </StyledRegisterForm>
                </AnimatePresence>
            )}
        </Formik>
    );
};

export default RegisterForm;
