import React, { useRef, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import AnnouncementElement from '../AnnouncementElement/AnnouncementElement';

import { StyledUserPanelAnnouncements, StyledScrollWrapper } from './StyledUserPanelAnnouncements';
import { StyledText } from '../../atoms/Text/StyledText';

import { getString } from '../../../strings';
import {
    listAnnouncements,
    listRolesForAnnouncements,
} from '../../../logic/requests/announcements';
import CreateAnnouncement from './CreateAnnouncement';
import useSuccessMessage from '../../../logic/hooks/useSuccessMessage';
import SuccessMessage from '../../molecules/SuccessMessage';
import { StyledLoaderWrapper } from '../../../styles/sharedStyles';
import Loader from '../Loader/Loader';
import { StyledTextWrapper } from '../AnnouncementElement/StyledAnnouncementElement';

const UserPanelAnnouncements = ({ isAdmin, isManager }) => {
    const scrollElement = useRef(null);
    const [isTrigger, setIsTrigger] = useState(false);
    const [isScrollBar, setIsScrollbar] = useState(false);
    const { data: announcements } = useQuery(['announcements'], listAnnouncements);
    const { data: roles } = useQuery(['roles', 'announcements'], listRolesForAnnouncements);
    const {
        infos: successMessageInfos,
        isShowing: isSuccessMessage,
        show: showSuccessMessage,
    } = useSuccessMessage();

    const onSuccessfulAdd = (infos) => {
        showSuccessMessage(infos);
    };

    useEffect(() => {
        const element = scrollElement?.current;
        if (element?.clientHeight < element?.scrollHeight) {
            setIsScrollbar(true);
        } else {
            setIsScrollbar(false);
        }
    }, [isTrigger]);

    return (
        <StyledUserPanelAnnouncements $centerItems={!announcements}>
            {!announcements ? (
                <StyledLoaderWrapper>
                    <Loader />
                </StyledLoaderWrapper>
            ) : !announcements.length && !isAdmin && !isManager ? (
                <StyledTextWrapper>
                    <StyledText
                        hasdeclaredtexttransform="uppercase"
                        hasdeclaredpadding="0 20px 0 20px"
                        hasdeclaredfontsize="clamp(26px, 2.396vw, 46px)"
                        hasdeclaredfontweight="700"
                        hasdeclaredtextalign="center"
                        hasdeclaredmaxwidth="600px"
                        hasdeclaredlineheight="1.4em"
                        as="h2"
                    >
                        {getString('pages_userPanel_no_announcements')}
                    </StyledText>
                </StyledTextWrapper>
            ) : (
                <>
                    <StyledText
                        hasdeclaredpadding="20px 20px 30px 0"
                        hasdeclaredfontsize="36px"
                        hasdeclaredfontweight="700"
                        hasdeclaredlineheight="1.4em"
                        as="h2"
                    >
                        {getString('pages_userPanel_ogloszenia')}:
                    </StyledText>
                    <StyledScrollWrapper isscrollbar={isScrollBar} ref={scrollElement}>
                        {(isAdmin || isManager) && (
                            <>
                                <CreateAnnouncement
                                    roles={roles}
                                    onSuccessfulAdd={onSuccessfulAdd}
                                />
                                {isSuccessMessage ? (
                                    <SuccessMessage
                                        title={getString(
                                            'UserPanelAnnouncements__success__created'
                                        )}
                                        infos={successMessageInfos}
                                    />
                                ) : undefined}
                                <StyledText
                                    hasdeclaredfontsize="22px"
                                    hasdeclaredfontweight="700"
                                    hasdeclaredtextalign="center"
                                    hasdeclaredtexttransform="uppercase"
                                    hasdeclaredmargin="clamp(30px, 4vw, 80px) 0 20px 0"
                                >
                                    {getString('UserPanelAnnouncementsList')}:
                                </StyledText>
                            </>
                        )}
                        {announcements?.map((announcement) => (
                            <AnnouncementElement
                                key={announcement.id}
                                id={announcement.id}
                                setIsTrigger={() => setIsTrigger(!isTrigger)}
                                isRead={announcement.isRead}
                                title={announcement.title}
                                createdBy={announcement.createdBy}
                                updatedBy={announcement.updatedBy}
                                updatedAt={announcement.updatedAt}
                                recipientsCount={announcement.recipientsCount}
                                readCount={announcement.readCount}
                                roles={announcement.roles}
                                text={announcement.text}
                                date={announcement.date}
                                manageableRoles={roles}
                                canEdit={announcement.canEdit}
                            />
                        ))}
                    </StyledScrollWrapper>
                </>
            )}
        </StyledUserPanelAnnouncements>
    );
};

export default UserPanelAnnouncements;
