import React from 'react';
import { motion } from 'framer-motion';

import { getString, getLang } from '../../../strings';

import { StyledCard } from '../../molecules/Home/StyledCard';
import { StyledText } from '../../atoms/Text/StyledText';
import { StyledCardBackgroundImage } from '../../atoms/StyledCardBackgroundImage/StyledCardBackgroundImage';
import { StyledTournamentLink, StyledTrainingSvg } from '../../atoms/Home/StyledHome';

import mobileTraining from '../../../images/mobile/treningiMobile.jpg';
import useWindowSize from '../../../utils/getWindowSize';

const HomePageCardTraining = () => {
    const width = useWindowSize();

    return (
        <StyledTournamentLink isang={getLang() === 'gb' ? true : false} to="/trainings">
            <StyledCard scaleSvg isang={getLang() === 'gb' ? true : false}>
                {width < 768 ? (
                    <StyledCardBackgroundImage src={mobileTraining} alt="tournaments" />
                ) : null}
                <motion.div
                    initial={{ rotate: 30 }}
                    animate={{ rotate: 0 }}
                    transition={{ duration: 0.2, delay: 0.2 }}
                >
                    <StyledTrainingSvg isang={getLang() === 'gb' ? true : false} />
                </motion.div>
                <StyledText
                    hasdeclaredfontsize="72px"
                    hasdeclaredfontcolor="#000"
                    hasdeclaredfontweight="600"
                    hascursor="pointer"
                    hasdeclaredpadding="24px 0px 0px"
                    as="h2"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.2, delay: 0.2 }}
                >
                    {getString('home_homePageCardTournaments_treningi')}
                </StyledText>
            </StyledCard>
        </StyledTournamentLink>
    );
};

export default HomePageCardTraining;
