import styled from 'styled-components';
import { motion } from 'framer-motion';

export const StyledMenuItemLinkWrapper = styled(motion.div)`
    background-color: #d2e2ff;
    width: 100%;
    height: 61px;

    a {
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        height: 100%;
        color: #000;
        text-decoration: none;
        font-family: 'FilsonProRegular';

        @media only screen and (max-width: 400px) {
            font-size: 12px;
        }
    }
`;
