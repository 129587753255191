import styled from 'styled-components';

export const StyledTournamentsWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    z-index: 2;

    @media only screen and (max-width: 725px) {
        > h1 {
            padding-top: 180px;
            font-size: 50px;
        }
    }

    @media only screen and (max-width: 513px) {
        > h1 {
            padding-top: 100px;
        }
    }

    @media only screen and (max-width: 420px) {
        > h1 {
            font-size: 44px;
        }
    }

    @media only screen and (max-width: 365px) {
        > h1 {
            padding-top: 140px;
            font-size: 38px;
        }
    }
`;
