import styled from 'styled-components';

export const StyledButtonWrapper = styled.div`
    padding-top: 10px;
    width: 100%;
    display: flex;
    justify-content: center;

    > button {
        background-color: #6786be;
        color: #fff;
        cursor: pointer;
        text-transform: uppercase;
        border-radius: 25px;
        height: 50px;
        width: 50%;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    @media only screen and (max-width: 1082px) {
        > button {
            font-size: ${({ bottomsize }) => (bottomsize ? '14px' : '16px')};
        }
    }

    @media only screen and (max-width: 926px) {
        > button {
            font-size: 18px;

            > p {
                font-size: 18px;
            }
        }
    }

    @media only screen and (max-width: 610px) {
        > button {
            font-size: 14px;

            > p {
                font-size: 14px;
            }
        }
    }

    @media only screen and (max-width: 515px) {
        padding: ${({ haspadding }) => (haspadding ? '10px 20px 0' : '0')};

        > button {
            width: 100%;
            font-size: 16px;

            > p {
                font-size: 16px;
            }
        }
    }
`;
