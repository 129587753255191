import React, { useCallback, useMemo } from 'react';
import { Field, Formik } from 'formik';
import * as yup from 'yup';

import { StyledInputWrapper } from '../../../styles/sharedStyles';
import { StyledText } from '../../atoms/Text/StyledText';
import { StyledInputsButtonsWrapper } from '../../atoms/TrackHours/StyledInputsButtonsWrapper';
import { StyledUserPanelAdditionsElement } from '../../atoms/UserPanelAdditions/StyledUserPanelAdditionsElement';
import { StyledInputsDivWrapper } from '../../atoms/StyledInputsDivWrapper/StyledInputsDivWrapper';

import { getString } from '../../../strings';
import { addAddition } from '../../../logic/requests/students';

const defaultValues = {
    trainer: '',
    amount: '0',
    date: new Date().toISOString().split('T')[0],
    description: '',
};

const handleSubmit = (data, { setSubmitting, resetForm }, onSuccess) => {
    addAddition(data)
        .then((res) => {
            resetForm();
            onSuccess?.();
        })
        .catch((err) => {
            console.error('addAddition', err);
        })
        .finally(() => {
            setSubmitting(false);
        });
};

const UserPanelAdditionsElement = ({ trainers, onSuccess }) => {
    const schema = useMemo(
        () =>
            yup.object().shape({
                trainer: yup
                    .number()
                    .required(getString('Formik__field_is_required'))
                    .oneOf(trainers.map(({ id }) => id)),
                amount: yup
                    .number()
                    .required(getString('Formik__field_is_required'))
                    .not([0], getString('Formik__field_is_required')),
                date: yup.date().required(getString('Formik__field_is_required')),
                description: yup.string().notRequired(),
            }),
        [trainers]
    );

    const onSubmit = useCallback(
        (data, ...params) => {
            const trainer = trainers.find(({ id }) => id === data.trainer);

            return handleSubmit(
                data,
                ...params,
                onSuccess?.({
                    month: data.date.split('-').slice(0, 2).join('-'),
                    infos: [
                        {
                            title:
                                getString('UserPanelAdditions__success__infos__title__trainer') +
                                ':',
                            value: `${trainer.lastname} ${trainer.firstname}`,
                        },
                        {
                            title:
                                getString('UserPanelAdditions__success__infos__title__amount') +
                                ':',
                            value: `${data.amount} PLN`,
                        },
                        {
                            title:
                                getString('UserPanelAdditions__success__infos__title__date') + ':',
                            value: data.date.split('-').reverse().join('.'),
                        },
                        ...(data.description?.length
                            ? [
                                  {
                                      title:
                                          getString(
                                              'UserPanelAdditions__success__infos__title__description'
                                          ) + ':',
                                      value: data.description,
                                  },
                              ]
                            : []),
                    ],
                })
            );
        },
        [trainers, onSuccess]
    );

    return (
        <Formik
            initialValues={defaultValues}
            validationSchema={schema}
            onSubmit={onSubmit}
            validateOnChange={false}
            validateOnBlur={false}
            on
        >
            {({ errors, initialValues, setFieldValue }) => (
                <StyledUserPanelAdditionsElement>
                    <StyledInputsDivWrapper>
                        <StyledInputWrapper error={errors.trainer} hasdeclaredwidth="100%">
                            <label htmlFor="trainer">
                                <StyledText
                                    hasdeclaredfontsize="20px"
                                    hasdeclaredtextalign="center"
                                    hasdeclaredpadding="8px 0 8px 0"
                                    hasdeclaredfontweight="600"
                                >
                                    {getString('User_panel_addition_dodaj_trener')}
                                </StyledText>
                            </label>
                            <Field
                                as="select"
                                name="trainer"
                                defaultValue={initialValues.trainer}
                                onChange={(e) =>
                                    setFieldValue(
                                        'trainer',
                                        e.target.value.length
                                            ? parseInt(e.target.value, 10)
                                            : undefined
                                    )
                                }
                            >
                                <option value="">
                                    - {getString('UserPanelAdditions__inputs__trainer__default')} -
                                </option>
                                {trainers.map(({ id, firstname, lastname }) => (
                                    <option key={id} value={id}>
                                        {lastname} {firstname}
                                    </option>
                                ))}
                            </Field>
                            <StyledText
                                as="h4"
                                hasdeclaredfontsize="16px"
                                hasdeclaredfontcolor="#F74017"
                                hasdeclaredtextalign="center"
                                hasdeclaredfontweight="700"
                                hasdeclaredtexttransform="uppercase"
                                hasdeclaredlineheight="1.4em"
                            >
                                {errors.trainer}
                            </StyledText>
                        </StyledInputWrapper>
                    </StyledInputsDivWrapper>
                    <StyledInputsDivWrapper>
                        <StyledInputWrapper error={errors.amount}>
                            <label htmlFor="amount">
                                <StyledText
                                    hasdeclaredfontsize="20px"
                                    hasdeclaredtextalign="center"
                                    hasdeclaredpadding="8px 0 8px 0"
                                    hasdeclaredfontweight="600"
                                >
                                    {getString('User_panel_addition_data_element_kwota')}
                                </StyledText>
                            </label>
                            <Field
                                name="amount"
                                as="input"
                                type="number"
                                defaultValue={initialValues.amount}
                            />
                            <StyledText
                                as="h4"
                                hasdeclaredfontsize="16px"
                                hasdeclaredfontcolor="#F74017"
                                hasdeclaredtextalign="center"
                                hasdeclaredfontweight="700"
                                hasdeclaredtexttransform="uppercase"
                                hasdeclaredlineheight="1.4em"
                            >
                                {errors.amount}
                            </StyledText>
                        </StyledInputWrapper>
                        <StyledInputWrapper error={errors.date}>
                            <label htmlFor="date">
                                <StyledText
                                    hasdeclaredfontsize="20px"
                                    hasdeclaredtextalign="center"
                                    hasdeclaredpadding="8px 0 8px 0"
                                    hasdeclaredfontweight="600"
                                >
                                    {getString('User_panel_addition_data')}
                                </StyledText>
                            </label>
                            <Field
                                name="date"
                                as="input"
                                type="date"
                                defaultValue={initialValues.date}
                            />
                            <StyledText
                                as="h4"
                                hasdeclaredfontsize="16px"
                                hasdeclaredfontcolor="#F74017"
                                hasdeclaredtextalign="center"
                                hasdeclaredfontweight="700"
                                hasdeclaredtexttransform="uppercase"
                                hasdeclaredlineheight="1.4em"
                            >
                                {errors.date}
                            </StyledText>
                        </StyledInputWrapper>
                    </StyledInputsDivWrapper>
                    <StyledInputsDivWrapper hasmarginbottom>
                        <StyledInputWrapper hasdeclaredwidth="80%" error={errors.description}>
                            <label htmlFor="description">
                                <StyledText
                                    hasdeclaredfontsize="20px"
                                    hasdeclaredtextalign="center"
                                    hasdeclaredpadding="8px 0 8px 0"
                                    hasdeclaredfontweight="600"
                                >
                                    {getString('User_panel_addition_data_element_opis')}
                                </StyledText>
                            </label>
                            <Field
                                as="textarea"
                                name="description"
                                defaultValue={initialValues.description}
                            />
                            <StyledText
                                as="h4"
                                hasdeclaredfontsize="16px"
                                hasdeclaredfontcolor="#F74017"
                                hasdeclaredtextalign="center"
                                hasdeclaredfontweight="700"
                                hasdeclaredtexttransform="uppercase"
                                hasdeclaredlineheight="1.4em"
                            >
                                {errors.description}
                            </StyledText>
                        </StyledInputWrapper>
                    </StyledInputsDivWrapper>
                    <StyledInputsButtonsWrapper>
                        <input type="submit" value={getString('User_panel_addition_zapisz')} />
                        <input type="reset" value={getString('User_panel_addition_anuluj')} />
                    </StyledInputsButtonsWrapper>
                </StyledUserPanelAdditionsElement>
            )}
        </Formik>
    );
};

export default UserPanelAdditionsElement;
