import styled from 'styled-components';

export const StyledUserPanelAddRole = styled.div`
    width: 100%;
    height: 100%;

    @media only screen and (max-width: 926px) {
        margin-top: 20px;

        > h2 {
            font-size: 30px;
        }
    }
`;
