import { useToggle } from 'react-use';

import { StyledManageContractsElement } from './ManageContractsElement.styles';
import { StyledText } from '../../atoms/Text/StyledText';
import { StyledHeading, StyledArrow } from '../../../styles/sharedStyles';
import { StyledHeadingCheckbox } from '../../atoms/SettlementsDoneElement/StyledHeadingCheckbox';

import ContractsTable from './ContractsTable';

const ManageContractsElement = ({ trainer, companies, contractTypes }) => {
    const [isOpen, toggleIsOpen] = useToggle(false);

    return (
        <StyledManageContractsElement isopen={isOpen}>
            <StyledHeading onClick={toggleIsOpen}>
                <StyledText
                    hasdeclaredfontsize="22px"
                    hasdeclaredfontweight="700"
                    hasdeclaredtextalign="center"
                    hasdeclaredtexttransform="uppercase"
                    hasdeclaredfontcolor="#6786BE"
                >
                    {trainer.lastname} {trainer.firstname}
                </StyledText>
                <StyledHeadingCheckbox>
                    <StyledArrow isopen={isOpen} />
                </StyledHeadingCheckbox>
            </StyledHeading>
            {isOpen && <ContractsTable contracts={trainer.contracts} companies={companies} contractTypes={contractTypes} />}
        </StyledManageContractsElement>
    );
};

export default ManageContractsElement;
