import styled from 'styled-components';

export const StyledContentWrapper = styled.div`
    width: 100%;
    position: realtive;
    z-index: 2;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-bottom: 60px;

    > form {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

    @media only screen and (max-width: 554px) {
        h1 {
            font-size: 42px;
            padding-top: 165px;
        }

        h4 {
            font-size: 22px;
        }

        h3 {
            font-size: 16px;
        }
    }
`;
