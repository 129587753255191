import styled from 'styled-components';

export const StyledButtonsWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 16px;

    > a {
        width: 48%;
        height: 50px;
        text-decoration: none;
        display: block;
    }

    > button {
        width: 48%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    > a > button {
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;
