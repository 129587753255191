import React, { useState, useMemo } from 'react';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';

import StudentPaymentsElement from './StudentPaymentsElement';

import { StyledHeading, StyledArrow } from '../../../styles/sharedStyles';
import { StyledHeadingCheckbox } from '../../atoms/SettlementsDoneElement/StyledHeadingCheckbox';
import { StyledText } from '../../atoms/Text/StyledText';
import { StyledStudentsPaymentsElement } from '../../atoms/UserPanelStudentsPayments/StyledStudentsPaymentsElement';
import { StyledElements } from '../../atoms/UserPanelStudentsPayments/StyledElements';
import { StyledPaymentsElementHeader } from '../../atoms/UserPanelStudentsPayments/StyledPaymentsElementHeader';
import { StyledDataHeaderElement } from '../../atoms/SettlementsDoneElement/StyledDataHeaderElement';
import { StyledDataContentPaymentsElement } from '../../atoms/UserPanelStudentsPayments/StyledDataContentPaymentsElement';
import { StyledSummaryWrapper } from '../../atoms/UserPanelStudentsPayments/StyledSummaryWrapper';

import { getLang, getLocale, getString } from '../../../strings';
import { getDateString } from '../../../logic/eventtime.js';
import Decimal from 'decimal.js';
import useScrollBar from '../../../logic/hooks/useScrollBar';

const StudentsPaymentsElement = ({ trigger, month, hours, paid, other, subjects }) => {
    const [isOpen, setIsOpen] = useState(false);
    const { scrollElement, isScrollBar } = useScrollBar(false, true);

    const handleOpen = () => {
        trigger();
        setIsOpen(!isOpen);
    };

    const monthString = useMemo(
        () =>
            new Date(...month.split('-')).toLocaleDateString(getLocale(getLang()), {
                year: 'numeric',
                month: 'long',
            }),
        [month]
    );

    const paidHours = useMemo(
        () =>
            ((val) =>
                val && val.length
                    ? val
                          .map((obj) => obj.price)
                          .reduce((prev, cur) => prev.plus(cur), new Decimal(0))
                    : undefined)(paid ? hours : other),
        [hours, other, paid]
    );
    const unpaidHours = useMemo(
        () =>
            ((val) =>
                val && val.length
                    ? val
                          .map((obj) => obj.price)
                          .reduce((prev, cur) => prev.plus(cur), new Decimal(0))
                    : undefined)(!paid ? hours : other),
        [hours, other, paid]
    );

    return (
        <StyledStudentsPaymentsElement isopen={isOpen}>
            <StyledHeading onClick={handleOpen}>
                <StyledText
                    hasdeclaredfontsize="22px"
                    hasdeclaredfontweight="700"
                    hasdeclaredtextalign="center"
                    hasdeclaredtexttransform="uppercase"
                    hasdeclaredfontcolor="#6786BE"
                >
                    {monthString}
                </StyledText>
                <StyledHeadingCheckbox>
                    <StyledArrow isopen={isOpen} />
                </StyledHeadingCheckbox>
            </StyledHeading>
            {isOpen ? (
                <StyledElements>
                    <ScrollSync>
                        <>
                            <ScrollSyncPane>
                                <StyledPaymentsElementHeader
                                    isscrollbar={isScrollBar}
                                    ref={scrollElement}
                                    hasalignself="flex-start"
                                    style={{ width: 'calc(100% - 40px)'}}
                                >
                                    <div>
                                        <StyledDataHeaderElement $hasDeclaredWidth="14%">
                                            <StyledText
                                                hasdeclaredfontsize="16px"
                                                hasdeclaredfontweight="700"
                                                hasdeclaredtextalign="center"
                                                hasdeclaredlineheight="1.4em"
                                            >
                                                {getString('students_payment_element_uczen')}
                                            </StyledText>
                                        </StyledDataHeaderElement>
                                        <StyledDataHeaderElement $hasDeclaredWidth="14%">
                                            <StyledText
                                                hasdeclaredfontsize="16px"
                                                hasdeclaredfontweight="700"
                                                hasdeclaredtextalign="center"
                                                hasdeclaredlineheight="1.4em"
                                            >
                                                {getString(
                                                    'Trainings_trainings_settlements_done_element_lekcja'
                                                )}
                                            </StyledText>
                                        </StyledDataHeaderElement>
                                        <StyledDataHeaderElement $hasDeclaredWidth="14%">
                                            <StyledText
                                                hasdeclaredfontsize="16px"
                                                hasdeclaredfontweight="700"
                                                hasdeclaredtextalign="center"
                                                hasdeclaredlineheight="1.4em"
                                            >
                                                {getString(
                                                    'Trainings_trainings_settlements_done_element_data'
                                                )}
                                            </StyledText>
                                        </StyledDataHeaderElement>
                                        <StyledDataHeaderElement $hasDeclaredWidth="14%">
                                            <StyledText
                                                hasdeclaredfontsize="16px"
                                                hasdeclaredfontweight="700"
                                                hasdeclaredtextalign="center"
                                                hasdeclaredlineheight="1.4em"
                                            >
                                                {getString(
                                                    'Trainings_trainings_settlements_done_element_godziny'
                                                )}
                                            </StyledText>
                                        </StyledDataHeaderElement>
                                        <StyledDataHeaderElement $hasDeclaredWidth="14%">
                                            <StyledText
                                                hasdeclaredfontsize="16px"
                                                hasdeclaredfontweight="700"
                                                hasdeclaredtextalign="center"
                                                hasdeclaredlineheight="1.4em"
                                            >
                                                {getString(
                                                    'Trainings_trainings_settlements_done_element_trener'
                                                )}
                                            </StyledText>
                                        </StyledDataHeaderElement>
                                        <StyledDataHeaderElement $hasDeclaredWidth="14%">
                                            <StyledText
                                                hasdeclaredfontsize="16px"
                                                hasdeclaredfontweight="700"
                                                hasdeclaredtextalign="center"
                                                hasdeclaredlineheight="1.4em"
                                            >
                                                {getString(
                                                    'Trainings_trainings_settlements_done_element_cena'
                                                )}
                                            </StyledText>
                                        </StyledDataHeaderElement>
                                    </div>
                                </StyledPaymentsElementHeader>
                            </ScrollSyncPane>
                            <StyledDataContentPaymentsElement>
                                {hours.map((hour) => (
                                    <StudentPaymentsElement
                                        key={hour.id}
                                        student={`${hour.student.firstname} ${hour.student.lastname}`}
                                        subject={
                                            subjects?.find(
                                                (el) => el.description && el.id === hour.subject
                                            )?.description[getLang()]
                                        }
                                        free={hour.free}
                                        date={getDateString(new Date(hour.date))}
                                        hours_count={hour.half ? '0.5' : hour.hours}
                                        trainer={`${hour.trainer.firstname} ${hour.trainer.lastname}`}
                                        price={new Decimal(hour.price).toFixed(2)}
                                        id={hour.id}
                                        paid={hour.paid}
                                    />
                                ))}
                            </StyledDataContentPaymentsElement>
                            <StyledSummaryWrapper>
                                <StyledText
                                    hasdeclaredfontsize="18px"
                                    hasdeclaredfontweight="700"
                                    hasdeclaredtextalign="left"
                                    hasdeclaredlineheight="1.4em"
                                >
                                    {getString('students_payment_element_oplacono')}:{' '}
                                    <span style={{ color: '#6786be' }}>
                                        {paidHours
                                            ? paidHours.dp() > 0
                                                ? paidHours.toFixed(2)
                                                : paidHours.toString()
                                            : 0}{' '}
                                        PLN
                                    </span>
                                </StyledText>
                                <StyledText
                                    hasdeclaredfontsize="18px"
                                    hasdeclaredfontweight="700"
                                    hasdeclaredtextalign="left"
                                    hasdeclaredlineheight="1.4em"
                                >
                                    {getString('students_payment_element_nie_oplacono')}:{' '}
                                    <span style={{ color: '#ee745c' }}>
                                        {unpaidHours
                                            ? unpaidHours.dp() > 0
                                                ? unpaidHours.toFixed(2)
                                                : unpaidHours.toString()
                                            : 0}{' '}
                                        PLN
                                    </span>
                                </StyledText>
                            </StyledSummaryWrapper>
                        </>
                    </ScrollSync>
                </StyledElements>
            ) : null}
        </StyledStudentsPaymentsElement>
    );
};

export default StudentsPaymentsElement;
