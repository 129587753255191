import styled from 'styled-components';

export const StyledButtonWrapper = styled.div`
    width: 100%;
    margin-top: 20px;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;

    > button {
        width: 48%;
        height: 50px;
    }

    > a {
        width: 100%;
        text-decoration: none;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;

        button {
            width: 48%;
            height: 50px;
        }
    }

    @media only screen and (max-width: 934px) {
        > a {
            > button {
                font-size: 16px;
            }
        }

        > button {
            font-size: 16px;
        }
    }
`;
