import styled from 'styled-components';
import { scrollRedStyles } from '../../../styles/sharedStyles';

export const StyledSeriesMenuWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    min-height: 60%;
    max-height: 60%;
    overflow-y: scroll;
    width: 100%;
    padding-right: 10px;
    padding-left: 10px;

    ${scrollRedStyles};

    > div {
        width: 100%;

        > div {
            width: 100%;

            &::-webkit-scrollbar {
                background: transparent;
                width: 0;
            }

            &::-webkit-scrollbar-track {
                background: transparent;
                width: 0;
            }

            &::-webkit-scrollbar-thumb {
                background: transparent;
                width: 0;
            }

            &::-webkit-scrollbar-thumb:hover {
                background: transparent;
                width: 0;
            }
        }
    }

    @media only screen and (max-width: 767px) {
        overflow-y: visible;
        padding: 0;
    }
`;
