import backendRequest from './index.js';

export const listAnnouncements = () => backendRequest('GET', '/announcements/list');

export const createAnnouncement = (data) => backendRequest('POST', '/announcements/create', data);

export const deleteAnnouncement = (id) => backendRequest('DELETE', `/announcements/delete/${id}`);

export const markAsRead = (id) => backendRequest('PUT', `/announcements/mark-as-read/${id}`);

export const editAnnouncement = (id, data) => backendRequest('PUT', `/announcements/edit/${id}`, data);

export const listRolesForAnnouncements = () => backendRequest('GET', '/announcements/list-roles');