import styled from 'styled-components';

export const StyledLoginButtonsWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    margin-top: 12px;
    position: relative;

    > button {
        display: flex;
        border-radius: 45px;
        align-items: center;
        justify-content: center;
    }
    a {
        text-decoration: none;
    }
    @media only screen and (max-width: 836px) {
        h3 {
            font-size: 16px;
        }
    }
    @media only screen and (max-width: 767px) {
        h3 {
            font-size: 22px;
        }
    }
    @media only screen and (max-width: 450px) {
        h3 {
            font-size: 16px;
        }
        > button {
            font-size: 12px;
            padding: 6px 8px;
        }
    }
`;
