import styled from 'styled-components';

export const StyledUserPanelResultsSum = styled.div`
    min-width: 200px;
    width: 23%;
    background-color: #fff;
    min-height: 50px;
    border-top: 2px solid #000;
    border-right: 2px solid #000;
    border-bottom: 2px solid #000;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    display: flex;
    justify-content: space-evenly;
`;
