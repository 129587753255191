import styled from 'styled-components';

export const StyledButtonsWrapper = styled.div`
    width: 100%;
    margin-top: 60px;
    margin-bottom: 20px;

    button {
        color: #fff;
        border: none;
        font-size: 18px;
        padding: 22px 0;
        cursor: pointer;
    }
`;
