import styled from 'styled-components';

export const StyledPlayerElement = styled.div`
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 12px;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    width: 100%;

    @media only screen and (max-width: 1300px) {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        margin-top: 10px;
        border: 1px solid #3d5ca2;

        > div {
            width: 50%;
            padding: 8px 0;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;

            &:first-child {
                width: 54px;
                height: 54px;
                padding: 0;
            }

            &:nth-child(2) {
                width: 100%;
            }
        }
    }

    @media only screen and (max-width: 767px) {
        width: 100%;
        padding-right: 0;
        border: 0;
        border-bottom: 2px solid #3d5ca2;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;

        > div {
            width: 100%;
            -webkit-box-align: start;
            -ms-flex-align: start;
            align-items: flex-start;

            &:first-child {
                width: 54px;
                height: 54px;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
            }
        }
    }
`;
