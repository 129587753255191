import styled from 'styled-components';

export const StyledFacebookIconWrapper = styled.div`
    position: absolute;
    right: 10px;
    bottom: 10px;
    width: 60px;
    height: 60px;

    > a {
        width: 100%;
        height: 100%;
        cursor: pointer;
        color: #fff;
        text-decoration: none;

        > p {
            display: none;
        }

        > svg {
            width: 100%;
            height: 100%;
        }
    }

    @media only screen and (max-width: 767px) {
        position: relative;
        width: 100%;
        height: 40px;
        bottom: auto;
        right: auto;
        margin-top: 15px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;

        > a {
            width: 220px;
            background-color: black;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            border-radius: 25px;
            border: 2px solid #fff;

            > p {
                display: block;
                text-align: center;
                margin-left: -22px;
            }

            > svg {
                width: 30px;
                height: 30px;
            }
        }
    }
`;
