import styled from 'styled-components';

export const StyledInputsButtonsWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    gap: 10px;
    margin: ${({ hasmargin }) => (hasmargin ? hasmargin : '0')};

    input[type='submit'] {
        font-weight: 700;
        font-size: 18px;
        text-align: center;
        background-color: #6786be;
        color: #fff;
        cursor: pointer;
        text-transform: uppercase;
        border-radius: 25px;
        height: 50px;
        width: 50%;
        border: none;
        transition: transform 250ms linear;

        &:hover {
            transform: scale(0.95);
        }
    }

    input[type='reset'],
    .reset {
        font-weight: 700;
        font-size: 18px;
        text-align: center;
        background-color: #ee745c;
        color: #fff;
        cursor: pointer;
        text-transform: uppercase;
        border-radius: 25px;
        height: 50px;
        border: none;
        width: 50%;
        transition: background-color 250ms linear;

        &:hover {
            background-color: #592e28;
        }
    }

    @media only screen and (max-width: 600px) {
        input[type='submit'],
        input[type='reset'] {
            font-size: 18px;
        }
    }

    @media only screen and (max-width: 1082px) {
        input[type='submit'],
        input[type='reset'] {
            font-size: 14px;
        }
    }

    @media only screen and (max-width: 926px) {
        input[type='submit'],
        input[type='reset'] {
            font-size: 18px;
        }
    }

    @media only screen and (max-width: 610px) {
        input[type='submit'],
        input[type='reset'] {
            font-size: 14px;
        }
    }

    @media only screen and (max-width: 515px) {
        input[type='submit'],
        input[type='reset'] {
            font-size: 16px;
            width: 100%;
        }
    }
`;
