import styled from 'styled-components';

import { ReactComponent as Cube } from '../../../images/cubes/444.svg';

export const Styled444Cube = styled(Cube)`
    @media only screen and (max-width: 1920px) {
        width: 210px;
        height: 210px;
    }

    @media only screen and (max-width: 1919px) {
        width: inherit;
        height: inherit;
    }

    @media only screen and (max-width: 1036px) {
        transform: translateX(-40px);
    }
`;
