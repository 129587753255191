import React, { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import Loader from '../Loader/Loader.js';
import UserPanelHeadingText from '../../atoms/UserPanel/UserPanelHeadingText.js';
import ErrorText from '../../atoms/ErrorText/ErrorText.js';

import { StyledText } from '../../atoms/Text/StyledText.js';
import { StyledTrackHours } from '../../atoms/TrackHours/StyledTrackHours.js';
import { StyledHourWrapper } from '../../atoms/TrackHours/StyledHourWrapper.js';
import { StyledForm } from '../../atoms/TrackHours/StyledForm.js';
import { StyledSelectWrapper } from '../../atoms/TrackHours/StyledSelectWrapper.js';
import { StyledInputsWrapper } from '../../atoms/TrackHours/StyledInputsWrapper.js';
import { StyledInputsButtonsWrapper } from '../../atoms/TrackHours/StyledInputsButtonsWrapper.js';
import { StyledInputwrapper } from '../../atoms/TrackHours/StyledInputwrapper.js';
import { StyledCheckbox } from '../../atoms/TrackHours/StyledCheckbox.js';
import { ReactComponent as CheckboxAccept } from '../../../images/checkboxAccept.svg';
import { StyledInputWithCheckbox } from '../../atoms/TrackHours/StyledInputWithCheckbox.js';
import { StyledLoaderWrapper, StyledGlobalHeadingWrapper } from '../../../styles/sharedStyles.js';

import { listStudents, listSubjects } from '../../../logic/requests/students.js';
import { getLang, getString } from '../../../strings/index.js';
import { getDateString } from '../../../logic/isotime.js';
import { cebulaCompare } from '../../../logic/arrays.js';

import { handleSubmit } from './UserPanelTrainerTrackHours.data.js';
import AddHourSuccess from './AddHourSuccess.js';
import { StyledCheckboxWrapper, StyledInputsBox, StyledMobileWrapper } from './UserPanelTrainerTrackHours.styles.js';

const UserPanelTrainerAddHours = ({ user }) => {
    const [students, setStudents] = useState();
    const [subjects, setSubjects] = useState();
    const [errors, setErrors] = useState({});
    const [err, setErr] = useState(false);
    const [submitSucc, setSubmitSucc] = useState();
    const [submitErr, setSubmitErr] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [half, setHalf] = useState(false);
    const navigate = useNavigate();

    const options = Array.from({ length: 99 }, (_, i) => i + 1);

    const hasFree = useMemo(
        () => user.roles.includes('trainer_new') || user.roles.includes('manager'),
        [user]
    );

    const hasRabat = useMemo(
        () =>
            user.roles.includes('trainer_new') ||
            user.roles.includes('manager') ||
            user.roles.includes('admin'),
        [user]
    );

    const form = useRef();
    const refs = {
            student: useRef(),
            subject: useRef(),
            hours: useRef(),
            half: useRef(),
            free: useRef(),
            date: useRef(),
            discount: useRef(),
        },
        getRefsData = () => ({
            student: parseInt(refs.student.current.value),
            subject: refs.subject.current.value,
            hours: parseInt(refs.hours.current.value),
            half: refs.half.current.checked,
            ...(hasFree ? { free: refs.free.current.checked } : {}),
            date: refs.date.current.value,
            discount: refs?.discount?.current?.value ? parseInt(refs.discount.current.value) : 0,
        });

    useEffect(() => {
        if (submitSucc) {
            setErrors({});
            const timeout = setTimeout(() => setSubmitSucc(false), 3000);
            return () => clearTimeout(timeout);
        }
    }, [submitSucc, setSubmitSucc]);

    useEffect(() => {
        if (submitErr) {
            const timeout = setTimeout(() => setSubmitErr(false), 3000);
            return () => clearTimeout(timeout);
        }
    }, [submitErr, setSubmitErr]);

    useEffect(() => {
        const { current } = refs.hours;
        if (!current) return;

        current.value = half ? 0.5 : 0;
    }, [half]);

    useEffect(() => {
        if (!(user && user.roles.includes('trainer'))) navigate('/my-account', { replace: true });

        listStudents()
            .then((res) => {
                setStudents(
                    res.sort(
                        (a, b) =>
                            cebulaCompare(a.lastname, b.lastname) ||
                            cebulaCompare(a.firstname, b.firstname)
                    )
                );
            })
            .catch((err) => {
                console.error('listStudents', err);
                setErr(true);
                setStudents([]);
            });

        listSubjects()
            .then((res) => {
                setSubjects(
                    res
                        .filter((el) => el.description)
                        .sort((a, b) =>
                            cebulaCompare(a.description[getLang()], b.description[getLang()])
                        )
                );
            })
            .catch((err) => {
                console.error('listSubjects', err);
                setErr(true);
                setSubjects([]);
            });
    }, []);

    const validateFields = useCallback(() => {
        const newErrors = {};

        if (refs.student.current && !parseInt(refs.student.current.value)) {
            newErrors.student = true;
        }
        if (refs.subject.current && !refs.subject.current.value) {
            newErrors.subject = true;
        }
        if (refs.hours.current && !parseInt(refs.hours.current.value)) {
            if (isSubmitted) newErrors.hours = false;
            else newErrors.hours = true;
        }
        if (refs.date.current && !refs.date.current.value) {
            newErrors.date = true;
        }

        setErrors(newErrors);
    }, [refs, isSubmitted]);

    useEffect(() => {
        if (!isSubmitted) return;

        validateFields();
    }, [isSubmitted, refs, half, validateFields]);

    return (
        <StyledTrackHours>
            {!(students && subjects) ? (
                <StyledLoaderWrapper>
                    <Loader />
                </StyledLoaderWrapper>
            ) : !(students.length && subjects.length) ? (
                <StyledLoaderWrapper>
                    <StyledText
                        hasdeclaredpadding="0px 20px"
                        hasdeclaredfontsize="22px"
                        hasdeclaredfontweight="700"
                        hasdeclaredtextalign="center"
                        hasdeclaredlineheight="1.4em"
                        as="p"
                    >
                        {getString(
                            err ? 'blad_wczytywania' : 'UserPanelTrainer__no_students_or_subjects'
                        )}
                    </StyledText>
                </StyledLoaderWrapper>
            ) : (
                <>
                    <StyledGlobalHeadingWrapper>
                        <UserPanelHeadingText
                            text={`${getString('UserPanelTrainerTrackHours__header_title')}:`}
                        />
                    </StyledGlobalHeadingWrapper>
                    <StyledHourWrapper>
                        <StyledForm
                            ref={form}
                            onSubmit={(e) => {
                                e.preventDefault();
                                setIsSubmitted(true);

                                const formData = getRefsData();

                                return handleSubmit(
                                    formData,
                                    hasFree,
                                    () => {
                                        setSubmitSucc(true);
                                        setErrors({});
                                        setHalf(false);
                                        setIsSubmitted(false);
                                        form.current.reset();
                                        refs.student.current.value = 0;
                                        refs.subject.current.value = 'cube';
                                        refs.hours.current.value = 1;
                                        refs.half.current.checked = false;
                                        if (hasFree) refs.free.current.checked = false;
                                        refs.date.current.value = getDateString(new Date());
                                        if (!isNaN(refs?.discount?.current?.value))
                                            refs.discount.current.value = 0;
                                    },
                                    () => setSubmitErr(true),
                                    setErrors
                                );
                            }}
                        >
                            <StyledSelectWrapper>
                                <StyledInputwrapper error={errors.student}>
                                    <label htmlFor="countTime">
                                        <StyledText
                                            hasdeclaredfontsize="20px"
                                            hasdeclaredtextalign="center"
                                            hasdeclaredpadding="8px 0 8px 0"
                                            hasdeclaredfontweight="600"
                                        >
                                            {getString(
                                                'UserPanelTrainerTrackHours__form__label_student'
                                            )}
                                            :
                                        </StyledText>
                                    </label>
                                    <select ref={refs.student} defaultValue={0}>
                                        <option value={0}>
                                            -{' '}
                                            {getString(
                                                'UserPanelTrainerTrackHours__form__select_student'
                                            )}{' '}
                                            -
                                        </option>
                                        {students.map((student) => (
                                            <option
                                                key={student.id}
                                                value={student.id}
                                            >{`${student.lastname} ${student.firstname}`}</option>
                                        ))}
                                    </select>
                                    {errors.student ? (
                                        <ErrorText
                                            text={getString('user_panel_to_pole_jest_wymagane')}
                                        />
                                    ) : null}
                                </StyledInputwrapper>
                                <StyledInputwrapper error={errors.subject}>
                                    <label htmlFor="countTime">
                                        <StyledText
                                            hasdeclaredfontsize="20px"
                                            hasdeclaredtextalign="center"
                                            hasdeclaredpadding="8px 0 8px 0"
                                            hasdeclaredfontweight="600"
                                        >
                                            {getString(
                                                'UserPanelTrainerTrackHours__form__label_subject'
                                            )}
                                            :
                                        </StyledText>
                                    </label>
                                    <select ref={refs.subject} defaultValue="cube">
                                        {subjects
                                            .filter((el) => el.description)
                                            .map((subject) => (
                                                <option key={subject.id} value={subject.id}>
                                                    {`${subject.description[getLang()]}`}
                                                </option>
                                            ))}
                                    </select>
                                    {errors.subject ? (
                                        <ErrorText
                                            text={getString('user_panel_to_pole_jest_wymagane')}
                                        />
                                    ) : null}
                                </StyledInputwrapper>
                            </StyledSelectWrapper>
                            <StyledInputsWrapper $hasSpaceBetween>
                                <StyledInputsBox>
                                    <StyledInputWithCheckbox>
                                        <div>
                                            <StyledInputwrapper
                                                error={errors.hours}
                                                hasdeclaredwidth={hasFree ? '50%' : '100%'}
                                                disabled={half}
                                            >
                                                <label htmlFor="countTime">
                                                    <StyledText
                                                        hasdeclaredfontsize="20px"
                                                        hasdeclaredtextalign="center"
                                                        hasdeclaredpadding="8px 0 8px 0"
                                                        hasdeclaredfontweight="600"
                                                    >
                                                        {getString(
                                                            'UserPanelTrainerTrackHours__form__label_hours_amount'
                                                        )}
                                                        :
                                                    </StyledText>
                                                </label>
                                                <input
                                                    ref={refs.hours}
                                                    id="countTime"
                                                    type="number"
                                                    min={0}
                                                    max={99}
                                                    defaultValue={1}
                                                    disabled={half}
                                                />
                                                {errors.hours ? (
                                                    <ErrorText
                                                        text={getString(
                                                            'user_panel_to_pole_jest_wymagane'
                                                        )}
                                                    />
                                                ) : null}
                                            </StyledInputwrapper>
                                            {hasRabat ? (
                                                <StyledInputwrapper
                                                    hasdeclaredwidth="48%"
                                                    error={errors.discount}
                                                    style={{ marginLeft: '3%' }}
                                                >
                                                    <label htmlFor="discount">
                                                        <StyledText
                                                            hasdeclaredfontsize="20px"
                                                            hasdeclaredtextalign="center"
                                                            hasdeclaredpadding="8px 0 8px 0"
                                                            hasdeclaredfontweight="600"
                                                        >
                                                            {getString(
                                                                'user_panel_trainer_track_hours_rabat'
                                                            )}
                                                            :
                                                        </StyledText>
                                                    </label>
                                                    <select ref={refs.discount} defaultValue={0}>
                                                        <option value={0}>
                                                            {getString(
                                                                'user_panel_trainer_track_hours'
                                                            )}
                                                        </option>
                                                        {options.map((value) => (
                                                            <option key={value} value={value}>
                                                                {value}%
                                                            </option>
                                                        ))}
                                                    </select>
                                                    {errors.discount ? (
                                                        <ErrorText
                                                            text={getString(
                                                                'user_panel_to_pole_jest_wymagane'
                                                            )}
                                                        />
                                                    ) : null}
                                                </StyledInputwrapper>
                                            ) : null}
                                        </div>
                                    </StyledInputWithCheckbox>
                                </StyledInputsBox>
                                <StyledCheckboxWrapper>
                                    <StyledMobileWrapper>
                                        <StyledCheckbox $withoutMarginLeft>
                                            <input
                                                id="half"
                                                ref={refs.half}
                                                type="checkbox"
                                                onChange={(e) => setHalf(e.target.checked)}
                                            />
                                            <label htmlFor="half">
                                                <StyledText
                                                    hasdeclaredfontsize="20px"
                                                    hasdeclaredtextalign="center"
                                                    hasdeclaredpadding="8px 0 8px 0"
                                                    hasdeclaredfontweight="600"
                                                >
                                                    {getString(
                                                        'UserPanelTrainerTrackHours__form__label_half'
                                                    )}
                                                    .
                                                    <span>
                                                        <CheckboxAccept />
                                                    </span>
                                                </StyledText>
                                            </label>
                                        </StyledCheckbox>
                                        {hasFree ? (
                                            <StyledCheckbox $withoutMarginLeft>
                                                <input id="free" ref={refs.free} type="checkbox" />
                                                <label htmlFor="free">
                                                    <StyledText
                                                        hasdeclaredfontsize="20px"
                                                        hasdeclaredtextalign="center"
                                                        hasdeclaredpadding="8px 0 8px 0"
                                                        hasdeclaredfontweight="600"
                                                    >
                                                        {getString(
                                                            'UserPanelTrainerTrackHours__form__label_d'
                                                        )}
                                                        .
                                                        <span>
                                                            <CheckboxAccept />
                                                        </span>
                                                    </StyledText>
                                                </label>
                                            </StyledCheckbox>
                                        ) : undefined}
                                    </StyledMobileWrapper>
                                    <StyledInputwrapper
                                        hasdeclaredwidth={hasFree ? 'calc(100% - 120px)' : 'calc(100% - 60px)'}
                                        error={errors.date}
                                    >
                                        <label htmlFor="hourDate">
                                            <StyledText
                                                hasdeclaredfontsize="20px"
                                                hasdeclaredtextalign="center"
                                                hasdeclaredpadding="8px 0 8px 0"
                                                hasdeclaredfontweight="600"
                                            >
                                                {getString(
                                                    'UserPanelTrainerTrackHours__form__label_date'
                                                )}
                                                :
                                            </StyledText>
                                        </label>
                                        <input
                                            id="hourDate"
                                            ref={refs.date}
                                            type="date"
                                            defaultValue={getDateString(new Date())}
                                        />
                                        {errors.date ? (
                                            <ErrorText
                                                text={getString('user_panel_to_pole_jest_wymagane')}
                                            />
                                        ) : null}
                                    </StyledInputwrapper>
                                </StyledCheckboxWrapper>
                            </StyledInputsWrapper>
                            <StyledInputsButtonsWrapper>
                                <input
                                    type="submit"
                                    value={getString('UserPanelTrainerTrackHours__form__add')}
                                />
                                <input
                                    type="reset"
                                    value={getString('UserPanelTrainerTrackHours__form__cancel')}
                                />
                            </StyledInputsButtonsWrapper>
                        </StyledForm>
                        {submitSucc ? (
                            <AddHourSuccess refs={refs} students={students} hasFree={hasFree} />
                        ) : undefined}
                        {submitErr ? (
                            <StyledText
                                hasdeclaredfontcolor="red"
                                hasdeclaredpadding="20px 0 40px"
                                hasdeclaredfontsize="22px"
                                hasdeclaredfontweight="700"
                                hasdeclaredtextalign="center"
                                hasdeclaredlineheight="1.4em"
                                as="p"
                            >
                                {getString('UserPanelTrainerTrackHours__form_submit__fail')}
                            </StyledText>
                        ) : undefined}
                    </StyledHourWrapper>
                </>
            )}
        </StyledTrackHours>
    );
};

export default UserPanelTrainerAddHours;
