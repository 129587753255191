import styled from 'styled-components';
import { motion } from 'framer-motion';
import { scrollBlueStyles } from '../../../styles/sharedStyles';

export const StyledAboutUs = styled(motion.div)`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justif-content: flex-start;

    max-height: 90%;
    overflow-y: scroll;

    ${scrollBlueStyles};

    @media only screen and (max-width: 767px) {
        padding-bottom: 40px;
        max-height: unset;
        height: auto;
        overflow-y: visible;
    }
`;

export const StyledAboutUsImageWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;

    @media only screen and (max-width: 630px) {
        width: 100%;
        flex-direction: column;
        gap: 10px;
    }
`;

export const StyledAboutUsImageWrapperImage = styled.div`
    width: 49%;
    margin-bottom: 40px;
    height: ${({ hasdeclaredheight }) => (hasdeclaredheight ? hasdeclaredheight : '200px')};

    > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 20px;

        -webkit-box-shadow: 12px 12px 11px -6px rgba(66, 68, 90, 1);
        -moz-box-shadow: 12px 12px 11px -6px rgba(66, 68, 90, 1);
        box-shadow: 12px 12px 11px -6px rgba(66, 68, 90, 1);
    }

    @media only screen and (max-width: 630px) {
        width: 100%;
    }
`;
