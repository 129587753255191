import React, { useEffect } from 'react';
import { useToggle } from 'react-use';
import parse from 'html-react-parser';

import {
    StyledFAQElement,
    StyledFAQAnswerText,
    StyledFAQHeaderText,
} from '../../atoms/FAQ/StyledFAQElement';
import { StyledText } from '../../atoms/Text/StyledText';
import { StyledArrow } from '../../../styles/sharedStyles';

const FAQElement = ({ headerText, answerText, triggerParentScroll }) => {
    const [isOpen, toggleIsOpen] = useToggle(false);

    useEffect(() => triggerParentScroll?.(), [triggerParentScroll, isOpen]);

    return (
        <StyledFAQElement onClick={toggleIsOpen}>
            <StyledFAQHeaderText>
                <StyledText
                    hasdeclaredfontsize="16px"
                    hasdeclaredfontcolor="#fff"
                    hasdeclaredfontweight="600"
                    hasdeclaredlineheight="1.4em"
                    hasdeclaredpadding="0 10px 0 10px"
                    hascursor="pointer"
                    hasdeclaredtextalign="left"
                >
                    {headerText}
                </StyledText>
                <StyledArrow isopen={isOpen} />
            </StyledFAQHeaderText>
            {isOpen && (
                <StyledFAQAnswerText>
                    <StyledText
                        hasdeclaredfontsize="16px"
                        hasdeclaredfontcolor="#fff"
                        hasdeclaredfontweight="600"
                        hasdeclaredpadding="20px"
                        hasdeclaredlineheight="1.4em"
                        hascursor="pointer"
                        hasdeclaredtextalign="justify"
                    >
                        {parse(answerText)}
                    </StyledText>
                </StyledFAQAnswerText>
            )}
        </StyledFAQElement>
    );
};

export default FAQElement;
