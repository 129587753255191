import { useCallback, useMemo, useState } from 'react';
import { getString } from '../../../strings';
import { StyledButton } from '../../atoms/Button/StyledButton';
import { StyledText } from '../../atoms/Text/StyledText';
import PaymentsPopup from '../Payments/PaymentsPopup';
import { StyledBigLoader } from '../../atoms/Loader/StyledBigLoader';
import { sendWelcomeEmail } from '../../../logic/requests/students';

const SendEmailPopup = ({ onClose, userId }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [emailSent, setEmailSent] = useState(false);
    const [isSubmited, setIsSubmited] = useState(false);

    const onSend = useCallback(() => {
        setIsLoading(true);
        setIsSubmited(true);
        sendWelcomeEmail(userId)
            .then(() => {
                setIsLoading(false);
                setEmailSent(true);
            })
            .catch(() => {
                setIsLoading(false);
                setEmailSent(false);
            });
    }, [userId]);

    const submitMessage = useMemo(
        () =>
            emailSent ? (
                <>
                    <StyledText
                        hasdeclaredfontsize="18px"
                        hasdeclaredpadding={'50px 20px 20px 20px'}
                        hasdeclaredfontweight="600"
                        hasdeclaredlineheight="1.4em"
                        hasdeclaredtextalign="center"
                        hasdeclaredfontcolor="#6AB95D"
                    >
                        {getString('UserPanelAddRole__form_submit__email_sent')}
                    </StyledText>
                </>
            ) : (
                <StyledText
                    hasdeclaredfontsize="18px"
                    hasdeclaredpadding={'50px 20px 20px 20px'}
                    hasdeclaredfontweight="600"
                    hasdeclaredlineheight="1.4em"
                    hasdeclaredtextalign="center"
                    hasdeclaredfontcolor="#F74017"
                >
                    {getString('UserPanelAddRole__form_submit__email_sent_fail')}
                </StyledText>
            ),
        [emailSent]
    );

    return (
        <PaymentsPopup>
            {isSubmited ? (
                isLoading ? (
                    <div
                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    >
                        <StyledBigLoader />
                    </div>
                ) : (
                    <>
                        {submitMessage}
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <StyledButton
                                hasdeclaredfontcolor="#fff"
                                hasdeclaredbgcolor="#ee745c"
                                hasdeclaredtextalign="center"
                                hasdeclaredcursor="pointer"
                                hasdeclaredwidth="150px"
                                hasdeclaredheight="50px"
                                hasdeclaredfontsize="20px"
                                hasdeclaredborderradius="35px"
                                hasdeclaredmargin="20px 0 0 0"
                                hasdeclaredfontweight="600"
                                whileTap={{ scale: 0.9 }}
                                whileHover={{ scale: 1.05 }}
                                onClick={onClose}
                            >
                                {getString('send_email_popup_close')}
                            </StyledButton>
                        </div>
                    </>
                )
            ) : (
                <>
                    <StyledText
                        hasdeclaredfontsize="18px"
                        hasdeclaredpadding={'50px 20px 20px 20px'}
                        hasdeclaredlineheight="1.4em"
                        hasdeclaredtextalign="center"
                    >
                        {getString('user_panel_students_send_welcome_email_confirmation')}
                    </StyledText>
                    <div style={{ display: 'flex', justifyContent: 'center', gap: '20px' }}>
                        <StyledButton
                            hasdeclaredfontcolor="#fff"
                            hasdeclaredbgcolor="#6d85ba"
                            hasdeclaredtextalign="center"
                            hasdeclaredcursor="pointer"
                            hasdeclaredwidth="150px"
                            hasdeclaredheight="50px"
                            hasdeclaredfontsize="20px"
                            hasdeclaredborderradius="35px"
                            hasdeclaredmargin="20px 0 0 0"
                            hasdeclaredfontweight="600"
                            whileTap={{ scale: 0.9 }}
                            whileHover={{ scale: 1.05 }}
                            onClick={onSend}
                        >
                            {getString('send_email_popup_yes')}
                        </StyledButton>
                        <StyledButton
                            hasdeclaredfontcolor="#fff"
                            hasdeclaredbgcolor="#ee745c"
                            hasdeclaredtextalign="center"
                            hasdeclaredcursor="pointer"
                            hasdeclaredwidth="150px"
                            hasdeclaredheight="50px"
                            hasdeclaredfontsize="20px"
                            hasdeclaredborderradius="35px"
                            hasdeclaredmargin="20px 0 0 0"
                            hasdeclaredfontweight="600"
                            whileTap={{ scale: 0.9 }}
                            whileHover={{ scale: 1.05 }}
                            onClick={onClose}
                        >
                            {getString('send_email_popup_cancel')}
                        </StyledButton>
                    </div>
                </>
            )}
        </PaymentsPopup>
    );
};

export default SendEmailPopup;
