import styled from 'styled-components';

export const StyledElement = styled.div`
    width: ${({ $hasDeclaredWidth }) => ($hasDeclaredWidth ? $hasDeclaredWidth : '25%')};
    display: flex;
    min-height: 30px;
    align-items: center;
    justify-content: center;
    padding: 0 4px;
`;
