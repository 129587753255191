import {
    beginAnalysis,
    editAnalysis,
    rejectAnalysis,
    submitAnalysis,
} from '../../../logic/requests/trainings';

export const handleBegin = (id, setStatus) => {
    beginAnalysis(id)
        .then(() => {
            setStatus?.('in-progress');
        })
        .catch((err) => {
            console.error('beginAnalysis', err);
        });
};

export const handleCancel = (id, reason, setStatus, hideBox) => {
    if (!reason?.length) {
        console.error('handleCancel', 'reason is empty', 'reason:', reason);
        return;
    }

    hideBox?.();
    // TODO: Jakiś loader?

    rejectAnalysis(id, reason)
        .then(() => {
            setStatus?.('cancelled');
        })
        .catch((err) => {
            console.error('handleCancel', err);
        });
};

export const handleFileSelect = (e, setFile) => {
    if (!e.target?.files?.length) return;

    const file = e.target.files[0];

    if (file.type !== 'application/pdf') {
        // TODO: Co tutaj?
        console.warn('handleFileSelect', 'Got invalid file type');
    }

    setFile?.(file);
};

export const handleSubmit = (id, file, status, setStatus, clearForm) => {
    if (!file) {
        return;
    }

    const pain = new FormData();
    pain.append('content', file);

    const submit = status === 'done' ? editAnalysis : submitAnalysis;

    submit(id, pain)
        .then(() => {
            clearForm?.();
            setStatus?.('done');
        })
        .catch((err) => {
            console.error('submitAnalysis', err);
        });
};
