import { useMemo, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import {
    StyledAnnouncementElement,
    StyledHeading,
    StyledHeadingCheckbox,
    StyledTextWrapper,
    StyledElement,
    StyledButtonsContainer,
} from './StyledAnnouncementElement';
import { StyledArrow } from '../../../styles/sharedStyles';
import { StyledText } from '../../atoms/Text/StyledText';
import { StyledButton } from '../../atoms/Button/StyledButton';
import parse from 'html-react-parser';

import { getString } from '../../../strings';
import {
    deleteAnnouncement,
    editAnnouncement,
    markAsRead,
} from '../../../logic/requests/announcements';
import PaymentsPopup from '../Payments/PaymentsPopup';

import AnnouncementForm from '../UserPanelAnnouncements/AnnouncementForm/AnnouncementForm';

const AnnouncementElement = ({
    setIsTrigger,
    isRead,
    id,
    title,
    text,
    date,
    createdBy,
    canEdit,
    updatedBy,
    updatedAt,
    roles,
    manageableRoles,
    recipientsCount,
    readCount,
}) => {
    const queryClient = useQueryClient();
    const [isOpen, setIsOpen] = useState(!isRead);
    const isRed = !isRead;
    const showReadButton = !isRead;
    const [confirmDeletePopup, setConfirmDeletePopup] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    const handleOpen = () => {
        setIsOpen(!isOpen);
        setIsTrigger();
    };

    const handleMarkAsRead = () => {
        markAsRead(id).then(() => {
            queryClient.invalidateQueries({ queryKey: ['announcements'] });
        });
    };

    const handleDelete = () => {
        deleteAnnouncement(id).then(() => {
            queryClient.invalidateQueries({ queryKey: ['announcements'] });
        });
    };

    const handleEdit = (data, { setSubmitting }) => {
        editAnnouncement(id, data).then(() => {
            queryClient.invalidateQueries({ queryKey: ['announcements'] });
            setSubmitting(false);
            setIsEdit(false);
        });
    };

    return (
        <StyledAnnouncementElement isopen={isOpen} hasdeclaredbordercolor={isRed}>
            {confirmDeletePopup ? (
                <PaymentsPopup
                    title={getString('user_panel_announcements_are_you_sure')}
                    onClose={handleDelete}
                    cancelTag={getString('student_payments_element_cancel')}
                    closeTag={getString('student_payments_element_yes')}
                    onCancel={() => setConfirmDeletePopup(false)}
                />
            ) : null}
            <StyledHeading onClick={handleOpen}>
                <StyledText
                    hasdeclaredfontsize="22px"
                    hasdeclaredfontweight="700"
                    hasdeclaredtextalign="center"
                    hasdeclaredtexttransform="uppercase"
                    hasdeclaredfontcolor="#6786BE"
                    hasdeclaredlinehetoLocaleDateTimeStringight="1.2em"
                    hasdeclaredpadding="0 0 0 50px"
                >
                    {title}
                </StyledText>
                <StyledHeadingCheckbox hasdeclaredbgcolor={isRed} $isOpen={isOpen}>
                    <StyledArrow isopen={isOpen} />
                </StyledHeadingCheckbox>
            </StyledHeading>
            {isOpen ? (
                <>
                    {isEdit ? (
                        <AnnouncementForm
                            initialValues={{ title, text, roles }}
                            id={id}
                            roles={manageableRoles}
                            onSubmit={handleEdit}
                            onReset={() => setIsEdit(false)}
                        />
                    ) : (
                        <StyledElement>
                            <StyledText
                                hasdeclaredfontsize="16px"
                                hasdeclaredfontweight="700"
                                hasdeclaredtexttransform="uppercase"
                                hasdeclaredfontcolor="#6786BE"
                            >
                                {getString('pages_userPanel_data')}:{' '}
                                <span style={{ color: '#000' }}>
                                    {new Date(date).toLocaleString()}
                                </span>
                            </StyledText>
                            {canEdit ? (
                                <StyledText
                                    hasdeclaredfontsize="16px"
                                    hasdeclaredfontweight="700"
                                    hasdeclaredtexttransform="uppercase"
                                    hasdeclaredfontcolor="#6786BE"
                                >
                                    {getString('pages_userPanel_announcements_created_by')}:{' '}
                                    <span style={{ color: '#000' }}>{createdBy}</span>
                                </StyledText>
                            ) : null}
                            {updatedBy ? (
                                <StyledText
                                    hasdeclaredfontsize="16px"
                                    hasdeclaredfontweight="700"
                                    hasdeclaredtexttransform="uppercase"
                                    hasdeclaredfontcolor="#6786BE"
                                >
                                    {getString('pages_userPanel_announcements_updated_by')}:{' '}
                                    <span style={{ color: '#000' }}>
                                        {updatedBy} ({new Date(updatedAt).toLocaleString()})
                                    </span>
                                </StyledText>
                            ) : null}
                            {canEdit ? (
                                <StyledText
                                    hasdeclaredfontsize="16px"
                                    hasdeclaredfontweight="700"
                                    hasdeclaredtexttransform="uppercase"
                                    hasdeclaredfontcolor="#6786BE"
                                >
                                    {getString('pages_userPanel_announcements_read')}:{' '}
                                    <span style={{ color: '#000' }}>
                                        {readCount} / {recipientsCount}
                                    </span>
                                </StyledText>
                            ) : null}
                            {canEdit ? (
                                <StyledText
                                    hasdeclaredfontsize="16px"
                                    hasdeclaredfontweight="700"
                                    hasdeclaredtexttransform="uppercase"
                                    hasdeclaredfontcolor="#6786BE"
                                >
                                    {getString('pages_userPanel_announcements_roles')}:{' '}
                                    <span style={{ color: '#000' }}>
                                        {roles
                                            .map((role) => getString(`roles_${role}`))
                                            .join(', ') || '-'}
                                    </span>
                                </StyledText>
                            ) : null}
                            <hr />
                            <StyledTextWrapper>{parse(text || '')}</StyledTextWrapper>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                {showReadButton && !canEdit ? (
                                    <StyledButton
                                        hasdeclaredfontcolor="#fff"
                                        hasdeclaredbgcolor="#6d85ba"
                                        hasdeclaredtextalign="center"
                                        hasdeclaredcursor="pointer"
                                        hasdeclaredwidth="280px"
                                        hasdeclaredheight="60px"
                                        hasdeclaredfontsize="20px"
                                        hasdeclaredborderradius="35px"
                                        hasdeclaredmargin="20px 0 0 0"
                                        hasdeclaredfontweight="600"
                                        whileTap={{ scale: 0.9 }}
                                        whileHover={{ scale: 1.05 }}
                                        onClick={handleMarkAsRead}
                                    >
                                        {getString('pages_userPanel_announcements_mark_as_read')}
                                    </StyledButton>
                                ) : null}
                            </div>
                            {canEdit ? (
                                <StyledButtonsContainer>
                                    <StyledButton
                                        hasdeclaredfontcolor="#fff"
                                        hasdeclaredbgcolor="#6d85ba"
                                        hasdeclaredtextalign="center"
                                        hasdeclaredcursor="pointer"
                                        hasdeclaredwidth="280px"
                                        hasdeclaredheight="60px"
                                        hasdeclaredfontsize="20px"
                                        hasdeclaredborderradius="35px"
                                        hasdeclaredmargin="20px 0 0 0"
                                        hasdeclaredfontweight="600"
                                        whileTap={{ scale: 0.9 }}
                                        whileHover={{ scale: 1.05 }}
                                        onClick={() => setIsEdit(true)}
                                    >
                                        {getString('pages_userPanel_announcements_edit')}
                                    </StyledButton>
                                    <StyledButton
                                        hasdeclaredfontcolor="#fff"
                                        hasdeclaredbgcolor="#ee745c"
                                        hasdeclaredtextalign="center"
                                        hasdeclaredcursor="pointer"
                                        hasdeclaredwidth="280px"
                                        hasdeclaredheight="60px"
                                        hasdeclaredfontsize="20px"
                                        hasdeclaredborderradius="35px"
                                        hasdeclaredmargin="20px 0 0 0"
                                        hasdeclaredfontweight="600"
                                        whileTap={{ scale: 0.9 }}
                                        whileHover={{ scale: 1.05 }}
                                        onClick={() => setConfirmDeletePopup(true)}
                                    >
                                        {getString('pages_userPanel_announcements_delete')}
                                    </StyledButton>
                                    {showReadButton ? (
                                        <StyledButton
                                            hasdeclaredfontcolor="#fff"
                                            hasdeclaredbgcolor="#6d85ba"
                                            hasdeclaredtextalign="center"
                                            hasdeclaredcursor="pointer"
                                            hasdeclaredwidth="280px"
                                            hasdeclaredheight="60px"
                                            hasdeclaredfontsize="20px"
                                            hasdeclaredborderradius="35px"
                                            hasdeclaredmargin="20px 0 0 0"
                                            hasdeclaredfontweight="600"
                                            whileTap={{ scale: 0.9 }}
                                            whileHover={{ scale: 1.05 }}
                                            onClick={handleMarkAsRead}
                                        >
                                            {getString(
                                                'pages_userPanel_announcements_mark_as_read'
                                            )}
                                        </StyledButton>
                                    ) : null}
                                </StyledButtonsContainer>
                            ) : null}
                        </StyledElement>
                    )}
                </>
            ) : null}
        </StyledAnnouncementElement>
    );
};

export default AnnouncementElement;
