import styled from 'styled-components';

export const StyledSubjectDataElement = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;

    > div {
        &:first-child {
            width: 100%;
            overflow-x: scroll;

            scrollbar-color: transparent transparent;
            scrollbar-width: none;

            &::-webkit-scrollbar {
                height: 0;
                width: 0;
            }

            &::-webkit-scrollbar-track {
                background: transparent;
            }

            &::-webkit-scrollbar-thumb {
                background: transparent;
            }
        }
    }
`;

export const StyledSubjectDataTextElement = styled.div`
    width: ${({ $hasDeclaredWidth }) => ($hasDeclaredWidth ? $hasDeclaredWidth : '33%')};
    display: flex;
    min-height: 30px;
    align-items: center;
    justify-content: center;
    padding: 0 4px;
`;
