import styled from 'styled-components';
import { scrollBlueStyles } from '../../../styles/sharedStyles';

export const StyledInformationContentWrapper = styled.div`
    width: 100%;
    height: 65%;
    margin-top: 220px;
    overflow-y: scroll;
    padding-right: 10px;

    ${scrollBlueStyles};

    @media only screen and (max-width: 767px) {
        overflow-y: visible;
        margin-top: 20px;
    }
`;
