// https://github.com/jaredpalmer/formik/issues/1633#issuecomment-520121543

import { useEffect } from 'react';
import usePrevious from 'react-use/lib/usePrevious';
import { connect } from 'formik';

const FormikOnChange = ({ onChange, formik }) => {
    const { values } = formik;
    const prevValues = usePrevious(values);

    useEffect(() => {
        // Don't run effect on form init
        if (prevValues) {
            onChange({ prevValues, nextValues: values, formik });
        }
    }, [values]);

    return null;
};

export default connect(FormikOnChange);
