import styled from 'styled-components';
import { motion } from 'framer-motion';
import { scrollBlueStyles } from '../../../styles/sharedStyles';

export const StyledOrderRek = styled(motion.div)`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justif-content: flex-start;

    max-height: ${({ issend }) => (issend ? '100%' : '90%')};
    overflow-y: ${({ issend }) => (issend ? 'visible' : 'scroll')};
    overflow-x: hidden;

    ${scrollBlueStyles};

    @media only screen and (max-width: 767px) {
        padding-bottom: 40px;
        max-height: unset;
        height: auto;
        overflow-y: visible;

        > h1 {
            font-size: 24px;
        }

        > h2 {
            margin-top: 0;
        }
    }

    @media only screen and (max-width: 767px) {
        > h1 {
            font-size: 20px;
        }
    }
`;

export const StyledAnswerWrapper = styled.div`
    max-width: 700px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 10px;

    > p {
        padding: 10px;

        > a {
            color: #6786be;
            text-decoration: none;
        }
    }

    @media only screen and (max-width: 520px) {
        width: 98%;
    }
`;

export const StyledCodeWrapper = styled.div`
    margin-top: 22px;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 30px;
`;

export const StyledInputWrapp = styled.div`
    width: ${({ isfullwidth }) => (isfullwidth ? '100%' : '50%')};
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    > div {
        width: ${({ isfullwidthinput }) => isfullwidthinput};

        > input {
            width: 100%;
            max-width: ${({ isfullwidthinput }) => (isfullwidthinput ? '667px' : '300px')};
            min-height: ${({ isfullwidthinput }) => (isfullwidthinput ? '60px' : 'unset')};
        }
    }

    @media only screen and (max-width: 1005px) {
        width: 100%;
        padding: 0 20px;

        > div {
            width: 100%;
            max-width: unset;

            input {
                max-width: unset;
                width: 100%;
            }
        }
    }
`;

export const StyledInputWrappSelect = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 667px;

    > div {
        width: 100%;

        > select {
            height: 60px;
            max-width: unset;
        }
    }

    @media only screen and (max-width: 1005px) {
        padding: 0 20px;
        max-width: unset;
    }
`;

export const StyledOrderRekForm = styled.form`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 80%;
    padding-bottom: 40px;
    max-width: 800px;

    @media only screen and (max-width: 1183px) {
        width: 95%;
    }

    @media only screen and (max-width: 1005px) {
        width: 100%;
        flex-direction: column;
        align-items: center;
    }
`;

export const StyledRadioCompetitionInput = styled.div`
    label {
        width: 36px;
        height: 36px;

        svg {
            width: 36px;
            height: 36px;
            cursor: pointer;
            fill: ${({ ischecked }) => (ischecked ? '#4c7bbc' : 'rgba(0,0,0,0.2)')};
            transition: fill 250ms;
            transform: translate(7px, 2px);
        }
    }

    input {
        opacity: 0;
        pointer-events: none;
    }
`;

export const StyledRadioCompetitionWrapper = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background: #fff;
    min-height: 60px;
    border: ${({ haserror }) => (haserror ? '3px solid red' : '3px solid #3474e8')};
    border-radius: 25px;
    padding: 8px 12px;
    margin-top: 12px;
    margin-bottom: 20px;
    flex-wrap: wrap;
    max-width: 667px;
    width: 100%;
`;

export const StyledRadioLabel = styled.label`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;

    p {
        color: #333;
        cursor: pointer;
    }

    input {
        pointer-events: none;
        opacity: 0;
        width: 0;
        height: 0;
    }

    input:not(:checked) + p {
        color: rgba(0, 0, 0, 0.2);
    }

    input:checked + p {
        color: #6786be;
        font-weight: bold;
    }
`;

export const StyledRadioWrapper = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background: #fff;
    min-height: 60px;
    border: ${({ haserror }) => (haserror ? '3px solid red' : '3px solid #3474e8')};
    border-radius: 25px;
    padding: 8px 12px;
    margin-top: 12px;
    flex-wrap: wrap;
    max-width: 667px;
    width: 100%;
`;

export const StyledRegulaminWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 10px;
    position: relative;
    max-width: 700px;

    > div {
        transform: translateX(15px);
    }
`;
