import styled from 'styled-components';

export const StyledSeriesResultsMobileElement = styled(({ hasdeclaredmargin, ...props }) => (
    <div {...props} />
))`
    display: flex;
    justify-content: space-between;
    min-height: 50px;
    width: 100%;
    margin: ${({ hasdeclaredmargin }) => hasdeclaredmargin ?? '0 0 20px 0'};
`;
