import styled from 'styled-components';

export const StyledAttemptLinkYoutube = styled.div`
    margin-top: 120px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    button {
        color: #fff;
        border: none;
        font-size: 18px;
        padding: 22px 0;
        cursor: pointer;
    }

    > input {
        width: 330px;
        font-size: 16px;
    }

    @media only screen and (max-width: 440px) {
        > input {
            width: 260px;
        }
    }
`;
