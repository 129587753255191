import React, { useState, useCallback, useMemo } from 'react';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';
import { useForm } from 'react-hook-form';
import SubjectDataElement from '../SubjectDataElement/SubjectDataElement';
import { yupResolver } from '@hookform/resolvers/yup';

import {
    StyledUserPanelSubjectDataElement,
    StyledElement,
    StyledHeader,
    StyledHeaderElement,
    StyledDeleteButton,
    StyledEditButton,
    StyledFuckingBtn,
    StyledDesktopPrices,
    StyledMobilePrices,
} from './UserPanelSubjectDataElement.styles';
import { StyledText } from '../../atoms/Text/StyledText';
import { StyledHeading, StyledArrow } from '../../../styles/sharedStyles';
import { StyledHeadingCheckbox } from '../../atoms/SettlementsDoneElement/StyledHeadingCheckbox';
import { StyledInputWrapper } from '../../../styles/sharedStyles';
import { StyledInputsDivWrapper } from '../../atoms/StyledInputsDivWrapper/StyledInputsDivWrapper';

import CloseIcon from '../../atoms/UserPanelStudens/CloseIcon';
import { getLang, getString } from '../../../strings';
import useScrollBar from '../../../logic/hooks/useScrollBar';
import { deleteSubject, editSubject } from '../../../logic/requests/students';
import { useQueryClient } from '@tanstack/react-query';
import { formSchema } from '../../organisms/UserPanelSubjectElement/UserPanelSubjectEditElement.data';
import PencilIcon from '../../atoms/UserPanelStudens/PencilIcon';
import AcceptIcon from '../../atoms/UserPanelStudens/AcceptIcon';
import PaymentsPopup from '../../organisms/Payments/PaymentsPopup';
import Decimal from 'decimal.js';

const UserPanelSubjectDataElement = ({ data }) => {
    const queryClient = useQueryClient();
    const [isEdit, setIsEdit] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [isError, setIsError] = useState({ message: null, showError: false });
    const { scrollElement, isScrollBar } = useScrollBar(false, true);
    const [confirmPopup, setConfirmPopup] = useState(false);

    const handleOpen = () => {
        setIsError({ message: null });
        setIsOpen(!isOpen);
    };

    const {
        register,
        handleSubmit,
        formState: { errors, isDirty },
        getValues,
    } = useForm({
        resolver: yupResolver(formSchema),
        defaultValues: {
            namePL: data?.description?.pl || '',
            nameGB: data?.description?.gb || '',
            nameUA: data?.description?.ua || '',
            nameCZ: data?.description?.cz || '',
            studentPrice: data.price,
            trainerPrice: data.price_trainer,
        },
    });

    const onSubmit = useCallback(
        (dataForm) => {
            if (!isDirty) setIsEdit(false);

            editSubject(data.id, dataForm)
                .then(() => {
                    setIsEdit(false);
                    queryClient.invalidateQueries({ queryKey: ['subjects', 'all'] });
                })
                .catch(() =>
                    setIsError({
                        message: getString('User_panel_subject_element_wystapil_blad'),
                        showError: true,
                    })
                );
        },
        [isDirty, queryClient, data.id]
    );

    const handleDeleteSubject = useCallback(() => {
        if (!confirmPopup) setConfirmPopup(true);

        if (confirmPopup)
            deleteSubject(data.id)
                .then(() => {
                    queryClient.invalidateQueries({ queryKey: ['subjects', 'all'] });
                })
                .catch(() => {
                    setIsError({ message: 'Nie można usunąć przedmiotu', showError: false });
                });
    }, [data.id, queryClient, confirmPopup]);

    const retEditButton = useMemo(
        () =>
            isEdit ? (
                <StyledEditButton type="submit" isedit={isEdit}>
                    <AcceptIcon />
                </StyledEditButton>
            ) : (
                <StyledFuckingBtn isedit={isEdit} onClick={() => setIsEdit(true)}>
                    <PencilIcon />
                </StyledFuckingBtn>
            ),
        [isEdit]
    );

    return (
        <>
            {confirmPopup ? (
                <PaymentsPopup
                    title={
                        isError.message || getString('user_panel_subject_data_element_czy_chcesz')
                    }
                    onClose={() => {
                        setConfirmPopup(false);
                        setIsError({ message: null, showError: false });
                    }}
                    cancelTag={isError.message ? null : getString('addition_data_element_usun')}
                    onCancel={handleDeleteSubject}
                />
            ) : null}
            <StyledUserPanelSubjectDataElement isopen={isOpen}>
                <StyledHeading onClick={handleOpen}>
                    <StyledText
                        hasdeclaredfontsize="22px"
                        hasdeclaredfontweight="700"
                        hasdeclaredtextalign="center"
                        hasdeclaredtexttransform="uppercase"
                        hasdeclaredfontcolor="#6786BE"
                    >
                        {data.description ? data.description[getLang()] : data.id}
                    </StyledText>
                    <StyledHeadingCheckbox>
                        <StyledArrow isopen={isOpen} />
                    </StyledHeadingCheckbox>
                </StyledHeading>
                {isOpen ? (
                    <StyledElement onSubmit={handleSubmit(onSubmit)}>
                        <ScrollSync>
                            <>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '8px',
                                    }}
                                >
                                    <ScrollSyncPane>
                                        <StyledHeader
                                            isscrollbar={isScrollBar}
                                            ref={scrollElement}
                                            hasalignself="flex-start"
                                        >
                                            <div>
                                                <StyledHeaderElement $hasWidth="10%" />
                                                <StyledHeaderElement $hasWidth="90%">
                                                    <StyledText
                                                        hasdeclaredfontsize="16px"
                                                        hasdeclaredfontweight="700"
                                                        hasdeclaredtextalign="center"
                                                        hasdeclaredlineheight="1.4em"
                                                    >
                                                        {data.description
                                                            ? data.description[getLang()]
                                                            : data.id}
                                                    </StyledText>
                                                </StyledHeaderElement>
                                            </div>
                                        </StyledHeader>
                                    </ScrollSyncPane>
                                    <SubjectDataElement
                                        lang="PL"
                                        isEdit={isEdit}
                                        register={register}
                                        name="namePL"
                                        errors={errors}
                                        value={getValues}
                                    />
                                    <SubjectDataElement
                                        lang="GB"
                                        isEdit={isEdit}
                                        register={register}
                                        name="nameGB"
                                        errors={errors}
                                        value={getValues}
                                    />
                                    <SubjectDataElement
                                        lang="CZ"
                                        isEdit={isEdit}
                                        register={register}
                                        name="nameCZ"
                                        errors={errors}
                                        value={getValues}
                                    />
                                    <SubjectDataElement
                                        lang="UA"
                                        isEdit={isEdit}
                                        register={register}
                                        name="nameUA"
                                        errors={errors}
                                        value={getValues}
                                    />
                                    <StyledDesktopPrices>
                                        <ScrollSyncPane>
                                            <StyledHeader
                                                isscrollbar={isScrollBar}
                                                ref={scrollElement}
                                                hasalignself="flex-start"
                                            >
                                                <div>
                                                    <StyledHeaderElement>
                                                        <StyledText
                                                            hasdeclaredfontsize="16px"
                                                            hasdeclaredfontweight="700"
                                                            hasdeclaredtextalign="center"
                                                            hasdeclaredlineheight="1.4em"
                                                        >
                                                            {getString(
                                                                'User_panel_subject_element_cena_ucznia'
                                                            )}
                                                        </StyledText>
                                                    </StyledHeaderElement>
                                                    <StyledHeaderElement>
                                                        <StyledText
                                                            hasdeclaredfontsize="16px"
                                                            hasdeclaredfontweight="700"
                                                            hasdeclaredtextalign="center"
                                                            hasdeclaredlineheight="1.4em"
                                                        >
                                                            {getString(
                                                                'User_panel_subject_element_cena_trenera'
                                                            )}
                                                        </StyledText>
                                                    </StyledHeaderElement>
                                                </div>
                                            </StyledHeader>
                                        </ScrollSyncPane>

                                        <StyledInputsDivWrapper
                                            hasmarginbottom
                                            hasborder
                                            $hasDeclaredPadding={isEdit}
                                        >
                                            <StyledInputWrapper error={errors?.['studentPrice']}>
                                                {isEdit ? (
                                                    <input
                                                        id="cenaZaGodzine"
                                                        type="number"
                                                        step="0.01"
                                                        disabled={!isEdit}
                                                        {...register('studentPrice')}
                                                    />
                                                ) : (
                                                    <StyledText
                                                        hasdeclaredfontsize="16px"
                                                        hasdeclaredtextalign="center"
                                                        hasdeclaredpadding="8px 0 8px 0"
                                                        hasdeclaredfontweight="600"
                                                        hasdeclaredlineheight="1.4em"
                                                    >
                                                        {new Decimal(
                                                            getValues('studentPrice')
                                                        ).toFixed(2)}
                                                    </StyledText>
                                                )}
                                                {errors?.['studentPrice'] ? (
                                                    <StyledText
                                                        as="h4"
                                                        hasdeclaredfontsize="16px"
                                                        hasdeclaredfontcolor="#F74017"
                                                        hasdeclaredtextalign="center"
                                                        hasdeclaredfontweight="700"
                                                        hasdeclaredtexttransform="uppercase"
                                                        hasdeclaredlineheight="1.4em"
                                                    >
                                                        {getString(
                                                            'User_panel_subject_element_cena_ucznia_error'
                                                        )}
                                                    </StyledText>
                                                ) : null}
                                            </StyledInputWrapper>
                                            <StyledInputWrapper error={errors?.['trainerPrice']}>
                                                {isEdit ? (
                                                    <input
                                                        id="kwotaDodatkowa"
                                                        type="number"
                                                        step="0.01"
                                                        disabled={!isEdit}
                                                        {...register('trainerPrice')}
                                                    />
                                                ) : (
                                                    <StyledText
                                                        hasdeclaredfontsize="16px"
                                                        hasdeclaredtextalign="center"
                                                        hasdeclaredpadding="8px 0 8px 0"
                                                        hasdeclaredfontweight="600"
                                                        hasdeclaredlineheight="1.4em"
                                                    >
                                                        {new Decimal(
                                                            getValues('trainerPrice')
                                                        ).toFixed(2)}
                                                    </StyledText>
                                                )}
                                                {errors?.['trainerPrice'] ? (
                                                    <StyledText
                                                        as="h4"
                                                        hasdeclaredfontsize="16px"
                                                        hasdeclaredfontcolor="#F74017"
                                                        hasdeclaredtextalign="center"
                                                        hasdeclaredfontweight="700"
                                                        hasdeclaredtexttransform="uppercase"
                                                        hasdeclaredlineheight="1.4em"
                                                    >
                                                        {getString(
                                                            'User_panel_subject_element_cena_trenera_error'
                                                        )}
                                                    </StyledText>
                                                ) : null}
                                            </StyledInputWrapper>
                                        </StyledInputsDivWrapper>
                                    </StyledDesktopPrices>
                                    <StyledMobilePrices>
                                        <StyledText
                                            hasdeclaredfontsize="16px"
                                            hasdeclaredfontweight="700"
                                            hasdeclaredtextalign="center"
                                            hasdeclaredlineheight="1.4em"
                                            >
                                            {getString('User_panel_subject_element_cena_ucznia')}
                                            </StyledText>
                                        <StyledInputsDivWrapper
                                            hasmarginbottom
                                            hasborder
                                            $hasDeclaredPadding={isEdit}
                                        >
                                            <StyledInputWrapper error={errors?.['studentPrice']} $withoutInputMarginBottom>
                                                {isEdit ? (
                                                    <input
                                                        id="cenaZaGodzine"
                                                        type="number"
                                                        step="0.01"
                                                        disabled={!isEdit}
                                                        {...register('studentPrice')}
                                                    />
                                                ) : (
                                                    <StyledText
                                                        hasdeclaredfontsize="16px"
                                                        hasdeclaredtextalign="center"
                                                        hasdeclaredpadding="8px 0 8px 0"
                                                        hasdeclaredfontweight="600"
                                                        hasdeclaredlineheight="1.4em"
                                                    >
                                                        {new Decimal(
                                                            getValues('studentPrice')
                                                        ).toFixed(2)}
                                                    </StyledText>
                                                )}
                                                {errors?.['studentPrice'] ? (
                                                    <StyledText
                                                        as="h4"
                                                        hasdeclaredfontsize="16px"
                                                        hasdeclaredfontcolor="#F74017"
                                                        hasdeclaredtextalign="center"
                                                        hasdeclaredfontweight="700"
                                                        hasdeclaredtexttransform="uppercase"
                                                        hasdeclaredlineheight="1.4em"
                                                    >
                                                        {getString(
                                                            'User_panel_subject_element_cena_ucznia_error'
                                                        )}
                                                    </StyledText>
                                                ) : null}
                                            </StyledInputWrapper>
                                        </StyledInputsDivWrapper>
                                        <StyledText
                                            hasdeclaredfontsize="16px"
                                            hasdeclaredfontweight="700"
                                            hasdeclaredtextalign="center"
                                            hasdeclaredlineheight="1.4em"
                                            >
                                            {getString('User_panel_subject_element_cena_trenera')}
                                            </StyledText>   
                                        <StyledInputsDivWrapper
                                            hasmarginbottom
                                            hasborder
                                            $hasDeclaredPadding={isEdit}
                                        >
                                            <StyledInputWrapper error={errors?.['trainerPrice']} $withoutInputMarginBottom>
                                                {isEdit ? (
                                                    <input
                                                        id="kwotaDodatkowa"
                                                        type="number"
                                                        step="0.01"
                                                        disabled={!isEdit}
                                                        {...register('trainerPrice')}
                                                    />
                                                ) : (
                                                    <StyledText
                                                        hasdeclaredfontsize="16px"
                                                        hasdeclaredtextalign="center"
                                                        hasdeclaredpadding="8px 0 8px 0"
                                                        hasdeclaredfontweight="600"
                                                        hasdeclaredlineheight="1.4em"
                                                    >
                                                        {new Decimal(
                                                            getValues('trainerPrice')
                                                        ).toFixed(2)}
                                                    </StyledText>
                                                )}
                                                {errors?.['trainerPrice'] ? (
                                                    <StyledText
                                                        as="h4"
                                                        hasdeclaredfontsize="16px"
                                                        hasdeclaredfontcolor="#F74017"
                                                        hasdeclaredtextalign="center"
                                                        hasdeclaredfontweight="700"
                                                        hasdeclaredtexttransform="uppercase"
                                                        hasdeclaredlineheight="1.4em"
                                                    >
                                                        {getString(
                                                            'User_panel_subject_element_cena_trenera_error'
                                                        )}
                                                    </StyledText>
                                                ) : null}
                                            </StyledInputWrapper>
                                        </StyledInputsDivWrapper>
                                    </StyledMobilePrices>
                                
                                </div>
                            </>
                        </ScrollSync>
                        <StyledDeleteButton onClick={handleDeleteSubject}>
                            <CloseIcon />
                        </StyledDeleteButton>
                        {retEditButton}
                        {isError.message && isError.showError ? (
                            <StyledText
                                as="h4"
                                hasdeclaredfontsize="16px"
                                hasdeclaredfontcolor="#F74017"
                                hasdeclaredtextalign="center"
                                hasdeclaredfontweight="700"
                                hasdeclaredtexttransform="uppercase"
                                hasdeclaredlineheight="1.4em"
                            >
                                {isError.message}
                            </StyledText>
                        ) : null}
                    </StyledElement>
                ) : null}
            </StyledUserPanelSubjectDataElement>
        </>
    );
};

export default UserPanelSubjectDataElement;
