import { useCallback, useMemo, useState } from 'react';
import PaymentsPopup from '../Payments/PaymentsPopup';
import { getString } from '../../../strings';
import { StyledText } from '../../atoms/Text/StyledText';
import { StyledButton } from '../../atoms/Button/StyledButton';
import { StyledBigLoader } from '../../atoms/Loader/StyledBigLoader';
import { StyledInputsDivWrapper } from '../../atoms/StyledInputsDivWrapper/StyledInputsDivWrapper';
import { StyledInputWrapper } from '../../../styles/sharedStyles';
import { downloadThanksCards } from './UserPanelTournament.data';

const GetThanksModal = ({ eventData, onClose }) => {
    const [thanksDownloaded, setThanksDownloaded] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [listUsers, setListUsers] = useState([]);
    const [blank, setBlank] = useState(0);

    const onSubmit = useCallback(() => {
        setIsLoading(true);
        downloadThanksCards(
            eventData,
            listUsers.map((u) => u.name),
            blank
        );
        setIsLoading(false);
        setThanksDownloaded(true);
    }, [blank, eventData, listUsers]);

    const handleAddUser = useCallback(() => {
        setListUsers((prevListUsers) => [
            ...prevListUsers,
            {
                index: prevListUsers.length + 1,
                name: '',
            },
        ]);
    }, []);

    const handleDeleteUser = useCallback(() => {
        setListUsers((prevListUsers) => prevListUsers.slice(0, prevListUsers.length - 1));
    }, []);

    const renderContent = useMemo(
        () =>
            thanksDownloaded ? (
                <div>
                    <StyledText
                        hasdeclaredfontsize="18px"
                        hasdeclaredpadding={'50px 20px 20px 20px'}
                        hasdeclaredlineheight="1.4em"
                        hasdeclaredtextalign="center"
                    >
                        {getString('userPanel_userPanelTournament_get_thanks_sent')}
                    </StyledText>
                    <div style={{ display: 'flex', justifyContent: 'center', gap: '20px' }}>
                        <StyledButton
                            hasdeclaredbgcolor="#6786BE"
                            hasdeclaredpadding="0 28px"
                            hasdeclaredfontcolor="#fff"
                            hasdeclaredborderradius="25px"
                            hasdeclaredfontweight="700"
                            hasdeclaredtextalign="center"
                            hasdeclaredcursor="pointer"
                            hasdeclaredfontsize="18px"
                            hasdeclaredtexttransform="uppercase"
                            hasdeclaredheight="50px"
                            hasdeclaredmargin="20px auto 0"
                            onClick={onClose}
                        >
                            {getString('all_trainer_pay_popup_zamknij')}
                        </StyledButton>
                    </div>
                </div>
            ) : isLoading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <StyledBigLoader />
                </div>
            ) : (
                <>
                    <StyledText
                        hasdeclaredfontsize="18px"
                        hasdeclaredpadding={'40px 20px 20px 20px'}
                        hasdeclaredlineheight="1.4em"
                        hasdeclaredtextalign="center"
                    >
                        {getString('userPanel_userPanelTournament_pobierzPodziekowania')}
                    </StyledText>

                    <StyledInputsDivWrapper>
                        <StyledInputWrapper>
                            <label htmlFor="blank">
                                <StyledText
                                    hasdeclaredfontsize="20px"
                                    hasdeclaredtextalign="center"
                                    hasdeclaredpadding="8px 0 8px 0"
                                    hasdeclaredfontweight="600"
                                >
                                    {getString('userPanel_userPanelTournament_get_thanks_blank')}
                                </StyledText>
                            </label>
                            <input
                                name="blank"
                                value={blank}
                                onChange={(e) => setBlank(+e.target.value)}
                                as="input"
                                type="number"
                            />
                        </StyledInputWrapper>
                    </StyledInputsDivWrapper>
                    <StyledInputsDivWrapper>
                        <StyledInputWrapper>
                            <label>
                                <StyledText
                                    hasdeclaredfontsize="20px"
                                    hasdeclaredtextalign="center"
                                    hasdeclaredpadding="8px 0 0 0"
                                    hasdeclaredfontweight="600"
                                >
                                    {getString('userPanel_userPanelTournament_get_thanks_filled')}
                                </StyledText>
                            </label>
                        </StyledInputWrapper>
                    </StyledInputsDivWrapper>
                    <div style={{ display: 'flex', justifyContent: 'center', gap: 10 }}>
                        <StyledButton
                            hasdeclaredfontcolor="#fff"
                            hasdeclaredbgcolor="#6d85ba"
                            hasdeclaredtextalign="center"
                            hasdeclaredcursor="pointer"
                            hasdeclaredwidth="150px"
                            hasdeclaredheight="50px"
                            hasdeclaredfontsize="20px"
                            hasdeclaredborderradius="35px"
                            hasdeclaredmargin="20px 0 20px 0"
                            hasdeclaredfontweight="600"
                            onClick={handleAddUser}
                        >
                            {getString('userPanel_userPanelTournament_get_thanks_add')}
                        </StyledButton>
                        <StyledButton
                            hasdeclaredfontcolor="#fff"
                            hasdeclaredbgcolor="#ee745c"
                            hasdeclaredtextalign="center"
                            hasdeclaredcursor="pointer"
                            hasdeclaredwidth="150px"
                            hasdeclaredheight="50px"
                            hasdeclaredfontsize="20px"
                            hasdeclaredborderradius="35px"
                            hasdeclaredmargin="20px 0 20px 0"
                            hasdeclaredfontweight="600"
                            onClick={handleDeleteUser}
                        >
                            {getString('userPanel_userPanelTournament_get_thanks_remove')}
                        </StyledButton>
                    </div>
                    {listUsers.map((user) => (
                        <StyledInputsDivWrapper key={user.index}>
                            <StyledInputWrapper>
                                <input
                                    name={`name_${user.index}`}
                                    value={user.name}
                                    onChange={(e) => {
                                        const updatedList = listUsers.map((u) =>
                                            u.index === user.index
                                                ? { ...u, name: e.target.value }
                                                : u
                                        );
                                        setListUsers(updatedList);
                                    }}
                                    as="input"
                                    type="text"
                                />
                            </StyledInputWrapper>
                        </StyledInputsDivWrapper>
                    ))}
                    <div style={{ display: 'flex', justifyContent: 'center', gap: '20px' }}>
                        <StyledButton
                            hasdeclaredfontcolor="#fff"
                            hasdeclaredbgcolor="#6d85ba"
                            hasdeclaredtextalign="center"
                            hasdeclaredcursor="pointer"
                            hasdeclaredwidth="150px"
                            hasdeclaredheight="50px"
                            hasdeclaredfontsize="20px"
                            hasdeclaredborderradius="35px"
                            hasdeclaredmargin="20px 0 0 0"
                            hasdeclaredfontweight="600"
                            whileTap={{ scale: 0.9 }}
                            whileHover={{ scale: 1.05 }}
                            onClick={onSubmit}
                        >
                            {getString('userPanel_userPanelTournament_get_thanks_download')}
                        </StyledButton>
                        <StyledButton
                            hasdeclaredfontcolor="#fff"
                            hasdeclaredbgcolor="#ee745c"
                            hasdeclaredtextalign="center"
                            hasdeclaredcursor="pointer"
                            hasdeclaredwidth="150px"
                            hasdeclaredheight="50px"
                            hasdeclaredfontsize="20px"
                            hasdeclaredborderradius="35px"
                            hasdeclaredmargin="20px 0 40px 0"
                            hasdeclaredfontweight="600"
                            whileTap={{ scale: 0.9 }}
                            whileHover={{ scale: 1.05 }}
                            onClick={onClose}
                        >
                            {getString('userPanel_userPanelTournament_payment_reminder_cancel')}
                        </StyledButton>
                    </div>
                </>
            ),
        [
            thanksDownloaded,
            onClose,
            isLoading,
            blank,
            setBlank,
            handleAddUser,
            handleDeleteUser,
            listUsers,
            onSubmit,
        ]
    );

    return <PaymentsPopup>{renderContent}</PaymentsPopup>;
};

export default GetThanksModal;
