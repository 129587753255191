import React from 'react';

import UserPanelButton from '../../atoms/UserPanelButton/UserPanelButton';

import { StyledUserPanelStudentsMenu } from '../../../styles/sharedStyles';

import { getString } from '../../../strings';
import { PAGEPANEL } from '../../constants/UserPanel';

const UserPanelStudentsMenu = ({
    subpanel,
    isOpen = false,
    changePanel = () => undefined,
    showFormer,
}) => (
    <StyledUserPanelStudentsMenu>
        <UserPanelButton
            isActive={isOpen}
            onClick={() => changePanel(PAGEPANEL.STUDENTS, PAGEPANEL.STUDENTS_LIST)}
            label={getString('pages_userPanel_uczniowie')}
            hasArrow
            isOpen={isOpen}
        />
        {isOpen ? (
            <>
                <UserPanelButton
                    isActive={subpanel === PAGEPANEL.STUDENTS_LIST}
                    onClick={() => changePanel(PAGEPANEL.STUDENTS, PAGEPANEL.STUDENTS_LIST)}
                    label={getString('UserPanelStudents__menu__students_list')}
                />
                {showFormer && (
                    <UserPanelButton
                        isActive={subpanel === PAGEPANEL.FORMER_STUDENTS_LIST}
                        onClick={() =>
                            changePanel(PAGEPANEL.STUDENTS, PAGEPANEL.FORMER_STUDENTS_LIST)
                        }
                        label={getString('pages_userPanel_former_students')}
                    />
                )}
                <UserPanelButton
                    isActive={subpanel === PAGEPANEL.STUDENTS_PREFS}
                    onClick={() => changePanel(PAGEPANEL.STUDENTS, PAGEPANEL.STUDENTS_PREFS)}
                    label={getString('UserPanelStudents__menu__students_preferences')}
                />
            </>
        ) : undefined}
    </StyledUserPanelStudentsMenu>
);

export default UserPanelStudentsMenu;
