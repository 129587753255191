import styled from 'styled-components';
import { Form } from 'formik';

export const StyledUserPanelAnnouncementsElement = styled(Form)`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
`;
