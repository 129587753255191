import React from 'react';
import { getString } from '../../../strings';
import RegisterErrorMessage from './RegisterErrorMessage';

import { StyledSelectWrapper } from '../../atoms/Register/StyledSelectWrapper';
import { StyledSelectInput } from './StyledSelectInput';
import { StyledText } from '../../atoms/Text/StyledText';

const SelectSize = ({ title, name, error, annotation, selected, onChange }) => {
    return (
        <StyledSelectWrapper>
            <label htmlFor={name}>
                <StyledText
                    hasdeclaredfontsize="20px"
                    hasdeclaredtextalign="center"
                    hasdeclaredpadding="8px 0 8px 0"
                    hasdeclaredfontweight="600"
                >
                    {title}
                </StyledText>
            </label>
            <StyledSelectInput
                as="select"
                component="select"
                error={error}
                name={name}
                id={name}
                onChange={onChange}
            >
                <option label={getString('register_selectSize_brak')}></option>
                <option value="S" label="S" selected={selected === 'S'}></option>
                <option value="M" label="M" selected={selected === 'M'}></option>
                <option value="L" label="L" selected={selected === 'L'}></option>
                <option value="XL" label="XL" selected={selected === 'XL'}></option>
            </StyledSelectInput>
            {annotation && (
                <StyledText
                    hasdeclaredfontsize="12px"
                    hasdeclaredtextalign="center"
                    hasdeclaredpadding="2px 0 1px 0"
                    hasdeclaredfontweight="600"
                >
                    {annotation}
                </StyledText>
            )}
            <RegisterErrorMessage name={name} />
        </StyledSelectWrapper>
    );
};

export default SelectSize;
