import styled from 'styled-components';

export const StyledWhitePlaceDiv = styled.div`
    min-width: 624px;
    width: 60%;
    background-color: #fff;
    margin-left: 2%;
    border: 2px solid #000;
    border-radius: 15px;
    min-height: 50px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    > p {
        min-width: 35px;
    }
`;
