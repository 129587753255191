import styled from 'styled-components';
import { ReactComponent as Cube } from '../../../images/cubes/sq1.svg';

export const StyledCubeSq1 = styled(Cube)`
    position: absolute;
    bottom: 60px;
    right: 20px;
    transform: rotate(38deg);
    width: 140px;
    height: 140px;
    fill: rgba(0, 0, 0, 0.1);

    @media only screen and (min-width: 1920px) {
        right: 40px;
        width: 170px;
        height: 170px;
    }
`;
