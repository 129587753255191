import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';

import { StyledElement, StyledHeaderElement } from './ManageContractsElement.styles';
import { StyledText } from '../../atoms/Text/StyledText';
import { StyledHeader } from '../../../styles/sharedStyles';
import { getString } from '../../../strings';
import ContractRow from './ContractRow';
import useScrollBar from '../../../logic/hooks/useScrollBar';

const ContractsTable = ({ contracts, companies, contractTypes }) => {
    const { scrollElement, isScrollBar } = useScrollBar(false, true);
    return (
        <StyledElement>
            <ScrollSync>
                <ScrollSyncPane>
                    <StyledHeader
                        isscrollbar={isScrollBar}
                        ref={scrollElement}
                        hasalignself="flex-start"
                        $isCheckboxMinusWidth={104}
                        $isCheckbox={true}
                    >
                        <div>
                            <StyledHeaderElement>
                                <StyledText
                                    hasdeclaredfontsize="16px"
                                    hasdeclaredfontweight="700"
                                    hasdeclaredtextalign="center"
                                    hasdeclaredlineheight="1.4em"
                                >
                                    {getString('user_panel_manage_contracts_element_company')}
                                </StyledText>
                            </StyledHeaderElement>
                            <StyledHeaderElement>
                                <StyledText
                                    hasdeclaredfontsize="16px"
                                    hasdeclaredfontweight="700"
                                    hasdeclaredtextalign="center"
                                    hasdeclaredlineheight="1.4em"
                                >
                                    {getString('user_panel_manage_contracts_element_start')}
                                </StyledText>
                            </StyledHeaderElement>
                            <StyledHeaderElement>
                                <StyledText
                                    hasdeclaredfontsize="16px"
                                    hasdeclaredfontweight="700"
                                    hasdeclaredtextalign="center"
                                    hasdeclaredlineheight="1.4em"
                                >
                                    {getString('user_panel_manage_contracts_element_koniec')}
                                </StyledText>
                            </StyledHeaderElement>
                            <StyledHeaderElement>
                                <StyledText
                                    hasdeclaredfontsize="16px"
                                    hasdeclaredfontweight="700"
                                    hasdeclaredtextalign="center"
                                    hasdeclaredlineheight="1.4em"
                                >
                                    {getString('manage_contracts_element_typ')}
                                </StyledText>
                            </StyledHeaderElement>
                            <StyledHeaderElement>
                                <StyledText
                                    hasdeclaredfontsize="16px"
                                    hasdeclaredfontweight="700"
                                    hasdeclaredtextalign="center"
                                    hasdeclaredlineheight="1.4em"
                                >
                                    {getString('manage_contracts_element_umowa')}
                                </StyledText>
                            </StyledHeaderElement>
                        </div>
                    </StyledHeader>
                    {contracts.map((contract) => (
                        <ContractRow contract={contract} key={contract.id} companies={companies} contractTypes={contractTypes} />
                    ))}
                </ScrollSyncPane>
            </ScrollSync>
        </StyledElement>
    );
};

export default ContractsTable;
