import React, { useMemo } from 'react';
import { ScrollSyncPane } from 'react-scroll-sync';
import Decimal from 'decimal.js';

import { StyledText } from '../../atoms/Text/StyledText';
import {
    StyledEmployeesDataElement,
    StyledWrapper,
    StyledEmployeesDataText,
    StyledIconBtn,
} from './EmployeesDataElement.styles';
import { StyledCheckboxDone } from '../../atoms/SettlementsDoneElement/StyledCheckboxDone';

import { ReactComponent as CheckboxAccept } from '../../../images/checkboxAccept.svg';

import { getString } from '../../../strings';
import downloadFile from '../../../utils/downloadFile';

const EmployeesDataElement = ({
    isCheckbox,
    employee_id,
    employee,
    hours,
    payment,
    isSubmitted,
    contract,
    month,
    toggleChecked,
}) => {
    const displayPayment = useMemo(() => {
        return new Decimal(payment).toFixed(2);
    }, [payment]);

    const recordLink = useMemo(
        () => `/api/employees/admin/get-hours-record/${employee_id}?month=${month}`,
        [employee_id, month]
    );

    const contractLink = useMemo(
        () => (contract ? `/api/employees/admin/get-contract/${contract.id}?month=${month}` : ''),
        [contract, month]
    );

    return (
        <StyledEmployeesDataElement $isCheckbox={isCheckbox}>
            {isCheckbox ? (
                <StyledCheckboxDone>
                    <input
                        type="checkbox"
                        id={`check_${month}-${employee_id}`}
                        onChange={toggleChecked}
                    />
                    <label htmlFor={`check_${month}-${employee_id}`}>
                        <CheckboxAccept />
                    </label>
                </StyledCheckboxDone>
            ) : undefined}
            <ScrollSyncPane>
                <div style={{ display: 'flex' }}>
                    <StyledWrapper $isRedBg={!isSubmitted && contract && contract !== 'error'}>
                        <StyledEmployeesDataText $hasDeclaredWidth="25%">
                            <StyledText
                                hasdeclaredfontsize="13px"
                                hasdeclaredfontweight="600"
                                hasdeclaredtextalign="center"
                            >
                                {employee}
                            </StyledText>
                        </StyledEmployeesDataText>
                        <StyledEmployeesDataText $hasDeclaredWidth="15%">
                            <StyledText
                                hasdeclaredfontsize="13px"
                                hasdeclaredfontweight="600"
                                hasdeclaredtextalign="center"
                            >
                                {hours}
                            </StyledText>
                        </StyledEmployeesDataText>
                        <StyledEmployeesDataText $hasDeclaredWidth="15%">
                            <StyledText
                                hasdeclaredfontsize="13px"
                                hasdeclaredfontweight="600"
                                hasdeclaredtextalign="center"
                            >
                                {displayPayment} PLN
                            </StyledText>
                        </StyledEmployeesDataText>
                        <StyledEmployeesDataText $hasDeclaredWidth="20%">
                            {isSubmitted ? (
                                <>
                                    <StyledText
                                        hasdeclaredfontsize="13px"
                                        hasdeclaredfontweight="600"
                                        hasdeclaredtextalign="center"
                                        hasdeclaredmargin="0 4px 0 8px"
                                    >
                                        {getString('employees_data_element_przeslano')}
                                    </StyledText>
                                    <StyledIconBtn
                                        onClick={() =>
                                            window.open(recordLink, '_blank', 'noreferrer,noopener')
                                        }
                                    >
                                        <svg
                                            width="26"
                                            height="17"
                                            viewBox="0 0 26 17"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M13 3.54167C12.5216 3.549 12.0467 3.62354 11.5899 3.76302C11.8012 4.12751 11.9137 4.53905 11.9167 4.95833C11.9167 5.2839 11.8513 5.60628 11.7242 5.90707C11.5972 6.20786 11.411 6.48116 11.1763 6.71137C10.9416 6.94158 10.6629 7.1242 10.3562 7.24878C10.0495 7.37337 9.72083 7.4375 9.38888 7.4375C8.96137 7.43459 8.54177 7.32424 8.17013 7.11698C7.87692 8.11431 7.91109 9.17684 8.26781 10.1541C8.62453 11.1313 9.2857 11.9737 10.1577 12.5619C11.0297 13.1501 12.0682 13.4543 13.1263 13.4314C14.1843 13.4086 15.2082 13.0598 16.053 12.4345C16.8977 11.8092 17.5204 10.9391 17.8329 9.94738C18.1454 8.9557 18.1318 7.89272 17.7941 6.90902C17.4564 5.92533 16.8116 5.07078 15.9512 4.46642C15.0908 3.86206 14.0583 3.53853 13 3.54167ZM25.8429 7.85365C23.3951 3.16935 18.5489 0 13 0C7.45107 0 2.60359 3.17156 0.157059 7.85409C0.0537999 8.05442 0 8.27575 0 8.50022C0 8.72469 0.0537999 8.94602 0.157059 9.14635C2.60495 13.8307 7.45107 17 13 17C18.5489 17 23.3964 13.8284 25.8429 9.14591C25.9462 8.94558 26 8.72425 26 8.49978C26 8.2753 25.9462 8.05398 25.8429 7.85365ZM13 14.875C8.54704 14.875 4.46467 12.4401 2.26008 8.5C4.46467 4.5599 8.54659 2.125 13 2.125C17.4534 2.125 21.5353 4.5599 23.7399 8.5C21.5358 12.4401 17.4534 14.875 13 14.875Z"
                                                fill="#fff"
                                            />
                                        </svg>
                                    </StyledIconBtn>
                                    <StyledIconBtn
                                        onClick={() => downloadFile(recordLink + '&download=true')}
                                    >
                                        <svg
                                            width="32"
                                            height="30"
                                            viewBox="0 0 32 30"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M30.4 21C31.2205 21 31.8968 21.5791 31.9892 22.3251L32 22.5V25.5C32 27.8965 30.0017 29.8555 27.482 29.9924L27.2 30H4.8C2.24371 30 0.154142 28.1266 0.00814819 25.7644L0 25.5V22.5C0 21.6716 0.716344 21 1.6 21C2.42054 21 3.09681 21.5791 3.18924 22.3251L3.2 22.5V25.5C3.2 26.2693 3.81766 26.9033 4.61341 26.9899L4.8 27H27.2C28.0205 27 28.6968 26.4209 28.7892 25.6749L28.8 25.5V22.5C28.8 21.6716 29.5163 21 30.4 21ZM16 0C16.8837 0 17.6 0.671573 17.6 1.5V15.8775L19.6686 13.9393C20.2454 13.3986 21.153 13.357 21.7806 13.8146L21.9314 13.9393C22.5081 14.4801 22.5525 15.3309 22.0645 15.9193L21.9314 16.0607L17.1314 20.5607L17.061 20.6228L16.9525 20.7055L16.7745 20.813L16.5941 20.8932L16.4256 20.9462L16.188 20.9897L16 21L15.8796 20.9958L15.6788 20.9696L15.5006 20.9254L15.3228 20.8595L15.1669 20.781L15.0132 20.6808C14.9625 20.6435 14.9142 20.6034 14.8686 20.5607L10.0686 16.0607C9.44379 15.4749 9.44379 14.5251 10.0686 13.9393C10.6454 13.3986 11.553 13.357 12.1806 13.8146L12.3314 13.9393L14.4 15.8775V1.5C14.4 0.671573 15.1163 0 16 0Z"
                                                fill="white"
                                            />
                                        </svg>
                                    </StyledIconBtn>
                                </>
                            ) : (
                                <StyledText
                                    hasdeclaredfontsize="13px"
                                    hasdeclaredfontweight="600"
                                    hasdeclaredtextalign="center"
                                    hasdeclaredmargin="0 4px 0 8px"
                                >
                                    {contract
                                        ? getString('employees_data_element_nie_przeslano')
                                        : '-'}
                                </StyledText>
                            )}
                        </StyledEmployeesDataText>
                        <StyledEmployeesDataText $hasDeclaredWidth="25%">
                            {contract === 'error' ? (
                                <StyledText
                                    hasdeclaredfontsize="13px"
                                    hasdeclaredfontweight="600"
                                    hasdeclaredtextalign="center"
                                    hasdeclaredmargin="0 4px 0 8px"
                                >
                                    {getString('employees_data_element_blad')}
                                </StyledText>
                            ) : contract ? (
                                <>
                                    <StyledText
                                        hasdeclaredfontsize="13px"
                                        hasdeclaredfontweight="600"
                                        hasdeclaredtextalign="center"
                                        hasdeclaredmargin="0 4px 0 8px"
                                    >
                                        {getString(`contract_${contract.type}`)}
                                    </StyledText>
                                    <StyledIconBtn
                                        onClick={() =>
                                            window.open(
                                                contractLink,
                                                '_blank',
                                                'noreferrer,noopener'
                                            )
                                        }
                                    >
                                        <svg
                                            width="26"
                                            height="17"
                                            viewBox="0 0 26 17"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M13 3.54167C12.5216 3.549 12.0467 3.62354 11.5899 3.76302C11.8012 4.12751 11.9137 4.53905 11.9167 4.95833C11.9167 5.2839 11.8513 5.60628 11.7242 5.90707C11.5972 6.20786 11.411 6.48116 11.1763 6.71137C10.9416 6.94158 10.6629 7.1242 10.3562 7.24878C10.0495 7.37337 9.72083 7.4375 9.38888 7.4375C8.96137 7.43459 8.54177 7.32424 8.17013 7.11698C7.87692 8.11431 7.91109 9.17684 8.26781 10.1541C8.62453 11.1313 9.2857 11.9737 10.1577 12.5619C11.0297 13.1501 12.0682 13.4543 13.1263 13.4314C14.1843 13.4086 15.2082 13.0598 16.053 12.4345C16.8977 11.8092 17.5204 10.9391 17.8329 9.94738C18.1454 8.9557 18.1318 7.89272 17.7941 6.90902C17.4564 5.92533 16.8116 5.07078 15.9512 4.46642C15.0908 3.86206 14.0583 3.53853 13 3.54167ZM25.8429 7.85365C23.3951 3.16935 18.5489 0 13 0C7.45107 0 2.60359 3.17156 0.157059 7.85409C0.0537999 8.05442 0 8.27575 0 8.50022C0 8.72469 0.0537999 8.94602 0.157059 9.14635C2.60495 13.8307 7.45107 17 13 17C18.5489 17 23.3964 13.8284 25.8429 9.14591C25.9462 8.94558 26 8.72425 26 8.49978C26 8.2753 25.9462 8.05398 25.8429 7.85365ZM13 14.875C8.54704 14.875 4.46467 12.4401 2.26008 8.5C4.46467 4.5599 8.54659 2.125 13 2.125C17.4534 2.125 21.5353 4.5599 23.7399 8.5C21.5358 12.4401 17.4534 14.875 13 14.875Z"
                                                fill="#fff"
                                            />
                                        </svg>
                                    </StyledIconBtn>
                                    <StyledIconBtn
                                        onClick={() =>
                                            downloadFile(contractLink + '&download=true')
                                        }
                                    >
                                        <svg
                                            width="32"
                                            height="30"
                                            viewBox="0 0 32 30"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M30.4 21C31.2205 21 31.8968 21.5791 31.9892 22.3251L32 22.5V25.5C32 27.8965 30.0017 29.8555 27.482 29.9924L27.2 30H4.8C2.24371 30 0.154142 28.1266 0.00814819 25.7644L0 25.5V22.5C0 21.6716 0.716344 21 1.6 21C2.42054 21 3.09681 21.5791 3.18924 22.3251L3.2 22.5V25.5C3.2 26.2693 3.81766 26.9033 4.61341 26.9899L4.8 27H27.2C28.0205 27 28.6968 26.4209 28.7892 25.6749L28.8 25.5V22.5C28.8 21.6716 29.5163 21 30.4 21ZM16 0C16.8837 0 17.6 0.671573 17.6 1.5V15.8775L19.6686 13.9393C20.2454 13.3986 21.153 13.357 21.7806 13.8146L21.9314 13.9393C22.5081 14.4801 22.5525 15.3309 22.0645 15.9193L21.9314 16.0607L17.1314 20.5607L17.061 20.6228L16.9525 20.7055L16.7745 20.813L16.5941 20.8932L16.4256 20.9462L16.188 20.9897L16 21L15.8796 20.9958L15.6788 20.9696L15.5006 20.9254L15.3228 20.8595L15.1669 20.781L15.0132 20.6808C14.9625 20.6435 14.9142 20.6034 14.8686 20.5607L10.0686 16.0607C9.44379 15.4749 9.44379 14.5251 10.0686 13.9393C10.6454 13.3986 11.553 13.357 12.1806 13.8146L12.3314 13.9393L14.4 15.8775V1.5C14.4 0.671573 15.1163 0 16 0Z"
                                                fill="white"
                                            />
                                        </svg>
                                    </StyledIconBtn>
                                </>
                            ) : (
                                <StyledText
                                    hasdeclaredfontsize="13px"
                                    hasdeclaredfontweight="600"
                                    hasdeclaredtextalign="center"
                                    hasdeclaredmargin="0 4px 0 8px"
                                >
                                    {getString('employees_data_element_brak')}
                                </StyledText>
                            )}
                        </StyledEmployeesDataText>
                    </StyledWrapper>
                </div>
            </ScrollSyncPane>
        </StyledEmployeesDataElement>
    );
};

export default EmployeesDataElement;
