import styled from 'styled-components';
import { motion } from 'framer-motion';
import { scrollBlueStyles } from '../../../styles/sharedStyles';

export const StyledTrainingsPartners = styled(motion.div)`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justif-content: flex-start;

    max-height: 90%;
    overflow-y: scroll;

    ${scrollBlueStyles};

    @media only screen and (max-width: 767px) {
        padding-bottom: 40px;
        max-height: unset;
        height: auto;
        overflow-y: visible;
    }

    @media only screen and (max-width: 391px) {
        > h1 {
            font-size: 30px;
        }
    }

    @media only screen and (max-width: 320px) {
        > h1 {
            font-size: 26px;
        }
    }
`;
