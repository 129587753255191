import React, { useState, useCallback } from 'react';
import { getString } from '../../strings';
import LoginLink from '../organisms/LoginLink/LoginLink';
import LogoLink from '../organisms/Logo/LogoLink';
import RegisterCube from '../molecules/RegisterCube/RegisterCube';
import LeftWrapper from '../organisms/Contact/LeftWrapper';
import ContactForm from '../organisms/Contact/ContactForm';
import MobileContactCube from '../molecules/Contact/MobileContactCube';
import FAQCategoryElement from '../organisms/FAQ/FAQCategoryElement';
import HelmetTemplate from '../molecules/HelmetTemplate/HelmetTemplate';

import {
    StyledContactPage,
    StyledRightFAQWrapper,
    StyledRightVector,
    StyledRightWrapper,
} from '../atoms/Contact/StyledContactForm';
import { StyledText } from '../atoms/Text/StyledText';
import { StyledOverflowWrapper } from '../atoms/FAQ/StyledFAQElement';

import useWindowSize from '../../utils/getWindowSize';
import { StyledButton } from '../atoms/Button/StyledButton';

import { faqQuestions } from '../../components/constants/FAQ/FAQ1';
import { useNavigate } from 'react-router-dom';
import { useBeforeUnload } from 'react-use';
import useScrollBar from '../../logic/hooks/useScrollBar';

const Contact = ({ isFAQPage }) => {
    const width = useWindowSize();
    const [isMessageSent, setIsMessageSent] = useState(false);
    const navigate = useNavigate();
    const setIsFAQPage = (faq) => navigate(faq ? '/contact/faq' : '/contact', { replace: true });
    useBeforeUnload(useCallback(() => navigate('/contact', { replace: true }), [navigate]));

    const { isScrollBar, scrollElement, trigger } = useScrollBar(true);

    return (
        <>
            <HelmetTemplate
                title={getString('seo_contact_title')}
                desc={getString('seo_contact_desc')}
                ogTitle={getString('seo_contact_twitter_title')}
            />
            <StyledContactPage
                hasdeclaredpadding={isMessageSent}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
            >
                <StyledRightVector />
                <LoginLink />
                <LogoLink />
                <RegisterCube isContactPage={true} />
                {width < 1075 ? <MobileContactCube /> : null}
                {width < 1075 && isFAQPage ? null : (
                    <LeftWrapper isFAQPage={isFAQPage} setIsFAQPage={setIsFAQPage} />
                )}
                {!isFAQPage ? (
                    <StyledRightWrapper
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.5 }}
                    >
                        {isMessageSent ? (
                            <StyledText
                                hasdeclaredfontsize="44px"
                                hasdeclaredtextalign="center"
                                hasdeclaredlineheight="1.2em"
                                hasdeclaredfontweight="600"
                                hasdeclaredfontcolor="#6AB95D"
                                hasdeclaredpadding="0 20px"
                                as="h2"
                            >
                                {getString('pages_contact_wiadomoscWyslana')}
                            </StyledText>
                        ) : (
                            <ContactForm onMessageSent={() => setIsMessageSent(true)} />
                        )}
                    </StyledRightWrapper>
                ) : (
                    <StyledRightFAQWrapper
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.5 }}
                    >
                        {width < 1075 ? (
                            <StyledButton
                                hasdeclaredbgcolor="#ee745c"
                                hasdeclaredwidth="70%"
                                hasdeclaredfontcolor="#fff"
                                hasdeclaredtextalign="center"
                                hasdeclaredborderradius="25px"
                                hasdeclaredheight="50px"
                                hasdeclaredfontsize="32px"
                                hasdeclaredfontweight="700"
                                hasdeclaredmargin="70px 0 0 0"
                                hasdeclaredcursor="pointer"
                                hasdeclaredtexttransform="uppercase"
                                onClick={() => setIsFAQPage(!isFAQPage)}
                                className="hover-red-effect"
                            >
                                {getString('contact_contactForm_napisz')}
                            </StyledButton>
                        ) : null}
                        <StyledOverflowWrapper isscrollbar={isScrollBar} ref={scrollElement}>
                            {faqQuestions.map((faq) => (
                                <FAQCategoryElement
                                    categoryName={faq.categoryName}
                                    triggerParentScroll={trigger}
                                    elements={faq.questions}
                                />
                            ))}
                        </StyledOverflowWrapper>
                    </StyledRightFAQWrapper>
                )}
            </StyledContactPage>
        </>
    );
};

export default Contact;
