import styled from 'styled-components';
import { ReactComponent as Cube } from '../../../images/cubes/444.svg';

export const StyledCube444 = styled(({ iscontactpage, ...props }) => <Cube {...props} />)`
    position: absolute;
    top: ${({ top1 }) => (top1 ? top1 : '82px')};
    left: ${({ left1 }) => (left1 ? left1 : '180px')};
    transform: rotate(-24deg);
    fill: rgba(0, 0, 0, 0.1);

    @media only screen and (max-width: 1266px) {
        display: none;
    }
`;
