import { useCallback, useEffect, useMemo, useState } from "react";
import { useQueries, useQueryClient } from "@tanstack/react-query";

import { getCurrentMonth } from "../../utils/time";

const useMonthlyPaginate = (
    keys,
    loadStuff,
    startMonth = getCurrentMonth(),
    initialMonths = [startMonth]
) => {
    const queryClient = useQueryClient();

    const [month, setMonth] = useState(startMonth);

    const [allMonths, setAllMonths] = useState(initialMonths);

    const queriesArray = useQueries({
        queries: allMonths.map((month) => ({
            queryKey: [...keys, month],
            queryFn: () => loadStuff(month),
        })),
    });

    const queries = useMemo(
        () =>
            Object.fromEntries(
                allMonths
                    .map((month, i) => [month, queriesArray[i]])
                    .filter(([, { data }]) => !data?.hasNoMore)
            ),
        [allMonths, queriesArray]
    );

    const queryData = useMemo(
        () =>
            Object.fromEntries(
                Object.entries(queries)
                    .map(([month, { data }]) => [month, data])
                    .filter(([, data]) => data)
            ),
        [queries]
    );

    const hasNoMore = useMemo(
        () => queriesArray.map(({ data }) => data?.hasNoMore).filter((value) => value).length > 0,
        [queriesArray]
    );

    const loadMore = useCallback(() => {
        if (hasNoMore) return;

        const date = new Date(month);
        date.setMonth(date.getMonth() - 1);
        const newMonth = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}`;

        return setMonth(newMonth);
    }, [hasNoMore, month]);

    const reloadMonth = useCallback(
        (month) =>
            queryClient.invalidateQueries({
                queryKey: [...keys, month],
            }),
        [queryClient, keys]
    );

    const isError = useMemo(() => queriesArray.some(({ isError }) => isError), [queriesArray]);

    const isLoading = useMemo(
        () => queriesArray.some(({ isLoading }) => isLoading),
        [queriesArray]
    );

    const isSuccess = useMemo(
        () => queriesArray.some(({ isSuccess }) => isSuccess),
        [queriesArray]
    );

    const status = useMemo(
        () =>
            ["error", "loading", "success"].find((status) =>
                queriesArray.some(({ status: queryStatus }) => queryStatus === status)
            ),
        [queriesArray]
    );

    useEffect(
        () => setAllMonths((months) => (months.includes(month) ? months : [...months, month])),
        [month, setAllMonths]
    );

    return {
        queries,
        queryData,
        isError,
        isLoading,
        isSuccess,
        status,
        loadMore,
        hasMore: !hasNoMore,
        reloadMonth,
    };
};

export default useMonthlyPaginate;
