import styled from 'styled-components';

export const StyledCheckbox = styled.div`
    position: relative;
    margin-left: ${({ $withoutMarginLeft }) => ($withoutMarginLeft ? '0' : '12px')};

    input {
        display: none;
    }

    label {
        p {
            display: block;
            cursor: pointer;

            > span {
                width: 40px;
                height: 40px;
                border: 3px solid ${({ error }) => (error ? '#F74017' : '#3474E8')};
                border-radius: 4px;
                transform: translateY(8px);
                display: flex;
                background-color: #fff;
                transition: background-color 250ms linear;
                cursor: pointer;

                svg {
                    width: 40px;
                    height: 40px;
                    display: none;
                }
            }
        }
    }

    input:checked + label {
        p {
            > span {
                background-color: #3474e8;
                transition: background-color 250ms linear;

                svg {
                    transform: translate(-2px, -8px);
                    display: block;
                }
            }
        }
    }

    @media only screen and (max-width: 1072px) {
        label {
            p {
                font-size: 14px;
            }
        }
    }

    @media only screen and (max-width: 926px) {
        label {
            p {
                font-size: 20px;
            }
        }
    }

    @media only screen and (max-width: 600px) {
        label {
            p {
                > span {
                    margin: 0 auto;
                }
            }
        }
    }
`;
