import styled from 'styled-components';
import { scrollBlueStyles } from '../../../styles/sharedStyles';

export const StyledRegistrationContentWrapper = styled.div`
    width: 100%;
    height: 75%;
    margin-top: 200px;
    overflow-y: scroll;
    padding: 22px 10px;

    ${scrollBlueStyles};

    @media only screen and (max-width: 767px) {
        height: 100%;
        margin-top: 20px;
        overflow-y: visible;
        padding: 22px 0 40px 0;
    }
`;
