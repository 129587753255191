import React, { useState, useMemo } from 'react';

import UserPanelAddRoleComponent from '../UserPanelAddRoleComponent/UserPanelAddRoleComponent';
import Loader from '../Loader/Loader';
import UserPanelHeadingText from '../../atoms/UserPanel/UserPanelHeadingText';

import { StyledText } from '../../atoms/Text/StyledText';
import { StyledUserPanelAddRole } from './UserPanelAddRole.styles';
import { StyledSumElement } from '../../atoms/UserPanelTrainerPay/StyledSumElement';
import { StyledSumRow } from '../../atoms/UserPanelTrainerPay/StyledSumRow';
import {
    StyledLoaderWrapper,
    StyledGlobalHeadingWrapper,
    StyledGlobalScrollWrapper,
} from '../../../styles/sharedStyles';

import { getString } from '../../../strings';
import { listRoles, listUsers } from '../../../logic/requests/students';
import { cebulaCompare } from '../../../logic/arrays';

import useScrollBar from '../../../logic/hooks/useScrollBar';
import { useQuery } from '@tanstack/react-query';

const UserPanelAddRole = () => {
    const { isScrollBar, scrollElement, trigger } = useScrollBar();

    const [err, setErr] = useState(false);

    const { data: users } = useQuery(['users', 'all'], () => {
        return listUsers().then((res) => {
            return res.map((user) => ({
                ...user,
                name: `${user.lastname} ${user.firstname}`,
            })).sort((a, b) => cebulaCompare(a.name, b.name));
        });
    });

    const { data: roles } = useQuery(['roles', 'all'], listRoles, {
        onError: () => setErr(true),
    });

    const sums = useMemo(() => {
        const initial = {
            students: 0,
            parents: 0,
            trainers: 0,
        };

        return users
            ? users.reduce((prev, cur) => {
                  for (let role of ['student', 'parent', 'trainer']) {
                      if (cur.roles.includes(role)) prev[`${role}s`]++;
                  }
                  return prev;
              }, initial)
            : initial;
    }, [users]);

    return (
        <StyledUserPanelAddRole>
            {err ? (
                <StyledLoaderWrapper>
                    <StyledText
                        hasdeclaredpadding="20px 20px 30px 0"
                        hasdeclaredfontsize="36px"
                        hasdeclaredfontweight="700"
                        hasdeclaredlineheight="1.4em"
                        as="h2"
                    >
                        {getString('blad_wczytywania')}:
                    </StyledText>
                </StyledLoaderWrapper>
            ) : !(users && roles) ? (
                <StyledLoaderWrapper>
                    <Loader />
                </StyledLoaderWrapper>
            ) : (
                <>
                    <StyledSumElement>
                        <StyledSumRow iscentertext>
                            <StyledText
                                hasdeclaredfontsize="30px"
                                hasdeclaredfontweight="600"
                                hasdeclaredlineheight="1.4em"
                                hasdeclaredfontcolor="#6786BE"
                                hasdeclaredpadding="0 0 20px 0"
                                hasdeclaredtexttransform="uppercase"
                            >
                                {getString('UserPanelAddRole__summary__students')}:
                            </StyledText>
                            <StyledText
                                hasdeclaredfontsize="30px"
                                hasdeclaredfontweight="600"
                                hasdeclaredlineheight="1.4em"
                                hasdeclaredpadding="0 0 20px 0"
                                hasdeclaredtextalign="center"
                            >
                                {sums.students}
                            </StyledText>
                        </StyledSumRow>
                        <StyledSumRow iscentertext>
                            <StyledText
                                hasdeclaredfontsize="18px"
                                hasdeclaredfontweight="600"
                                hasdeclaredlineheight="1.4em"
                                hasdeclaredfontcolor="#6786BE"
                            >
                                {getString('UserPanelAddRole__summary__parents')}:
                            </StyledText>
                            <StyledText
                                hasdeclaredfontsize="18px"
                                hasdeclaredfontweight="600"
                                hasdeclaredlineheight="1.4em"
                                hasdeclaredtextalign="center"
                            >
                                {sums.parents}
                            </StyledText>
                        </StyledSumRow>
                        <StyledSumRow iscentertext>
                            <StyledText
                                hasdeclaredfontsize="18px"
                                hasdeclaredfontweight="600"
                                hasdeclaredlineheight="1.4em"
                                hasdeclaredfontcolor="#6786BE"
                            >
                                {getString('UserPanelAddRole__summary__trainers')}:
                            </StyledText>
                            <StyledText
                                hasdeclaredfontsize="18px"
                                hasdeclaredfontweight="600"
                                hasdeclaredlineheight="1.4em"
                                hasdeclaredtextalign="center"
                            >
                                {sums.trainers}
                            </StyledText>
                        </StyledSumRow>
                    </StyledSumElement>
                    <StyledGlobalHeadingWrapper>
                        <UserPanelHeadingText
                            text={`${getString('UserPanelTrainerAddRoleManageRoles')}:`}
                        />
                    </StyledGlobalHeadingWrapper>
                    <StyledGlobalScrollWrapper isscrollbar={isScrollBar} ref={scrollElement}>
                        <UserPanelAddRoleComponent
                            users={users}
                            roles={roles}
                            trigger={trigger}
                        />
                    </StyledGlobalScrollWrapper>
                </>
            )}
        </StyledUserPanelAddRole>
    );
};

export default UserPanelAddRole;
