import styled from 'styled-components';

export const StyledLoginLink = styled.div`
    width: 200px;
    height: 100px;
    background-color: #000;
    top: 0;
    right: 0;
    position: fixed;
    border-bottom-left-radius: 80%;
    padding-right: 14px;

    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-end;

    border-left: 6px solid #fff;
    border-bottom: 6px solid #fff;
    z-index: 5;

    > a {
        text-decoration: none;
        color: #000;

        > p {
            position: relative;
            cursor: pointer;
            margin-top: 18px;

            &::before {
                content: '';
                width: 100%;
                height: 3px;
                background-color: #6786be;
                position: absolute;
                bottom: -8px;
                left: 0;
                border-radius: 45px;
                transition: background-color 250ms ease-in;
            }

            &:hover {
                &::before {
                    background-color: #ee745c;
                }
            }
        }
    }

    @media only screen and (max-width: 485px) {
        width: 160px;
        height: 75px;
        > a {
            > p {
                transform: translateY(-9px);
                font-size: 16px;
            }
        }
    }
`;
