import styled from 'styled-components';
import { scrollRedStyles } from '../../../styles/sharedStyles';

export const StyledScrollContainer = styled.div`
    width: 340px;
    max-height: 100%;
    overflow-y: scroll;

    ${scrollRedStyles};

    @media only screen and (max-width: 1196px) {
        width: 360px;
        padding-left: 20px;
    }

    @media only screen and (max-width: 767px) {
        overflow-y: visible;
        max-height: unset;
        width: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-left: 0;
    }
`;
