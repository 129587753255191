const readFileAsync = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => {
            resolve(reader.result);
        };

        reader.onerror = reject;

        reader.readAsArrayBuffer(file);
    });

const arrayBufferToBase64 = (buffer) => {
    const bytes = new Uint8Array(buffer);

    let binary = "",
        i = 0;

    while (i < bytes.byteLength) {
        binary += String.fromCharCode(bytes[i]);
        i++;
    }

    return window.btoa(binary);
};

export { readFileAsync, arrayBufferToBase64 };
