import React, { useMemo } from 'react';
import parse from 'html-react-parser';
import { getString } from '../../../strings';
import TournamentsCompetitions from '../../molecules/Tournaments/TournamentsCompetitions';

import { StyledText } from '../../atoms/Text/StyledText';
import { StyledInformationWrapper } from '../../molecules/Tournament/StyledInformationWrapper';
import { StyledInformationContent } from '../../atoms/Tournament/StyledInformationContent';
import { StyledInformationContentWrapper } from '../../atoms/Tournament/StyledInformationContentWrapper';

import { getDateTimeString, getUTCDateString } from '../../../logic/eventtime.js';
import useScrollBar from '../../../logic/hooks/useScrollBar';

const TournamentInformationContent = ({ event }) => {
    const { scrollElement, isScrollBar } = useScrollBar();

    const time_start = useMemo(() => getUTCDateString(new Date(event.time_start)), [event]),
        time_end = useMemo(() => getUTCDateString(new Date(event.time_end)), [event]),
        register_time_start = useMemo(
            () =>
                event.register_time_start
                    ? getDateTimeString(new Date(event.register_time_start))
                    : null,
            [event]
        ),
        register_time_end = useMemo(
            () =>
                event.register_time_end
                    ? getDateTimeString(new Date(event.register_time_end))
                    : null,
            [event]
        ),
        pending_deadline = useMemo(() =>
            event.pending_deadline ? getDateTimeString(new Date(event.pending_deadline)) : null
        ),
        competition_deadline = useMemo(() =>
            event.competition_deadline
                ? getDateTimeString(new Date(event.competition_deadline))
                : null
        ),
        payment_return_deadline = useMemo(() =>
            event.pending_deadline
                ? getDateTimeString(new Date(event.payment_return_deadline))
                : null
        );

    return (
        <StyledInformationContent>
            <StyledInformationContentWrapper isscrollbar={isScrollBar} ref={scrollElement}>
                <StyledInformationWrapper>
                    <StyledText
                        hasdeclaredfontsize="18px"
                        hasdeclaredfontweight="600"
                        hasdeclaredfontcolor="#6786BE"
                        hasdeclaredtexttransform="uppercase"
                        hasdeclaredpadding="0 0 5px 0"
                        hasdeclaredlineheight="1.4em"
                        as="p"
                    >
                        {getString('tournament_tournamentInformationContent_data')}
                    </StyledText>
                    <StyledText
                        hasdeclaredfontsize="18px"
                        hasdeclaredfontweight="600"
                        hasdeclaredlineheight="1.4em"
                    >
                        {time_start === time_end ? time_start : `${time_start} - ${time_end}`}
                    </StyledText>
                </StyledInformationWrapper>
                {event?.type?.id === 'on-site' ? (
                    <StyledInformationWrapper>
                        <StyledText
                            hasdeclaredfontsize="18px"
                            hasdeclaredfontweight="600"
                            hasdeclaredfontcolor="#6786BE"
                            hasdeclaredtexttransform="uppercase"
                            hasdeclaredpadding="0 0 5px 0"
                            hasdeclaredlineheight="1.4em"
                            as="p"
                        >
                            {getString('tournament_tournamentInformationContent_miejsce')}
                        </StyledText>
                        <StyledText
                            hasdeclaredfontsize="18px"
                            hasdeclaredfontweight="600"
                            hasdeclaredlineheight="1.4em"
                        >
                            {event.location_name}
                        </StyledText>
                        <StyledText hasdeclaredfontsize="18px" hasdeclaredlineheight="1.4em">
                            {event.location_address}
                        </StyledText>
                    </StyledInformationWrapper>
                ) : (
                    <></>
                )}
                <StyledInformationWrapper>
                    <StyledText
                        hasdeclaredfontsize="18px"
                        hasdeclaredfontweight="600"
                        hasdeclaredfontcolor="#6786BE"
                        hasdeclaredtexttransform="uppercase"
                        hasdeclaredpadding="0 0 5px 0"
                        hasdeclaredlineheight="1.4em"
                        as="p"
                    >
                        {getString('tournament_tournamentInformationContent_konkurencje')}
                    </StyledText>
                    <TournamentsCompetitions
                        competitions={event.competitions}
                        hasdeclaredmargin="0 5px 0 0"
                    />
                </StyledInformationWrapper>
                <StyledInformationWrapper>
                    <StyledText
                        hasdeclaredfontsize="18px"
                        hasdeclaredfontweight="600"
                        hasdeclaredfontcolor="#6786BE"
                        hasdeclaredtexttransform="uppercase"
                        hasdeclaredpadding="0 0 5px 0"
                        hasdeclaredlineheight="1.4em"
                        as="p"
                    >
                        {getString('tournament_tournamentInformationContent_organizatorzy')}
                    </StyledText>
                    <StyledText
                        hasdeclaredfontsize="18px"
                        hasdeclaredfontweight="600"
                        hasdeclaredlineheight="1.4em"
                        as="p"
                    >
                        {parse(event.organizers)}
                    </StyledText>
                </StyledInformationWrapper>
                {event?.wca_delegates?.length ? (
                    <StyledInformationWrapper>
                        <StyledText
                            hasdeclaredfontsize="18px"
                            hasdeclaredfontweight="600"
                            hasdeclaredfontcolor="#6786BE"
                            hasdeclaredtexttransform="uppercase"
                            hasdeclaredpadding="0 0 5px 0"
                            hasdeclaredlineheight="1.4em"
                            as="p"
                        >
                            {getString('tournament_tournamentInformationContent_delegaciWca')}
                        </StyledText>
                        <StyledText
                            hasdeclaredfontsize="18px"
                            hasdeclaredfontweight="600"
                            hasdeclaredlineheight="1.4em"
                            as="p"
                        >
                            {event.wca_delegates.map((delegate, i) => (
                                <>
                                    {delegate.email ? (
                                        <a
                                            aria-label={getString('seo_aria_label_wyslij_email')}
                                            href={`mailto:${delegate.email}`}
                                        >
                                            {delegate.firstname} {delegate.lastname}
                                        </a>
                                    ) : (
                                        `${delegate.firstname} ${delegate.lastname}`
                                    )}
                                    {i < event.wca_delegates.length - 1 ? ', ' : ''}
                                </>
                            ))}
                        </StyledText>
                    </StyledInformationWrapper>
                ) : (
                    <></>
                )}
                <StyledInformationWrapper>
                    <StyledText
                        hasdeclaredfontsize="18px"
                        hasdeclaredfontweight="600"
                        hasdeclaredfontcolor="#6786BE"
                        hasdeclaredtexttransform="uppercase"
                        hasdeclaredpadding="0 0 5px 0"
                        hasdeclaredlineheight="1.4em"
                        as="p"
                    >
                        {getString('tournament_tournamentInformationContent_limitZawodnikow')}
                    </StyledText>
                    <StyledText
                        hasdeclaredfontsize="18px"
                        hasdeclaredfontweight="600"
                        hasdeclaredfontcolor="#ee745c"
                        hasdeclaredlineheight="1.4em"
                    >
                        {event?.max_places
                            ? event?.register_status === 'upcoming'
                                ? event.max_places
                                : `${event?.taken_places ?? 0}/${event.max_places}`
                            : getString('tournament_tournamentInformationContent_brakDanych')}
                    </StyledText>
                </StyledInformationWrapper>
                <StyledInformationWrapper>
                    <StyledText
                        hasdeclaredfontsize="18px"
                        hasdeclaredfontweight="600"
                        hasdeclaredfontcolor="#6786BE"
                        hasdeclaredtexttransform="uppercase"
                        hasdeclaredpadding="0 0 5px 0"
                        hasdeclaredlineheight="1.4em"
                        as="p"
                    >
                        {getString('tournament_tournamentInformationContent_wpisowe')}
                    </StyledText>
                    <StyledText
                        hasdeclaredfontsize="18px"
                        hasdeclaredfontweight="600"
                        hasdeclaredlineheight="1.4em"
                    >
                        {event.entry_fee} {getString('tournament_tournamentInformationContent_pln')}
                    </StyledText>
                    <StyledText hasdeclaredfontsize="18px" hasdeclaredlineheight="1.4em">
                        <StyledText
                            hasdeclaredfontsize="18px"
                            hasdeclaredlineheight="1.4em"
                            hasdeclareddisplay="inline"
                            as="span"
                        >
                            {getString(
                                'tournament_tournamentInformationContent_ostatecznyTerminZawarcia'
                            )}{' '}
                        </StyledText>
                        <StyledText
                            hasdeclaredfontsize="18px"
                            hasdeclaredlineheight="1.4em"
                            hasdeclaredfontweight="600"
                            hasdeclareddisplay="inline"
                            as="span"
                        >
                            {payment_return_deadline}
                        </StyledText>
                    </StyledText>
                    <StyledText
                        hasdeclaredfontsize="16px"
                        hasdeclaredfontweight="600"
                        hasdeclaredlineheight="1.4em"
                    >
                        {parse(event.entry_fee_text_1 || '')}
                    </StyledText>
                    <StyledText hasdeclaredfontsize="14px" hasdeclaredlineheight="1.4em">
                        {parse(event.entry_fee_text_2 || '')}
                    </StyledText>
                </StyledInformationWrapper>
                <StyledInformationWrapper>
                    <StyledText
                        hasdeclaredfontsize="18px"
                        hasdeclaredfontweight="600"
                        hasdeclaredfontcolor="#6786BE"
                        hasdeclaredtexttransform="uppercase"
                        hasdeclaredpadding="0 0 5px 0"
                        hasdeclaredlineheight="1.4em"
                        as="p"
                    >
                        {getString('tournament_tournamentInformationContent_rejestracja')}
                    </StyledText>
                    <StyledText
                        hasdeclaredfontsize="18px"
                        hasdeclaredfontweight="600"
                        hasdeclaredlineheight="1.4em"
                    >
                        {register_time_start} - {register_time_end}
                    </StyledText>
                    <StyledText hasdeclaredfontsize="18px" hasdeclaredlineheight="1.4em">
                        <StyledText
                            hasdeclaredfontsize="18px"
                            hasdeclaredlineheight="1.4em"
                            hasdeclareddisplay="inline"
                            as="span"
                        >
                            {getString(
                                'tournament_tournamentInformationContent_ostatecznyTerminAkceptowania'
                            )}{' '}
                        </StyledText>
                        <StyledText
                            hasdeclaredfontsize="18px"
                            hasdeclaredlineheight="1.4em"
                            hasdeclaredfontweight="600"
                            hasdeclareddisplay="inline"
                            as="span"
                        >
                            {pending_deadline}
                        </StyledText>
                    </StyledText>
                    <StyledText hasdeclaredfontsize="18px" hasdeclaredlineheight="1.4em">
                        <StyledText
                            hasdeclaredfontsize="18px"
                            hasdeclaredlineheight="1.4em"
                            hasdeclareddisplay="inline"
                            as="span"
                        >
                            {getString(
                                'tournament_tournamentInformationContent_ostatecznyTerminZmianyKonkurencji'
                            )}{' '}
                        </StyledText>
                        <StyledText
                            hasdeclaredfontsize="18px"
                            hasdeclaredlineheight="1.4em"
                            hasdeclaredfontweight="600"
                            hasdeclareddisplay="inline"
                            as="span"
                        >
                            {competition_deadline}
                        </StyledText>
                    </StyledText>
                    <StyledText hasdeclaredfontsize="14px" hasdeclaredlineheight="1.4em">
                        {parse(event.register_text || '')}
                    </StyledText>
                </StyledInformationWrapper>
            </StyledInformationContentWrapper>
        </StyledInformationContent>
    );
};

export default TournamentInformationContent;
