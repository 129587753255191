import styled from 'styled-components';

export const StyledScriptHeadingLeft = styled.div`
    display: flex;
    align-items: center;
    width: 65%;

    > p {
        width: 25%;
        padding: 0 4px;
        display: block;
        text-align: center;
    }

    @media only screen and (min-width: 1920px) {
        > p {
            font-size: 18px;
        }
    }

    @media only screen and (max-width: 1084px) {
        > p {
            font-size: 11px;
        }
    }

    @media only screen and (max-width: 767px) {
        > p {
            font-size: 16px;
        }
    }

    @media only screen and (max-width: 728px) {
        width: 85%;
        margin-left: 15%;
    }

    @media only screen and (max-width: 546px) {
        > p {
            font-size: 14px;
        }
    }

    @media only screen and (max-width: 457px) {
        > p {
            font-size: 12px;
        }
    }

    @media only screen and (max-width: 420px) {
        > p {
            font-size: 14px;
        }
    }
`;
