import styled from 'styled-components';
import { scrollBlueStyles } from '../../../styles/sharedStyles';

export const StyledPaymentsElementHeader = styled.div`
    width: 100%;
    overflow-x: scroll;
    align-self: ${({ hasalignself }) => (hasalignself ? hasalignself : 'flex-end')};

    ${scrollBlueStyles};

    > div {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        align-self: flex-end;
        border: 4px solid transparent;
        min-width: 700px;
    }
`;
