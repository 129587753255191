import React from 'react';

import Cube444 from '../../molecules/LoginCube/Cube444';
import CubeMinx from '../../molecules/LoginCube/CubeMinx';
import CubeSkewb from '../../molecules/LoginCube/CubeSkewb';
import CubeSq1 from '../../molecules/LoginCube/CubeSq1';

import { StyledCubeIcons } from '../../atoms/CubeIcons/StyledCubeIcons';

const CubeIcons = () => {
    return (
        <StyledCubeIcons>
            <Cube444 />
            <CubeSkewb />
            <CubeMinx />
            <CubeSq1 />
        </StyledCubeIcons>
    );
};

export default CubeIcons;
