import styled from 'styled-components';
import { motion } from 'framer-motion';

export const StyledErrorMessageWrapper = styled(motion.div)`
    position: absolute;
    top: 70px;
    left: 0;
    width: 100%;
    padding-left: 14px;

    @media only screen and (max-width: 1126px) {
        p {
            color: #f94016;
        }
    }

    @media only screen and (max-width: 836px) {
        padding-left: 6px;
    }

    @media only screen and (max-width: 767px) {
        padding-left: 14px;
    }

    @media only screen and (max-width: 450px) {
        padding-left: 0;
        top: 58px;

        p {
            text-align: center;
            font-size: 14px;
        }
    }
`;
