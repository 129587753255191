import Decimal from 'decimal.js';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useToggle } from 'react-use';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';

import { StyledButton } from '../../atoms/Button/StyledButton';
import { StyledHeading, StyledArrow } from '../../../styles/sharedStyles';
import { StyledDataSum } from '../../atoms/SettlementsDoneDataElement/StyledDataSum';
import { StyledCheckboxDone } from '../../atoms/SettlementsDoneElement/StyledCheckboxDone';
import { StyledHeadingCheckbox } from '../../atoms/SettlementsDoneElement/StyledHeadingCheckbox';
import { StyledText } from '../../atoms/Text/StyledText';
import { StyledAllPayTrainer } from '../../atoms/UserPanelAllTrainerPay/StyledAllPayTrainer';
import { StyledPayAllElementHeader } from '../../atoms/UserPanelAllTrainerPay/StyledPayAllElementHeader';
import { StyledScrolContentPayAll } from '../../atoms/UserPanelAllTrainerPay/StyledScrolContentPayAll';
import { StyledScrollHeaderPayAll } from '../../atoms/UserPanelAllTrainerPay/StyledScrollHeaderPayAll';
import { StyledButtonsWrapper } from '../../atoms/UserPanelParent/StyledButtonsWrapper';
import { StyledFlexBoxWrapper } from './OpenElement.styles';
import { StyledPayAllElement } from '../../atoms/UserPanelTrainerPay/StyledPayAllElement';
import { StyledPayColoredWrapper } from '../../atoms/UserPanelTrainerPay/StyledPayColoredWrapper';

import { ReactComponent as CheckboxAccept } from '../../../images/checkboxAccept.svg';
import { getLang, getString } from '../../../strings';
import { taxPercent } from '../../constants/pay';
import { cebulaCompare } from '../../../logic/arrays';
import useScrollBar from '../../../logic/hooks/useScrollBar';
import AllTrainerPayPopup from '../../molecules/AllTrainerPayPopup/AllTrainerPayPopup';

const OpenElement = ({
    defaultOpen,
    triggerParentScroll,
    keyElement,
    val,
    firstElement,
    trigger2,
}) => {
    const [showCheckboxes, toggleShowCheckboxes] = useToggle(false);

    const [isOpen, toggleIsOpen] = useToggle(defaultOpen ?? false);
    const [isOpenPayPopup, toggleIsOpenPayPopup] = useToggle(false);
    const [checkedTrainers, setCheckedTrainers] = useState([]);

    const { isScrollBar, scrollElement, trigger } = useScrollBar(true, true);
    const isAtLeastOneUnpaid = useMemo(
        () =>
            val &&
            Object.values(val).some(
                (t) => (!t.salary?.paid || t.salary?.paid !== 1) && new Decimal(t.pay).gt(0)
            ),
        [val]
    );

    useEffect(() => trigger2?.(), [trigger2, isOpen]);
    useEffect(() => trigger?.(), [trigger, isOpen]);
    useEffect(() => triggerParentScroll?.(), [triggerParentScroll, isOpen]);

    const revenue = useMemo(
        () =>
            val && Object.keys(val).length
                ? Object.values(val)
                      .map((obj) => obj.price)
                      .reduce((prev, cur) => prev.plus(cur), new Decimal(0))
                : undefined,
        [val]
    );

    const trainerPayWithoutAdmin = useMemo(
        () =>
            val && Object.keys(val).length
                ? Object.values(val).reduce(
                      (prev, cur) => prev.plus(cur.is_admin ? 0 : cur.pay),
                      new Decimal(0)
                  )
                : undefined,
        [val]
    );

    const income = useMemo(
        () =>
            revenue && trainerPayWithoutAdmin ? revenue.minus(trainerPayWithoutAdmin) : undefined,
        [revenue, trainerPayWithoutAdmin]
    );

    const hoursAmount = useMemo(
        () =>
            val && Object.keys(val).length
                ? Object.values(val)
                      .map((obj) => obj.hours)
                      .reduce((prev, cur) => prev.plus(cur), new Decimal(0))
                : undefined,
        [val]
    );

    const free = useMemo(
        () =>
            val && Object.keys(val).length
                ? Object.values(val)
                      .map((obj) => obj.free)
                      .reduce((prev, cur) => prev.plus(cur), new Decimal(0))
                : undefined,
        [val]
    );

    const paidHours = useMemo(
        () =>
            val && Object.keys(val).length
                ? Object.values(val)
                      .map((obj) => obj.paidHours)
                      .reduce((prev, cur) => prev.plus(cur), new Decimal(0))
                : undefined,
        [val]
    );

    const halfHours = useMemo(
        () =>
            val && Object.keys(val).length
                ? Object.values(val)
                        .map((obj) => obj.halfHours)
                        .reduce((prev, cur) => prev.plus(cur), new Decimal(0))
                : undefined,
        [val]
    );

    const free_hours_price = useMemo(
        () =>
            val && Object.keys(val).length
                ? Object.values(val)
                      .map((obj) => obj.free_hours_price)
                      .reduce((prev, cur) => prev.plus(cur), new Decimal(0))
                : undefined,
        [val]
    );

    const incomeAfterTaxes = useMemo(
        () =>
            income
                ? income.times(new Decimal(1).minus(new Decimal(taxPercent).dividedBy(100)))
                : undefined,
        [income]
    );

    const getRowBorder = useCallback((trainer, month) => {
        if (trainer.salary) {
            switch (trainer.salary.paid) {
                case 1:
                    return '#5FEE5C'; //green - paid
                case 2:
                    return '#F9EC4B'; //yellow - partially paid
                default:
                    return '#EE745C'; //red - not paid
            }
        } else {
            //JS months starts from 0
            if (new Date().getMonth() + 1 > month) {
                if (!trainer.salary) return '#EE745C';
            } else {
                if (new Decimal(trainer.pay).eq(0)) return '#5FEE5C'; //green - paid
                return '#6786BE'; //Default
            }
        }
    }, []);

    const onChangeCheckedTrainers = useCallback(
        (trainer) => {
            if (checkedTrainers.some((t) => t.id === trainer.id)) {
                setCheckedTrainers(checkedTrainers.filter((t) => t.id !== trainer.id));
            } else {
                const t = {
                    id: trainer.id,
                    name: trainer.name,
                    salary: trainer.salary,
                    maxAmountToPay: trainer.pay,
                };
                setCheckedTrainers([...checkedTrainers, t]);
            }
        },
        [checkedTrainers, trainerPayWithoutAdmin]
    );

    const handlePay = () => {
        setCheckedTrainers([]);
    };

    return (
        <StyledPayColoredWrapper isfirstchild={firstElement} isopen={isOpen}>
            <StyledHeading onClick={toggleIsOpen}>
                <StyledText
                    hasdeclaredfontsize="22px"
                    hasdeclaredfontweight="700"
                    hasdeclaredtextalign="center"
                    hasdeclaredtexttransform="uppercase"
                    hasdeclaredfontcolor="#6786BE"
                >
                    {(() => {
                        const [year, month] = keyElement.split('-'),
                            prettyMonth = new Date(year, month - 1).toLocaleString(getLang(), {
                                month: 'long',
                            });

                        return `${prettyMonth} ${year}`.toLocaleUpperCase();
                    })()}
                </StyledText>
                <StyledHeadingCheckbox>
                    <StyledArrow isopen={isOpen} />
                </StyledHeadingCheckbox>
            </StyledHeading>
            {isOpen && (
                <StyledPayAllElement>
                    <ScrollSync>
                        <>
                            <StyledFlexBoxWrapper $calcWidth={showCheckboxes}>
                                <ScrollSyncPane>
                                    <StyledScrollHeaderPayAll
                                        isscrollbar={isScrollBar}
                                        ref={scrollElement}
                                        calcwidth={showCheckboxes}
                                    >
                                        <div>
                                            <StyledPayAllElementHeader>
                                                <div>
                                                    <StyledText
                                                        hasdeclaredfontsize="16px"
                                                        hasdeclaredfontweight="600"
                                                        hasdeclaredlineheight="1.4em"
                                                        hasdeclaredtextalign="center"
                                                        hasdeclaredpadding="0 4px 2px 4px"
                                                    >
                                                        {getString(
                                                            'Trainings_trainings_all_trainer_pay_trener'
                                                        )}
                                                    </StyledText>
                                                </div>
                                                <div>
                                                    <StyledText
                                                        hasdeclaredfontsize="16px"
                                                        hasdeclaredfontweight="600"
                                                        hasdeclaredlineheight="1.4em"
                                                        hasdeclaredtextalign="center"
                                                        hasdeclaredpadding="0 4px 2px 4px"
                                                    >
                                                        {getString(
                                                            'Trainings_trainings_all_trainer_pay_godziny'
                                                        )}
                                                    </StyledText>
                                                </div>
                                                <div>
                                                    <StyledText
                                                        hasdeclaredfontsize="16px"
                                                        hasdeclaredfontweight="600"
                                                        hasdeclaredlineheight="1.4em"
                                                        hasdeclaredtextalign="center"
                                                        hasdeclaredpadding="0 4px 2px 4px"
                                                    >
                                                        {getString(
                                                            'Trainings_trainings_all_trainer_pay_wyplata'
                                                        )}
                                                    </StyledText>
                                                </div>
                                                <div>
                                                    <StyledText
                                                        hasdeclaredfontsize="16px"
                                                        hasdeclaredfontweight="600"
                                                        hasdeclaredlineheight="1.4em"
                                                        hasdeclaredtextalign="center"
                                                        hasdeclaredpadding="0 4px 2px 4px"
                                                    >
                                                        {getString(
                                                            'Trainings_trainings_all_trainer_pay_dochod'
                                                        )}
                                                    </StyledText>
                                                </div>
                                                <div>
                                                    <StyledText
                                                        hasdeclaredfontsize="16px"
                                                        hasdeclaredfontweight="600"
                                                        hasdeclaredlineheight="1.4em"
                                                        hasdeclaredtextalign="center"
                                                        hasdeclaredpadding="0 4px 2px 4px"
                                                    >
                                                        {getString(
                                                            'Trainings_trainings_all_trainer_pay_przychod'
                                                        )}
                                                    </StyledText>
                                                </div>
                                            </StyledPayAllElementHeader>
                                        </div>
                                    </StyledScrollHeaderPayAll>
                                </ScrollSyncPane>
                            </StyledFlexBoxWrapper>
                            {Object.values(val)
                                .sort((a, b) => {
                                    const lastname = cebulaCompare(a.lastname, b.lastname);
                                    if (lastname) return lastname;

                                    const firstname = cebulaCompare(a.firstname, b.firstname);
                                    if (firstname) return firstname;

                                    return 0;
                                })
                                .map((trainer) => (
                                    <StyledFlexBoxWrapper>
                                        {showCheckboxes ? (
                                            <StyledCheckboxDone
                                                $isDisabled={
                                                    trainer.salary?.paid === 1 ||
                                                    new Decimal(trainer.pay).eq(0)
                                                }
                                            >
                                                <input
                                                    type="checkbox"
                                                    id={`pay-${trainer.id}-${keyElement}`}
                                                    disabled={
                                                        trainer.salary?.paid === 1 ||
                                                        new Decimal(trainer.pay).eq(0)
                                                    }
                                                    onChange={() =>
                                                        onChangeCheckedTrainers(trainer)
                                                    }
                                                    checked={checkedTrainers.some(
                                                        (t) => t.id === trainer.id
                                                    )}
                                                />
                                                <label htmlFor={`pay-${trainer.id}-${keyElement}`}>
                                                    <CheckboxAccept />
                                                </label>
                                            </StyledCheckboxDone>
                                        ) : undefined}
                                        <ScrollSyncPane>
                                            <StyledScrolContentPayAll>
                                                <div>
                                                    <StyledAllPayTrainer
                                                        bordercolor={getRowBorder(
                                                            trainer,
                                                            keyElement.split('-')[1]
                                                        )}
                                                    >
                                                        <div>
                                                            <StyledText
                                                                hasdeclaredfontsize="16px"
                                                                hasdeclaredfontweight="600"
                                                                hasdeclaredlineheight="1.4em"
                                                                hasdeclaredtextalign="center"
                                                                hasdeclaredpadding="0 4px 2px 4px"
                                                            >
                                                                {trainer.name}
                                                            </StyledText>
                                                        </div>
                                                        <div>
                                                            <StyledText
                                                                hasdeclaredfontsize="16px"
                                                                hasdeclaredfontweight="600"
                                                                hasdeclaredlineheight="1.4em"
                                                                hasdeclaredtextalign="center"
                                                                hasdeclaredpadding="0 4px 2px 4px"
                                                            >
                                                                {trainer.hours}
                                                            </StyledText>
                                                        </div>
                                                        <div>
                                                            <StyledText
                                                                hasdeclaredfontsize="16px"
                                                                hasdeclaredfontweight="600"
                                                                hasdeclaredlineheight="1.4em"
                                                                hasdeclaredtextalign="center"
                                                                hasdeclaredpadding="0 4px 2px 4px"
                                                            >
                                                                {new Decimal(trainer.pay).toFixed(
                                                                    2
                                                                )}{' '}
                                                                PLN{' '}
                                                                {trainer.salary &&
                                                                trainer.salary.paid === 2
                                                                    ? `(${Decimal(
                                                                          trainer.salary.totalAmount
                                                                      )
                                                                          .minus(
                                                                              trainer.salary
                                                                                  .paidAmount
                                                                          )
                                                                          .toNumber()
                                                                          .toFixed(2)} PLN)`
                                                                    : null}
                                                            </StyledText>
                                                        </div>
                                                        <div>
                                                            <StyledText
                                                                hasdeclaredfontsize="16px"
                                                                hasdeclaredfontweight="600"
                                                                hasdeclaredlineheight="1.4em"
                                                                hasdeclaredtextalign="center"
                                                                hasdeclaredpadding="0 4px 2px 4px"
                                                            >
                                                                {new Decimal(trainer.price)
                                                                    .minus(trainer.pay)
                                                                    .toFixed(2)}{' '}
                                                                PLN
                                                            </StyledText>
                                                        </div>
                                                        <div>
                                                            <StyledText
                                                                hasdeclaredfontsize="16px"
                                                                hasdeclaredfontweight="600"
                                                                hasdeclaredlineheight="1.4em"
                                                                hasdeclaredtextalign="center"
                                                                hasdeclaredpadding="0 4px 2px 4px"
                                                            >
                                                                {new Decimal(trainer.price)
                                                                    .minus(trainer.free_hours_price)
                                                                    .toFixed(2)}{' '}
                                                                PLN
                                                            </StyledText>
                                                        </div>
                                                    </StyledAllPayTrainer>
                                                </div>
                                            </StyledScrolContentPayAll>
                                        </ScrollSyncPane>
                                    </StyledFlexBoxWrapper>
                                ))}
                        </>
                    </ScrollSync>
                    <StyledButtonsWrapper $centerSingleBtn={showCheckboxes} bottomsize>
                        {showCheckboxes ? (
                            <>
                                <StyledButton
                                    hasdeclaredbgcolor="#6786BE"
                                    hasdeclaredpadding="0 28px"
                                    hasdeclaredfontcolor="#fff"
                                    hasdeclaredborderradius="25px"
                                    hasdeclaredfontweight="700"
                                    hasdeclaredtextalign="center"
                                    hasdeclaredcursor="pointer"
                                    hasdeclaredfontsize="18px"
                                    hasdeclaredtexttransform="uppercase"
                                    onClick={toggleIsOpenPayPopup}
                                >
                                    {getString('open_elment_wyplac')}
                                </StyledButton>
                                <StyledButton
                                    hasdeclaredbgcolor="#EE745C"
                                    hasdeclaredpadding="0 28px"
                                    hasdeclaredfontcolor="#fff"
                                    hasdeclaredborderradius="25px"
                                    hasdeclaredfontweight="700"
                                    hasdeclaredtextalign="center"
                                    hasdeclaredcursor="pointer"
                                    hasdeclaredfontsize="18px"
                                    hasdeclaredtexttransform="uppercase"
                                    onClick={toggleShowCheckboxes}
                                >
                                    {getString('open_element_anuluj')}
                                </StyledButton>
                            </>
                        ) : isAtLeastOneUnpaid ? (
                            <StyledButton
                                hasdeclaredbgcolor="#EE745C"
                                hasdeclaredpadding="0 28px"
                                hasdeclaredfontcolor="#fff"
                                hasdeclaredborderradius="25px"
                                hasdeclaredfontweight="700"
                                hasdeclaredtextalign="center"
                                hasdeclaredcursor="pointer"
                                hasdeclaredfontsize="18px"
                                hasdeclaredtexttransform="uppercase"
                                hasdeclaredmargin="0 auto"
                                onClick={toggleShowCheckboxes}
                                className="hover-red-effect"
                            >
                                {getString('open_elment_wybierz_i_wyplac')}
                            </StyledButton>
                        ) : null}
                    </StyledButtonsWrapper>
                    <StyledDataSum>
                        <div>
                            <StyledText
                                hasdeclaredfontsize="18px"
                                hasdeclaredfontweight="600"
                                hasdeclaredlineheight="1.4em"
                                hasdeclaredfontcolor="#6786BE"
                                hasdeclaredtexttransform="uppercase"
                            >
                                {getString('open_element_przychod')}:{' '}
                                <span style={{ color: '#000' }}>
                                    {revenue.dp() > 0
                                        ? revenue.minus(free_hours_price).toFixed(2)
                                        : revenue.minus(free_hours_price).toString()}{' '}
                                    PLN
                                </span>
                            </StyledText>
                        </div>
                        <div>
                            <StyledText
                                hasdeclaredfontsize="18px"
                                hasdeclaredfontweight="600"
                                hasdeclaredlineheight="1.4em"
                                hasdeclaredfontcolor="#6786BE"
                                hasdeclaredtexttransform="uppercase"
                            >
                                {getString('open_element_godziny')}:{' '}
                                <span style={{ color: '#000' }}>
                                    {paidHours.toString()} (D: {free.toString()} | P:{' '}
                                    {halfHours.toString()} | Ł: {hoursAmount.toString()})
                                </span>
                            </StyledText>
                        </div>
                        <div>
                            <StyledText
                                hasdeclaredfontsize="18px"
                                hasdeclaredfontweight="600"
                                hasdeclaredlineheight="1.4em"
                                hasdeclaredfontcolor="#6786BE"
                                hasdeclaredtexttransform="uppercase"
                            >
                                {getString('open_element_dochód')}:{' '}
                                <span style={{ color: '#000' }}>
                                    {income.dp() > 0 ? income.toFixed(2) : income.toString()} (
                                    {incomeAfterTaxes.dp() > 0
                                        ? incomeAfterTaxes.toFixed(2)
                                        : incomeAfterTaxes.toString()}
                                    ) PLN
                                </span>
                            </StyledText>
                        </div>
                        <div>
                            <StyledText
                                hasdeclaredfontsize="18px"
                                hasdeclaredfontweight="600"
                                hasdeclaredlineheight="1.4em"
                                hasdeclaredfontcolor="#6786BE"
                                hasdeclaredtexttransform="uppercase"
                            >
                                {getString('open_element_wypłaty')}:{' '}
                                <span style={{ color: '#000' }}>
                                    {trainerPayWithoutAdmin?.dp() > 0
                                        ? trainerPayWithoutAdmin.toFixed(2)
                                        : trainerPayWithoutAdmin.toString()}{' '}
                                    PLN
                                </span>
                            </StyledText>
                        </div>
                    </StyledDataSum>
                </StyledPayAllElement>
            )}
            {isOpenPayPopup ? (
                <AllTrainerPayPopup
                    trainers={checkedTrainers}
                    closePopup={toggleIsOpenPayPopup}
                    month={keyElement}
                    handlePay={handlePay}
                />
            ) : null}
        </StyledPayColoredWrapper>
    );
};

export default OpenElement;
