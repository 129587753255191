import styled from 'styled-components';
import { scrollStyles } from '../../atoms/UserPanelGlobalStyles/scrollStyles';

export const StyledEmployeeDataCostElement = styled.form`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;

    > div {
        &:first-child {
            ${scrollStyles}
            width: calc(100% - 54px);
        }
    }
`;
