import styled from 'styled-components';

export const StyledResultsHeader = styled.div`
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;
