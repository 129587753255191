import styled from 'styled-components';

export const StyledTrainingsForm = styled.form`
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    max-width: 800px;

    > div {
        width: 50%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        align-items: center;
        position: relative;
        padding-bottom: 20px;

        input {
            height: 45px;
            width: 320px;
            border-radius: 25px;
            margin-top: 0;
            margin-bottom: 0;
            padding: 0 18px;
            font-size: 20px;
            font-family: 'Poppins';
            outline: none;
        }

        :nth-child(5),
        :nth-child(6) {
            width: 100%;
        }
    }

    @media only screen and (max-width: 1044px) {
        > div {
            > div {
                width: 280px;
                > input {
                    width: 100%;
                }
            }
        }
    }

    @media only screen and (max-width: 906px) {
        > div {
            > div {
                width: 240px;
                > input {
                    width: 100%;
                }
            }
        }
    }

    @media only screen and (max-width: 767px) {
        > div {
            width: 100%;
            > div {
                width: 100%;
                > input {
                    width: 80%;
                    max-width: unset;
                }
            }
        }
    }

    @media only screen and (max-width: 450px) {
        > div {
            > div {
                > input {
                    width: 100%;
                }
            }
        }
    }
`;
