import React from 'react';

import {
    StyledUserPanelTournamentWrapper,
    StyledLoaderWrapper,
} from '../../../styles/sharedStyles';
import { StyledText } from '../../atoms/Text/StyledText';
import UserPanelReqElement from '../UserPanelReqElement/UserPanelReqElement';
import UserPanelReqElementTrainer from '../../molecules/UserPanelReqElementTrainer/UserPanelReqElementTrainer';
import Loader from '../Loader/Loader';
import { StyledTextWrapper } from './UserPanelReq.styles';
import UserPanelHeadingText from '../../atoms/UserPanel/UserPanelHeadingText';
import { StyledGlobalHeadingWrapper } from '../../../styles/sharedStyles';

import { getString } from '../../../strings';

const UserPanelReq = ({ analyses, setAnalyses, err, trainer = false }) =>
    err ? (
        <StyledTextWrapper>
            <StyledText
                hasdeclaredtexttransform="uppercase"
                hasdeclaredpadding="0 20px 0 20px"
                hasdeclaredfontsize="clamp(26px, 2.396vw, 46px)"
                hasdeclaredfontweight="700"
                hasdeclaredtextalign="center"
                hasdeclaredmaxwidth="600px"
                hasdeclaredlineheight="1.4em"
                as="h2"
            >
                {getString('blad_wczytywania')}
            </StyledText>
        </StyledTextWrapper>
    ) : !analyses ? (
        <StyledUserPanelTournamentWrapper loading={!err && !analyses}>
            <StyledLoaderWrapper>
                <Loader />
            </StyledLoaderWrapper>
        </StyledUserPanelTournamentWrapper>
    ) : !analyses.length ? (
        <StyledTextWrapper>
            <StyledText
                hasdeclaredtexttransform="uppercase"
                hasdeclaredpadding="0 20px 0 20px"
                hasdeclaredfontsize="clamp(26px, 2.396vw, 46px)"
                hasdeclaredfontweight="700"
                hasdeclaredtextalign="center"
                hasdeclaredmaxwidth="600px"
                hasdeclaredlineheight="1.4em"
                as="h2"
            >
                {getString('UserPanelReq__brak_zamowien')}
            </StyledText>
        </StyledTextWrapper>
    ) : (
        <>
            <StyledGlobalHeadingWrapper>
                <UserPanelHeadingText text={`Zamówienia:`} />
            </StyledGlobalHeadingWrapper>
            <StyledUserPanelTournamentWrapper loading={!err && !analyses}>
                {analyses.map(
                    trainer
                        ? (analysis) => (
                              <UserPanelReqElementTrainer
                                  key={analysis.id}
                                  name={analysis.name}
                                  format={analysis.format}
                                  competition={analysis.competition}
                                  phone={analysis.phone}
                                  email={analysis.email}
                                  link={analysis.link}
                                  status={analysis.status}
                                  orderId={analysis.id}
                                  setStatus={(status) =>
                                      setAnalyses((val) =>
                                          val.map((a) =>
                                              a.id === analysis.id
                                                  ? {
                                                        ...a,
                                                        status,
                                                    }
                                                  : a
                                          )
                                      )
                                  }
                              />
                          )
                        : (analysis, idx) => (
                              <UserPanelReqElement
                                  key={analysis.id}
                                  number={analyses.length - idx}
                                  format={analysis.format}
                                  competition={analysis.competition}
                                  date={analysis.created}
                                  trainer={analysis.trainer}
                                  orderId={analysis.id}
                                  status={analysis.status}
                                  remove={() =>
                                      setAnalyses((val) => val.filter((a) => a.id !== analysis.id))
                                  }
                              />
                          )
                )}
            </StyledUserPanelTournamentWrapper>
        </>
    );

export default UserPanelReq;
