import React, { useState, useRef } from 'react';

import UserPanelTournamentButton from '../UserPanelTournament/UserPanelTournamentButton';
import CompetitionElement from '../CompetitionElement/CompetitionElement';

import { StyledText } from '../../atoms/Text/StyledText';
import { StyledInputWrapper, StyledCustomFile } from './UserPanelReqElementTrainer.styles';

import { getString } from '../../../strings';

import {
    handleBegin,
    handleCancel,
    handleFileSelect,
    handleSubmit,
} from './UserPanelReqElementTrainer.data';

import {
    StyledUserPanelReqElement,
    StyledDataWrapper,
    StyledLogoWrapper,
    StyledTextContent,
    StyledContent,
    StyledButtonsWrapper,
    StyledButtonWrapper,
} from '../../organisms/UserPanelReqElement/UserPanelReqElement.styles';

const UserPanelReqElementTrainer = ({
    name,
    format,
    competition,
    phone,
    email,
    link,
    status,
    orderId,
    setStatus,
}) => {
    const [cancel, setCancel] = useState(false);
    const [submit, setSubmit] = useState(false);
    const [file, setFile] = useState();
    const rejectionReason = useRef();

    const buttons = (() => {
        const ret = [];

        switch (status) {
            case 'pending':
                // może przyjąć albo odrzucić
                ret.push(
                    <UserPanelTournamentButton
                        name={getString('UserPanelReq__rozpocznij')}
                        color="#4E6BAA"
                        onClick={() => handleBegin(orderId, setStatus)}
                    />,
                    <UserPanelTournamentButton
                        name={getString('UserPanelReq__odrzuc_analize')}
                        color="#E18872"
                        onClick={() => setCancel((val) => !val)}
                    />
                );
                break;
            case 'in-progress':
                // może przesłać albo odrzucić
                ret.push(
                    <UserPanelTournamentButton
                        name={getString('UserPanelReq__przeslij')}
                        color="#4E6BAA"
                        onClick={() => {
                            setSubmit((val) => !val);
                            setFile(undefined);
                        }}
                    />,
                    <UserPanelTournamentButton
                        name={getString('UserPanelReq__odrzuc_analize')}
                        color="#E18872"
                        onClick={() => setCancel((val) => !val)}
                    />
                );
                break;
            case 'done':
                // może wyświetlić albo edytować
                ret.push(
                    <UserPanelTournamentButton
                        name={getString('UserPanelReq__zobacz')}
                        color="#4E6BAA"
                        onClick={() =>
                            window.open(
                                `/api/trainings/analysis/get-content/${orderId}`,
                                '_blank',
                                'noopener,noreferrer'
                            )
                        }
                    />,
                    <UserPanelTournamentButton
                        name={getString('UserPanelReq__edytuj')}
                        color="#E18872"
                        onClick={() => {
                            setSubmit((val) => !val);
                            setFile(undefined);
                        }}
                    />
                );
                break;
            case 'expired':
                // może ponowić (przedłużyć termin) albo odrzucić
                ret.push(
                    <UserPanelTournamentButton
                        name={getString('UserPanelReq__kontynuuj')}
                        color="#4E6BAA"
                        onClick={() => handleBegin(orderId, setStatus)}
                    />,
                    <UserPanelTournamentButton
                        name={getString('UserPanelReq__zakoncz')}
                        color="#E18872"
                        onClick={() => setCancel((val) => !val)}
                    />
                );
                break;
            default:
                return;
        }

        return ret;
    })();

    return (
        <StyledUserPanelReqElement>
            <StyledContent>
                <StyledText
                    hasdeclaredfontsize="32px"
                    hasdeclaredfontweight="700"
                    hasdeclaredfontcolor="#000"
                    hasdeclaredtexttransform="uppercase"
                    as="h2"
                >
                    {name}
                </StyledText>
                <StyledDataWrapper>
                    <StyledLogoWrapper>
                        <StyledText
                            hasdeclaredtextalign="center"
                            hasdeclaredfontsize="32px"
                            hasdeclaredfontweight="700"
                            hasdeclaredtexttransform="uppercase"
                        >
                            {format}
                        </StyledText>
                        <CompetitionElement competition={competition} />
                    </StyledLogoWrapper>
                    <StyledTextContent>
                        <div>
                            <StyledText
                                hasdeclaredfontsize="20px"
                                hasdeclaredfontweight="600"
                                hasdeclaredfontcolor="#4E6BAA"
                                hasdeclaredtexttransform="uppercase"
                            >
                                {getString('UserPanelReqElement__tel')}:
                            </StyledText>
                            <StyledText
                                hasdeclaredfontsize="20px"
                                hasdeclaredfontweight="600"
                                hasdeclaredtexttransform="uppercase"
                            >
                                {phone}
                            </StyledText>
                        </div>
                        <div>
                            <StyledText
                                hasdeclaredfontsize="20px"
                                hasdeclaredfontweight="600"
                                hasdeclaredfontcolor="#4E6BAA"
                                hasdeclaredtexttransform="uppercase"
                            >
                                {getString('UserPanelReqElement__email')}:
                            </StyledText>
                            <StyledText
                                hasdeclaredfontsize="20px"
                                hasdeclaredfontweight="600"
                                hasdeclaredtexttransform="uppercase"
                            >
                                {email}
                            </StyledText>
                        </div>
                        <div>
                            <StyledText
                                hasdeclaredfontsize="20px"
                                hasdeclaredfontweight="600"
                                hasdeclaredfontcolor="#4E6BAA"
                                hasdeclaredtexttransform="uppercase"
                            >
                                {getString('UserPanelReqElement__nagranie')}:
                            </StyledText>
                            <a href={link}>
                                <StyledText
                                    hasdeclaredfontsize="20px"
                                    hasdeclaredfontweight="600"
                                    hasdeclaredtexttransform="uppercase"
                                    hasdeclaredfontcolor="#E18872"
                                    hascursor="pointer"
                                >
                                    {getString('UserPanelReqElement__link')}
                                </StyledText>
                            </a>
                        </div>
                        <div>
                            <StyledText
                                hasdeclaredfontsize="20px"
                                hasdeclaredfontweight="600"
                                hasdeclaredfontcolor="#4E6BAA"
                                hasdeclaredtexttransform="uppercase"
                            >
                                {getString('UserPanelReqElement__status')}:
                            </StyledText>
                            <StyledText
                                hasdeclaredfontsize="20px"
                                hasdeclaredfontweight="600"
                                hasdeclaredtexttransform="uppercase"
                                hasdeclaredfontcolor={status === 'done' ? '#6AB95D' : '#E18872'}
                            >
                                {getString(`UserPanelReq__analysis_status__${status}__trainer`)}
                            </StyledText>
                        </div>
                    </StyledTextContent>
                </StyledDataWrapper>
            </StyledContent>
            {buttons.length === 1 ? (
                <StyledButtonWrapper>{buttons}</StyledButtonWrapper>
            ) : buttons.length > 1 ? (
                <StyledButtonsWrapper>{buttons}</StyledButtonsWrapper>
            ) : undefined}
            {cancel ? (
                <StyledInputWrapper>
                    <StyledText
                        hasdeclaredtexttransform="uppercase"
                        hasdeclaredcursor="pointer"
                        hasdeclaredtextalign="center"
                        hasdeclaredfontsize="18px"
                        hasdeclaredfontweight="600"
                        hasdeclaredpadding="18px 0 0 0"
                    >
                        {getString('UserPanelReq__powod_anulowania')}
                    </StyledText>
                    <textarea ref={rejectionReason} />
                    <UserPanelTournamentButton
                        name={getString('UserPanelReq__wyslij')}
                        color="#4E6BAA"
                        onClick={() =>
                            handleCancel(orderId, rejectionReason.current.value, setStatus, () => {
                                rejectionReason.current.value = undefined;
                                setCancel(false);
                            })
                        }
                    />
                </StyledInputWrapper>
            ) : undefined}
            {submit ? (
                <StyledInputWrapper>
                    <StyledCustomFile file={file}>
                        <label htmlFor="upload">{getString('UserPanelReq__submit_title')}</label>
                        <input
                            id="upload"
                            type="file"
                            accept="application/pdf"
                            onChange={(e) => handleFileSelect(e, setFile)}
                        />
                    </StyledCustomFile>
                    <UserPanelTournamentButton
                        name={getString('UserPanelReq__wyslij')}
                        color="#4E6BAA"
                        onClick={(e) =>
                            handleSubmit(orderId, file, status, setStatus, () => {
                                setSubmit(false);
                                setFile(undefined);
                            })
                        }
                    />
                </StyledInputWrapper>
            ) : undefined}
        </StyledUserPanelReqElement>
    );
};

export default UserPanelReqElementTrainer;
