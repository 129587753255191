import { useState, useCallback, useMemo } from 'react';
import { useToggle } from 'react-use';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';

import EmployeeDataCostElement from '../EmployeeDataCostElement/EmployeeDataCostElement';
import EmployeeTableDataElement from '../EmployeeTableDataElement/EmployeeTableDataElement';
import { useForm } from 'react-hook-form';
import { StyledInputsButtonsWrapper } from '../../atoms/TrackHours/StyledInputsButtonsWrapper';
import {
    StyledUserPanelEmployeeDataElement,
    StyledElement,
    StyledHeader,
    StyledHeaderElement,
    StyledLeftBottomBtn,
    StyledForm,
} from './UserPanelEmployeeDataElement.styles';
import { StyledText } from '../../atoms/Text/StyledText';
import { StyledHeading, StyledArrow, StyledInputWrapper } from '../../../styles/sharedStyles';
import { StyledHeadingCheckbox } from '../../atoms/SettlementsDoneElement/StyledHeadingCheckbox';
import { StyledInputsDivWrapper } from '../../atoms/StyledInputsDivWrapper/StyledInputsDivWrapper';
import { yupResolver } from '@hookform/resolvers/yup';

import { getString, getLang } from '../../../strings';
import useScrollBar from '../../../logic/hooks/useScrollBar';

import { formSchema } from './UserPanelEmployeeDataElement.data';
import { addTrainerSubject } from '../../../logic/requests/subjects';
import { useQueryClient } from '@tanstack/react-query';
import PaymentsPopup from '../../organisms/Payments/PaymentsPopup';

const UserPanelEmployeeDataElement = ({
    trainer: {
        id,
        firstname,
        lastname,
        current_contract,
        price,
        bonus_hours,
        bonus_amount,
        subjects,
    },
    allSubjects,
    trainers,
}) => {
    const queryClient = useQueryClient();

    const { scrollElement, isScrollBar } = useScrollBar(false, true);
    const { scrollElement: scrollElement2, isScrollBar: isScrollBar2 } = useScrollBar(false, true);

    const [isOpen, toggleIsOpen] = useToggle(false);
    const [isOpenForm, setIsOpenForm] = useState(false);
    const [isError, setIsError] = useState({ showError: false });
    const [confirmPopup, setConfirmPopup] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors, isDirty },
        reset,
    } = useForm({
        resolver: yupResolver(formSchema),
    });

    const trainer = useMemo(
        () => trainers.find(({ id: trainerId }) => id === trainerId),
        [trainers, id]
    );

    const onSubmit = useCallback(
        (dataForm) => {
            if (!isDirty) setIsOpenForm(false);

            addTrainerSubject({
                subjectId: dataForm.subject,
                trainerId: id,
                trainerPrice: dataForm.price.toString().replace(',', '.'),
            })
                .then(() => {
                    queryClient.invalidateQueries({
                        queryKey: ['trainers', 'all', 'employeeConf'],
                    });
                    setConfirmPopup(true);
                    reset();
                    setIsOpenForm();
                    setIsError({ showError: false });
                })
                .catch(() => {
                    setIsError({
                        message: getString('User_panel_subject_element_wystapil_blad'),
                        showError: true,
                    });
                });
        },
        [id, isDirty, queryClient, reset]
    );

    const retIsOpen = useMemo(
        () =>
            isOpen ? (
                <StyledElement>
                    <ScrollSync>
                        <>
                            {isOpenForm ? (
                                <StyledForm onSubmit={handleSubmit(onSubmit)}>
                                    <StyledInputsDivWrapper>
                                        <StyledInputWrapper
                                            error={errors.subject}
                                            hasdeclaredwidth="50%"
                                        >
                                            <label htmlFor="subject">
                                                <StyledText
                                                    hasdeclaredfontsize="20px"
                                                    hasdeclaredtextalign="center"
                                                    hasdeclaredpadding="8px 0 8px 0"
                                                    hasdeclaredfontweight="600"
                                                >
                                                    {getString(
                                                        'User_panel_employee_data_element_przedmiot'
                                                    )}
                                                </StyledText>
                                            </label>
                                            <select id="subject" {...register('subject')}>
                                                <option />
                                                {allSubjects.map((el) => (
                                                    <option key={el.id} value={el.id}>
                                                        {el.description[getLang()]}
                                                    </option>
                                                ))}
                                            </select>
                                            <StyledText
                                                as="h4"
                                                hasdeclaredfontsize="16px"
                                                hasdeclaredfontcolor="#F74017"
                                                hasdeclaredtextalign="center"
                                                hasdeclaredfontweight="700"
                                                hasdeclaredtexttransform="uppercase"
                                                hasdeclaredlineheight="1.4em"
                                            >
                                                {getString(
                                                    'user_panel_employee_data_element_przedmiot_jest_wymagany'
                                                )}
                                            </StyledText>
                                        </StyledInputWrapper>
                                        <StyledInputWrapper
                                            error={errors.price}
                                            hasdeclaredwidth="50%"
                                        >
                                            <label htmlFor="price">
                                                <StyledText
                                                    hasdeclaredfontsize="20px"
                                                    hasdeclaredtextalign="center"
                                                    hasdeclaredpadding="8px 0 8px 0"
                                                    hasdeclaredfontweight="600"
                                                >
                                                    {getString(
                                                        'User_panel_employee_data_element_kwota'
                                                    )}
                                                </StyledText>
                                            </label>
                                            <input
                                                id="price"
                                                {...register('price')}
                                            />
                                            <StyledText
                                                as="h4"
                                                hasdeclaredfontsize="16px"
                                                hasdeclaredfontcolor="#F74017"
                                                hasdeclaredtextalign="center"
                                                hasdeclaredfontweight="700"
                                                hasdeclaredtexttransform="uppercase"
                                                hasdeclaredlineheight="1.4em"
                                            >
                                                {getString(
                                                    'user_panel_employee_data_element_cena_jest_wymagany'
                                                )}
                                            </StyledText>
                                        </StyledInputWrapper>
                                    </StyledInputsDivWrapper>
                                    <StyledInputsButtonsWrapper hasmargin="18px 0 0 0">
                                        <input
                                            type="submit"
                                            value={getString(
                                                'User_panel_employee_data_element_zapisz'
                                            )}
                                        />
                                        <input
                                            type="reset"
                                            onClick={() => setIsOpenForm(false)}
                                            value={getString(
                                                'User_panel_employee_data_element_anuluj'
                                            )}
                                        />
                                    </StyledInputsButtonsWrapper>
                                    {isError.showError ? (
                                        <div style={{ width: '100%', marginTop: '25px' }}>
                                            <StyledText
                                                as="h4"
                                                hasdeclaredfontsize="16px"
                                                hasdeclaredfontcolor="#F74017"
                                                hasdeclaredtextalign="center"
                                                hasdeclaredfontweight="700"
                                                hasdeclaredtexttransform="uppercase"
                                                hasdeclaredlineheight="1.4em"
                                            >
                                                {isError.message}
                                            </StyledText>
                                        </div>
                                    ) : null}
                                </StyledForm>
                            ) : (
                                <>
                                    <ScrollSyncPane>
                                        <StyledHeader
                                            isscrollbar={isScrollBar}
                                            ref={scrollElement}
                                            hasalignself="flex-start"
                                            $removeOneIcon
                                        >
                                            <div>
                                                <StyledHeaderElement>
                                                    <StyledText
                                                        hasdeclaredfontsize="16px"
                                                        hasdeclaredfontweight="700"
                                                        hasdeclaredtextalign="center"
                                                        hasdeclaredlineheight="1.4em"
                                                    >
                                                        {getString(
                                                            'User_panel_employee_konfiguracja_stawka_godzinowa2'
                                                        )}
                                                    </StyledText>
                                                </StyledHeaderElement>
                                                <StyledHeaderElement>
                                                    <StyledText
                                                        hasdeclaredfontsize="16px"
                                                        hasdeclaredfontweight="700"
                                                        hasdeclaredtextalign="center"
                                                        hasdeclaredlineheight="1.4em"
                                                    >
                                                        {getString(
                                                            'User_panel_employee_konfiguracja_wysokosc_premii2'
                                                        )}
                                                    </StyledText>
                                                </StyledHeaderElement>
                                                <StyledHeaderElement>
                                                    <StyledText
                                                        hasdeclaredfontsize="16px"
                                                        hasdeclaredfontweight="700"
                                                        hasdeclaredtextalign="center"
                                                        hasdeclaredlineheight="1.4em"
                                                    >
                                                        {getString(
                                                            'User_panel_employee_konfiguracja_godzin_do_premii2'
                                                        )}
                                                    </StyledText>
                                                </StyledHeaderElement>
                                                <StyledHeaderElement>
                                                    <StyledText
                                                        hasdeclaredfontsize="16px"
                                                        hasdeclaredfontweight="700"
                                                        hasdeclaredtextalign="center"
                                                        hasdeclaredlineheight="1.4em"
                                                    >
                                                        {getString(
                                                            'User_panel_employess_conf_element'
                                                        )}
                                                    </StyledText>
                                                </StyledHeaderElement>
                                            </div>
                                        </StyledHeader>
                                        <EmployeeDataCostElement
                                            price={price}
                                            bonus_hours={bonus_hours}
                                            bonus_amount={bonus_amount}
                                            contract_type={
                                                current_contract?.type ? getString(`contract_${current_contract?.type}`) :
                                                getString('contract__none')
                                            }
                                            trainer={trainer}
                                        />
                                        {subjects?.length ? (
                                            <div style={{ width: '100%', marginTop: '20px' }}>
                                                <StyledText
                                                    hasdeclaredfontsize="22px"
                                                    hasdeclaredfontweight="700"
                                                    hasdeclaredtextalign="center"
                                                    hasdeclaredtexttransform="uppercase"
                                                    hasdeclaredfontcolor="#6786BE"
                                                >
                                                    {getString(
                                                        'User_panel_employee_data_element_spersonalizowane_przedmioty'
                                                    )}
                                                </StyledText>
                                            </div>
                                        ) : null}
                                    </ScrollSyncPane>
                                </>
                            )}
                        </>
                    </ScrollSync>
                    <ScrollSync>
                        <>
                            {!isOpenForm && subjects?.length ? (
                                <>
                                    <ScrollSyncPane>
                                        <StyledHeader
                                            isscrollbar={isScrollBar2}
                                            ref={scrollElement2}
                                            hasalignself="flex-start"
                                        >
                                            <div>
                                                <StyledHeaderElement $hasWidth="50%">
                                                    <StyledText
                                                        hasdeclaredfontsize="16px"
                                                        hasdeclaredfontweight="700"
                                                        hasdeclaredtextalign="center"
                                                        hasdeclaredlineheight="1.4em"
                                                    >
                                                        {getString(
                                                            'User_panel_employee_data_element_przedmiot2'
                                                        )}
                                                    </StyledText>
                                                </StyledHeaderElement>
                                                <StyledHeaderElement $hasWidth="50%">
                                                    <StyledText
                                                        hasdeclaredfontsize="16px"
                                                        hasdeclaredfontweight="700"
                                                        hasdeclaredtextalign="center"
                                                        hasdeclaredlineheight="1.4em"
                                                    >
                                                        {getString(
                                                            'User_panel_employee_data_element_kwota_trenera'
                                                        )}
                                                    </StyledText>
                                                </StyledHeaderElement>
                                            </div>
                                        </StyledHeader>
                                        {subjects.map(({ description, price, subject }) => (
                                            <EmployeeTableDataElement
                                                key={description.toString()}
                                                subjectDescription={description}
                                                subjectId={subject}
                                                price={price}
                                                trainerId={id}
                                                allSubjects={allSubjects}
                                            />
                                        ))}
                                    </ScrollSyncPane>
                                </>
                            ) : null}
                            <StyledLeftBottomBtn onClick={() => setIsOpenForm(!isOpenForm)}>
                                <svg
                                    width="36"
                                    height="36"
                                    viewBox="0 0 36 36"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M33.0105 14.2474L21.7527 14.2474L21.7527 2.98966C21.7527 1.60824 20.6324 0.487935 19.251 0.487936L16.7493 0.487936C15.3679 0.487937 14.2476 1.60824 14.2476 2.98966L14.2476 14.2474L2.98982 14.2474C1.6084 14.2474 0.488099 15.3677 0.488099 16.7491V19.2509C0.488099 20.6323 1.6084 21.7526 2.98982 21.7526L14.2476 21.7526L14.2476 33.0103C14.2476 34.3918 15.3679 35.5121 16.7493 35.5121L19.251 35.5121C20.6324 35.5121 21.7527 34.3918 21.7527 33.0103L21.7527 21.7526L33.0105 21.7526C34.3919 21.7526 35.5122 20.6323 35.5122 19.2509V16.7491C35.5122 15.3677 34.3919 14.2474 33.0105 14.2474Z"
                                        fill="white"
                                    />
                                </svg>
                            </StyledLeftBottomBtn>
                        </>
                    </ScrollSync>
                </StyledElement>
            ) : null,
        [
            allSubjects,
            bonus_amount,
            bonus_hours,
            current_contract?.type,
            errors.price,
            errors.subject,
            handleSubmit,
            id,
            isError.message,
            isError.showError,
            isOpen,
            isOpenForm,
            isScrollBar,
            isScrollBar2,
            onSubmit,
            price,
            register,
            scrollElement,
            scrollElement2,
            subjects,
            trainer,
        ]
    );

    return (
        <>
            {confirmPopup ? (
                <PaymentsPopup
                    title={getString("employee_table_data_element_dodano_spersonalizowany_przedmiot")}
                    onClose={() => {
                        setConfirmPopup(false);
                        setIsError({ message: null, showError: false });
                    }}
                />
            ) : null}
            <StyledUserPanelEmployeeDataElement isopen={isOpen}>
                <StyledHeading onClick={toggleIsOpen}>
                    <StyledText
                        hasdeclaredfontsize="22px"
                        hasdeclaredfontweight="700"
                        hasdeclaredtextalign="center"
                        hasdeclaredtexttransform="uppercase"
                        hasdeclaredfontcolor="#6786BE"
                    >
                        {firstname} {lastname}
                    </StyledText>
                    <StyledHeadingCheckbox>
                        <StyledArrow isopen={isOpen} />
                    </StyledHeadingCheckbox>
                </StyledHeading>
                {retIsOpen}
            </StyledUserPanelEmployeeDataElement>
        </>
    );
};

export default UserPanelEmployeeDataElement;
