import React, { useMemo, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import UserPanelEmployeeConfElement from './UserPanelEmployeeConfElement';
import UserPanelEmployeeDataElement from '../../molecules/UserPanelEmployeeDataElement/UserPanelEmployeeDataElement';
import Loader from '../Loader/Loader';
import SuccessMessage from '../../molecules/SuccessMessage';
import useSuccessMessage from '../../../logic/hooks/useSuccessMessage';
import UserPanelHeadingText from '../../atoms/UserPanel/UserPanelHeadingText';

import { StyledUserPanelEmployeeConf } from '../../atoms/UserPanelEmployeeConfiguration/StyledUserPanelEmployeeConf';
import {
    StyledGlobalHeadingWrapper,
    StyledGlobalScrollWrapper,
    StyledLoaderWrapper,
} from '../../../styles/sharedStyles';
import { StyledText } from '../../atoms/Text/StyledText';

import { getString } from '../../../strings';
import useScrollBar from '../../../logic/hooks/useScrollBar';
import { createUTCDate } from '../../../logic/time';

import { listInactiveTrainers, listTrainersEmployeeConf } from '../../../logic/requests/employees';
import { listSubjects } from '../../../logic/requests/students';
const now = new Date();

const UserPanelEmployeeConfiguration = () => {
    const { scrollElement, isScrollBar } = useScrollBar();
    const { infos, isShowing, show } = useSuccessMessage();
    const [retSubjects, retSetSubjects] = useState();

    const { data, status } = useQuery(
        ['trainers', 'all', 'employeeConf'],
        listTrainersEmployeeConf
    );

    const { data: dataInactive } = useQuery(
        ['trainers', 'inactive', 'employeeConf'],
        listInactiveTrainers
    );

    const trainers = useMemo(
        () =>
            !data
                ? []
                : Object.values(data)
                      .map(({ contracts, ...trainer }) => ({
                          ...trainer,
                          contracts: contracts?.map(({ start, end, ...contract }) => ({
                              ...contract,
                              start: createUTCDate(start),
                              end: end ? createUTCDate(end) : end,
                          })),
                      }))
                      .map(({ contracts, ...trainer }) => ({
                          ...trainer,
                          contracts,
                          current_contract: contracts?.find(
                              ({ start, end }) =>
                                  start.getTime() <= now.getTime() &&
                                  (!end || end.getTime() >= now.getTime())
                          ),
                      }))
                      .sort((a, b) => {
                          const lastname = a.lastname.localeCompare(b.lastname);
                          if (lastname) return lastname;

                          const firstname = a.firstname.localeCompare(a.firstname);
                          if (firstname) return firstname;

                          return 0;
                      }),
        [data]
    );

    const inactiveTrainers =  useMemo(
        () =>
            !dataInactive
                ? []
                : Object.values(dataInactive)
                      .sort((a, b) => {
                          const lastname = a.lastname.localeCompare(b.lastname);
                          if (lastname) return lastname;

                          const firstname = a.firstname.localeCompare(a.firstname);
                          if (firstname) return firstname;

                          return 0;
                      }),
        [dataInactive]
    );

    useEffect(() => {
        listSubjects()
            .then((res) => retSetSubjects(res.filter((el) => el.description)))
    }, []);

    switch (status) {
        case 'loading':
            return (
                <StyledLoaderWrapper>
                    <Loader />
                </StyledLoaderWrapper>
            );
        case 'error':
            return (
                <StyledLoaderWrapper>
                    <StyledText>Error</StyledText>
                </StyledLoaderWrapper>
            );
        case 'success':
            break;
        default:
            return null;
    }

    return (
        <StyledUserPanelEmployeeConf>
            <StyledGlobalHeadingWrapper>
                <UserPanelHeadingText
                    text={`${getString('User_panel_employee_konfiguracja_pracownika')}:`}
                />
            </StyledGlobalHeadingWrapper>
            <StyledGlobalScrollWrapper isscrollbar={isScrollBar} ref={scrollElement}>
                {!isShowing ? null : <SuccessMessage title={getString("success")} infos={infos} />}
                <UserPanelEmployeeConfElement trainers={inactiveTrainers} onSuccess={show} />
                <StyledText
                    hasdeclaredfontsize="22px"
                    hasdeclaredfontweight="700"
                    hasdeclaredtextalign="center"
                    hasdeclaredtexttransform="uppercase"
                    hasdeclaredmargin="clamp(30px, 4vw, 80px) 0 20px 0"
                >
                    {getString('User_panel_employee_konfiguracja_pracownicy')}:
                </StyledText>
                {trainers.map(({ id, ...trainer }) => (
                    <UserPanelEmployeeDataElement key={id} trainer={{ id, ...trainer }} trainers={trainers} allSubjects={retSubjects} />
                ))}
            </StyledGlobalScrollWrapper>
        </StyledUserPanelEmployeeConf>
    );
};

export default UserPanelEmployeeConfiguration;
