import styled from 'styled-components';

export const StyledEditWrapper = styled.div`
    min-height: 48px;
    width: 100%;
    min-width: 700px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border: 4px solid ${({ bordercolor }) => (bordercolor ? bordercolor : '#6786be')};
    border-radius: 25px;
    background-color: #fff;
    gap: 4px;
`;
