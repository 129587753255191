import styled from 'styled-components';

export const StyledInputsWrapper = styled.div`
    width: 100%;
    display: flex;
    gap: 10px;
    justify-content: ${({ $hasSpaceBetween }) => $hasSpaceBetween ? 'space-between' : 'flex-start'};

    > div {
        &:first-child {
            width: 50%;
            display: flex;
        }
    }

    @media only screen and (max-width: 600px) {
        flex-direction: column;

        > div {
            &:first-child {
                width: 100%;
            }
        }
    }
`;
