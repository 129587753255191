import styled from 'styled-components';

export const StyledResult = styled.div`
    border-bottom: 3px solid #c4c4c4;
    padding: 12px 18px;
    width: 50px;
    text-align: center;

    @media only screen and (max-width: 642px) {
        padding: 8px 14px;
    }
`;
