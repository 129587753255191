import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const StyledLogoLink = styled(Link)`
    position: fixed;
    top: 0;
    left: 0;
    width: 185px;
    height: 185px;
    background-color: #000;
    display: block;
    border-bottom-right-radius: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-right: 6px solid #fff;
    border-bottom: 6px solid #fff;
    z-index: 5;
    overflow: hidden;

    > img {
        width: 70%;
        height: 70%;
        object-fit: contain;
        transform: translate(-15px, -12px);
    }

    @media only screen and (max-width: 1266px) {
        width: 160px;
        height: 160px;
    }

    @media only screen and (max-width: 500px) {
        width: 125px;
        height: 125px;

        > img {
            transform: translate(-7px, -12px);
        }
    }

    @media only screen and (max-width: 363px) {
        width: 105px;
        height: 105px;
    }
`;
