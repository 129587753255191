import { useCallback, useState } from 'react';
import { ScrollSyncPane } from 'react-scroll-sync';

import { StyledStudentPaymentsElement } from '../../atoms/UserPanelStudentsPayments/StyledStudentPaymentsElement';
import { StyledDataElement } from '../../atoms/SettlementsDoneDataElement/StyledDataElement';
import { StyledText } from '../../atoms/Text/StyledText';
import { StyledStudentPaymentsElementWrapper } from '../../atoms/UserPanelStudentsPayments/StyledStudentPaymentsElementWrapper';
import { StyledScrollWrapperElement } from '../../atoms/UserPanelStudentsPayments/StyledScrollWrapperElement';
import PaymentsPopup from '../Payments/PaymentsPopup';
import { useQueryClient } from '@tanstack/react-query';
import AcceptIcon from '../../atoms/UserPanelStudens/AcceptIcon';
import { StyledSavePayElement } from '../../atoms/PayElement/StyledSavePayElement';

import { getString } from '../../../strings';
import { markHourAsPaid } from '../../../logic/requests/students';
import { StyledButtonsWrapper } from '../../atoms/Payments/StyledButtonsWrapper';
import { StyledButton } from '../../atoms/Button/StyledButton';
import { StyledUPopupCloseButton } from '../../atoms/Payments/StyledUPopupCloseButton';

const StudentPaymentsElement = ({
    student,
    subject,
    date,
    hours_count,
    trainer,
    free,
    price,
    id,
    paid,
}) => {
    const [confirmPopup, setConfirmPopup] = useState(false);
    const queryClient = useQueryClient();

    const handleChange = () => {
        setConfirmPopup((prev) => !prev);
    };

    const handleAcceptHour = useCallback(() => {
        markHourAsPaid(id).then((res) => {
            queryClient.invalidateQueries({ queryKey: ['studentsHours'] });
        });
    }, [id, queryClient]);

    return (
        <>
            {confirmPopup ? (
                <PaymentsPopup
                    title={
                        getString('student_payments_element_are_you_sure') +
                        ` Szczegóły: ${student} ${hours_count}h ${date} ${subject}`
                    }
                    onClose={handleAcceptHour}
                    cancelTag={getString('student_payments_element_cancel')}
                    closeTag={getString('student_payments_element_yes')}
                    onCancel={() => setConfirmPopup(false)}
                >
                    <div
                        style={{
                            widht: '100%',
                            height: '100%',
                            paddingBottom: '20px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            flex: 1,
                        }}
                    >
                        <StyledText
                            hasdeclaredfontsize="18px"
                            hasdeclaredpadding="50px 20px 0px 20px"
                            hasdeclaredlineheight="1.4em"
                            hasdeclaredtextalign="center"
                        >
                            {getString('student_payments_element_are_you_sure')}
                        </StyledText>
                        <StyledText
                            hasdeclaredfontsize="18px"
                            hasdeclaredpadding="20px 20px 50px 20px"
                            hasdeclaredlineheight="1.4em"
                            hasdeclaredtextalign="center"
                        >
                            Szczegóły: {student} {hours_count}h {date} {subject}
                        </StyledText>
                        <StyledButtonsWrapper>
                            <StyledButton onClick={() => setConfirmPopup(false)}>
                                {getString('student_payments_element_cancel')}
                            </StyledButton>
                            <StyledUPopupCloseButton onClick={handleAcceptHour}>
                                <StyledText
                                    hasdeclaredfontsize="18px"
                                    hasdeclaredtextalign="center"
                                    hasdeclaredfontweight="700"
                                    hasdeclaredfontcolor="#fff"
                                    hascursor="pointer"
                                >
                                    {getString('student_payments_element_yes')}
                                </StyledText>
                            </StyledUPopupCloseButton>
                        </StyledButtonsWrapper>
                    </div>
                </PaymentsPopup>
            ) : null}
            <StyledStudentPaymentsElementWrapper>
                <ScrollSyncPane>
                    <StyledScrollWrapperElement>
                        <StyledStudentPaymentsElement
                            hasDeclaredBorderColor={free ? '#5FEE5C' : undefined}
                        >
                            <StyledDataElement>
                                <StyledText
                                    hasdeclaredfontsize="13px"
                                    hasdeclaredfontweight="600"
                                    hasdeclaredtextalign="center"
                                >
                                    {student}
                                </StyledText>
                            </StyledDataElement>
                            <StyledDataElement>
                                <StyledText
                                    hasdeclaredfontsize="13px"
                                    hasdeclaredfontweight="600"
                                    hasdeclaredtextalign="center"
                                >
                                    {subject}
                                </StyledText>
                            </StyledDataElement>
                            <StyledDataElement>
                                <StyledText
                                    hasdeclaredfontsize="13px"
                                    hasdeclaredfontweight="600"
                                    hasdeclaredtextalign="center"
                                >
                                    {date}
                                </StyledText>
                            </StyledDataElement>
                            <StyledDataElement>
                                <StyledText
                                    hasdeclaredfontsize="13px"
                                    hasdeclaredfontweight="600"
                                    hasdeclaredtextalign="center"
                                >
                                    {hours_count}
                                </StyledText>
                            </StyledDataElement>
                            <StyledDataElement>
                                <StyledText
                                    hasdeclaredfontsize="13px"
                                    hasdeclaredfontweight="600"
                                    hasdeclaredtextalign="center"
                                >
                                    {trainer}
                                </StyledText>
                            </StyledDataElement>
                            <StyledDataElement>
                                <StyledText
                                    hasdeclaredfontsize="13px"
                                    hasdeclaredfontweight="600"
                                    hasdeclaredtextalign="center"
                                >
                                    {price}
                                </StyledText>
                            </StyledDataElement>
                        </StyledStudentPaymentsElement>
                            {!paid && (
                                <StyledSavePayElement
                                    style={{ transform: 'translateY(0)', minWidth: '40px', minHeight: '40px' }}
                                    onClick={handleChange}
                                    type="submit"
                                    isedit
                                >
                                    <AcceptIcon />
                                </StyledSavePayElement>
                            )}
                    </StyledScrollWrapperElement>
                </ScrollSyncPane>
            </StyledStudentPaymentsElementWrapper>
        </>
    );
};

export default StudentPaymentsElement;
