import styled from 'styled-components';
import { scrollBlueStyles } from '../../../styles/sharedStyles';

export const StyledPlayerElementWrapper = styled.div`
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    position: relative;
    min-height: 60vh;
    overflow-y: scroll;
    margin-top: 90px;
    padding-right: 10px;

    ${scrollBlueStyles};

    @media only screen and (min-width: 1441px) {
        margin-top: 54px;
    }

    @media only screen and (max-width: 767px) {
        overflow-y: visible;
        margin-top: 40px;
    }

    @media only screen and (max-height: 599px) {
        margin-top: 40px;
    }
`;
