import styled from 'styled-components';

export const StyledInputWithCheckbox = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;

    > div {
        display: flex;
    }
`;
