import styled from 'styled-components';

export const StyledTournamentTimetableContent = styled.div`
    width: 100%;
    height: 100vh;
    padding-top: 200px;
    position: relative;
    z-index: 1;

    @media only screen and (max-width: 767px) {
        height: auto;
        padding-top: 0;
    }
`;
