import styled from 'styled-components';
import { ReactComponent as Cube } from '../../../images/cubes/sq1.svg';

export const StyledCubeSq1 = styled(({ iscontactpage, ...props }) => <Cube {...props} />)`
    position: absolute;
    bottom: ${({ hasdeclaredbottom }) => (hasdeclaredbottom ? hasdeclaredbottom : '80px')};
    right: ${({ hasdecalredright }) => (hasdecalredright ? hasdecalredright : '80px')};
    transform: rotate(38deg);
    fill: rgba(0, 0, 0, 0.1);
`;
