import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function ScrollToTop() {
    const { pathname } = useLocation(),
        zabawa = pathname.split('/').find((part) => part);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [zabawa]);

    return null;
}
