import React, { useCallback, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';

import { StyledText } from '../../atoms/Text/StyledText';
import { StyledUserPanelAllEvents } from './UserPanelAllEvents.styles';
import {
    StyledGlobalHeadingWrapper,
    StyledGlobalScrollWrapper,
    StyledLoaderWrapper,
} from '../../../styles/sharedStyles';
import UserPanelHeadingText from '../../atoms/UserPanel/UserPanelHeadingText';
import useScrollBar from '../../../logic/hooks/useScrollBar';
import Loader from '../Loader/Loader';
import UserPanelTournament from '../UserPanelTournament/UserPanelTournament';
import { getEvents } from '../../../logic/requests/events.js';
import { getString } from '../../../strings';

const UserPanelAllEvents = ({ user }) => {
    const { scrollElement, isScrollBar } = useScrollBar();

    const [allEvents, setAllEvents] = useState();
    const [retAllEvents, setRetAllEvents] = useState();
    const [retOverEvents, setRetOverEvents] = useState();
    const [hasMore, setHasMore] = useState(true);
    const [isError, setIsError] = useState({ message: null });

    const loadAllEvents = useCallback(
        () =>
            getEvents({ cursorId: allEvents ? allEvents[allEvents.length - 1].id : 0 })
                .then((res) => {
                    setAllEvents((events) => [
                        ...(events ?? []),
                        ...(Array.isArray(res) ? res : []),
                    ]);
                    setIsError({ message: null });
                    if (!(Array.isArray(res) && res.length)) setHasMore(false);
                })
                .catch(() => {
                    setIsError({ message: 'Błąd podczas ładowania zawodów' });
                    setHasMore(false);
                }),
        [allEvents]
    );

    useEffect(() => {
        if (!allEvents) {
            return;
        }

        const retEvents = allEvents?.filter((event) => event.status !== 'over');
        const retOverEvents = allEvents?.filter((event) => event.status === 'over');

        setRetAllEvents(retEvents);
        setRetOverEvents(retOverEvents);
    }, [allEvents]);

    useEffect(() => {
        loadAllEvents();
    }, []);

    return (
        <StyledUserPanelAllEvents>
            {!isError.message ? (
                <>
                    <StyledGlobalHeadingWrapper>
                        <UserPanelHeadingText
                            text={`${getString('User_panel_all_events_wszystkie_zawody')}:`}
                        />
                    </StyledGlobalHeadingWrapper>
                    <StyledGlobalScrollWrapper isscrollbar={isScrollBar} ref={scrollElement}>
                        {!allEvents ? (
                            <StyledLoaderWrapper>
                                <Loader />
                            </StyledLoaderWrapper>
                        ) : (
                            <InfiniteScroll
                                loadMore={loadAllEvents}
                                hasMore={hasMore}
                                initialLoad={false}
                                getScrollParent={() => scrollElement.current}
                                useWindow={!isScrollBar}
                            >
                                {retAllEvents?.map((event) => (
                                    <UserPanelTournament
                                        key={event.id}
                                        event={event}
                                        user={user}
                                        allUsers
                                    />
                                ))}
                                <StyledText
                                    hasdeclaredtexttransform="uppercase"
                                    hasdeclaredfontsize="54px"
                                    hasdeclaredpadding={
                                        retAllEvents?.length
                                            ? '32px 20px 32px 20px'
                                            : '0 20px 32px 20px'
                                    }
                                    hasdeclaredfontweight="700"
                                    hasdeclaredtextalign="center"
                                >
                                    {getString('pages_userPanel_minione')}
                                </StyledText>
                                {retOverEvents?.map((event) => (
                                    <UserPanelTournament
                                        key={event.id}
                                        event={event}
                                        user={user}
                                        allUsers
                                    />
                                ))}
                            </InfiniteScroll>
                        )}
                    </StyledGlobalScrollWrapper>
                </>
            ) : (
                <StyledLoaderWrapper>
                    <StyledText
                        hasdeclaredtexttransform="uppercase"
                        hasdeclaredpadding="0 20px 0 20px"
                        hasdeclaredfontsize="46px"
                        hasdeclaredfontweight="700"
                        hasdeclaredtextalign="center"
                        hasdeclaredmaxwidth="600px"
                        hasdeclaredlineheight="1.4em"
                        as="h2"
                    >
                        {isError.message}
                    </StyledText>
                </StyledLoaderWrapper>
            )}
        </StyledUserPanelAllEvents>
    );
};

export default UserPanelAllEvents;
