import styled from 'styled-components';

export const StyledTitleCell = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    @media only screen and (max-width: 1179px) {
        > p {
            font-size: 12px;
        }
    }

    @media only screen and (max-width: 1017px) {
        > p {
            font-size: 10px;
        }
    }

    @media only screen and (max-width: 871px) {
        > p {
            font-size: 8px;
        }
    }

    @media only screen and (max-width: 767px) {
        > p {
            font-size: 12px;
        }
    }

    @media only screen and (max-width: 600px) {
        > p {
            font-size: 14px;
        }
    }

    @media only screen and (max-width: 519px) {
        > p {
            font-size: 12px;
        }
    }

    @media only screen and (max-width: 411px) {
        > p {
            font-size: 10px;
        }
    }
`;
