import styled from 'styled-components';
import { ReactComponent as Cube } from '../../../images/cubes/minx.svg';

export const StyledCubeMinx = styled(({ iscontactpage, ...props }) => <Cube {...props} />)`
    position: absolute;
    right: ${({ hasdeclaredright }) => (hasdeclaredright ? hasdeclaredright : '240px')};
    top: ${({ hasdeclaredtop }) => (hasdeclaredtop ? hasdeclaredtop : '140px')};
    transform: rotate(24deg);
    fill: rgba(0, 0, 0, 0.1);
`;
