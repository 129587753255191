import styled from 'styled-components';

export const StyledNumberWrapper = styled.div`
    width: 6%;
    min-width: 60px;
    margin-right: 2%;
    background: ${({ iscolored }) => (iscolored ? '#6786be' : 'transparent')};
    border: ${({ iscolored }) => (iscolored ? '3px solid #000' : 'none')};
    border-radius: ${({ iscolored }) => (iscolored ? '15px' : 'none')};
    min-height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: 1100px) {
        > p {
            font-size: 15px;
        }
    }
`;
