import styled from 'styled-components';
import { motion } from 'framer-motion';

export const StyledRegisterImage = styled(motion.div)`
    width: 100%;
    text-align: center;

    svg {
        width: 60%;
        height: 100%;
        margin-top: 5px;
    }

    @media only screen and (max-width: 1630px) {
        svg {
            width: 90%;
        }
    }
`;
