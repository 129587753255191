import styled from 'styled-components';

export const StyledContent = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;

    @media only screen and (max-width: 926px) {
        padding: 0 20px;
    }
`;
