import styled from 'styled-components';
import { ReactComponent as Cube } from '../../../images/cubes/minx.svg';

export const StyledCubeMinx = styled(({ iscontactpage, ...props }) => <Cube {...props} />)`
    position: absolute;
    left: 20px;
    top: 499px;
    transform: rotate(24deg);
    width: 200px;
    height: 200px;
    fill: rgba(0, 0, 0, 0.1);
`;
