import styled from 'styled-components';

export const StyledNumberMobileWrapper = styled.div`
    width: 65px;
    margin-right: 12px;
    background: #6786be;
    border: 2px solid #000;
    border-radius: 25px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
