import styled from 'styled-components';

export const StyledSelects = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;

    @media only screen and (max-width: 600px) {
        flex-direction: column;
    }
`;
