import styled from 'styled-components';
import bgShape from '../../../images/tournaments/blueShape.svg';

export const StyledRightShape = styled.div`
    width: 46%;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    background: url(${bgShape}) no-repeat 170px;
    background-size: cover;

    @media only screen and (max-width: 1621px) {
        background-position: 60px 0;
    }

    @media only screen and (max-width: 1439px) {
        background-position: 70px 0;
    }

    @media only screen and (max-width: 1174px) {
        background-size: cover;
        background-position: 40px 0;
    }

    @media only screen and (max-width: 990px) {
        background-position: 10px 0;
    }

    @media only screen and (max-width: 926px) {
        background: transparent;
    }
`;
