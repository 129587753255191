import styled from 'styled-components';

export const StyledSponsorsWrapper = styled.div`
    margin: 20px auto 40px auto;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    max-width: 1000px;

    > img {
        margin-top: 20px;
        max-width: 28%;
        object-fit: contain;

        &:last-child {
            max-width: 40%;
        }
    }
`;
