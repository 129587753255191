import styled from 'styled-components';
import { motion } from 'framer-motion';

export const StyledButton = styled(motion.button)`
    font-size: ${({ hasdeclaredfontsize }) => (hasdeclaredfontsize ? hasdeclaredfontsize : '16px')};
    font-weight: ${({ hasdeclaredfontweight }) =>
        hasdeclaredfontweight ? hasdeclaredfontweight : 'normal'};
    color: ${({ hasdeclaredfontcolor }) =>
        hasdeclaredfontcolor ? hasdeclaredfontcolor : 'var(--black)'};
    font-family: ${({ hasdeclaredfontfamily }) =>
        hasdeclaredfontfamily ? hasdeclaredfontfamily : 'Poppins'};
    text-decoration: ${({ hasdeclaredtextdecoration }) =>
        hasdeclaredtextdecoration ? hasdeclaredtextdecoration : 'none'};
    display: ${({ hasdeclareddisplay }) =>
        hasdeclareddisplay ? hasdeclareddisplay : 'inline-block'};
    text-transform: ${({ hasdeclaredtexttransform }) =>
        hasdeclaredtexttransform ? hasdeclaredtexttransform : 'none'};
    transform: ${({ hasdeclaredtransform }) =>
        hasdeclaredtransform ? hasdeclaredtransform : 'translate(0,0)'};
    text-align: ${({ hasdeclaredtextalign }) =>
        hasdeclaredtextalign ? hasdeclaredtextalign : 'left'};
    padding: ${({ hasdeclaredpadding }) => (hasdeclaredpadding ? hasdeclaredpadding : '0')};
    margin: ${({ hasdeclaredmargin }) => (hasdeclaredmargin ? hasdeclaredmargin : '0')};
    max-width: ${({ hasdeclaredmaxwidth }) => (hasdeclaredmaxwidth ? hasdeclaredmaxwidth : 'none')};
    width: ${({ hasdeclaredwidth }) => (hasdeclaredwidth ? hasdeclaredwidth : 'auto')};
    height: ${({ hasdeclaredheight }) => (hasdeclaredheight ? hasdeclaredheight : 'auto')};
    background-color: ${({ hasdeclaredbgcolor }) =>
        hasdeclaredbgcolor ? hasdeclaredbgcolor : 'transparent'};
    cursor: ${({ hasdeclaredcursor }) => (hasdeclaredcursor ? hasdeclaredcursor : 'default')};
    border: ${({ hasdeclaredborder }) => (hasdeclaredborder ? hasdeclaredborder : 'none')};
    border-radius: ${({ hasdeclaredborderradius }) =>
        hasdeclaredborderradius ? hasdeclaredborderradius : 'none'};

    &.hover-red-effect {
        transition: background-color 250ms linear;

        &:hover {
            background-color: #592e28;
        }
    }
`;
