import React, { useRef, useState } from 'react';
import { getString } from '../../strings';
import LogoLink from '../organisms/Logo/LogoLink';
import LoginLink from '../organisms/LoginLink/LoginLink';
import RegisterCube from '../molecules/RegisterCube/RegisterCube';
import HelmetTemplate from '../molecules/HelmetTemplate/HelmetTemplate';

import { StyledThankYouPage } from '../../components/atoms/ThankYouPage/StyledThankYouPage';
import { StyledText } from '../../components/atoms/Text/StyledText';
import { StyledButton } from '../atoms/Button/StyledButton';
import { StyledEmailInput } from '../atoms/ThankYouPage/StyledEmailInput';
import { StyledContentWrapper } from '../atoms/ThankYouPage/StyledContentWrapper';
import { requestPasswordLink } from '../../logic/requests/users';

const ForgottenPassword = () => {
    const [isReset, setIsReset] = useState(false);
    const [isResetTimeout, setIsResetTimeout] = useState();
    const [err, setErr] = useState();
    const [errTimeout, setErrTimeout] = useState();
    const emailField = useRef();

    const handleSubmit = (event) => {
        event.preventDefault();

        setErr(null);

        const email = emailField?.current?.value;
        if (!email) {
            setErr(getString('pages_forgottenPassword_wpiszSwojEmail'));
            return;
        }

        requestPasswordLink(encodeURIComponent(email))
            .then((res) => {
                if (isResetTimeout) clearTimeout(isResetTimeout);

                setIsReset(true);
                setIsResetTimeout(
                    setTimeout(() => {
                        setIsReset(false);
                        setIsResetTimeout(null);
                    }, 3000)
                );
            })
            .catch((err) => {
                if (errTimeout) clearTimeout(errTimeout);

                setErr('Błąd');
                setErrTimeout(
                    setTimeout(() => {
                        setErr(null);
                        setErrTimeout(null);
                    }, 3000)
                );
            });
    };

    return (
        <>
            <HelmetTemplate
                title={getString('seo_forgotten_password_title2')}
                desc={getString('seo_forgotten_password_desc2')}
                ogTitle={getString('soe_forgotten_password_twitter_title2')}
            />
            <StyledThankYouPage
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
            >
                <RegisterCube registerCubeFill="rgba(0,0,0,0.1)" />
                <LogoLink />
                <LoginLink />
                <StyledContentWrapper>
                    <StyledText
                        hasdeclaredfontsize="52px"
                        hasdeclaredfontweight="bold"
                        hasdeclaredmargin="0 0 38px 0"
                        hasdeclaredtextalign="center"
                        hasdeclaredpadding="0 20px"
                        as="h1"
                    >
                        {getString('pages_forgottenPassword_zapomnialesHasla')}
                    </StyledText>
                    <StyledText
                        hasdeclaredtextalign="center"
                        hasdeclaredpadding="0 20px 40px 20px"
                        hasdeclaredfontsize="22px"
                        hasdeclaredmaxwidth="500px"
                        hasdeclaredlineheight="1.3em"
                    >
                        {getString('pages_forgottenPassword_wpiszSwojAdresEmailAbyMoc')}
                    </StyledText>
                    <form onSubmit={handleSubmit}>
                        <StyledEmailInput type="email" name="email" ref={emailField} />
                        {err ? (
                            <StyledText
                                hasdeclaredfontsize="18px"
                                hasdeclaredfontweight="700"
                                hasdeclaredmargin="0 0 22px 0"
                            >
                                {err}
                            </StyledText>
                        ) : (
                            <></>
                        )}
                        <StyledButton
                            hasdeclaredbgcolor="#6786be"
                            hasdeclaredpadding="12px 44px"
                            hasdeclaredfontcolor="#fff"
                            hasdeclaredfontweight="bold"
                            hasdeclaredborderradius="40px"
                            hasdeclaredfontsize="22px"
                            hasdeclaredcursor="pointer"
                            whileHover={{ scale: 0.95 }}
                        >
                            {getString('pages_forgottenPassword_resetuj')}
                        </StyledButton>
                    </form>
                    {isReset ? (
                        <StyledText
                            hasdeclaredmargin="32px 0 0 0"
                            hasdeclaredfontsize="24px"
                            hasdeclaredfontweight="600"
                            hasdeclaredfontcolor="#6786BE"
                        >
                            {getString('pages_forgottenPassword_emailZostalWyslany')}
                        </StyledText>
                    ) : (
                        <></>
                    )}
                </StyledContentWrapper>
            </StyledThankYouPage>
        </>
    );
};

export default ForgottenPassword;
