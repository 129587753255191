import styled from 'styled-components';

export const StyledUserPanelReqElement = styled.div`
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    width: 84%;
    margin-bottom: 60px;

    @media only screen and (max-width: 1334px) {
        width: 80%;
    }

    @media only screen and (max-width: 1186px) {
        width: 95% !important;
    }
`;

export const StyledDataWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;

    @media only screen and (max-width: 934px) {
        flex-direction: column;
        align-items: center;
    }
`;

export const StyledLogoWrapper = styled.div`
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    > svg {
        width: 30%;
        height: 30%;
        object-fit: contain;
    }

    @media only screen and (max-width: 926px) {
        width: 100%;
        display: flex;
        justify-content: center;

        > svg {
            height: 30%;
            max-height: 120px;
        }
    }
`;

export const StyledTextContent = styled.div`
    width: 70%;

    > div {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 7px;

        > a {
            text-decoration: none;
            cursor: pointer;
        }

        > p {
            a {
                cursor: pointer;
                text-decoration: none;
            }
        }
    }

    @media only screen and (max-width: 1240px) {
        > div {
            > p {
                font-size: 16px;
            }
        }
    }

    @media only screen and (max-width: 1186px) {
        width: 100%;
        margin-top: 10px;
    }
`;

export const StyledContent = styled.div`
    width: 100%;
    border-radius: 40px;
    background-color: #fff;
    border: 10px solid #6786be;
    padding: 16px;
    box-sizing: border-box;

    @media only screen and (max-width: 926px) {
        h2 {
            text-align: center;
            font-size: 32px;
        }
    }

    @media only screen and (max-width: 500px) {
        h2 {
            text-align: center;
            font-size: 26px;
        }
    }
`;

export const StyledButtonsWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: ${({ center }) => (center ? 'center' : 'space-between')};
    margin-top: ${({ ismgtop }) => (ismgtop ? '0' : '20px')};

    > button {
        width: 48%;
        height: 50px;
    }

    @media only screen and (max-width: 1232px) {
        > button {
            font-size: 16px;
        }
    }

    @media only screen and (max-width: 520px) {
        > button {
            font-size: 12px;
        }
    }
`;

export const StyledButtonWrapper = styled.div`
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;

    > button {
        width: 48%;
        height: 50px;
    }

    > a {
        width: 100%;
        text-decoration: none;
        display: flex;
        justify-content: flex-end;

        button {
            width: 48%;
            height: 50px;
        }
    }

    @media only screen and (max-width: 1232px) {
        > a {
            > button {
                font-size: 16px;
            }
        }

        > button {
            font-size: 16px;
        }
    }

    @media only screen and (max-width: 534px) {
        > a {
            > button {
                font-size: 12px;
            }
        }

        > button {
            font-size: 12px;
        }
    }
`;
