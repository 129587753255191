import React from 'react';
import { useLocation } from 'react-router';

import HelmetTemplate from '../molecules/HelmetTemplate/HelmetTemplate';
import { StyledPage } from '../atoms/Styled404/StyledPage';
import { StyledText } from '../atoms/Text/StyledText';
import { getString } from '../../strings';

const Page404 = () => {
    const location = useLocation();

    const state = location.state,
        messages = state ? state.messages : ['Page not found'];

    return (
        <>
            <HelmetTemplate title={getString('seo_404_title')} desc={getString('seo_404_desc')} />
            <StyledPage>
                {messages.map((message) => (
                    <StyledText hasdeclaredfontsize="48px">{message}</StyledText>
                ))}
            </StyledPage>
        </>
    );
};

export default Page404;
