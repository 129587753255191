import styled from "styled-components"

export const StyledAnnouncementElement = styled.div`
    width: 100%;
    margin-bottom: 20px;
    border-radius: 25px 9px 9px 25px;
    min-height: 43px;
    border: 4px solid ${({ hasdeclaredbordercolor }) => hasdeclaredbordercolor ? "#ee745c": "#6786BE"};
    background-color: #fff;
    box-sizing: border-box;
    padding-bottom: ${({ isopen }) => isopen ? "20px" : "0"};
    position: relative;
`

export const StyledHeading = styled.div`
    width: 100%;
    display: flex;
    align-items: center;    
    min-height: 43px;
    cursor: pointer;

    > p {
        width: calc(100% - 50px);
    }

    * {
        cursor: pointer;
        user-select: none;
    }

    @media only screen and (max-width: 425px){
        > p {
            font-size: 18px;
        }
    }
`

export const StyledHeadingCheckbox = styled.div`
    width: 50px;
    height: 50px;
    background-color: ${({ hasdeclaredbgcolor }) => hasdeclaredbgcolor ? "#ee745c" : "#6786BE"};
    border-radius: ${({ $isOpen }) => $isOpen ? "0px 9px 0px 9px" : "0px 9px 9px 0px"};
    cursor: pointer;
    transform: translateX(4px);
    display: flex;
    align-items: center;
    justify-content: center;
    
    i {
        margin-left: 4px;
    }
`

export const StyledElement = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
    padding-right: 10px;
    padding-left: 10px;
    width: 100%;
`

export const StyledTextWrapper = styled.div`
    font-family: Poppins;
    margin-top: 20px;
    text-align: justify;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 160px;

    @media only screen and (max-width: 926px) {
        padding-bottom: 0;
    }
`

export const StyledButtonsContainer = styled.div`
    display: flex;
    justify-content: center;

    @media only screen and (max-width: 630px){
        align-items: center;
        flex-direction: column;
    }

    @media only screen and (min-width: 631px){
        gap: 20px;
    }
`;