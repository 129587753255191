import styled from 'styled-components';
import { motion } from 'framer-motion';

export const StyledTournamentPage = styled(motion.section)`
    width: 100%;
    min-height: 100vh;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    background: #f9ec4b;
    overflow: hidden;

    @media only screen and (max-width: 767px) {
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
`;
