import backendRequest from "./index.js";

const getSeries = ({ cursorId } = { cursorId: 0 }) =>
    backendRequest("GET", `/series/list?cursor_id=${cursorId}`);

const getAllResultsForSeries = (seriesId) =>
    backendRequest("GET", `/series/get-results/series/${seriesId}`);

const getAllResultsForEvent = (eventId) =>
    backendRequest("GET", `/series/get-results/event/${eventId}`);

export { getSeries, getAllResultsForSeries, getAllResultsForEvent };
