import styled from 'styled-components';
import { scrollBlueStyles } from '../../../styles/sharedStyles';

export const StyledContent = styled.div`
    width: 1060px;
    height: calc(100vh - 200px);
    overflow-y: scroll;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 10px 100px;
    margin-bottom: 10px;

    ${scrollBlueStyles};

    @media only screen and (max-width: 1064px) {
        overflow-y: visible;
        width: 90%;
        height: auto;

        > h2 {
            font-size: 36px;
        }
    }

    @media only screen and (max-width: 725px) {
        > div {
            > h2 {
                font-size: 50px;
            }
        }
    }

    @media only screen and (max-width: 365px) {
        > div {
            > h2 {
                font-size: 38px;
            }
        }
    }
`;
