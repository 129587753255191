import React, { useMemo, useState, useCallback } from 'react';
import { ScrollSyncPane } from 'react-scroll-sync';
import Decimal from 'decimal.js';

import { StyledEmployeeDataCostElement } from './EmployeeDataCostElement.styles';
import { StyledEditInputElement } from '../../../styles/sharedStyles';
import { StyledText } from '../../atoms/Text/StyledText';
import { StyledSavePayElement } from '../../atoms/PayElement/StyledSavePayElement';
import { StyledGlobalUserPanelWrapper } from '../../atoms/StyledGlobalUserPanelWrapper/StyledGlobalUserPanelWrapper';
import { StyledEditWrapper } from '../../atoms/StyledEditWrapper/StyledEditWrapper';
import { StyledTextElement } from '../../atoms/StyledTextElement/StyledTextElement';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { formSchema } from './EmployeeDataCostElement.data';

import { StyledFuckingBtn } from '../AdditionDataElement/AdditionDataElement.styles';

import PencilIcon from '../../atoms/UserPanelStudens/PencilIcon';
import AcceptIcon from '../../atoms/UserPanelStudens/AcceptIcon';
import { getString } from '../../../strings';
import { getDateString } from '../../../logic/eventtime';
import { useQueryClient } from '@tanstack/react-query';
import { editTrainer } from '../../../logic/requests/employees';

const emptyValue = '...';

const EmployeeDataCostElement = ({ price, bonus_hours, bonus_amount, contract_type, trainer }) => {
    const [isEdit, setIsEdit] = useState(false);
    const queryClient = useQueryClient();

    const {
        register,
        handleSubmit,
        formState: { errors, isDirty },
        reset,
    } = useForm({
        resolver: yupResolver(formSchema),
        defaultValues: {
            price,
            bonus_hours,
            bonus_amount,
            contract_type,
        },
    });

    const displayPrice = useMemo(() => {
        if (!price) return emptyValue;

        const parsed = new Decimal(price);

        return parsed.dp() > 0 ? parsed.toFixed(2) : parsed.toString();
    }, [price]);

    const displayBonusAmount = useMemo(() => {
        if (!bonus_amount) return emptyValue;

        const parsed = new Decimal(bonus_amount);

        return parsed.dp() > 0 ? parsed.toFixed(2) : parsed.toString();
    }, [bonus_amount]);

    const onSubmit = useCallback(
        (dataForm) => {
            if (!isDirty) setIsEdit(false);

            editTrainer(trainer.id, {
                bonusAmount: dataForm.bonus_amount.replace(',', '.'),
                bonusHours: dataForm.bonus_hours,
                price: dataForm.price.replace(',', '.'),
            }).then(() => {
                queryClient.invalidateQueries({
                    queryKey: ['trainers', 'all', 'employeeConf'],
                });
                reset();
                setIsEdit(false);
            });
        },
        [isDirty, queryClient, reset, trainer.id]
    );

    const retEditBtn = useMemo(
        () =>
            isEdit ? (
                <StyledSavePayElement type="submit" isedit={isEdit}>
                    <AcceptIcon />
                </StyledSavePayElement>
            ) : (
                <StyledFuckingBtn
                    as="button"
                    type="button"
                    isedit={isEdit}
                    onClick={() => setIsEdit(!isEdit)}
                >
                    <PencilIcon />
                </StyledFuckingBtn>
            ),
        [isEdit]
    );

    return (
        <StyledEmployeeDataCostElement onSubmit={handleSubmit(onSubmit)}>
            <ScrollSyncPane>
                <div>
                    {isEdit ? (
                        <StyledEditWrapper>
                            <StyledEditInputElement error={errors.price} $hasDeclaredWidth="25%">
                                <input type="text" {...register('price')} />
                            </StyledEditInputElement>
                            <StyledEditInputElement
                                error={errors.bonus_amount}
                                $hasDeclaredWidth="25%"
                            >
                                <input type="text" {...register('bonus_amount')} />
                            </StyledEditInputElement>
                            <StyledEditInputElement
                                error={errors.bonus_hours}
                                $hasDeclaredWidth="25%"
                            >
                                <input type="text" {...register('bonus_hours')} />
                            </StyledEditInputElement>
                            <StyledTextElement>
                                <StyledText
                                    hasdeclaredfontsize="13px"
                                    hasdeclaredfontweight="600"
                                    hasdeclaredtextalign="center"
                                >
                                    {contract_type ?? emptyValue}
                                </StyledText>
                            </StyledTextElement>
                        </StyledEditWrapper>
                    ) : (
                        <StyledGlobalUserPanelWrapper>
                            <StyledTextElement>
                                <StyledText
                                    hasdeclaredfontsize="13px"
                                    hasdeclaredfontweight="600"
                                    hasdeclaredtextalign="center"
                                >
                                    {displayPrice}
                                </StyledText>
                            </StyledTextElement>
                            <StyledTextElement>
                                <StyledText
                                    hasdeclaredfontsize="13px"
                                    hasdeclaredfontweight="600"
                                    hasdeclaredtextalign="center"
                                >
                                    {displayBonusAmount}
                                </StyledText>
                            </StyledTextElement>
                            <StyledTextElement>
                                <StyledText
                                    hasdeclaredfontsize="13px"
                                    hasdeclaredfontweight="600"
                                    hasdeclaredtextalign="center"
                                >
                                    {bonus_hours ?? emptyValue}
                                </StyledText>
                            </StyledTextElement>
                            <StyledTextElement>
                                <StyledText
                                    hasdeclaredfontsize="13px"
                                    hasdeclaredfontweight="600"
                                    hasdeclaredtextalign="center"
                                >
                                    {contract_type ?? emptyValue}
                                </StyledText>
                            </StyledTextElement>
                        </StyledGlobalUserPanelWrapper>
                    )}
                </div>
            </ScrollSyncPane>
            {retEditBtn}
        </StyledEmployeeDataCostElement>
    );
};

export default EmployeeDataCostElement;
