import styled from 'styled-components';

export const StyledTextWrapper = styled.div`
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;

    overflow-y: scroll;
    margin-top: 120px;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        background: transparent;
        width: 0;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
        width: 0;
    }

    &::-webkit-scrollbar-thumb {
        background: transparent;
        width: 0;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: transparent;
        width: 0;
    }

    @media only screen and (min-width: 1441px) {
        margin-top: 54px;
    }

    @media only screen and (max-width: 767px) {
        overflow-y: visible;
        margin-top: 40px;
    }
`;
