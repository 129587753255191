import { StyledText } from '../../../atoms/Text/StyledText';
import { StyledInputWrapper } from '../../../../styles/sharedStyles';
import { getString } from '../../../../strings';
import { Field, Formik } from 'formik';
import { useMemo, useState } from 'react';
import * as yup from 'yup';
import { StyledInputsButtonsWrapper } from '../../../atoms/TrackHours/StyledInputsButtonsWrapper';
import { StyledUserPanelAnnouncementsElement } from '../../../atoms/UserPanelAnnouncements/StyledUserPanelAnnouncementsElement';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { ReactComponent as CheckboxAccept } from '../../../../images/checkboxAccept.svg';
import {
    StyledAnnouncementFormRoles,
    StyledCheckboxWrapperForRoles,
    StyledPlatformsWrapper,
} from './StyledAnnouncementForm';
import './styles.css';
import { StyledCheckboxDone } from '../../../atoms/SettlementsDoneElement/StyledCheckboxDone';
import DiscordIcon from './DiscordIcon';
import EmailIcon from './EmailIcon';

const AnnouncementForm = ({ id = 'new', initialValues, onSubmit, onReset, roles = [] }) => {
    const schema = useMemo(
        () =>
            yup.object({
                title: yup
                    .string()
                    .required(getString('User_panel_announcements_title_is_required')),
            }),
        []
    );

    const [convertedText, setConvertedText] = useState(initialValues.text);
    const [selectedRoles, setSelectedRoles] = useState(initialValues.roles || []);
    const [sendMail, setSendMail] = useState(false);
    const [sendDiscord, setSendDiscord] = useState(false);

    const handleCheckboxChange = (role) => {
        setSelectedRoles((prev) => {
            if (prev.includes(role)) {
                return prev.filter((r) => r !== role);
            } else {
                return [...prev, role];
            }
        });
    };

    const handleSubmit = (values, actions) => {
        values = {
            ...values,
            text: convertedText,
            roles: selectedRoles,
            sendMail,
            sendDiscord,
        };
        onSubmit(values, actions);
    };

    const handleReset = (values) => {
        onReset && onReset(values);
        setConvertedText('');
        setSelectedRoles([]);
        setSendMail(false);
        setSendDiscord(false);
    };

    const rolesCheckboxes = useMemo(
        () => (
            roles.map((role) => (
                <StyledCheckboxWrapperForRoles key={`${role}-${Math.random()}`}>
                    <StyledCheckboxDone>
                        <input
                            id={`${role}-${id}`}
                            type="checkbox"
                            checked={selectedRoles?.includes(role)}
                            onChange={() => handleCheckboxChange(role)}
                        />
                        <label htmlFor={`${role}-${id}`}>
                            <CheckboxAccept />
                        </label>
                    </StyledCheckboxDone>
                    <StyledText
                        hasdeclaredfontweight="600"
                        hasdeclaredlineheight="1.4em"
                    >
                        {getString(`roles_${role}`)}
                    </StyledText>
                </StyledCheckboxWrapperForRoles>
            ))
        ), [id, roles, selectedRoles]);

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={handleSubmit}
            onReset={handleReset}
            validateOnChange={false}
            validateOnBlur={false}
        >
            {({ errors }) => (
                <StyledUserPanelAnnouncementsElement>
                    <StyledInputWrapper hasdeclaredwidth="100%" error={errors.title}>
                        <label htmlFor="title">
                            <StyledText
                                hasdeclaredfontsize="20px"
                                hasdeclaredtextalign="center"
                                hasdeclaredpadding="8px 0 8px 0"
                                hasdeclaredfontweight="600"
                            >
                                {getString('User_panel_announcements_title')}
                            </StyledText>
                        </label>
                        <Field as="input" type="text" name="title" />
                        <StyledText
                            as="h4"
                            hasdeclaredfontsize="16px"
                            hasdeclaredfontcolor="#F74017"
                            hasdeclaredtextalign="center"
                            hasdeclaredfontweight="700"
                            hasdeclaredtexttransform="uppercase"
                            hasdeclaredlineheight="1.4em"
                        >
                            {errors.title}
                        </StyledText>
                    </StyledInputWrapper>
                    <StyledInputWrapper hasdeclaredwidth="100%" error={errors.text}>
                        <label htmlFor="text">
                            <StyledText
                                hasdeclaredfontsize="20px"
                                hasdeclaredtextalign="center"
                                hasdeclaredpadding="8px 0 8px 0"
                                hasdeclaredfontweight="600"
                            >
                                {getString('User_panel_announcements_text')}
                            </StyledText>
                        </label>
                        <ReactQuill
                            theme="snow"
                            value={convertedText}
                            onChange={setConvertedText}
                            style={{
                                backgroundColor: 'white',
                                borderColor: '#6786be',
                                borderStyle: 'solid',
                                borderWidth: '4px',
                                borderRadius: '25px',
                            }}
                        />
                        <input type="hidden" name="text" value={convertedText} />
                        <StyledText
                            as="h4"
                            hasdeclaredfontsize="16px"
                            hasdeclaredfontcolor="#F74017"
                            hasdeclaredtextalign="center"
                            hasdeclaredfontweight="700"
                            hasdeclaredtexttransform="uppercase"
                            hasdeclaredlineheight="1.4em"
                        >
                            {errors.text}
                        </StyledText>
                    </StyledInputWrapper>
                    {roles ? (
                        <StyledInputWrapper hasdeclaredwidth="100%">
                            <StyledText
                                hasdeclaredfontsize="20px"
                                hasdeclaredtextalign="center"
                                hasdeclaredpadding="8px 0 8px 0"
                                hasdeclaredfontweight="600"
                            >
                                {getString('User_panel_announcements_roles')}
                            </StyledText>
                            <StyledAnnouncementFormRoles>
                               {rolesCheckboxes}
                            </StyledAnnouncementFormRoles>
                        </StyledInputWrapper>
                    ) : null}
                    <StyledText
                        hasdeclaredfontsize="20px"
                        hasdeclaredtextalign="center"
                        hasdeclaredpadding="8px 0 8px 0"
                        hasdeclaredfontweight="600"
                    >
                        {getString('User_panel_announcements_platforms')}
                    </StyledText>
                    <StyledPlatformsWrapper>
                        <div onClick={() => setSendDiscord((prev) => !prev)}>
                            <DiscordIcon selected={sendDiscord} />
                        </div>
                        <div onClick={() => setSendMail((prev) => !prev)}>
                            <EmailIcon selected={sendMail} />
                        </div>
                    </StyledPlatformsWrapper>
                    <StyledInputsButtonsWrapper>
                        <input type="reset" value={getString('User_panel_addition_anuluj')} />
                        <input type="submit" value={getString('User_panel_addition_zapisz')} />
                    </StyledInputsButtonsWrapper>
                </StyledUserPanelAnnouncementsElement>
            )}
        </Formik>
    );
};

export default AnnouncementForm;
