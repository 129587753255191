import styled from 'styled-components';

export const StyledMobileWrapper = styled.div`
    display: flex;
    gap: 20px;

    @media only screen and (max-width: 600px) {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`

export const StyledCheckboxWrapper = styled.div`
    width: 50%;
    display: flex;
    justify-content: space-between;
    gap: 10px;

    @media only screen and (max-width: 600px) {
        width: 100%
    }
`

export const StyledInputsBox = styled.div`
    width: 50%;
     
    @media only screen and (max-width: 600px) {
        width: 100%
    }
`