import styled from 'styled-components';
import { motion } from 'framer-motion';

export const StyledAvatarInformation = styled(motion.div)`
    position: absolute;
    top: 25px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    background-color: #fff;
    width: 375px;
    height: 400px;
    z-index: 4;
    border: 6px solid #3474e8;
    border-radius: 25px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 25px;

    p {
        line-height: 1.2em;
        margin-top: 8px;
    }

    button {
        -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
    }

    @media only screen and (max-width: 751px) {
        top: 150px;
    }

    @media only screen and (max-width: 340px) {
        width: 230px;
    }
`;
