import React from 'react';

import UserPanelSubjectElement from '../UserPanelSubjectElement/UserPanelSubjectElement';
import UserPanelSubjectDataElement from '../../molecules/UserPanelSubjectDataElement/UserPanelSubjectDataElement';
import UserPanelHeadingText from '../../atoms/UserPanel/UserPanelHeadingText';

import { StyledUserPanelSubjects } from '../../atoms/UserPanelSubjects/StyledUserPanelSubjects';
import {
    StyledGlobalHeadingWrapper,
    StyledGlobalScrollWrapper,
    StyledLoaderWrapper,
} from '../../../styles/sharedStyles';
import { StyledText } from '../../atoms/Text/StyledText';

import { getString } from '../../../strings';
import useScrollBar from '../../../logic/hooks/useScrollBar';
import { listSubjects } from '../../../logic/requests/students';
import { useQuery } from '@tanstack/react-query';
import Loader from '../Loader/Loader';
import useSuccessMessage from '../../../logic/hooks/useSuccessMessage';
import SuccessMessage from '../../molecules/SuccessMessage';

const UserPanelSubjects = () => {
    const { scrollElement, isScrollBar } = useScrollBar();

    const {
        data: subjectsResponse,
        isLoading,
        isError,
    } = useQuery(['subjects', 'all'], listSubjects);

    const {
        infos: successMessageInfos,
        isShowing: isSuccessMessage,
        show: showSuccessMessage,
    } = useSuccessMessage();

    const onSuccessfulAdd = (infos) => {
        showSuccessMessage(infos);
    };

    return (
        <StyledUserPanelSubjects>
            {isSuccessMessage ? (
                <SuccessMessage
                    title={getString('UserPanelSubjects__success__title')}
                    infos={successMessageInfos}
                />
            ) : undefined}
            <StyledGlobalHeadingWrapper>
                <UserPanelHeadingText
                    text={`${getString('User_panel_subject_dodaj_przedmiot')}:`}
                />
            </StyledGlobalHeadingWrapper>
            <StyledGlobalScrollWrapper isscrollbar={isScrollBar} ref={scrollElement}>
                <UserPanelSubjectElement onSuccessfulAdd={onSuccessfulAdd} />
                <StyledText
                    hasdeclaredfontsize="22px"
                    hasdeclaredfontweight="700"
                    hasdeclaredtextalign="center"
                    hasdeclaredtexttransform="uppercase"
                    hasdeclaredmargin="clamp(30px, 4vw, 80px) 0 20px 0"
                >
                    {getString('User_panel_subject_przedmioty')}:
                </StyledText>
                {!isLoading ? (
                    subjectsResponse.map((data) => (
                        <UserPanelSubjectDataElement key={data.id} data={data} />
                    ))
                ) : !isError ? (
                    <StyledLoaderWrapper>
                        <Loader />
                    </StyledLoaderWrapper>
                ) : (
                    <StyledLoaderWrapper>
                        <StyledText
                            hasdeclaredpadding="0px 20px"
                            hasdeclaredfontsize="22px"
                            hasdeclaredfontweight="700"
                            hasdeclaredtextalign="center"
                            hasdeclaredlineheight="1.4em"
                            as="p"
                        >
                            {getString('User_panel_employess_blad_wczytywania')}
                        </StyledText>
                    </StyledLoaderWrapper>
                )}
            </StyledGlobalScrollWrapper>
        </StyledUserPanelSubjects>
    );
};

export default UserPanelSubjects;
