import styled from 'styled-components';

export const StyledEmailInput = styled.input`
    width: 260px;
    height: 40px;
    margin-bottom: 22px;
    border-radius: 25px;
    padding: 4px 16px;
    font-size: 18px;
    font-family: 'Poppins';
    border: 3px solid #6786be;
    outline: none;
`;
