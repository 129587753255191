import styled from 'styled-components';
import { scrollRedStyles } from '../../../styles/sharedStyles';

export const StyledFAQElement = styled.button`
    width: 100%;
    min-height: 50px;
    margin-top: 20px;
    border-radius: 25px;
    background-color: #602b26;
    cursor: pointer;
    border: none;

    &:hover {
        > div {
            &:first-child {
                background-color: #602b26;
            }
        }
    }
`;

export const StyledFAQAnswerText = styled.div`
    width: 100%;
`;

export const StyledFAQCategoryElement = styled.div`
    width: 100%;

    > button {
        border: none;

        &:first-child {
            padding: 0 20px;
            width: 100%;
            min-height: 50px;
            margin-top: 20px;
            border-radius: 25px;
            background-color: ${({ isopen }) => (isopen ? '#602B26' : '#ee745c')};
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            align-items: center;
            transition: background-color 250ms linear;

            &:hover {
                background-color: #602b26;
            }
        }
    }
`;

export const StyledFAQHeaderText = styled.div`
    width: 100%;
    min-height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ee745c;
    border-radius: 25px;
    padding: 2px 10px;
    transition: background-color 250ms linear;
`;

export const StyledOverflowWrapper = styled.div`
    width: 80%;
    overflow-y: scroll;
    padding: 0 20px 20px 10px;
    margin-top: 80px;
    min-height: 80%;
    max-height: 80%;

    ${scrollRedStyles};

    > div {
        &:first-child {
            margin-top: 0;
        }
    }

    @media only screen and (max-width: 1074px) {
        overflow-y: visible;
        max-height: unset;
        min-height: unset;
        width: 100%;
        margin-top: 20px;
    }
`;
