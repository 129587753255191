import React from 'react';

import { StyledMenuElement } from './StyledMenuElement';
import { StyledText } from '../../atoms/Text/StyledText';
import { StyledArrow } from '../../../styles/sharedStyles';
import { StyledSeriesList } from '../../atoms/Series/StyledSeriesList';
import { StyledIsOpenWrapper } from '../../atoms/Series/StyledIsOpenWrapper';

import { ACTIVERESULTSTYPE } from '../../constants/Series';

const SeriesList = ({ series, isMobile, activeResults, setActiveResults }) => {
    const isActiveSerie = activeResults
        ? // typ to seria i ID się zgadza
          activeResults.type === ACTIVERESULTSTYPE.SERIES
            ? series.id === activeResults.id
            : // lub typ to wydarzenie i id zgadza się z jednym z wydarzeń w serii
            activeResults.type === ACTIVERESULTSTYPE.EVENT
            ? series.events.find((event) => event.id === activeResults.id)
            : false
        : false;

    return (
        <StyledSeriesList ismobile={isMobile}>
            <StyledMenuElement
                ismobile={isMobile}
                onClick={() =>
                    setActiveResults(
                        (activeResults?.type === ACTIVERESULTSTYPE.SERIES &&
                            activeResults?.id === series.id) ||
                            (activeResults?.type === ACTIVERESULTSTYPE.EVENT &&
                                series.events.map((event) => event.id).includes(activeResults?.id))
                            ? null
                            : { type: ACTIVERESULTSTYPE.SERIES, code: series.code }
                    )
                }
                hasdeclaredbgcolor={isActiveSerie ? '#602B26' : '#ee745c'}
            >
                <StyledText
                    hasdeclaredfontsize="24px"
                    hasdeclaredfontcolor="#fff"
                    hasdeclaredfontweight="600"
                    hasdeclaredtexttransform="uppercase"
                    hasdeclaredlineheight="1.4em"
                    hasdeclaredtextalign="center"
                    hasdeclaredpadding="0 10px"
                    hascursor="pointer"
                >
                    {series.name}
                </StyledText>
                <StyledArrow isopen={isActiveSerie} />
            </StyledMenuElement>
            {isActiveSerie ? (
                <StyledIsOpenWrapper>
                    {series.events.map((event, idx) => (
                        <StyledMenuElement
                            key={idx}
                            ismobile={isMobile}
                            onClick={() =>
                                setActiveResults(
                                    activeResults?.type === ACTIVERESULTSTYPE.EVENT &&
                                        activeResults?.id === event.id
                                        ? { type: ACTIVERESULTSTYPE.SERIES, code: series.code }
                                        : { type: ACTIVERESULTSTYPE.EVENT, code: event.code }
                                )
                            }
                            hasdeclaredbgcolor={
                                activeResults &&
                                activeResults.type === ACTIVERESULTSTYPE.EVENT &&
                                activeResults.id === event.id
                                    ? '#602B26'
                                    : '#ee745c'
                            }
                        >
                            <StyledText
                                hasdeclaredfontsize="24px"
                                hasdeclaredfontcolor="#fff"
                                hasdeclaredfontweight="600"
                                hasdeclaredtexttransform="uppercase"
                                hasdeclaredlineheight="1.4em"
                                hasdeclaredtextalign="center"
                                hascursor="pointer"
                            >
                                {event.city}
                            </StyledText>
                        </StyledMenuElement>
                    ))}
                </StyledIsOpenWrapper>
            ) : null}
        </StyledSeriesList>
    );
};

export default SeriesList;
