import styled from 'styled-components';
import { motion } from 'framer-motion';
import { scrollBlueStyles } from '../../../styles/sharedStyles';

export const StyledLeftPanel = styled(motion.div)`
    width: 60%;
    margin-right: 10%;
    height: calc(100vh - 160px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 20px 10px;
    position: relative;
    z-index: 1;

    > div {
        width: 100%;
        height: 100%;
        overflow-y: ${({ hasscroll }) => (hasscroll ? 'visible' : 'scroll')};

        ${scrollBlueStyles}
    }

    @media only screen and (max-width: 1621px) {
        width: 55%;
        min-width: 55%;
    }

    @media only screen and (max-width: 1334px) {
        align-items: flex-start;
    }

    @media only screen and (max-width: 1114px) {
        width: 58%;
        min-width: 58%;
    }

    @media only screen and (max-width: 990px) {
        width: 55%;
        min-width: 55%;
    }

    @media only screen and (max-width: 926px) {
        width: 100%;
        overflow-y: visible;
        align-items: center;
        height: auto;
        margin-right: 0;
        padding: 20px 0;
    }
`;
