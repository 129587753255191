import styled from 'styled-components';

export const StyledDataElement = styled.div`
    width: 16%;
    display: flex;
    min-height: 30px;
    align-items: center;
    justify-content: center;
    padding: 0 4px;
`;
