import styled from 'styled-components';
import { motion } from 'framer-motion';
import { scrollBlueStyles } from '../../../styles/sharedStyles';

export const StyledCoach = styled(motion.div)`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justif-content: flex-start;
    max-height: 90%;
    overflow-y: scroll;

    ${scrollBlueStyles};

    > h2,
    p {
        max-width: 700px;
    }

    @media only screen and (max-width: 767px) {
        padding-bottom: 40px;
        max-height: unset;
        height: auto;
        overflow-y: visible;
    }

    @media only screen and (max-width: 400px) {
        h2 {
            font-size: 30px;
        }
    }
`;

export const StyledBgWrapper = styled.div`
    width: 100%;
    border-radius: 25px;
    border: 6px solid #6786be;
    background-color: #fff;
    padding: 12px;
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    gap: 10px;

    > div {
        width: ${({ hasavatar }) => (hasavatar ? '30%' : '0%')};
        min-width: ${({ hasavatar }) => (hasavatar ? '180px' : 'unset')};

        > div {
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border: 2px solid black;
                border-radius: 15px;
            }
        }
    }

    > p {
        display: block;
        width: ${({ hasavatar }) => (hasavatar ? '70%' : '100%')};
    }

    @media only screen and (max-width: 517px) {
        flex-direction: column;
        align-items: center;

        p {
            font-size: 18px;
            width: 100%;
            padding: 8px;
        }

        > div {
            width: 65%;
        }
    }
`;

export const StyledCoachElement = styled.div`
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 700px;
    margin-bottom: 40px;

    @media only screen and (max-width: 517px) {
        > h2 {
            font-size: 24px;
        }
    }
`;

export const StyledEmailWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;

    > p {
        a {
            color: #000;
            text-decoration: none;
            cursor: pointer;
        }
    }

    @media only screen and (max-width: 460px) {
        p {
            font-size: 15px;
        }
    }

    @media only screen and (max-width: 340px) {
        p {
            font-size: 13px;
        }
    }
`;

export const StyledPhoneWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 7px;

    > p {
        a {
            color: #000;
            text-decoration: none;
            cursor: pointer;
        }
    }

    @media only screen and (max-width: 460px) {
        p {
            font-size: 15px;
        }
    }

    @media only screen and (max-width: 340px) {
        p {
            font-size: 13px;
        }
    }
`;
