import styled from 'styled-components';

export const StyledRegisterCompetitions = styled.div`
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: #fff;
    border: ${({ bordersize }) => (bordersize ? bordersize : '3px')} solid #3474e8;
    border-radius: 25px;
    padding: 8px 12px;
    margin-top: 12px;
    flex-wrap: wrap;
`;
