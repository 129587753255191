import { StyledText } from '../../atoms/Text/StyledText';
import { StyledInfo } from '../../atoms/TrackHours/StyledInfo';
import { StyledRowInfo } from '../../atoms/TrackHours/StyledRowInfo';
import { StyledSuccesMessage } from '../../atoms/TrackHours/StyledSuccesMessage';

const SuccessMessage = ({ title = '', infos = [] }) => (
    <StyledSuccesMessage>
        <StyledText
            hasdeclaredfontcolor="#6AB95D"
            hasdeclaredpadding="0 0 20px"
            hasdeclaredfontsize="28px"
            hasdeclaredfontweight="700"
            hasdeclaredtextalign="center"
            hasdeclaredlineheight="1.4em"
            hasdeclaredtexttransform="uppercase"
            as="p"
        >
            {title}
        </StyledText>
        <StyledInfo>
            <StyledRowInfo>
                {infos.map(({ title, value }) => (
                    <div key={title}>
                        <StyledText
                            hasdeclaredfontsize="20px"
                            hasdeclaredfontweight="600"
                            hasdeclaredfontcolor="#6786be"
                        >
                            {title}
                        </StyledText>
                        <StyledText hasdeclaredfontsize="20px" hasdeclaredfontweight="600">
                            {value}
                        </StyledText>
                    </div>
                ))}
            </StyledRowInfo>
        </StyledInfo>
    </StyledSuccesMessage>
);

export default SuccessMessage;
