import React, { useMemo, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getString } from "../../../strings";

import useEventRegisterCountdown from "../../../hooks/useEventRegisterCountdown";

import TournamentsCompetitions from "../../molecules/Tournaments/TournamentsCompetitions";

import { StyledText } from "../../atoms/Text/StyledText";
import { StyledButton } from "../../atoms/Button/StyledButton";
import { StyledTournament } from "../../atoms/Tournaments/StyledTournament";
import { StyledLeftTournamentWrapper } from "../../molecules/Tournaments/StyledLeftTournamentWrapper";
import { StyledRightTournamentWrapper } from "../../molecules/Tournaments/StyledRightTournamentWrapper";
import { StyledTournamentContent } from "../../molecules/Tournaments/StyledTournamentContent";
import { StyledTournamentData } from "../../molecules/Tournaments/StyledTournamentData";
import { StyledMapWrapper } from "../../atoms/Tournaments/StyledMapWrapper";
import { StyledTournamentContentWrapper } from "../../atoms/Tournaments/StyledTournamentContentWrapper";
import { StyledRightMapWrapper } from "../../molecules/Tournaments/StyledRightMapWrapper";

import { getUTCDateString } from "../../../logic/eventtime.js";
import { getPlayersForEvent } from "../../../logic/requests/events.js";

import REGISTER_STATUS from "../../constants/register_status.json";

const TournamentElement = ({ event }) => {
    if (!event) event = {};

    const [players, setPlayers] = useState(null);

    const time_start = useMemo(() => getUTCDateString(new Date(event.time_start)), [event]);

    const time_end = useMemo(() => getUTCDateString(new Date(event.time_end)), [event]);

    const [registerStatus, setRegisterStatus] = useState(event.register_status);

    const registerText = useEventRegisterCountdown(event, setRegisterStatus);

    const shouldShowRegister =
        registerStatus === "open" && event?.user_register_status !== REGISTER_STATUS.CONFIRMED;

    useEffect(() => {
        if (!event) return;

        getPlayersForEvent(event.id)
            .then((res) => {
                setPlayers(res);
            })
            .catch((error) => console.error(error));
    }, [event]);

    const limitText = useMemo(
        () => (
            <>
                {players?.confirmed?.length ? (
                    <span style={{ color: "#5FEE5C" }}>{players?.confirmed?.length}</span>
                ) : null}
                {event?.taken_places | players?.unconfirmed?.length ? (
                    <span style={{ color: "#000" }}>|</span>
                ) : null}
                <span>
                    {(event?.taken_places ?? 0) + (players?.unconfirmed?.length ?? 0)}{" "}
                    <span style={{ color: "#000" }}>/</span>{" "}
                    <span style={{ color: "#6786be" }}>{event.max_places}</span>
                </span>{" "}
                {players?.reserve?.length ? (
                    <span style={{ color: "#000" }}> ({players?.reserve?.length})</span>
                ) : null}
            </>
        ),
        [
            players?.confirmed?.length,
            event?.taken_places,
            event.max_places,
            players?.reserve?.length,
        ]
    );

    return (
        <StyledTournament>
            <StyledLeftTournamentWrapper>
                {event?.visible === 1 ? (
                    <Link to={`/competition/${event.code}`}>
                        <StyledText
                            hasdeclaredfontsize="54px"
                            hasdeclaredtexttransform="uppercase"
                            hasdeclaredfontweight="700"
                            hasdeclaredpadding="0 0 24px 0"
                            hascursor="pointer"
                            as="h2"
                        >
                            {event.name}
                        </StyledText>
                    </Link>
                ) : (
                    <StyledText
                        hasdeclaredfontsize="54px"
                        hasdeclaredtexttransform="uppercase"
                        hasdeclaredfontweight="700"
                        hasdeclaredpadding="0 0 24px 0"
                        as="h2"
                    >
                        {event.name}
                    </StyledText>
                )}
            </StyledLeftTournamentWrapper>
            <StyledRightTournamentWrapper>
                <StyledTournamentContent>
                    {event?.has_logo ? (
                        event?.visible === 1 ? (
                            <Link to={`/competition/${event.code}`}>
                                <img src={`/api/events/get-logo/${event.id}/event`} alt="logo" />
                            </Link>
                        ) : (
                            <img src={`/api/events/get-logo/${event.id}/event`} alt="logo" />
                        )
                    ) : (
                        <></>
                    )}
                    <StyledTournamentContentWrapper>
                        <StyledTournamentData>
                            <StyledText hasdeclaredfontcolor="#6786be">{`${getString(
                                "tournament_tournamentElement_data"
                            )}:`}</StyledText>
                            <StyledText>
                                {time_start === time_end
                                    ? time_start
                                    : `${time_start} - ${time_end}`}
                            </StyledText>
                        </StyledTournamentData>
                        <StyledTournamentData>
                            <StyledText hasdeclaredfontcolor="#6786be">
                                {registerStatus === "closed"
                                    ? `${getString("user_panel_tournament_zawodnicy")}:`
                                    : `${getString("tournament_tournamentElement_limit")}:`}
                            </StyledText>
                            <StyledText hasdeclaredfontcolor="#DF7B63">
                                {registerStatus === "closed" ? (
                                    <span style={{ color: "#000" }}>
                                        {event?.taken_places ?? 0}
                                    </span>
                                ) : event?.max_places ? (
                                    registerStatus === "upcoming" ? (
                                        event.max_places
                                    ) : (
                                        limitText
                                    )
                                ) : registerStatus === "upcoming" ? (
                                    getString("tournament_tournamentElement_informacjeWkrotce")
                                ) : (
                                    getString("tournament_tournamentElement_informacjeWkrotce")
                                )}
                            </StyledText>
                        </StyledTournamentData>
                        <StyledTournamentData>
                            <StyledText hasdeclaredfontcolor="#6786be">{`${getString(
                                "tournament_tournamentElement_rejestracja"
                            )}:`}</StyledText>
                            <StyledText hasdeclaredfontcolor="#DF7B63">
                                {(() => {
                                    switch (registerStatus) {
                                        case "upcoming":
                                            return `${getString(
                                                "tournament_tournamentElement_start"
                                            )} ${registerText}`;
                                        case "open":
                                            return `${getString(
                                                "tournament_tournamentElement_koniec"
                                            )} ${registerText}`;
                                        case "closed":
                                            return (
                                                <span style={{ color: "#000" }}>
                                                    {getString(
                                                        "tournament_tournamentElement_zakonczona"
                                                    )}
                                                </span>
                                            );
                                        default:
                                            return getString("tournament_tournamentElement_informacjeWkrotce")
                                    }
                                })()}
                            </StyledText>
                        </StyledTournamentData>
                        <StyledTournamentData>
                            <StyledText hasdeclaredfontcolor="#6786be">{`${getString(
                                "tournament_tournamentElement_konkurencje"
                            )}:`}</StyledText>
                            <StyledText hasdeclaredfontcolor="#DF7B63">
                                {event.competitions && event.competitions.length ? (
                                    <TournamentsCompetitions
                                        competitions={event.competitions}
                                        size="20px"
                                    />
                                ) : (
                                    getString("tournament_tournamentElement_informacjeWkrotce2")
                                )}
                            </StyledText>
                        </StyledTournamentData>
                    </StyledTournamentContentWrapper>
                </StyledTournamentContent>
                <StyledRightMapWrapper>
                    {event?.type?.id === "on-site" &&
                    event?.location_name &&
                    event?.location_address ? (
                        <StyledMapWrapper>
                            <iframe
                                width="100%"
                                height="100%"
                                frameBorder="0"
                                style={{ border: 0 }}
                                src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}&q=${event.location_name}, ${event.location_address}&zoom=5`}
                                allowFullScreen
                            />
                        </StyledMapWrapper>
                    ) : (
                        <></>
                    )}
                    {event?.visible === 1 ? (
                        <StyledButton
                            hasdeclaredwidth="100%"
                            hasdeclaredtextalign="center"
                            whileTap={{ scale: 0.9 }}
                            whileHover={{ scale: 1.05 }}
                        >
                            <Link
                                to={
                                    `/competition/${event.code}` +
                                    (shouldShowRegister ? "/register" : "")
                                }
                                style={{
                                    backgroundColor: shouldShowRegister ? "#ee745c" : "#6d85ba",
                                }}
                            >
                                {shouldShowRegister
                                    ? getString("tournament_tournamentElement_zarejestrujSie")
                                    : getString("tournament_tournamentElement_wiecejInformacji")}
                            </Link>
                        </StyledButton>
                    ) : (
                        <></>
                    )}
                </StyledRightMapWrapper>
            </StyledRightTournamentWrapper>
        </StyledTournament>
    );
};

export default TournamentElement;
