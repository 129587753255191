import React from 'react';
import { getString } from '../../../strings';
import { ReactComponent as Discord } from '../../../images/discordv2.svg';
import { ReactComponent as Phone } from '../../../images/home/telefon2.svg';

import { StyledText } from '../../atoms/Text/StyledText';
import {
    StyledEmailSvg,
    StyledLeftWrapper,
    StyledSvgAndTextWrapper,
    StyledTextWrapper,
} from '../../atoms/Contact/StyledContactForm';
import { StyledButton } from '../../atoms/Button/StyledButton';

const LeftWrapper = ({ isFAQPage, setIsFAQPage }) => {
    return (
        <StyledLeftWrapper
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
        >
            <a aria-label={getString('seo_aria_label_zadzwon')} href="tel:796 340 358">
                <StyledSvgAndTextWrapper>
                    <Phone />
                    <StyledText
                        hasdeclaredfontsize="54px"
                        hasdeclaredfontcolor="#fff"
                        hasdeclaredfontweight="600"
                        hascursor="pointer"
                        as="p"
                    >
                        {getString('contact_leftWrapper_zadzwon')}
                    </StyledText>
                </StyledSvgAndTextWrapper>
                <StyledTextWrapper>
                    <StyledText
                        hasdeclaredfontweight="600"
                        hasdeclaredfontsize="38px"
                        hasdeclaredfontcolor="#6d85ba"
                        hascursor="pointer"
                    >
                        796 340 358
                    </StyledText>
                </StyledTextWrapper>
            </a>
            <a
                aria-label={getString('seo_aria_label_przejdz_do_strony')}
                target="_blank"
                href="https://discord.gg/WKBtNzEFsF"
                rel="noreferrer"
            >
                <div>
                    <Discord width="200px" height="120px" />
                </div>
                <StyledTextWrapper>
                    <StyledText
                        hasdeclaredfontsize="28px"
                        hasdeclaredfontcolor="#6d85ba"
                        hasdeclaredfontweight="600"
                        hascursor="pointer"
                        as="h3"
                    >
                        {getString('contact_leftWrapper_dolaczDoSerwera')}
                    </StyledText>
                </StyledTextWrapper>
            </a>
            <a
                aria-label={getString('seo_aria_label_wyslij_email')}
                href="mailto:kontakt@cube4fun.pl"
                target="_blank"
                rel="noreferrer"
            >
                <StyledText
                    hasdeclaredfontsize="54px"
                    hasdeclaredfontcolor="#fff"
                    hasdeclaredfontweight="600"
                    hascursor="pointer"
                    hasdeclaredtexttransform="uppercase"
                    hasdeclaredmargin="32px 0 18px 0"
                    as="p"
                >
                    <StyledEmailSvg />
                </StyledText>
                <StyledTextWrapper>
                    <StyledText
                        hasdeclaredfontsize="28px"
                        hasdeclaredfontcolor="#6d85ba"
                        hasdeclaredfontweight="600"
                        hascursor="pointer"
                        as="h3"
                    >
                        kontakt@cube4fun.pl
                    </StyledText>
                </StyledTextWrapper>
            </a>
            <StyledButton
                hasdeclaredbgcolor={isFAQPage ? '#602B26' : '#ee745c'}
                hasdeclaredwidth="400px"
                hasdeclaredfontcolor="#fff"
                hasdeclaredtextalign="center"
                hasdeclaredborderradius="25px"
                hasdeclaredheight="50px"
                hasdeclaredfontsize="32px"
                hasdeclaredfontweight="700"
                hasdeclaredmargin="10px 0 0 0"
                hasdeclaredcursor="pointer"
                onClick={() => setIsFAQPage(!isFAQPage)}
            >
                FAQ
            </StyledButton>
        </StyledLeftWrapper>
    );
};

export default LeftWrapper;
