import backendRequest from ".";

export const listHoursRecords = (month) =>
    backendRequest(
        "GET",
        `/employees/admin/list-hours-records${month ? `?group_by=${month}` : ""}`
    );

export const submitHoursRecord = (data) =>
    backendRequest("POST", "/employees/submit-hours-record", data, "multipart/form-data");

export const listTrainersEmployeeConf = () =>
    backendRequest("GET", "/employees/admin/list-trainers");

export const listInactiveTrainers = () => 
    backendRequest("GET", "/employees/admin/list-inactive-trainers");

export const addEmployeeConf = (data) =>
    backendRequest("POST", "/employees/admin/add-employee-conf", data);

export const listCompanies = () => backendRequest("GET", "/employees/admin/list-companies");

export const getContractTypes = () => backendRequest("GET", "/employees/admin/list-contract-types");

export const editTrainer = (id, data) =>
    backendRequest("PUT", `/employees/admin/edit-trainer/${id}`, data);

export const createContract = (data) => backendRequest("POST", "/employees/admin/create-contract", data, "multipart/form-data");

export const listContracts = () => backendRequest("GET", "/employees/admin/list-contracts");

export const deleteContract = (id) => backendRequest("DELETE", `/employees/admin/delete-contract/${id}`);

export const updateContract = (id, data) =>
    backendRequest("PUT", `/employees/admin/update-contract/${id}`, data, "multipart/form-data");