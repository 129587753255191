import React from 'react';

import { ErrorMessage } from 'formik';
import { StyledText } from '../../atoms/Text/StyledText';
import { StyledError } from '../../../components/atoms/Register/StyledError';

const RegisterErrorMessage = ({ name, hasDeclaredBottomPosition }) => {
    return (
        <ErrorMessage name={name}>
            {(errorMsg) => (
                <StyledError
                    key={errorMsg}
                    initial={{ opacity: 0, x: '100px' }}
                    animate={{
                        opacity: 1,
                        x: 0,
                    }}
                    exit={{ opacity: 0 }}
                    hasdeclaredbottomposition={hasDeclaredBottomPosition}
                >
                    <StyledText
                        hasdeclaredfontsize="16px"
                        hasdeclaredfontcolor="#F74017"
                        hasdeclaredtextalign="center"
                        hasdeclaredfontweight="bold"
                        hasdeclaredlineheight="1.3em"
                        as="h4"
                    >
                        {errorMsg}
                    </StyledText>
                </StyledError>
            )}
        </ErrorMessage>
    );
};

export default RegisterErrorMessage;
