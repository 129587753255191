import React, { useMemo, useState, useCallback } from 'react';
import Decimal from 'decimal.js';
import { ScrollSyncPane } from 'react-scroll-sync';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
    StyledAdditionDataElement,
    StyledAdditionDataTextElement,
    StyledFuckingBtn,
} from './AdditionDataElement.styles';

import { StyledEditWrapper } from '../../atoms/StyledEditWrapper/StyledEditWrapper';
import { StyledEditInputElement, StyledClosePayElement } from '../../../styles/sharedStyles';
import { StyledText } from '../../atoms/Text/StyledText';
import { StyledSavePayElement } from '../../atoms/PayElement/StyledSavePayElement';
import { StyledGlobalUserPanelWrapper } from '../../atoms/StyledGlobalUserPanelWrapper/StyledGlobalUserPanelWrapper';
import { useQueryClient } from '@tanstack/react-query';
import CloseIcon from '../../atoms/UserPanelStudens/CloseIcon';
import PencilIcon from '../../atoms/UserPanelStudens/PencilIcon';
import AcceptIcon from '../../atoms/UserPanelStudens/AcceptIcon';

import { editAddition, deleteAddition } from '../../../logic/requests/students';
import { formSchema } from './AddotonDataElement.data';
import PaymentsPopup from '../../organisms/Payments/PaymentsPopup';
import { getString } from '../../../strings';

const AdditionDataElement = ({ id, trainer, amount, description, allTrainers }) => {
    const queryClient = useQueryClient();
    const [isEdit, setIsEdit] = useState(false);
    const [confirmPopup, setConfirmPopup] = useState(false);

    const displayAmount = useMemo(() => {
        const value = new Decimal(amount);

        return `${value.dp() ? value.toFixed(2) : value.toString()} PLN`;
    }, [amount]);

    const {
        register,
        handleSubmit,
        formState: { errors, isDirty },
    } = useForm({
        resolver: yupResolver(formSchema),
        defaultValues: {
            trainer: trainer.id,
            amount: amount,
            description: description,
        },
    });

    const onSubmit = useCallback(
        (data) => {
            if (isDirty) {
                editAddition(id, data)
                    .then(() => {
                        queryClient.invalidateQueries({ queryKey: ['additions', 'all'] });
                        setIsEdit(false);
                    })
                    .catch();
            } else {
                setIsEdit(false);
            }
        },
        [isDirty, id, setIsEdit, queryClient]
    );

    const handleDelete = useCallback(() => {
        if (!confirmPopup) setConfirmPopup(true);

        if (confirmPopup)
            deleteAddition(id).then(() => {
                queryClient.invalidateQueries({ queryKey: ['additions', 'all'] });
                setConfirmPopup(false);
            });
    }, [id, queryClient, confirmPopup]);

    const retEditButton = useMemo(
        () =>
            isEdit ? (
                <StyledSavePayElement type="submit" isedit={isEdit}>
                    <AcceptIcon />
                </StyledSavePayElement>
            ) : (
                <StyledFuckingBtn as="button" isedit={isEdit} onClick={() => setIsEdit(true)}>
                    <PencilIcon />
                </StyledFuckingBtn>
            ),
        [isEdit]
    );

    return (
        <StyledAdditionDataElement>
            {confirmPopup ? (
                <PaymentsPopup
                    title={getString('addition_data_element_czy_na_pewno')}
                    onClose={() => setConfirmPopup(false)}
                    cancelTag={getString('addition_data_element_usun')}
                    onCancel={handleDelete}
                />
            ) : null}
            <form onSubmit={handleSubmit(onSubmit)}>
                <ScrollSyncPane>
                    <div>
                        {isEdit ? (
                            <StyledEditWrapper>
                                <StyledEditInputElement
                                    error={errors.trainer}
                                    $hasDeclaredWidth="40%"
                                >
                                    <select defaultValue={trainer.id} {...register('trainer')}>
                                        {allTrainers.map((student) => (
                                            <option key={student.id} value={student.id}>
                                                {student.name}
                                            </option>
                                        ))}
                                    </select>
                                </StyledEditInputElement>
                                <StyledEditInputElement
                                    error={errors.amount}
                                    $hasDeclaredWidth="20%"
                                >
                                    <input type="number" step="0.01" {...register('amount')} />
                                </StyledEditInputElement>
                                <StyledEditInputElement
                                    error={errors.description}
                                    $hasDeclaredWidth="40%"
                                >
                                    <input type="text" {...register('description')} />
                                </StyledEditInputElement>
                            </StyledEditWrapper>
                        ) : (
                            <StyledGlobalUserPanelWrapper>
                                <StyledAdditionDataTextElement $hasDeclaredWidth="40%">
                                    <StyledText
                                        hasdeclaredfontsize="13px"
                                        hasdeclaredfontweight="600"
                                        hasdeclaredtextalign="center"
                                    >
                                        {trainer.firstname} {trainer.lastname}
                                    </StyledText>
                                </StyledAdditionDataTextElement>
                                <StyledAdditionDataTextElement $hasDeclaredWidth="20%">
                                    <StyledText
                                        hasdeclaredfontsize="13px"
                                        hasdeclaredfontweight="600"
                                        hasdeclaredtextalign="center"
                                    >
                                        {displayAmount}
                                    </StyledText>
                                </StyledAdditionDataTextElement>
                                <StyledAdditionDataTextElement $hasDeclaredWidth="40%">
                                    <StyledText
                                        hasdeclaredfontsize="13px"
                                        hasdeclaredfontweight="600"
                                        hasdeclaredtextalign="center"
                                    >
                                        {description}
                                    </StyledText>
                                </StyledAdditionDataTextElement>
                            </StyledGlobalUserPanelWrapper>
                        )}
                    </div>
                </ScrollSyncPane>
                {retEditButton}
                <StyledClosePayElement type="button" onClick={handleDelete}>
                    <CloseIcon />
                </StyledClosePayElement>
            </form>
        </StyledAdditionDataElement>
    );
};

export default AdditionDataElement;
