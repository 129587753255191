import styled from 'styled-components';

export const StyledHourWrapper = styled.div`
    width: 100%;

    @media only screen and (max-width: 926px) {
        display: flex;
        flex-direction: column-reverse;
    }
`;
