import React, { useRef } from 'react';

const LoadingImage = ({ loadingCallback, ...props }) => {
    const newRef = useRef(),
        ref = props?.ref ?? newRef,
        onLoad = () => {
            loadingCallback?.();

            if (props.onLoad) props.onLoad();
        },
        onError = () => {
            loadingCallback?.();

            if (props.onError) props.onError();
        };
    return (
        /* <img> zawsze musi tu być zwrócony - nie zacznie się ładować, jeśli nie jest częścią DOM'u */
        <img ref={ref} onLoad={onLoad} onError={onError} {...props} />
    );
};

export default LoadingImage;
