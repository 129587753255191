import styled from 'styled-components';

export const StyledMaxWidthCell = styled.div`
    width: calc(100% - 128px);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ee745c;
    border: 2px solid #000;
    border-radius: 10px;

    @media only screen and (max-width: 1355px) {
        > p {
            font-size: 14px;
        }
    }

    @media only screen and (max-width: 1179px) {
        width: calc(100% - 98px);
    }

    @media only screen and (max-width: 1061px) {
        > p {
            font-size: 12px;
        }
    }

    @media only screen and (max-width: 1017px) {
        width: calc(100% - 78px);

        > p {
            font-size: 10px;
        }
    }

    @media only screen and (max-width: 871px) {
        width: calc(100% - 68px);

        > p {
            font-size: 8px;
        }
    }

    @media only screen and (max-width: 767px) {
        width: calc(100% - 128px);

        > p {
            font-size: 16px;
        }
    }

    @media only screen and (max-width: 689px) {
        > p {
            font-size: 14px;
        }
    }

    @media only screen and (max-width: 600px) {
        width: 100%;
        min-height: 60px;

        > p {
            font-size: 16px;
        }
    }

    @media only screen and (max-width: 519px) {
        min-height: 40px;

        > p {
            font-size: 12px;
        }
    }

    @media only screen and (max-width: 411px) {
        min-height: 35px;
    }
`;
