import styled from 'styled-components';

export const StyledDataWrapper = styled.div`
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    padding-bottom: 12px;

    @media only screen and (max-width: 1084px) {
        p {
            font-size: 16px;
        }
    }
`;
