import styled from 'styled-components';

export const StyledCookiesPopUp = styled.div`
    width: 100%;
    background: rgba(0, 0, 0, 0.72);
    position: fixed;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 10px 20px;
    z-index: 100;

    @media only screen and (max-width: 1623px) {
        flex-direction: column;

        > button {
            padding-left: 0px;
        }
    }
`;
