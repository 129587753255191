import styled from 'styled-components';

export const StyledWCAIDInformation = styled.div`
    width: 100%;
    margin-top: 12px;
    position: relative;

    > div {
        position: absolute;
        z-index: 2;
        top: -220px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: #fff;
        border: 6px solid #3474e8;
        border-radius: 25px;
        padding: 12px;
        width: 330px;

        > div {
            display: flex;
            flex-direction: column;
            width: 100%;
            align-items: center;

            > button {
                width: 90%;
            }
        }
    }

    @media only screen and (max-width: 380px) {
        > div {
            width: ${({ isusereditpanel }) => (isusereditpanel ? '100%' : '330px')};
        }
    }

    @media only screen and (max-width: 350px) {
        > div {
            width: ${({ isusereditpanel }) => (isusereditpanel ? '100%' : '300px')};
        }
    }
`;
