const PAGEPANEL = {
    EVENTS: 'competitions',
    USERDATA: 'edit',
    REQ: 'analysis',
    REQ_TRAINER: 'orders',
    LESSONS: 'lessons',
    PAYMENTS: 'payments',
    USERS: 'users',
    USERS_NEWSLETTER: 'newsletter',
    STUDENTS: 'students',
    STUDENTS_LIST: 'list',
    FORMER_STUDENTS_LIST: 'former-list',
    STUDENTS_PREFS: 'preferences',
    TRAINER_TRACK_HOURS: 'track-hours',
    TRAINER_PAY: 'pay',
    ALL_PAY: 'all-pay',
    ADD_ROLE: 'add-role',
    STUDENTS_PAYMENTS: 'students-payments',
    SUMMARY: 'summary',
    ADDITIONS: 'additions',
    EMPLOYE_CONFIGURATION: 'employee-configuration',
    SUBJECTS: 'subject',
    EMPLOYEES: 'employees',
    TRAINERS: 'trainers',
    MANAGE_CONTRACTS: 'manage-contracts',
    ALL_EVENTS: 'all-events',
    DIPLOMAS: 'diplomas',
    ANNOUNCEMENTS: "announcements"
};

export { PAGEPANEL };
