import styled from 'styled-components';

export const StyledRightMapWrapper = styled.div`
    a {
        text-decoration: none;
        color: #fff;
        text-transform: uppercase;
        display: block;
        padding: 8px 22px;
        background-color: #6786be;
        font-weight: 600;
        border-radius: 25px;
        margin-top: 20px;
    }

    @media only screen and (max-width: 1064px) {
        a {
            font-size: 14px;
        }
    }

    @media only screen and (max-width: 796px) {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        a {
            margin-top: 34px;
        }
    }
`;
