import { useState, useEffect, useCallback, useMemo } from 'react';
import Decimal from 'decimal.js';

import Loader from '../Loader/Loader';
import PayAllElement from './PayAllElement';

import { StyledText } from '../../atoms/Text/StyledText';
import { StyledUserPanelTrainerPay } from '../../atoms/UserPanelTrainerPay/StyledUserPanelTrainerPay';
import { StyledPayWrapper } from '../../atoms/UserPanelTrainerPay/StyledPayWrapper';
import { StyledContent } from '../../atoms/UserPanelTrainerPay/StyledContent';
import { StyledButton } from '../../atoms/Button/StyledButton';
import { StyledSumElement } from '../../atoms/UserPanelTrainerPay/StyledSumElement';
import { StyledSumRow } from '../../atoms/UserPanelTrainerPay/StyledSumRow';
import { StyledButtonWrapper } from '../../atoms/UserPanelTrainerPay/StyledButtonWrapper';
import { StyledLoaderWrapper, StyledGlobalHeadingWrapper } from '../../../styles/sharedStyles';

import { listAllPay } from '../../../logic/requests/students';
import { getString } from '../../../strings';
import { taxPercent } from '../../constants/pay';
import useScrollBar from '../../../logic/hooks/useScrollBar';
import UserPanelHeadingText from '../../atoms/UserPanel/UserPanelHeadingText';
import { useQuery } from '@tanstack/react-query';

const UserPanelTrainerPay = () => {
    const { isScrollBar, scrollElement, trigger } = useScrollBar(true);
    const [month, setMonth] = useState('');

    const [hasMore, setHasMore] = useState(true);
    const [err, setErr] = useState(false);
    const [isCheckboxes, setIsCheckboxes] = useState(false);

    const { data: hours, refetch } = useQuery(['listAllPay'], async () => {
        const res = await listAllPay(month);
        if (res.hasNoMore) {
            setHasMore(false);
        } else {
            return {
                ...(hours ?? {}),
                ...res,
            };
        }
        if (!hours) {
            setErr(true);
            return [];
        }
        return {
            ...(hours ?? {}),
            ...res,
        };
    });

    const [lastMonth, setLastMonth] = useState(
        (() => {
            const months = Object.keys(hours ?? {});

            if (months.length) {
                const [year, month] = months[months.length - 1].split('-');
                const monthNum = parseInt(month);

                return monthNum === 0
                    ? `${parseInt(year) - 1}-12`
                    : `${year}-${String(monthNum).padStart(2, '0')}`;
            } else {
                const now = new Date();
                const [year, month] = [now.getFullYear(), now.getMonth() + 2];
                return `${year}-${String(month).padStart(2, '0')}`;
            }
        })()
    );

    const loadMonth = useCallback(() => {
        const [year, month] = lastMonth.split('-').map(Number);
        let newYear = year;
        let newMonth = month - 1;
    
        if (newMonth === 0) {
            newYear -= 1;
            newMonth = 12;
        }
    
        const next = `${newYear}-${String(newMonth).padStart(2, '0')}`;
    
        setLastMonth(next);
        setMonth(next);
        refetch();
    }, [lastMonth, refetch]);
    

    const revenue = useMemo(
        () =>
            hours && Object.keys(hours).length
                ? Object.values(Object.values(hours)[0])
                      .map((obj) => obj.price)
                      .reduce((prev, cur) => prev.plus(cur), new Decimal(0))
                : undefined,
        [hours]
    );

    const hoursAmount = useMemo(
        () =>
            hours && Object.keys(hours).length
                ? Object.values(Object.values(hours)[0]).reduce(
                      (prev, cur) => prev.plus(cur.hours),
                      new Decimal(0)
                  )
                : undefined,
        [hours]
    );

    const paidHours = useMemo(
        () =>
            hours && Object.keys(hours).length
                ? Object.values(Object.values(hours)[0]).reduce(
                      (prev, cur) => prev.plus(cur.paidHours),
                      new Decimal(0)
                  )
                : undefined,
        [hours]
    );

    const halfHours = useMemo(
        () =>
            hours && Object.keys(hours).length
                ? Object.values(Object.values(hours)[0]).reduce(
                      (prev, cur) => prev.plus(cur.halfHours),
                      new Decimal(0)
                  )
                : undefined,
        [hours]
    );

    const free = useMemo(
        () =>
            hours && Object.keys(hours).length
                ? Object.values(Object.values(hours)[0])
                      .map((obj) => obj.free)
                      .reduce((prev, cur) => prev.plus(cur), new Decimal(0))
                : undefined,
        [hours]
    );

    const trainerPayWithoutAdmin = useMemo(
        () =>
            hours && Object.keys(hours).length
                ? Object.values(Object.values(hours)[0]).reduce(
                      (prev, cur) => prev.plus(cur.is_admin ? 0 : cur.pay),
                      new Decimal(0)
                  )
                : undefined,
        [hours]
    );

    const income = useMemo(
        () =>
            revenue && trainerPayWithoutAdmin ? revenue.minus(trainerPayWithoutAdmin) : undefined,
        [revenue, trainerPayWithoutAdmin]
    );

    const revenueAfterTaxes = useMemo(
        () =>
            income
                ? income.times(new Decimal(1).minus(new Decimal(taxPercent).dividedBy(100)))
                : undefined,
        [income]
    );

    return (
        <StyledUserPanelTrainerPay>
            {!hours ? (
                <StyledLoaderWrapper>
                    <Loader />
                </StyledLoaderWrapper>
            ) : !Object.keys(hours).length ? (
                <StyledLoaderWrapper>
                    <StyledText
                        hasdeclaredpadding="0px 20px"
                        hasdeclaredfontsize="22px"
                        hasdeclaredfontweight="700"
                        hasdeclaredtextalign="center"
                        hasdeclaredlineheight="1.4em"
                        as="p"
                    >
                        {getString(err ? 'blad_wczytywania' : 'UserPanelTrainerPay__no_hours')}
                    </StyledText>
                </StyledLoaderWrapper>
            ) : (
                <>
                    <StyledSumElement>
                        <StyledSumRow iscentertext>
                            <StyledText
                                hasdeclaredfontsize="30px"
                                hasdeclaredfontweight="600"
                                hasdeclaredlineheight="1.4em"
                                hasdeclaredfontcolor="#6786BE"
                                hasdeclaredpadding="0 0 20px 0"
                            >
                                {getString('user_panel_all_trainer_pay_przychod')}:
                            </StyledText>
                            <StyledText
                                hasdeclaredfontsize="30px"
                                hasdeclaredfontweight="600"
                                hasdeclaredlineheight="1.4em"
                                hasdeclaredpadding="0 0 20px 0"
                                hasdeclaredtextalign="center"
                            >
                                {revenue &&
                                    (revenue.dp() > 0
                                        ? revenue.toFixed(2)
                                        : revenue.toString())}{' '}
                                PLN
                            </StyledText>
                        </StyledSumRow>
                        <StyledSumRow iscentertext>
                            <StyledText
                                hasdeclaredfontsize="18px"
                                hasdeclaredfontweight="600"
                                hasdeclaredlineheight="1.4em"
                                hasdeclaredfontcolor="#6786BE"
                            >
                                {getString('user_panel_all_trainer_pay_godzin')}:
                            </StyledText>
                            <StyledText
                                hasdeclaredfontsize="18px"
                                hasdeclaredfontweight="600"
                                hasdeclaredlineheight="1.4em"
                                hasdeclaredtextalign="center"
                            >
                                {paidHours.toString()} (D: {free.toString()} | P:{' '}
                                {halfHours.toString()} | Ł: {hoursAmount.toString()})
                            </StyledText>
                        </StyledSumRow>
                        <StyledSumRow iscentertext>
                            <StyledText
                                hasdeclaredfontsize="18px"
                                hasdeclaredfontweight="600"
                                hasdeclaredlineheight="1.4em"
                                hasdeclaredfontcolor="#6786BE"
                            >
                                {getString('user_panel_all_trainer_pay_wyplaty')}:
                            </StyledText>
                            <StyledText
                                hasdeclaredfontsize="18px"
                                hasdeclaredfontweight="600"
                                hasdeclaredlineheight="1.4em"
                                hasdeclaredtextalign="center"
                            >
                                {trainerPayWithoutAdmin.dp() > 0
                                    ? trainerPayWithoutAdmin.toFixed(2)
                                    : trainerPayWithoutAdmin.toString()}
                            </StyledText>
                        </StyledSumRow>
                        <StyledSumRow iscentertext>
                            <StyledText
                                hasdeclaredfontsize="18px"
                                hasdeclaredfontweight="600"
                                hasdeclaredlineheight="1.4em"
                                hasdeclaredfontcolor="#6786BE"
                            >
                                {getString('user_panel_all_trainer_pay_dochod')}:
                            </StyledText>
                            <StyledText
                                hasdeclaredfontsize="18px"
                                hasdeclaredfontweight="600"
                                hasdeclaredlineheight="1.4em"
                                hasdeclaredtextalign="center"
                            >
                                {income.dp() > 0 ? income.toFixed(2) : income.toString()} (
                                {revenueAfterTaxes.dp() > 0
                                    ? revenueAfterTaxes.toFixed(2)
                                    : revenueAfterTaxes.toString()}
                                ) PLN
                            </StyledText>
                        </StyledSumRow>
                    </StyledSumElement>
                    <StyledContent>
                        <StyledGlobalHeadingWrapper>
                            <UserPanelHeadingText text="Wynagrodzenie:" />
                        </StyledGlobalHeadingWrapper>
                        <StyledPayWrapper isscrollbar={isScrollBar} ref={scrollElement}>
                            <PayAllElement
                                hours={hours}
                                trigger={trigger}
                                showParentCheckboxes={isCheckboxes}
                            />
                        </StyledPayWrapper>
                    </StyledContent>
                </>
            )}
            {hasMore ? (
                <StyledButtonWrapper haspadding>
                    <StyledButton onClick={loadMonth}>
                        <StyledText
                            hasdeclaredfontsize="22px"
                            hasdeclaredfontweight="600"
                            hascursor="pointer"
                        >
                            {getString('Trainings_trainings_all_trainer_pay_zaladujWiecej')}
                        </StyledText>
                    </StyledButton>
                </StyledButtonWrapper>
            ) : (
                <StyledText
                    hasdeclaredfontsize="22px"
                    hasdeclaredfontweight="600"
                    hasdeclaredpadding="20px 0"
                    hasdeclaredtextalign="center"
                >
                    {getString('Trainings_trainings_all_trainer_pay_brakKolejnychMiesiecy')}
                </StyledText>
            )}
        </StyledUserPanelTrainerPay>
    );
};

export default UserPanelTrainerPay;
