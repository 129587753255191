import styled from 'styled-components';

export const StyledSeriesCubes = styled.div`
    width: 40%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    overflow: hidden;

    > svg {
        width: 200px;

        &:first-child {
            top: 90px;
            left: 30px;
        }

        &:nth-child(2) {
            top: 400px;
            left: 80px;
            width: 250px;
        }
    }

    @media only screen and (min-width: 1440px) {
        > svg {
            width: 260px;
            &:nth-child(2) {
                top: 600px;
                width: 320px;
            }
        }
    }

    @media only screen and (max-width: 1100px) {
        display: none;
    }
`;
