// https://stackoverflow.com/a/47349064

const toString = (obj) => {
    //ECMA specification: http://www.ecma-international.org/ecma-262/6.0/#sec-tostring

    if (obj === null) return "null";

    if (typeof obj === "boolean" || typeof obj === "number") return obj.toString();

    if (typeof obj === "string") return obj;

    if (typeof obj === "symbol") throw new TypeError();

    //we know we have an object. perhaps return JSON.stringify?
    return obj.toString();
};

// domyślna funkcja sortowania tablic
const defaultCompare = (x, y) => {
    //INFO: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort
    //ECMA specification: http://www.ecma-international.org/ecma-262/6.0/#sec-sortcompare

    if (x === undefined && y === undefined) return 0;

    if (x === undefined) return 1;

    if (y === undefined) return -1;

    const xString = toString(x),
        yString = toString(y);

    if (xString < yString) return -1;

    if (xString > yString) return 1;

    return 0;
};

const cebulaCompare = (x, y) => {
    //INFO: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort
    //ECMA specification: http://www.ecma-international.org/ecma-262/6.0/#sec-sortcompare

    if (x === undefined && y === undefined) return 0;

    if (x === undefined) return 1;

    if (y === undefined) return -1;

    const xString = toString(x),
        yString = toString(y);

    return xString.localeCompare(yString, "pl");
};

const dateKeyCompare = ([a], [b]) => {
    const a_split = a.split("-"),
        b_split = b.split("-");

    const year = parseInt(b_split[0]) - parseInt(a_split[0]);
    if (year) return year;

    const month = parseInt(b_split[1]) - parseInt(a_split[1]);
    return month;
};

export { cebulaCompare, defaultCompare, dateKeyCompare };
