import styled from 'styled-components';

export const StyledTournamentData = styled.div`
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;

    p {
        font-size: 20px;
        padding: 8px 8px 8px 0;
    }

    @media only screen and (max-width: 1017px) {
        p {
            font-size: 18px;
            padding: 6px 8px 6px 0;
        }
    }

    @media only screen and (max-width: 450px) {
        p {
            font-size: 16px;
        }
    }
`;
